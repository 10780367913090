import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import MapIcon from '@mui/icons-material/Map';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import api from '../utils/api.ts';
import { getNewNotifications } from '../utils/getters';
import { patchNotification } from '../utils/utils';
import AuthContext from '../context/AuthContext';
import Layout from '../components/ui/Layout.tsx';

import { setFriendsRequests } from '../features/network/networkSlice';
import {
  setAlertsSnackbarOpen,
  setAlertsSnackbarSeverity,
  setAlertsSnackbarText,
} from '../features/app/alertsSnackbarSlice';
import { addSavedEvent } from '../features/events/savedEventsSlice';

import {
  DRAWER_WIDTH,
  WIDTH_BREAKPOINT,
} from '../theme';

export default function FriendRequest() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const width = useSelector((state) => state.app.width);

  const { uuid } = useParams();
  const { user } = useContext(AuthContext);

  const [friendRequest, setFriendRequest] = useState(null);
  const [friendRequestNotFound, setFriendRequestNotFound] = useState(null);
  const [notLoggedIn, setNotLoggedIn] = useState(null);
  const [backdropOpen, setBackdropOpen] = useState(true);

  const friendRequests = useSelector((state) => state.network.friendsRequests);

  const [actionButtonDisabled, setActionButtonDisabled] = useState(false);
  const [showActionButtons, setShowActionButtons] = useState(true);
  const [showMapButton, setShowMapButton] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const notificationUuid = searchParams.get('notification');

  const handleAction = async (e, uuid, action) => {
    setBackdropOpen(true);
    setActionButtonDisabled(true);
    dispatch(setFriendsRequests(friendRequests.filter(el => el.uuid !== uuid)));
    await api.post('/users/friends/requests/', {
      action: action,
      uuid: uuid,
    }).then(response => {
      getNewNotifications(dispatch);
      setShowMapButton(true);
      setBackdropOpen(false);
      if (action === 'accept') {
        dispatch(setAlertsSnackbarSeverity('success'));
        dispatch(setAlertsSnackbarText(t('Friend request accepted!')));
      } else if (action === 'reject') {
        dispatch(setAlertsSnackbarSeverity('info'));
        dispatch(setAlertsSnackbarText(t('Friend request rejected!')));
      };
      dispatch(setAlertsSnackbarOpen(true));
      setShowActionButtons(false);
      if (response.data) { dispatch(addSavedEvent(response.data)) };
    }).catch(err => {
      setShowMapButton(true);
      setBackdropOpen(false);
      if (err.response.status === 404) {
        dispatch(setAlertsSnackbarSeverity('error'));
        dispatch(setAlertsSnackbarText(t('No such friend request!')));
        dispatch(setAlertsSnackbarOpen(true));
      };
    });
  };

  const fetchData = async () => {
    setBackdropOpen(true);
    await api.get(`/friend-requests/${uuid}/`).then(response => {
      setFriendRequest(response.data);
      setFriendRequestNotFound(false);
      setBackdropOpen(false);
    }).catch(err => {
      if (err.response.status === 401) {
        setNotLoggedIn(true);
      } else if (err.response.status === 404) {
        setFriendRequestNotFound(true);
      };
      setShowMapButton(true);
      setBackdropOpen(false);
    });
  };

  useEffect(() => {
    fetchData();
    patchNotification(dispatch, notificationUuid);

    if (!user) {
      setFriendRequest(null);
      setNotLoggedIn(true);
      // setFriendRequestNotFound(null);
    };
  }, [user?.username]);

  if (friendRequest) {
    return (
      <React.Fragment>
        <Layout>
          <Box sx={{ flexGrow: 1, paddingLeft: width > WIDTH_BREAKPOINT && `${DRAWER_WIDTH + 16}px` }}>
            <Grid container spacing={2}>
              <Grid item xs={1} />
              <Grid item xs={10}>
                <Grid container spacing={2}>
                  <Grid item xs={2} />
                  <Grid item xs={8}>
                    <Typography variant="h5" gutterBottom mt={2} align="center">
                      {friendRequest.username}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    {friendRequest.status === 0 && showActionButtons && (
                      <Stack direction="row" spacing={0}>
                        <IconButton
                          disabled={actionButtonDisabled}
                          aria-label="clear" color="error"
                          onClick={(e) => handleAction(e, friendRequest.uuid, 'reject')}>
                          <ClearIcon />
                        </IconButton>
                        <IconButton
                          disabled={actionButtonDisabled}
                          aria-label="check" color="success"
                          onClick={(e) => handleAction(e, friendRequest.uuid, 'accept')}>
                          <CheckIcon />
                        </IconButton>
                      </Stack>
                    )}
                  </Grid>
                </Grid>
                <Typography variant="body2" gutterBottom mt={2} align="center">
                  {friendRequest.created_on}
                </Typography>
                {showMapButton && (
                  <Box align="center" mt={4}>
                    <Button href="/" variant="contained" startIcon={<MapIcon />}>
                      {t('Go to map')}
                    </Button>
                  </Box>
                )}
              </Grid>
              <Grid item xs={1} />
            </Grid>
          </Box>
        </Layout>
      </React.Fragment>
    );
  } else if (friendRequestNotFound) {
    return (
      <Layout>
        <Alert severity="warning">
          {t('Friend request not found. Please double check the url.')}
        </Alert>
        {showMapButton && (
          <Box align="center" mt={4}>
            <Button href="/" variant="contained" startIcon={<MapIcon />}>
              {t('Go to map')}
            </Button>
          </Box>
        )}
      </Layout>
    )
  } else if (notLoggedIn) {
    return (
      <Layout>
        <Alert severity="warning">
          {t('Please log in to see this friend request.')}
        </Alert>
        {showMapButton && (
          <Box align="center" mt={4}>
            <Button href="/" variant="contained" startIcon={<MapIcon />}>
              {t('Go to map')}
            </Button>
          </Box>
        )}
      </Layout>
    )
  }
  else {
    return (
      <Layout>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, top: '8vh' }}
          open={backdropOpen}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Layout>
    );
  };
};