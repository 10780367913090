import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import dayjs from 'dayjs';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import { APP_NAME } from '../../config';
import { APP_VERSION } from '../../version.ts';

import { setChangelogDialogOpen } from '../../features/app/dialogsSlice';

import AuthContext from '../../context/AuthContext';

import ChangelogDialog from './ChangelogDialog.tsx';

const StyledTypography = styled(Typography)`
  font-size: 10px;
  display: block;
`;

const Wrapper = styled(Box)`
  position: absolute;
  width: 100%;
`;

interface VersionInfoProps {
  bottom: string | number;
};

const VersionInfo: React.FC<VersionInfoProps> = ({ bottom }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useContext(AuthContext);

  const handleClick = () => {
    if (user) {
      dispatch(setChangelogDialogOpen(true));
    };
  };

  return (
    <>
      <Wrapper
        id="version-info"
        onClick={handleClick}
        sx={{
          bottom: bottom,
          cursor: user ? 'pointer' : undefined,
        }}
      >
        <Divider />
        <Box p={1}>
          <StyledTypography variant="caption">
            {APP_NAME} © {dayjs().year()}
          </StyledTypography>
          <StyledTypography variant="caption">
            {t('Version')}: {APP_VERSION}
          </StyledTypography>
          <StyledTypography variant="caption">
            {t('All rights reserved.')}
          </StyledTypography>
        </Box>
      </Wrapper>
      <ChangelogDialog />
    </>
  );
};

export default VersionInfo;