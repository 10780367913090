import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { isMobile } from 'react-device-detect';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { setWithinMeters } from '../../features/settings/settingsSlice';

import AuthContext from '../../context/AuthContext';

import {
  PREMIUM_RANGE,
  RANGES,
} from '../../consts/map';
import {
  backdropBlur,
  DRAWER_WIDTH,
  fabBoxShadow,
  navbarBoxShadow,
  WIDTH_BREAKPOINT,
  yellowBoxShadow,
} from '../../theme';
import {
  getFabSize,
  vhToPx,
} from '../../utils/utils';

interface Action {
  icon: JSX.Element;
  name: string;
  value: number;
}

const StyledTypography = styled(Typography)`
  text-transform: lowercase;
  pointer-events: none;
`;

interface RangeSpeedDialProps {
  centerGeolocator: () => void;
  handleChange: (range: number) => void;
  handleChangeCommited: (range: number) => void;
};

const RangeSpeedDial: React.FC<RangeSpeedDialProps> = ({
  centerGeolocator,
  handleChange,
  handleChangeCommited,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { user } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const withinMeters = useSelector((state: any) => state.settings.withinMeters);
  const width = useSelector((state: any) => state.app.width);
  const [range, setRange] = useState(withinMeters);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const anyDialogOpen = useSelector((state: any) => state.dialogs.anyDialogOpen);
  const [ranges, setRanges] = useState(RANGES);
  const plan = useSelector((state: any) => state.account.plan);
  const shouldCenterRef = useRef(false);

  const actions: Action[] = ranges.map(r => ({
    icon: (
      <StyledTypography variant="body2" data-name={`${r}m`}>
        {r}m
      </StyledTypography>
    ),
    name: `${r}m`,
    value: r,
  }));

  const [selectedAction, setSelectedAction] = useState<Action | null | undefined>(actions.find((a) => a.value === withinMeters));

  const handleOpen = () => {
    setOpen(true);
  };

  const setSpeedDialUsed = () => {
    const speedDialUsed = JSON.parse(localStorage.getItem('speedDialUsed') || 'false');
    if (!speedDialUsed) {
      localStorage.setItem('speedDialUsed', 'true');
      setTooltipOpen(false);
    };
  };

  const handleClick = () => {
    setSpeedDialUsed();
    if (!isMobile) setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLongPress = () => {
    setSpeedDialUsed();
    handleOpen();
  };

  const handleDragEnter = (action: Action) => {
    handleChange(action.value);
    setSelectedAction(action);
  };

  const handleDragLeave = () => {
    setSelectedAction(null);
  };

  const handleActionRelease = () => {
    if (selectedAction) {
      handleChangeCommited(selectedAction.value);
      setRange(selectedAction.value);
      shouldCenterRef.current = true;
    };
    handleClose();
  };

  const handleTouchMove = (event: React.TouchEvent) => {
    const touch = event.touches[0];
    const elem = document.elementFromPoint(touch.clientX, touch.clientY);

    if (elem && (
      (elem.tagName === 'SPAN' && elem.parentElement?.tagName === 'BUTTON') ||
      elem.tagName === 'BUTTON'
    )) {
      const actionName = elem.getAttribute('data-name');
      const action = actions.find((a) => a.name === actionName);
      if (action) {
        handleChange(action.value);
        setSelectedAction(action);
      }
    } else {
      dispatch(setWithinMeters(range));
      setSelectedAction(null);
    };
  };

  useEffect(() => {
    const speedDialUsed = JSON.parse(localStorage.getItem('speedDialUsed') || 'false');
    if (!speedDialUsed) {
      localStorage.setItem('speedDialUsed', 'false');
    };
    setTimeout(() => {
      if (!speedDialUsed) setTooltipOpen(true);
    }, 250);
  }, []);

  useEffect(() => {
    if (plan === 'premium') {
      setRanges([...RANGES, PREMIUM_RANGE]);
    } else {
      setRanges(RANGES);
    };
  }, [plan]);

  useEffect(() => {
    shouldCenterRef.current = true;
    if (user) {
      const action = actions.find((a) => a.value === withinMeters);
      if (action) {
        setSelectedAction(action);
      };
    } else {
      if (selectedAction?.value === PREMIUM_RANGE || typeof selectedAction === 'undefined') {
        const biggestFreeRange = RANGES[RANGES.length - 1];
        const action = actions.find((a) => a.value === biggestFreeRange);
        if (action) {
          setSelectedAction(action);
        };
        dispatch(setWithinMeters(biggestFreeRange));
        setRange(biggestFreeRange);
      };
    };
  }, [user]);

  useEffect(() => {
    const action = actions.find((a) => a.value === withinMeters);
    if (action) {
      setSelectedAction(action);
    } else {
      setRange(withinMeters)
    };
  }, [withinMeters]);

  useEffect(() => {
    if (shouldCenterRef.current) {
      setTimeout(() => {
        centerGeolocator();
        shouldCenterRef.current = false;
      }, 100);
    };
  }, [range, withinMeters]);

  useEffect(() => {
    if (open) shouldCenterRef.current = false;
  }, [open]);

  return (
    <SpeedDial
      FabProps={{ size: getFabSize(window.innerWidth) }}
      ariaLabel="RangeSpeedDial"
      icon={
        <Tooltip
          open={tooltipOpen && (width > WIDTH_BREAKPOINT || !anyDialogOpen)}
          title={
            <Typography variant="caption" sx={{ display: 'flex', alignItems: 'center' }}>
              <ArrowBackIcon fontSize="small" />&nbsp;
              {(isMobile ? t('long press') : t('click')) + ' ' + t('here')}
            </Typography>}
          arrow
          placement="right-start"
          PopperProps={{
            sx: { '&.MuiTooltip-popper': { zIndex: 10 } }
          }}
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: 'transparent',
                backdropFilter: backdropBlur,
                boxShadow: navbarBoxShadow,
                transform: 'rotate(-30deg) !important',
                borderRadius: '10px',
                zIndex: 10,
              },
            },
            arrow: { sx: { color: 'transparent' } },
            popper: {
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [-25, 0],
                  },
                },
              ],
            },
          }}
        >
          <Typography>{withinMeters}m</Typography>
        </Tooltip>
      }
      open={open}
      onClick={handleClick}
      // onClose={handleClose}
      onTouchStart={handleLongPress}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleActionRelease}
      // onMouseDown={handleLongPress}
      // onMouseUp={handleActionRelease}
      sx={{
        zIndex: 1,
        position: 'fixed',
        bottom: width <= WIDTH_BREAKPOINT ? `${5 + 7 + 2}vh` : '10vh',
        left: width <= WIDTH_BREAKPOINT ? '1.5vh' : `${vhToPx(1.5) + DRAWER_WIDTH}px`,
        alignItems: 'start',
        touchAction: 'none', // fix for map being dragged while selecting action
        '.MuiSpeedDial-fab': {
          textTransform: 'lowercase',
          backgroundColor: 'transparent',
          backdropFilter: backdropBlur,
          fontSize: '2rem',
          boxShadow: fabBoxShadow,
          // pointerEvents: isMapDragged ? 'none' : 'auto',
          '&:hover': {
            backgroundColor: isMobile ? 'transparent' : 'rgba(255, 255, 255, 0.08)',
          },
        },
      }}
    >
      {actions.map((action, index) => (
        <SpeedDialAction
          key={action.name}
          icon={action.icon}
          tooltipTitle=""
          onClick={handleActionRelease}
          onDragEnter={() => {
            handleDragEnter(action);
            setSelectedAction(action);
          }}
          onDragLeave={handleDragLeave}
          data-name={action.name}
          onMouseEnter={() => {
            handleDragEnter(action);
            setSelectedAction(action);
          }}
          onMouseLeave={() => {
            const _action = actions.find(action => action.value === range)
            if (_action) setSelectedAction(_action);
            dispatch(setWithinMeters(range));
          }}
          sx={{
            width: '56px',
            height: '56px',
            backgroundColor: 'rgba(23, 23, 23, 0.6)',
            backdropFilter: backdropBlur,
            color: '#fff',
            right: index === 0 ? '-57px' : index === 1 ? '-40px' : '7px',
            top: index === 0 ? '80px' : index === 1 ? '105px' : index === 2 ? '160px' : '178px',
            boxShadow: selectedAction?.value === action.value ? fabBoxShadow : index === 3 ? yellowBoxShadow : 'unset',
          }}
        />
      ))}
    </SpeedDial>
  );
};

export default RangeSpeedDial;
