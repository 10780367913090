import { createSlice } from '@reduxjs/toolkit';

export const mapFiltersSlice = createSlice({
  name: 'mapFilters',
  initialState: {
    eventsCategories: []
  },
  reducers: {
    setEventsCategories: (state, action) => {
      state.eventsCategories = action.payload
    },
  },
})

export const { setEventsCategories } = mapFiltersSlice.actions;

export default mapFiltersSlice.reducer;
