import { createSlice } from '@reduxjs/toolkit';

import { EMPTY_GEOJSON } from '../../consts/map';

export const userEventsSlice = createSlice({
  name: 'userEvents',
  initialState: {
    value: EMPTY_GEOJSON,
    past: EMPTY_GEOJSON,
  },
  reducers: {
    setUserEvents: (state, action) => {
      state.value = action.payload;
    },
    setUserPastEvents: (state, action) => {
      state.past = action.payload;
    },
    updateUserEvent: (state, action) => {
      const { uuid, properties } = action.payload;

      const featureIndex = state.value.features.findIndex(feature => feature.properties.uuid === uuid);
      if (featureIndex >= 0) {
        state.value = {
          ...state.value,
          features: [
            ...state.value.features.slice(0, featureIndex),
            {
              ...state.value.features[featureIndex],
              properties: {
                ...state.value.features[featureIndex].properties,
                ...properties
              }
            },
            ...state.value.features.slice(featureIndex + 1)
          ]
        };
      };
    },
    addUserEvent: (state, action) => {
      const newFeature = {
        type: 'Feature',
        properties: {
          uuid: action.payload.uuid,
          ...action.payload.properties,
        },
        geometry: action.payload.geometry,
      };
      state.value = {
        ...state.value,
        features: [newFeature, ...state.value.features],
      };
    },
    removeUserEventByUuid: (state, action) => {
      const uuid = action.payload;
      state.value = {
        ...state.value,
        features: state.value.features.filter(feature => feature.properties.uuid !== uuid),
      };
      state.past = {
        ...state.past,
        features: state.past.features.filter(feature => feature.properties.uuid !== uuid),
      };
    },
    resetUserEvents: (state) => {
      state.value = EMPTY_GEOJSON;
      state.past = EMPTY_GEOJSON;
    },
  },
});

export const {
  addUserEvent,
  removeUserEventByUuid,
  resetUserEvents,
  setUserEvents,
  setUserPastEvents,
  updateUserEvent,
} = userEventsSlice.actions;

export default userEventsSlice.reducer;
