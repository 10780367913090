import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import MapIcon from '@mui/icons-material/Map';
import Stack from '@mui/material/Stack';

import api from '../utils/api.ts';
import { getNewNotifications } from '../utils/getters';
import { patchNotification } from '../utils/utils';
import AuthContext from '../context/AuthContext';
import EventInvitationCard from '../components/events/EventInvitationCard';
import Layout from '../components/ui/Layout.tsx';
import {
  DRAWER_WIDTH,
  WIDTH_BREAKPOINT,
} from '../theme';

import { setEventInvitations } from '../features/events/eventInvitationsSlice';
import {
  setAlertsSnackbarOpen,
  setAlertsSnackbarSeverity,
  setAlertsSnackbarText,
} from '../features/app/alertsSnackbarSlice';
import { addSavedEvent } from '../features/events/savedEventsSlice';
import CenteredBox from '../components/ui/CenteredBox.tsx';
import NoContentAlert from '../components/ui/NoContentAlert.tsx';

const EventInvitation: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { uuid } = useParams();
  const { user } = useContext(AuthContext);

  const [invitation, setInvitation] = useState(null);
  const [invitationNotFound, setInvitationNotFound] = useState<boolean | null>(null);
  const [notLoggedIn, setNotLoggedIn] = useState<boolean | null>(null);
  const [backdropOpen, setBackdropOpen] = useState(true);

  const eventInvitations = useSelector((state: any) => state.eventInvitations.value);
  const width = useSelector((state: any) => state.app.width);

  const [actionButtonDisabled, setActionButtonDisabled] = useState(false);
  const [showMapButton, setShowMapButton] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const notificationUuid = searchParams.get('notification');

  const GoToMap = (
    <CenteredBox mt={4}>
      <Button href="/" variant="contained" startIcon={<MapIcon />}>
        {t('Go to map')}
      </Button>
    </CenteredBox>
  );

  const handleAction = async (e, uuid: string, action: string) => {
    setBackdropOpen(true);
    setActionButtonDisabled(true);
    dispatch(setEventInvitations(eventInvitations.filter(el => el.uuid !== uuid)));
    await api.post('/invitations/', {
      action: action,
      uuid: uuid,
    }).then(response => {
      getNewNotifications();
      setShowMapButton(true);
      setBackdropOpen(false);
      if (action === 'accept') {
        dispatch(setAlertsSnackbarSeverity('success'));
        dispatch(setAlertsSnackbarText(t('Invitation accepted!')));
      } else if (action === 'reject') {
        dispatch(setAlertsSnackbarSeverity('info'));
        dispatch(setAlertsSnackbarText(t('Invitation rejected!')));
      };
      dispatch(setAlertsSnackbarOpen(true));
      if (response.data) { dispatch(addSavedEvent(response.data)) };
    }).catch(err => {
      // console.log(err)
      setShowMapButton(true);
      setBackdropOpen(false);
      if (err.response.status === 404) {
        dispatch(setAlertsSnackbarSeverity('error'));
        dispatch(setAlertsSnackbarText(t('No such invitation!')));
        dispatch(setAlertsSnackbarOpen(true));
      };
    });
  };

  // TODO
  const handleShowOnMapClick = () => {
    alert('Not implemented!')
    // navigate('/');
  };

  const fetchData = async () => {
    setBackdropOpen(true);
    await api.get(`/invitations/${uuid}/`).then(response => {
      setInvitation(response.data);
      setInvitationNotFound(false);
      setShowMapButton(false);
      setBackdropOpen(false);
    }).catch(err => {
      if (err.response.status === 401) {
        setNotLoggedIn(true);
      } else if (err.response.status === 404) {
        setInvitationNotFound(true);
      };
      setShowMapButton(true);
      setBackdropOpen(false);
    });
  };

  useEffect(() => {
    fetchData();
    if (notificationUuid) patchNotification(dispatch, notificationUuid);

    if (!user) {
      setInvitation(null);
      setNotLoggedIn(true);
      setShowMapButton(true);
      setInvitationNotFound(null);
    };
  }, [user?.username]);

  if (invitation) {
    return (
      <React.Fragment>
        <Layout>
          <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '2rem',
            paddingLeft: width > WIDTH_BREAKPOINT ? `${DRAWER_WIDTH + 16}px` : '16px',
            paddingRight: '16px',
          }}>
            <Grid container>
              <Grid item xs={width <= WIDTH_BREAKPOINT ? 0 : 2} />
              <Grid item xs={width <= WIDTH_BREAKPOINT ? 12 : 8} >
                <EventInvitationCard
                  border
                  invitation={invitation}
                  handleAction={handleAction}
                  actionButtonDisabled={actionButtonDisabled}
                  handleShowOnMapClick={handleShowOnMapClick}
                />
              </Grid>
              <Grid item xs={width <= WIDTH_BREAKPOINT ? 0 : 2} />
            </Grid>
          </Box>
          {showMapButton && GoToMap}
        </Layout>
      </React.Fragment>
    );
  } else if (invitationNotFound) {
    return (
      <Layout>
        <Stack mt={4}>
          <NoContentAlert text={t('Invitation not found or the event is over.') as string} />
          {showMapButton && GoToMap}
        </Stack>
      </Layout>
    )
  } else if (notLoggedIn) {
    return (
      <Layout>
        <Alert severity="info">
          {t('Please log in to see the invitation.')}
        </Alert>
        {showMapButton && GoToMap}
      </Layout>
    )
  }
  else {
    return (
      <Layout>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, top: '8vh' }}
          open={backdropOpen}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Layout>
    );
  };
};

export default EventInvitation;