import { createSlice } from '@reduxjs/toolkit';

import { EMPTY_FEATURE } from '../../consts/map';

export const confirmDeleteEventDialogSlice = createSlice({
  name: 'confirmDeleteEventDialog',
  initialState: {
    open: false,
    event: EMPTY_FEATURE,
  },
  reducers: {
    resetConfirmDeleteEventDialogEvent: (state) => {
      state.event = EMPTY_FEATURE;
    },
    setConfirmDeleteEventDialogEvent: (state, action) => {
      state.event = action.payload
    },
    setConfirmDeleteEventDialogOpen: (state, action) => {
      state.open = action.payload
    }
  }
})

export const {
  resetConfirmDeleteEventDialogEvent,
  setConfirmDeleteEventDialogEvent,
  setConfirmDeleteEventDialogOpen,
} = confirmDeleteEventDialogSlice.actions;

export default confirmDeleteEventDialogSlice.reducer;
