import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import Check from '@mui/icons-material/Check';
import Clear from '@mui/icons-material/Clear';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import CloseButton from '../ui/CloseButton.tsx';
import PaperComponent from '../PaperComponent';

import api from '../../utils/api.ts';

import {
  setAlertsSnackbarOpen,
  setAlertsSnackbarSeverity,
  setAlertsSnackbarText,
} from '../../features/app/alertsSnackbarSlice';
import { setEndEventDialogOpen } from '../../features/dialogs/confirmEndEventDialogSlice';
import { setEventDialogOpen } from '../../features/dialogs/eventDialogSlice';
import { updateUserEvent } from '../../features/events/userEventsSlice';

import { borderedDialogPaperProps } from '../../theme';

export default function ConfirmEndEventDialog() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [endButtonDisabled, setEndButtonDisabled] = useState(false);

  const open = useSelector((state) => state.confirmEndEventDialog.open);
  const event = useSelector((state) => state.confirmEndEventDialog.event);

  const handleClose = () => {
    dispatch(setEndEventDialogOpen(false));
  };

  const handleEnd = async () => {
    if (endButtonDisabled) { return };
    setEndButtonDisabled(true);
    const data = { uuid: event.properties.uuid };

    await api.patch('/events/end/', data).then(response => {
      const updateData = {
        uuid: response.data.uuid,
        properties: {
          end: response.data.end,
          timeline: response.data.timeline,
        },
      };
      dispatch(updateUserEvent(updateData));

      dispatch(setAlertsSnackbarSeverity('info'));
      dispatch(setAlertsSnackbarText(t('Event has been moved to past')));
      dispatch(setAlertsSnackbarOpen(true));
      dispatch(setEventDialogOpen(false));
      handleClose();
      setTimeout(() => { setEndButtonDisabled(false) }, 1000);
    }).catch(err => {
      dispatch(setAlertsSnackbarSeverity('error'));
      dispatch(setAlertsSnackbarText(t('Something went wrong.')));
      dispatch(setAlertsSnackbarOpen(true));

      setTimeout(() => {
        setEndButtonDisabled(false);
      }, 1500);
    });
  };

  useEffect(() => {
    if (!open) setEndButtonDisabled(false);
  }, [open]);

  return (
    <Dialog
      open={open}
      PaperComponent={PaperComponent}
      hideBackdrop={false}
      aria-labelledby="draggable-dialog-title"
      aria-describedby="alert-dialog-description"
      {...borderedDialogPaperProps}
    >
      <DialogTitle textAlign="center" style={{ cursor: 'move' }} id="draggable-dialog-title">
        {t('Confirm end')}
      </DialogTitle>
      <CloseButton onClick={handleClose} />
      <form>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('Are you sure you want to end')} <strong>{event.properties?.name}</strong> {t('before time')}?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button
            variant="outlined"
            startIcon={<Clear />}
            onClick={handleClose}
          >
            {t('Cancel')}
          </Button>
          <Button
            disabled={endButtonDisabled}
            onClick={handleEnd}
            variant="outlined"
            endIcon={<Check />}
            color="warning"
          >
            {t('End')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
};