import api from '../utils/api.ts';

import { setCategories } from '../features/events/eventsCategoriesSlice';

export const getEventCategories = async (dispatch) => {
  const r = await api.get('/categories/events/').then(response => {
    dispatch(setCategories(response.data));
  });
};

export const disableCalendarDays = (disabled) => {
  Array.from(document.querySelectorAll('.rdrDay, .rdrStaticRange')).forEach(button => {
    button.disabled = disabled;
  });
};