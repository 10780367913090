import { styled } from '@mui/material/styles';

import Box from '@mui/material/Box';

const FullBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export default FullBox;