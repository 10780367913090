import { styled } from '@mui/material/styles';

import Box from '@mui/material/Box';

const CenteredBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default CenteredBox;