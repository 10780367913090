import React from 'react';
import { useDispatch } from 'react-redux';
import { styled } from '@mui/material/styles';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import PersonAddDisabledIcon from '@mui/icons-material/PersonAddDisabled';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Typography from '@mui/material/Typography';

import {
  setImageDialogAlt,
  setImageDialogOpen,
  setImageDialogSrc,
} from '../../features/dialogs/imageDialogSlice';

import BigAvatar from './BigAvatar.tsx';

import { User } from '../../types/User/types.ts';

const StyledCard = styled(Card)`
  width: 100%;
  border-radius: 25px;
  background-color: transparent;

  .MuiCardHeader-action { 
    margin: auto;
  };
`;

interface UserSearchCardMinProps {
  feature: User;
  handleAddFriend: (e, uuid: string, action: string) => void;
  actionButtonDisabled: boolean;
};

const UserSearchCardMin: React.FC<UserSearchCardMinProps> = ({
  feature,
  handleAddFriend,
  actionButtonDisabled,
}) => {
  const dispatch = useDispatch();

  const handleUserPictureClick = () => {
    if (feature.properties.picture) {
      dispatch(setImageDialogSrc(feature.properties.picture));
      dispatch(setImageDialogAlt(feature.properties.username));
      dispatch(setImageDialogOpen(true));
    };
  };

  return (
    <StyledCard id={feature.properties.uuid} variant="outlined">
      <CardHeader
        avatar={
          <BigAvatar
            src={feature.properties.picture}
            size="30px"
            onClick={handleUserPictureClick}
            sx={{ width: '50px', height: '50px' }}
          />
        }
        action={!feature.properties.invited ? (
          <IconButton
            disabled={actionButtonDisabled}
            edge="end" aria-label="delete"
            onClick={(e) => handleAddFriend(e, feature.properties.uuid, 'create')}>
            <PersonAddIcon />
          </IconButton>
        ) : (
          <IconButton
            disabled={actionButtonDisabled}
            edge="end" aria-label="delete"
            onClick={(e) => handleAddFriend(e, feature.properties.uuid, 'cancel')}>
            <PersonAddDisabledIcon />
          </IconButton>
        )}
        title={
          <Typography variant="h6">
            {feature.properties.username}
          </Typography>
        }
        subheader={
          <Typography variant="body2">
            {[feature.properties.firstName, feature.properties.lastName].join(' ')}
          </Typography>
        }
      />
    </StyledCard>
  )
};

export default UserSearchCardMin;