import React from 'react';
import { css, styled } from '@mui/material/styles';

import Box from '@mui/material/Box';
import DoneIcon from '@mui/icons-material/Done';
import Stack, { StackProps } from '@mui/material/Stack';
import Typography, { TypographyTypeMap } from '@mui/material/Typography';

import CenteredBox from './CenteredBox.tsx';

import { mediumSeaGreen } from '../../theme';

interface SuccessAlertProps extends StackProps {
  text?: string;
  size?: string;
  color?: string;
  variant?: TypographyTypeMap['props']['variant'];
};

const StyledAnnouncementOutlinedIcon = styled(DoneIcon) <SuccessAlertProps>`
  ${(props) => css`
    font-size: ${props.size};
  `}
`;

const TextWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const SuccessAlert: React.FC<SuccessAlertProps> = ({
  text,
  size,
  color,
  direction,
  spacing,
  variant,
}) => {
  return (
    <>
      <Stack spacing={spacing} direction={direction} sx={{ color: color }}>
        <CenteredBox>
          <StyledAnnouncementOutlinedIcon size={size} />
        </CenteredBox>
        {text && (
          <TextWrapper>
            <Typography variant={variant}>
              {text}
            </Typography>
          </TextWrapper>
        )}
      </Stack>
    </>
  );
};

SuccessAlert.defaultProps = {
  text: '',
  size: '70px',
  direction: 'column',
  spacing: 4,
  color: mediumSeaGreen,
};

export default SuccessAlert;