export const API_URL = 'https://api.r-ound.me';
export const MEDIA_URL = `${API_URL}/media`;
export const APP_NAME = 'R‑ound.Me';
export const APP_URL = 'https://r-ound.me';
export const CONTACT_EMAIL = 'contact@r‑ound.me';
export const I18N_DEBUG = false;
export const LAUNCH_DATE = new Date('2024-12-01T00:00:00');

export const MAPBOX_ACCESS_TOKEN = 'pk.eyJ1Ijoicm91bmRtZSIsImEiOiJjbGduaXI1dWQwYnZtM3Bxa2piaTVoaDdoIn0.FKrO7e2ZD6mFOyayQ-tnjg';

export const VAPID_KEY = 'BNamzn_1F4H6uXW5pswZv_hLLLJ8woYMgl3ZC5e-9YKXVsm2hAH1whymOGpxuVzFsgUbMs0ZxyRsCTY5et_ELNw';
export const FIREBASE_CONFIG = {
  apiKey: 'AIzaSyBVGJJGlLnOCWXiXsF0IqqMQs0aa3clvsE',
  authDomain: 'r-ound-me.firebaseapp.com',
  projectId: 'r-ound-me',
  storageBucket: 'r-ound-me.appspot.com',
  messagingSenderId: '49102542668',
  appId: '1:49102542668:web:0fed7289da74d3ce5e21e9',
  measurementId: 'G-6KQ9XXTZWK'
};

export const PREMIUM_DISABLED = true;