import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import Check from '@mui/icons-material/Check';
import Clear from '@mui/icons-material/Clear';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { APP_NAME } from '../../config';
import { borderedDialogPaperProps } from '../../theme';

import { setLocationPermissionDialogOpen } from '../../features/app/dialogsSlice';

const LocationPermissionDialog: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const open = useSelector((state: any) => state.dialogs.locationPermissionDialogOpen);
  const geolocateControl = useSelector((state: any) => state.map.geolocateControl);

  const handleCancel = () => {
    dispatch(setLocationPermissionDialogOpen(false));
  };

  const handleSubmit = () => {
    dispatch(setLocationPermissionDialogOpen(false));
    if (geolocateControl._watchState !== 'ACTIVE_LOCK') {
      geolocateControl.trigger();
    };
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      {...borderedDialogPaperProps}
    >
      <DialogTitle id="alert-dialog-title" variant="h6">
        {t('Allow location access?')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" variant="body2">
          {t("Your location is required to use every feature of {{appName}}.", { appName: APP_NAME })}
        </DialogContentText>
        <DialogContentText variant="caption" mt={1}>
          {t('You can always do it later.')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          startIcon={<Clear />}
          onClick={handleCancel}
        >
          {t('Decline')}
        </Button>
        <Button
          variant="contained"
          endIcon={<Check />}
          onClick={handleSubmit}
        // autoFocus
        >
          {t('Allow')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LocationPermissionDialog;