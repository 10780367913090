import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Divider from '@mui/material/Divider';
import GavelIcon from '@mui/icons-material/Gavel';
import InfoIcon from '@mui/icons-material/Info';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import QuizIcon from '@mui/icons-material/Quiz';
import SecurityIcon from '@mui/icons-material/Security';

import { setBottomBarValue } from '../../features/app/appSlice';
import { resetDialogs } from '../../features/app/dialogsSlice';
import { setAuthDialogOpen } from '../../features/dialogs/authDialogSlice.ts';

import { APP_NAME } from '../../config';
import { closeDrawer } from '../../utils/utils';

export default function DrawerItems() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const closeDialogs = () => {
    dispatch(setBottomBarValue(0));
    dispatch(setAuthDialogOpen(false));
    dispatch(resetDialogs());
  };

  const navigateWithTimeout = (path) => {
    setTimeout(() => navigate(path), 1);
  };

  const handleContactClick = () => {
    closeDialogs();
    closeDrawer(dispatch);
    navigateWithTimeout('/contact/');
  };

  const handleAboutClick = () => {
    closeDialogs();
    closeDrawer(dispatch);
    navigateWithTimeout('/about/');
  };

  const handleFAQsClick = () => {
    closeDialogs();
    closeDrawer(dispatch);
    navigateWithTimeout('/faqs/');
  };

  const handleTermsClick = () => {
    closeDialogs();
    closeDrawer(dispatch);
    navigateWithTimeout('/terms-of-service/');
  };

  const handlePrivacyClick = () => {
    closeDialogs();
    closeDrawer(dispatch);
    navigateWithTimeout('/privacy-policy/');
  };

  return (
    <React.Fragment>
      <ListItem key="Contact" disablePadding onClick={handleContactClick}>
        <ListItemButton>
          <ListItemIcon>
            <QuestionAnswerIcon />
          </ListItemIcon>
          <ListItemText primary={t('Contact us')} />
        </ListItemButton>
      </ListItem>
      <ListItem key="About" disablePadding onClick={handleAboutClick}>
        <ListItemButton>
          <ListItemIcon>
            <InfoIcon />
          </ListItemIcon>
          <ListItemText primary={t('About') + ' ' + APP_NAME} />
        </ListItemButton>
      </ListItem>
      <ListItem key="FAQs" disablePadding onClick={handleFAQsClick}>
        <ListItemButton>
          <ListItemIcon>
            <QuizIcon />
          </ListItemIcon>
          <ListItemText primary={t('FAQs')} />
        </ListItemButton>
      </ListItem>
      <Divider />
      <ListItem key="Terms of Service" disablePadding onClick={handleTermsClick}>
        <ListItemButton>
          <ListItemIcon>
            <GavelIcon />
          </ListItemIcon>
          <ListItemText primary={t('Terms of Service')} />
        </ListItemButton>
      </ListItem>
      <ListItem key="Privacy Policy" disablePadding onClick={handlePrivacyClick}>
        <ListItemButton>
          <ListItemIcon>
            <SecurityIcon />
          </ListItemIcon>
          <ListItemText primary={t('Privacy Policy')} />
        </ListItemButton>
      </ListItem>
      <Divider />
    </React.Fragment>
  )
}