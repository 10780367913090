import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import dayjs from 'dayjs';

import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import Chip from '@mui/material/Chip';
import LabelIcon from '@mui/icons-material/Label';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import PhotoOutlinedIcon from '@mui/icons-material/PhotoOutlined';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import BookmarkButton from '../ui/BookmarkButton';
import CategoryChipsWrapper from './CategoryChipsWrapper.tsx';
import CopyLinkButton from '../ui/CopyLinkButton.tsx';
import EventMenu from './EventMenu';
import InviteFriendsButton from '../ui/InviteFriendsButton';
import ShareButton from '../ui/ShareButton.tsx';
import ShowOnMapButton from '../ui/ShowOnMapButton';

import {
  setImageDialogAlt,
  setImageDialogOpen,
  setImageDialogSrc,
} from '../../features/dialogs/imageDialogSlice';

import {
  getDistance,
  formatDistance,
} from '../../utils/mapUtils';

import { APP_URL } from '../../config';
import {
  grayedOutFilter,
  sunsetOrange,
} from '../../theme';

export default function EventCard(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const userPosition = useSelector((state) => state.userPosition.value);

  const handleEventPictureClick = (userEvent) => {
    dispatch(setImageDialogSrc(userEvent.properties.picture));
    dispatch(setImageDialogAlt(userEvent.properties.name));
    dispatch(setImageDialogOpen(true));
  };

  return (
    <React.Fragment>
      <Badge
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        badgeContent={props.feature.properties.cancelled ? t('Cancelled') : 0}
        sx={{
          position: 'absolute',
          top: '0.5rem',
          left: '2rem',
          '& .MuiBadge-badge': {
            backgroundColor: sunsetOrange,
          }
        }}
      />
      <Card
        id={props.feature.properties.uuid}
        variant="outlined"
        sx={{
          width: '100%',
          borderRadius: '25px',
          backgroundColor: 'transparent',
        }}>
        <CardHeader
          avatar={
            props.feature.properties.picture ? (
              <Badge
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                badgeContent={props.feature.properties.invited ? <MarkEmailReadIcon sx={{ fontSize: '1rem' }} /> : ''}
              >
                <CardMedia
                  component="img"
                  image={props.feature.properties.picture}
                  alt={props.feature.properties.name}
                  onClick={() => handleEventPictureClick(props.feature)}
                  sx={{
                    width: '100px',
                    height: '100px',
                    cursor: 'pointer',
                    aspectRatio: '1/1',
                    borderRadius: '10px',
                    filter: props.feature.properties.cancelled && grayedOutFilter,
                  }}
                />
              </Badge>
            ) : (
              <Badge
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                badgeContent={props.feature.properties.invited ? <MarkEmailReadIcon sx={{ fontSize: '1rem' }} /> : ''}
              >
                <CardMedia sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100px',
                  aspectRatio: '1/1',
                  border: '1px solid #fff',
                  borderRadius: '10px',
                  filter: props.feature.properties.cancelled && grayedOutFilter,
                }}>
                  <Avatar sx={{ bgcolor: 'transparent', width: '48px', height: '48px' }} aria-label="recipe">
                    <PhotoOutlinedIcon sx={{ color: '#fff', fontSize: '48px' }} />
                  </Avatar>
                </CardMedia>
              </Badge>
            )
          }
          action={
            <EventMenu
              feature={props.feature}
              edit={props.edit}
              end={props.end}
              cancel={props.cancel}
              delete={props.delete}
              redo={props.redo}
              report={props.report}
            />
          }
          title={
            <Typography
              variant="h5"
              sx={{
                flexGrow: 1,
                wordBreak: 'break-word',
                filter: props.feature.properties.cancelled && grayedOutFilter,
              }}
            >
              {props.feature.properties.name}
            </Typography >
          }
          subheader={
            <Stack sx={{
              filter: props.feature.properties.cancelled && grayedOutFilter,
            }}
            >
              <Typography variant="caption">
                {props.feature.properties.own ? (
                  props.feature.properties.private ? t('Your private pin') : t('Your public pin')
                ) : (
                  !props.feature.properties.anonymous && props.feature.properties.user ? (
                    <>
                      <span style={{
                        filter: props.feature.properties.advertiserDeleted && grayedOutFilter,
                        display: 'inline-flex',
                        alignItems: 'center',
                        verticalAlign: 'middle',
                      }}>
                        {props.feature.properties.advertiserDeleted && <PersonOffIcon fontSize="smaller" />}{props.feature.properties.user}
                      </span>&nbsp;<span style={{ verticalAlign: 'middle' }}>{`- ${props.feature.properties.private ? t('private pin') : t('public pin')}`}</span>
                    </>
                  ) : (
                    props.feature.properties.private ? t('Private pin') : t('Public pin')
                  )
                )}
              </Typography>
              {(
                props.feature.properties.timeline !== 'past' &&
                !props.feature.properties.cancelled &&
                userPosition.length === 2
              ) && (
                  <Typography>
                    {formatDistance(getDistance(props.feature.geometry.coordinates, userPosition))}
                  </Typography>
                )}
              <Typography variant="caption" color="text.secondary">
                {dayjs.unix(props.feature.properties.start).format('L LT')} - {dayjs.unix(props.feature.properties.end).format('L LT')}
              </Typography>
            </Stack>
          }
        />
        {(props.feature.properties.description || props.feature.properties.categories.length > 0) && (
          <CardContent>
            {props.feature.properties.description && (
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ wordBreak: 'break-word' }}
              >
                {props.feature.properties.description}
              </Typography>
            )}
            {props.feature.properties.categories?.length > 0 && (
              <CategoryChipsWrapper>
                {props.feature.properties.categories.map((category, index2) => (
                  <Chip icon={<LabelIcon />}
                    size="small"
                    label={category.name}
                    key={index2}
                    sx={{
                      marginLeft: index2 === 0 ? '1.5rem' : undefined,
                      marginRight: index2 === props.feature.properties.categories.length - 1 ? '1.5rem !important' : undefined,
                    }}
                  />
                ))}
              </CategoryChipsWrapper>
            )}
          </CardContent>
        )}
        <CardActions disableSpacing>
          {props.feature.properties.timeline !== 'past' &&
            !props.feature.properties.cancelled && (
              <React.Fragment>
                {(props.feature.properties.own ||
                  !props.feature.properties.private) && (
                    navigator.canShare ? (
                      <ShareButton event={props.feature} />
                    ) : (
                      <CopyLinkButton link={`${APP_URL}/?pin=${props.feature.properties.uuid}`} />
                    )
                  )}
                <ShowOnMapButton feature={props.feature} />
                {(props.feature.properties.own ||
                  props.feature.properties.othersCanInvite) && (
                    <InviteFriendsButton event={props.feature} />
                  )}
              </React.Fragment>
            )}
          {props.save && (
            <BookmarkButton event={props.feature} />
          )}
        </CardActions>
      </Card>
    </React.Fragment>
  );
};