import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch, } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { css, styled } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import QrCodeIcon from '@mui/icons-material/QrCode';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import {
  setAlertsSnackbarOpen,
  setAlertsSnackbarSeverity,
  setAlertsSnackbarText,
} from '../../features/app/alertsSnackbarSlice';
import {
  setProfileDialogOpen,
  setQrCodeDialogOpen,
} from '../../features/app/dialogsSlice';
import {
  setDisplayName,
  setFirstName,
  setLastName,
  setPicture,
} from '../../features/profile/profileSlice';

import {
  DISPLAY_NAME_MAX_LENGTH,
  FIRST_NAME_MAX_LENGTH,
  LAST_NAME_MAX_LENGTH,
} from '../../limits.ts';

import api from '../../utils/api.ts';
import {
  countCharacters,
  formatName,
} from '../../utils/utils';

import AvatarWithImageUpload from './AvatarWithImageUpload.tsx';
import CenteredBox from '../ui/CenteredBox.tsx';
import CloseButton from '../ui/CloseButton.tsx';
import ProfileSkeleton from './ProfileSkeleton.tsx';
import SmallLoader from '../ui/SmallLoader.tsx';

import {
  customDialogStyle,
  WIDTH_BREAKPOINT,
} from '../../theme';

const StyledButton = styled(Button)`
  width: 5.25rem;
`;

const StyledCenteredBox = styled(CenteredBox)`
  width: 100px;
`;

const StyledDialogActions = styled(DialogActions)`
  justify-content: space-around;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  margin: 0;
`;

const StyledGridItem = styled(Grid, { shouldForwardProp: (prop) => prop !== 'width' }) <{ width: number }>`
  display: flex;
  flex-direction: column;

  ${(props) => css`
    align-items: ${props.width <= WIDTH_BREAKPOINT / 2 ? 'flex-start' : 'center'};
  `}
`;

const StyledTextField = styled(TextField)`
  width: 50%;
`;

const ProfileDialog: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const width = useSelector((state: any) => state.app.width);
  const [_firstName, _setFirstName] = useState('');
  const [_lastName, _setLastName] = useState('');
  const [_displayName, _setDisplayName] = useState('');

  const firstName = useSelector((state: any) => state.profile.firstName);
  const lastName = useSelector((state: any) => state.profile.lastName);
  const displayName = useSelector((state: any) => state.profile.displayName);

  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);

  const [displayNameErrorHelperText, setDisplayNameErrorHelperText] = useState<string | null>('');

  const [loading, setLoading] = useState(true);

  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [displayNameError, setDisplayNameError] = useState(false);

  const initialLoad = useRef(true);
  const [firstLoad, setFirstLoad] = useState(true);

  const picture = useSelector((state: any) => state.profile.picture);
  // const [_picture, _setPicture] = useState<string | ArrayBuffer | undefined>(picture);
  const [_picture, _setPicture] = useState<any>(picture);

  const open = useSelector((state: any) => state.dialogs.profileDialogOpen);

  const handleClose = () => {
    dispatch(setProfileDialogOpen(false));
  };

  const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const firstNameLength = countCharacters(e.target.value);
    setFirstNameError(firstNameLength > FIRST_NAME_MAX_LENGTH);
    _setFirstName(e.target.value);
    setSaveButtonDisabled(
      e.target.value === firstName ||
      firstNameLength > FIRST_NAME_MAX_LENGTH ||
      lastNameError ||
      displayNameError
    );
  };

  const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const lastNameLength = countCharacters(e.target.value);
    setLastNameError(lastNameLength > LAST_NAME_MAX_LENGTH);
    _setLastName(e.target.value);
    setSaveButtonDisabled(
      e.target.value === lastName ||
      firstNameError ||
      lastNameLength > LAST_NAME_MAX_LENGTH ||
      displayNameError
    );
  };

  const handleDisplayNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const displayNameLength = countCharacters(e.target.value);
    setDisplayNameErrorHelperText(`${displayNameLength}/${DISPLAY_NAME_MAX_LENGTH} ` + t('characters') + ' *');
    setDisplayNameError(countCharacters(e.target.value) > DISPLAY_NAME_MAX_LENGTH);
    _setDisplayName(formatName(e.target.value));
    setSaveButtonDisabled(
      e.target.value === displayName ||
      firstNameError ||
      lastNameError ||
      displayNameLength > DISPLAY_NAME_MAX_LENGTH
    );
  };

  const fetchData = async () => {
    // try {
    // const response = await api.get('/location/');
    // if (response.status === 200) {
    //   console.log(response.data)
    // }
    // setRes(response.data.response);
    // } catch {
    //   setRes('Something went wrong');
    // }
    if (open) {
      setLoading(true);
      await api.get('/users/profile/').then(response => {
        _setFirstName(response.data.firstName);
        dispatch(setFirstName(response.data.firstName));

        _setLastName(response.data.lastName);
        dispatch(setLastName(response.data.lastName));

        _setDisplayName(response.data.displayName);
        dispatch(setDisplayName(response.data.displayName));

        _setPicture(response.data.picture);
        dispatch(setPicture(response.data.picture));

        setLoading(false);
        setFirstLoad(false);
      }).catch(err => {

      });
    };
  };

  const handleSave = async () => {
    setLoading(true);
    setSaveButtonDisabled(true);
    // _setFirstName(_firstName);
    // _setLastName(_lastName);
    // _setDisplayName(formatName(_displayName));
    // _setPicture(_picture);

    const formData = new FormData();
    formData.append('picture', _picture);
    formData.append('displayName', _displayName);
    formData.append('firstName', _firstName);
    formData.append('lastName', _lastName);

    await api.post('/users/profile/', formData).then(response => {
      setLoading(false);
      if (response.status === 200) {
        dispatch(setFirstName(response.data.firstName));
        dispatch(setLastName(response.data.lastName));
        dispatch(setDisplayName(formatName(response.data.displayName)));
        // dispatch(setPicture(response.data.picture));
        dispatch(setPicture(_picture));
        dispatch(setAlertsSnackbarSeverity('success'));
        dispatch(setAlertsSnackbarText(t('Profile saved!')));
      } else {
        dispatch(setAlertsSnackbarSeverity('error'));
        dispatch(setAlertsSnackbarText(t('Something went wrong!')));
      };
      dispatch(setAlertsSnackbarOpen(true));
    }).catch(err => {
      setLoading(false);
      if (err.response.status === 409) {
        setDisplayNameErrorHelperText(t('Nickname already taken - try another'));
        setDisplayNameError(true);
      } else {
        dispatch(setAlertsSnackbarSeverity('error'));
        dispatch(setAlertsSnackbarText(t('Something went wrong!')));
        dispatch(setAlertsSnackbarOpen(true));
        setTimeout(() => {
          setSaveButtonDisabled(false);
        }, 2000);
      };
    });
  };

  useEffect(() => {
    // https://stackoverflow.com/questions/53253940/make-react-useeffect-hook-not-run-on-initial-render
    if (initialLoad.current) {
      initialLoad.current = false;
      return;
    } else {
      fetchData();
    };
    if (!open) {
      setTimeout(() => {
        setSaveButtonDisabled(true);
        setFirstNameError(false);
        setLastNameError(false);
        setDisplayNameError(false);
        _setPicture(picture);
        _setDisplayName(displayName);
        _setFirstName(firstName);
        _setLastName(lastName);
      }, 500);
    };
  }, [open]);  // FIXME

  return (
    <Dialog
      hideBackdrop={width <= WIDTH_BREAKPOINT}
      fullScreen={width <= WIDTH_BREAKPOINT}
      fullWidth
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          borderRadius: width > WIDTH_BREAKPOINT ? '25px' : undefined,
          // top: width > WIDTH_BREAKPOINT ? '-5vh' : 'unset',
          minHeight: width > WIDTH_BREAKPOINT ? '40vh' : undefined,
        }
      }}
      {...customDialogStyle}
    >
      <Box>
        <DialogTitle textAlign="center">
          {t('Edit profile')}
          {loading && <SmallLoader />}
          <CloseButton onClick={handleClose} />
        </DialogTitle>
        <DialogContent>
          {!firstLoad ? (
            <>
              <Grid container alignItems="center">
                <StyledGridItem item xs={4} mt={2} width={width}>
                  <AvatarWithImageUpload
                    picture={_picture}
                    setPicture={_setPicture}
                    setSaveButtonDisabled={setSaveButtonDisabled}
                  />
                  <StyledCenteredBox mt={1}>
                    <IconButton onClick={() => dispatch(setQrCodeDialogOpen(true))}>
                      <QrCodeIcon />
                    </IconButton>
                  </StyledCenteredBox>
                </StyledGridItem>
                <Grid item xs={8}>
                  <Stack direction="column" spacing={1}>
                    <FormGroup>
                      <StyledFormControlLabel
                        label=""
                        control={
                          <TextField
                            // inputRef={focusUsernameInputField}
                            onChange={handleDisplayNameChange}
                            margin="dense"
                            id="username"
                            label={t('Nickname')}
                            // type="email"
                            value={_displayName}
                            fullWidth
                            variant="standard"
                            autoComplete="off"
                            helperText={displayNameError ? displayNameErrorHelperText : `${countCharacters(_displayName)}/${DISPLAY_NAME_MAX_LENGTH} ` + t('characters') + ' *'}
                            error={displayNameError}
                          />
                        }
                      />
                    </FormGroup>
                    <Stack direction="row" spacing={1}>
                      <StyledTextField
                        id="first-name"
                        label={t('First name')}
                        variant="standard"
                        onChange={handleFirstNameChange}
                        value={_firstName}
                        helperText={`${countCharacters(_firstName)}/${FIRST_NAME_MAX_LENGTH} ` + t('characters')}
                        error={firstNameError}
                      />
                      <StyledTextField
                        id="last-name"
                        label={t('Last name')}
                        variant="standard"
                        onChange={handleLastNameChange}
                        value={_lastName}
                        helperText={`${countCharacters(_lastName)}/${LAST_NAME_MAX_LENGTH} ` + t('characters')}
                        error={lastNameError}
                      />
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
              <br />
              <Typography variant="caption" display="block" gutterBottom>
                * {t('nickname can be blank but is required for other users to be able to find your profile')}
              </Typography>
            </>
          ) : (
            <Box mt={3}>
              <ProfileSkeleton />
            </Box>
          )}
        </DialogContent>
      </Box>
      {!firstLoad && (
        <StyledDialogActions>
          <StyledButton
            disabled={saveButtonDisabled}
            variant="contained"
            onClick={handleSave}
          >
            {t('Save')}
          </StyledButton>
        </StyledDialogActions>
      )}
    </Dialog>
  );
}

export default ProfileDialog;