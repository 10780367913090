import React from 'react';

import Avatar from '@mui/material/Avatar';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';

import { User } from '../../types/User/types';

import { mainColor } from '../../theme';

interface FriendAvatarProps {
  user: User;
  border?: boolean;
}
const FriendAvatar: React.FC<FriendAvatarProps> = ({ user, border }) => {
  return (
    <label htmlFor="avatar-upload"
      style={{ display: 'flex', justifyContent: 'center' }}
    >
      <Avatar src={user.properties.picture}
        sx={{
          boxShadow: border ? `0 0 0 2px ${mainColor}` : undefined,
          bgcolor: 'transparent',
          width: 50,
          height: 50,
        }}>
        <PersonOutlineIcon sx={{ fontSize: 25, color: '#fff' }} />
      </Avatar>
    </label>
  );
};

FriendAvatar.defaultProps = {
  border: false,
};

export default FriendAvatar;
