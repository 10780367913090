import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import PinDropIcon from '@mui/icons-material/PinDrop';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { setNetworkDialogOpen } from '../../features/app/dialogsSlice';
import {
  setImageDialogAlt,
  setImageDialogOpen,
  setImageDialogSrc,
} from '../../features/dialogs/imageDialogSlice';
import {
  setUserDialogOpen,
  setUserDialogUser,
} from '../../features/dialogs/userDialogSlice';

import {
  formatDistance,
  getDistance,
} from '../../utils/mapUtils';

import { DEFAULT_ZOOM } from '../../consts/map';

import BigAvatar from './BigAvatar.tsx';
import InviteFriendButton from '../ui/InviteFriendButton.tsx';
import OnlineBadge from './OnlineBadge.tsx';
import UserMenu from './UserMenu.tsx';

import { User } from '../../types/User/types.ts';

interface FriendCardMinProps {
  feature: User;
};

const FriendCardMin: React.FC<FriendCardMinProps> = ({ feature }) => {
  const dispatch = useDispatch();

  const map = useSelector((state: any) => state.map.value);
  const userPosition = useSelector((state: any) => state.userPosition.value);

  const handleUserPictureClick = () => {
    if (feature.properties.picture) {
      dispatch(setImageDialogSrc(feature.properties.picture));
      dispatch(setImageDialogAlt(feature.properties.username));
      dispatch(setImageDialogOpen(true));
    };
  };

  const handleShowOnMapClick = () => {
    dispatch(setNetworkDialogOpen(false));
    const currentZoom = map.getZoom();
    map.flyTo({
      center: feature.geometry.coordinates,
      zoom: currentZoom <= DEFAULT_ZOOM ? DEFAULT_ZOOM : currentZoom,
      duration: 500,
    });
  };

  const handleCardClick = () => {
    dispatch(setUserDialogUser(feature));
    dispatch(setUserDialogOpen(true));
  };

  return (
    <Card
      id={feature.properties.uuid}
      variant="outlined"
      sx={{
        // maxWidth: 345,
        width: '100%',
        // border: 'none',
        borderRadius: '25px',
        backgroundColor: 'transparent',
      }}>
      <CardHeader
        avatar={
          feature.properties.online ? (
            <OnlineBadge
              overlap="circular"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              variant="dot"
            >
              <BigAvatar
                src={feature.properties.picture}
                size="50px"
                onClick={handleUserPictureClick}
                sx={{ width: '75px', height: '75px' }}
              />
            </OnlineBadge>
          ) : (
            <BigAvatar
              src={feature.properties.picture}
              size="50px"
              onClick={handleUserPictureClick}
              sx={{ width: '75px', height: '75px' }}
            />
          )
        }
        action={
          <Stack>
            <Box sx={{ marginLeft: 'auto' }}>
              <UserMenu feature={feature} />
            </Box>
            <Stack direction="row">
              {feature.properties.online &&
                feature.geometry.coordinates.length === 2 &&
                window.location.pathname === '/' && (
                  // todo: use ShowOnMapButton
                  <IconButton
                    onClick={handleShowOnMapClick}
                    aria-label="show"
                    sx={{ marginLeft: 'auto' }}
                  >
                    <PinDropIcon />
                  </IconButton>
                )}
              <InviteFriendButton user={feature} />
            </Stack>
          </Stack>
        }
        title={
          <>
            <Typography
              variant={feature.properties.username ? 'h6' : 'caption'}
              onClick={handleCardClick}
              sx={{ cursor: 'pointer' }}
            >
              {feature.properties.username || feature.properties.uuid}
            </Typography>
            <Typography variant="body2">
              {[feature.properties.firstName, feature.properties.lastName].join(' ')}
            </Typography>
          </>
        }
        subheader={
          userPosition.length === 2 &&
          feature.geometry.coordinates.length === 2 &&
          formatDistance(getDistance(feature.geometry.coordinates, userPosition))
        }
      />
    </Card>
  )
};

export default FriendCardMin;