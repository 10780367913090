import React, { useContext, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';

import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import IconButton from '@mui/material/IconButton';

import api from '../../utils/api.ts';
import AuthContext from '../../context/AuthContext';

import {
  setAlertsSnackbarOpen,
  setAlertsSnackbarSeverity,
  setAlertsSnackbarText,
} from '../../features/app/alertsSnackbarSlice';
import {
  setConfirmRemoveEventFromSavedDialogEvent,
  setConfirmRemoveEventFromSavedDialogOpen,
} from '../../features/dialogs/confirmRemoveEventFromSavedDialogSlice';
import {
  setEventDialogOpen,
  setEventDialogEvent,
} from '../../features/dialogs/eventDialogSlice';
import {
  addSavedEvent,
  removeSavedEventByUuid,
} from '../../features/events/savedEventsSlice';
import {
  addEvent,
  removeEventByUuid,
} from '../../features/events/eventsSlice';

import { getDistance } from '../../utils/mapUtils';

export default function BookmarkButton(props) {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { user } = useContext(AuthContext);
  const [disabled, setDisabled] = useState(false);

  const withinMeters = useSelector((state) => state.settings.withinMeters);
  const userPosition = useSelector((state) => state.userPosition.value);

  const handleClick = async () => {
    if (disabled) { return };
    setDisabled(true);
    if (!user) {
      dispatch(setAlertsSnackbarSeverity('warning'));
      dispatch(setAlertsSnackbarText(t('Please log in to save the event.')));
      dispatch(setAlertsSnackbarOpen(true));

      setTimeout(() => {
        setDisabled(false);
      }, 3000);
      return;
    };

    if (props.event.properties.saved) {
      if (
        userPosition.length !== 2 ||
        getDistance(props.event.geometry.coordinates, userPosition) > withinMeters ||
        props.event.properties.private ||
        props.event.properties.timeline !== 'ongoing' ||
        props.event.properties.cancelled
      ) {
        dispatch(setConfirmRemoveEventFromSavedDialogEvent(props.event));
        dispatch(setConfirmRemoveEventFromSavedDialogOpen(true));
        setTimeout(() => { setDisabled(false) }, 1000);
      } else {
        await api.delete('/events/remove/', {
          'data': {
            uuid: props.event.properties.uuid,
            range: withinMeters,
            longitude: userPosition[0],
            latitude: userPosition[1],
          }
        }).then(response => {
          dispatch(removeSavedEventByUuid(props.event.properties.uuid));
          if (response.data) {
            dispatch(setEventDialogEvent(response.data));
            dispatch(addEvent(response.data));
          } else {
            dispatch(setEventDialogOpen(false));
          };

          dispatch(setAlertsSnackbarSeverity('info'));
          dispatch(setAlertsSnackbarText(t('Removed from saved')));
          dispatch(setAlertsSnackbarOpen(true));

          setDisabled(false);
        }).catch(err => {
          dispatch(setAlertsSnackbarSeverity('error'));
          dispatch(setAlertsSnackbarText(t('Something went wrong.')));
          dispatch(setAlertsSnackbarOpen(true));

          setTimeout(() => { setDisabled(false) }, 1000);
        });
      };
    } else {
      await api.post('/events/save/', { uuid: props.event.properties.uuid, }).then(response => {
        dispatch(setEventDialogEvent(response.data));
        dispatch(removeEventByUuid(props.event.properties.uuid));
        dispatch(addSavedEvent(response.data));

        dispatch(setAlertsSnackbarSeverity('info'));
        dispatch(setAlertsSnackbarText(t('Added to saved')));
        dispatch(setAlertsSnackbarOpen(true));

        setDisabled(false);
      }).catch(err => {
        dispatch(setAlertsSnackbarSeverity('error'));
        if (err.response.status === 404) {
          dispatch(setAlertsSnackbarText(t('No such ongoing event!')));
        } else {
          dispatch(setAlertsSnackbarText(t('Something went wrong.')));
        };
        dispatch(setAlertsSnackbarOpen(true));

        setTimeout(() => { setDisabled(false) }, 1000);
      });
    };
  };

  return (
    <IconButton
      disabled={disabled}
      onClick={handleClick}
      aria-label="invite"
      sx={{ marginLeft: 'auto' }}
    >
      {props.event.properties.saved ? <BookmarkIcon /> : <BookmarkBorderIcon />}
    </IconButton>
  );
};