import dayjs, { Dayjs } from 'dayjs';

import {
  ADDITIONAL_HOURS,
  MIN_PIN_DURATION,
  OFFSET_HOURS,
} from '../consts/pins.ts';
import {
  FREE_MAX_DAYS_FROM_NOW,
  FREE_MAX_DAYS_PIN_DURATION,
  PREMIUM_MAX_DAYS_FROM_NOW,
  PREMIUM_MAX_DAYS_PIN_DURATION,
} from '../limits.ts';

export const getStartMinDateTime = (): Dayjs => {
  return dayjs().subtract(OFFSET_HOURS, 'hour');
};

export const getStartMaxDateTime = (plan: 'free' | 'premium'): Dayjs => {
  const maxDurationDays = plan === 'free' ? FREE_MAX_DAYS_FROM_NOW : PREMIUM_MAX_DAYS_FROM_NOW;
  return dayjs().add(maxDurationDays, 'day');
};

export const getEndMinDateTime = (): Dayjs => {
  return dayjs().add(MIN_PIN_DURATION, 'minute');
};

export const getEndMaxDateTime = (
  startDatetime: Dayjs | null,
  plan: 'free' | 'premium',
): Dayjs => {
  const maxDaysFromNow = plan === 'free' ? FREE_MAX_DAYS_FROM_NOW : PREMIUM_MAX_DAYS_FROM_NOW;
  const pinDuration = plan === 'free' ? FREE_MAX_DAYS_PIN_DURATION : PREMIUM_MAX_DAYS_PIN_DURATION;

  const maxDateTimeForNow = dayjs().add(maxDaysFromNow, 'day').add(ADDITIONAL_HOURS, 'hour');
  const maxDateTimeForPin = startDatetime
    ? dayjs(startDatetime).add(pinDuration, 'day').add(ADDITIONAL_HOURS, 'hour')
    : maxDateTimeForNow;

  return dayjs(Math.min(maxDateTimeForNow.valueOf(), maxDateTimeForPin.valueOf()));
};