import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import * as locales from 'react-date-range/dist/locale';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { LAUNCH_DATE } from '../../config';

import staticRangesGenerator from '../../staticRanges';
import {
  customDialogStyle,
  mainColor,
  noFullScreenDialogPaperProps,
} from '../../theme';

import CloseButton from '../ui/CloseButton.tsx';

const localesMap = {
  'pl': 'pl',
  'en': 'enGB',
};

export default function CalendarDialog(props) {
  const { t } = useTranslation();

  const staticRanges = staticRangesGenerator(t);
  const language = useSelector((state) => state.settings.language);

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      {...noFullScreenDialogPaperProps}
      sx={{ ...customDialogStyle.sx, zIndex: 12 }}
    >
      <DialogTitle textAlign="center">
        {t('Select dates')}
      </DialogTitle>
      <CloseButton onClick={props.handleClose} />
      <DialogContent sx={{ padding: 0 }}>
        <DateRangePicker
          onChange={item => {
            if (item.selection.endDate > new Date()) {
              item.selection.endDate = new Date();
            };
            props.setState([item.selection]);
          }}
          showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          months={1}
          ranges={props.state}
          direction="vertical"
          staticRanges={staticRanges}
          inputRanges={[]}
          weekStartsOn={1}
          minDate={LAUNCH_DATE}
          maxDate={new Date()}
          locale={locales[localesMap[language]]}
          rangeColors={[mainColor]}
        />
      </DialogContent>
    </Dialog>
  );
}