import { createSlice } from '@reduxjs/toolkit';

import { EMPTY_FEATURE } from '../../consts/map';

export const shareEventDialogSlice = createSlice({
  name: 'shareEventDialog',
  initialState: {
    open: false,
    event: EMPTY_FEATURE,
  },
  reducers: {
    resetShareEventDialogEvent: (state) => {
      state.event = EMPTY_FEATURE;
    },
    setShareEventDialogEvent: (state, action) => {
      state.event = action.payload
    },
    setShareEventDialogOpen: (state, action) => {
      state.open = action.payload
    }
  }
})

export const {
  resetShareEventDialogEvent,
  setShareEventDialogEvent,
  setShareEventDialogOpen,
} = shareEventDialogSlice.actions;

export default shareEventDialogSlice.reducer;
