import React from 'react';
import { css, styled } from '@mui/material/styles';

import ListItem from '@mui/material/ListItem';

interface FriendRequestCardMinWrapperProps {
  children: React.ReactNode;
  friendRequests?: [];
};

const StyledListItem = styled(ListItem, { shouldForwardProp: (prop) => prop !== 'friendRequests' }) <FriendRequestCardMinWrapperProps>`
  justify-content: center;
  flex: 0 0 auto;
  padding: 0;
  width: 100%;

  ${(props) => css`
    padding: ${props.friendRequests && props.friendRequests.length <= 1 ? 0 : '4px'};
    width: ${props.friendRequests && props.friendRequests.length > 1 ? '80%' : '100%'};
  `}
`;

const FriendRequestCardMinWrapper: React.FC<FriendRequestCardMinWrapperProps> = ({
  children,
  friendRequests,
}) => {
  return (
    <StyledListItem friendRequests={friendRequests}>
      {children}
    </StyledListItem>
  );
};


FriendRequestCardMinWrapper.defaultProps = {
  friendRequests: [],
};

export default FriendRequestCardMinWrapper;