import { createSlice } from '@reduxjs/toolkit';

import { EMPTY_FEATURE } from '../../consts/map';

export const eventInvitationDialogSlice = createSlice({
  name: 'eventInvitationDialog',
  initialState: {
    open: false,
    invitation: {
      event: EMPTY_FEATURE,
    },
  },
  reducers: {
    setEventInvitationDialogInvitation: (state, action) => {
      state.invitation = action.payload
    },
    setEventInvitationDialogOpen: (state, action) => {
      state.open = action.payload
    }
  }
})

export const {
  setEventInvitationDialogInvitation,
  setEventInvitationDialogOpen,
} = eventInvitationDialogSlice.actions;

export default eventInvitationDialogSlice.reducer;
