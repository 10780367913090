import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import { styled } from '@mui/material/styles';

import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';

import CenteredBox from './CenteredBox.tsx';
import CloseButton from './CloseButton.tsx';
import GoldButton from './GoldButton.tsx';

import { PREMIUM_DISABLED } from '../../config';
import {
  BASE_PRICES_PER_MONTH,
  CURRENCIES,
  CURRENCIES_MAP,
  PERIODS,
  REDUCED_PRICES_PER_MONTH,
} from '../../consts/app';
import {
  grayedOutFilter,
  honeyGlow,
  mutedCoral,
  white,
  WIDTH_BREAKPOINT,
} from '../../theme';
import { roundToOneDecimal } from '../../utils/utils';

import {
  setPremiumConfirmDialogOpen,
  setTrialConfirmDialogOpen,
} from '../../features/app/dialogsSlice';
import {
  setCurrencyCode,
  setPeriod,
  setPremiumDialogOpen,
} from '../../features/dialogs/premiumDialogSlice.ts';

// @ts-ignore
import { ReactComponent as RoundMePlusLogo } from '../../assets/R+ound.Me.svg';

const MenuProps = {
  sx: {
    '&.Mui-selected': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
      },
    },
    '& .MuiPaper-root': {
      borderRadius: '10px',
    },
    '& .MuiList-root': {
      borderRadius: '10px',
      padding: '0 !important',
    },
  },
};

const StyledBadge = styled(Badge)`
  width: 100%;
  display: flex;
  justify-content: center;

  .MuiBadge-badge {
    right: 5rem;
    background-color: ${mutedCoral};
  }
`;

const StyledCenteredBox = styled(CenteredBox)`
  width: 75%;
  border-radius: 10px;
  height: 6rem;
  border: 1px solid white;
  filter: ${PREMIUM_DISABLED ? grayedOutFilter : undefined};
`;

const StyledList = styled(List)`
  list-style-type: disc;
`;

const StyledListItem = styled(ListItem)`
  display: list-item;
  padding: 0.25rem 0;
`;

const StyledMenuItem = styled(MenuItem)`
  justify-content: center;
  min-height: unset;

  &.Mui-selected {
    background-color: rgba(255, 255, 255, 0.1);
    &:hover {
      background-color: ${isMobile ? 'rgba(255, 255, 255, 0.1)' : 'rgba(255, 255, 255, 0.25)'};
    }
  }
`;

const StyledRadio = styled(Radio)`
  & .MuiSvgIcon-root {
    font-size: 1rem;
  }
  &.Mui-checked {
    color: ${!PREMIUM_DISABLED ? honeyGlow : undefined};
  }
`;

const StyledRoundMePlusLogo = styled(RoundMePlusLogo)`
  width: 140px;
  height: 36px;
`;

const StyledSelect = styled(Select)`
  height: 1.5rem;
  margin-left: 0.5rem;
  border-radius: 15px;
  font-size: 0.75rem;

  &.MuiInputBase-root {
    &:hover {
      border-color: ${honeyGlow};
    }
  }

  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${!PREMIUM_DISABLED ? honeyGlow : white};
    &:hover {
      border-color: ${!PREMIUM_DISABLED ? honeyGlow : white};
    }
  }

  &.MuiOutlinedInput-notchedOutline:hover {
    borderColor: ${honeyGlow};
  }
`;

const PremiumDialog: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const width = useSelector((state: any) => state.app.width);
  const open = useSelector((state: any) => state.premiumDialog.open);
  const currencyCode = useSelector((state: any) => state.premiumDialog.currencyCode);
  const [currencySymbol, setCurrencySymbol] = useState(CURRENCIES_MAP[currencyCode]);
  const period = useSelector((state: any) => state.premiumDialog.period);

  const periodsMap = {
    1: t('1 month'),
    3: t('3 months'),
    6: t('6 months'),
    12: t('1 year'),
  };

  const handleClose = () => {
    dispatch(setPremiumDialogOpen(false));
  };

  const handleCurrencyChange = (e: any) => {
    dispatch(setCurrencyCode(e.target.value));
    setCurrencySymbol(CURRENCIES_MAP[e.target.value]);
  };

  const handlePeriodChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setPeriod(+(e.target as HTMLInputElement).value));
  };

  const handleUpgradeClick = () => {
    dispatch(setPremiumConfirmDialogOpen(true));
  };

  const handleTrialClick = () => {
    dispatch(setTrialConfirmDialogOpen(true));
  };

  return (
    <Dialog
      hideBackdrop={width <= WIDTH_BREAKPOINT}
      fullScreen={width <= WIDTH_BREAKPOINT}
      fullWidth
      open={open}
      onClose={handleClose}
      // https://github.com/mui/material-ui/issues/43106#issuecomment-2314809028
      closeAfterTransition={false}
      PaperProps={{ style: { borderRadius: width <= WIDTH_BREAKPOINT ? 'unset' : '25px' } }}
      sx={{
        top: width <= WIDTH_BREAKPOINT ? '8vh' : undefined,
        zIndex: 11,
      }}
    >
      <DialogTitle textAlign="center" style={{ cursor: 'move' }}>
        {t('Upgrade your account')}
      </DialogTitle>
      <CloseButton onClick={handleClose} />
      <DialogContent>
        <CenteredBox>
          <StyledBadge
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            badgeContent={PREMIUM_DISABLED ? t('Currently unavailable') : 0}
          >
            <StyledCenteredBox>
              <StyledRoundMePlusLogo />
            </StyledCenteredBox>
          </StyledBadge>
        </CenteredBox>
        <CenteredBox mt={2}>
          {t('What you get for just')}&nbsp;<strong>{BASE_PRICES_PER_MONTH[currencyCode]} {currencySymbol}/{t('month')}</strong>
        </CenteredBox>
        <CenteredBox mt={1}>
          <Typography variant="caption">
            {t('Change currency')}
          </Typography>
          <StyledSelect
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={currencyCode}
            onChange={handleCurrencyChange}
            onClose={() => {
              setTimeout(() => {
                (document.activeElement as HTMLElement).blur();
              }, 0);
            }}
            MenuProps={MenuProps}
          >
            {CURRENCIES.map(code => (
              <StyledMenuItem key={code} value={code}>{code}</StyledMenuItem>
            ))}
          </StyledSelect>
        </CenteredBox>
        <Box pl={6} pr={2} mt={2}>
          <StyledList>
            <StyledListItem>
              {t('No more pin limits - create as many as you want!')}
            </StyledListItem>
            <StyledListItem disablePadding>
              {t('Why leave anyone out? Invite all your friends to the pins!')}
            </StyledListItem>
            <StyledListItem disablePadding>
              {t('Reach further, explore more - all the pins in range of 2 km at hand!')}
            </StyledListItem>
          </StyledList>
        </Box>
        <CenteredBox mt={3}>
          <Typography variant="subtitle1" fontWeight="bold" color={!PREMIUM_DISABLED ? honeyGlow : undefined}>
            {t('Plus for longer, Plus for less')}
          </Typography>
        </CenteredBox>
        <CenteredBox mt={2}>
          <Typography variant="subtitle2">
            {t('The longer you stay Plus, the more you save!')}
          </Typography>
        </CenteredBox>
        <Box mt={4} pl={3}>
          <FormControl disabled={PREMIUM_DISABLED}>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue={1}
              name="radio-buttons-group"
              value={period}
              onChange={handlePeriodChange}
            >
              {PERIODS.map(periodLength => (
                <FormControlLabel
                  key={periodLength}
                  value={periodLength}
                  control={<StyledRadio />}
                  label={
                    <Typography variant="body2">
                      {periodsMap[periodLength]} {t('for')} {periodLength !== 1 && <s>{roundToOneDecimal(BASE_PRICES_PER_MONTH[currencyCode] * periodLength)}</s>} {roundToOneDecimal(REDUCED_PRICES_PER_MONTH[periodLength][currencyCode] * periodLength)} {currencySymbol} ({t('just')} <strong>{REDUCED_PRICES_PER_MONTH[periodLength][currencyCode]} {currencySymbol}/{t('month')}</strong>)
                    </Typography>
                  }
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Box>
        <CenteredBox mt={3} px={3}>
          <Typography variant="caption">
            {t('In the next step you will see a summary')}
          </Typography>
        </CenteredBox>
        <CenteredBox mt={4}>
          <GoldButton
            variant="contained"
            disabled={PREMIUM_DISABLED}
            onClick={handleUpgradeClick}
          >
            {t('Upgrade')}
          </GoldButton>
        </CenteredBox>
        <CenteredBox mt={3} mb={1}>
          <GoldButton
            variant="text"
            disabled={PREMIUM_DISABLED}
            onClick={handleTrialClick}
          >
            {t('Try for free for 7 days')}
          </GoldButton>
        </CenteredBox>
      </DialogContent>
    </Dialog>
  );
};

export default PremiumDialog;