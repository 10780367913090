import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';

import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Drawer from '@mui/material/Drawer';
import Fab from '@mui/material/Fab';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import TuneIcon from '@mui/icons-material/Tune';
import Typography from '@mui/material/Typography';

import { setEventsCategories } from '../../features/map/mapFiltersSlice';

import { fabBoxShadow } from '../../theme';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function MapFiltersDrawer(props) {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const fabSize = useSelector((state) => state.app.fabSize);

  const eventsCategories = useSelector(state => state.eventsCategories.value)
  const selectedEventsCategories = useSelector(state => state.mapFilters.eventsCategories);

  const userPosition = useSelector((state) => state.userPosition.value);
  const map = useSelector((state) => state.map.value);
  const isMapDragged = useSelector((state) => state.map.isDragged);

  const toggleDrawer = (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    };

    props.setOpen(!props.open);
    props.openRef.current = !props.open;
  };

  const handleChange = (e, newValue) => {
    dispatch(setEventsCategories(newValue.sort((a, b) => a.name.localeCompare(b.name))));

    ['events', 'userEvents', 'savedEvents'].forEach(layer => {
      // props.map.setFilter(layer, ['in', ['get', ['literal', ['categories', 'name']]], ['Accident', 'Party']]);
      // props.map.setFilter(layer, ['in', ['get', 'id', ['literal', ['categories']]], [1, 2]]);
      // props.map.setFilter(layer, ['any', ['in', ['get', 'categoriesIds'], ['literal', [1, 2]]]]);
      // let filters = selectedEventsCategoriesIds.map(c => ['in', parseInt(c), ['get', 'categoriesIds']]);
      let filters = newValue.map(el => ['in', parseInt(el.id), ['get', 'categoriesIds']]);
      map.setFilter(layer, ['any', ...filters]);
    });
    if (newValue.length === 0) {
      ['events', 'userEvents', 'savedEvents'].forEach(layer => {
        map.setFilter(layer, null);
      });
    };
  };

  return (userPosition.length > 0 && (
    <React.Fragment key={'right'} >
      <Fab color="primary" aria-label="add" size={fabSize}
        onClick={toggleDrawer}
        sx={{
          zIndex: 10,
          backgroundColor: 'transparent',
          fontSize: '2.5rem',
          boxShadow: fabBoxShadow,
          pointerEvents: isMapDragged ? 'none' : 'auto',
          '&:hover': {
            backgroundColor: 'transparent',
          },
        }}
      >
        <TuneIcon fontSize="2.5rem" />
      </Fab>
      <Drawer
        anchor="bottom"
        open={props.open}
        // open={true}
        // onClose={toggleDrawer}
        BackdropProps={{ invisible: true }}
        // sx={{top: '7vh'}}
        PaperProps={{
          style: {
            // top: '20vh',
            // bottom: '20vh',
            maxHeight: '25vh',
            display: 'inline-block',
            height: 'auto',
            // minHeight: '50vh',
            borderRadius: '15px 0 0 15px',
            // zIndex: 10,
          }
        }}
        sx={{
          position: 'unset',
          // zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Typography align="center" mt={1} variant="subtitle1">
          {t('Filters')}
        </Typography>
        <Box
          sx={{
            // width: width > 900 ? 'unset' : '35vw',
            // minWidth: width > 900 ? '20vw' : '40vw',
            // maxWidth: '50vw',
            // height: '20vh',
          }}
          role="presentation"
          // onClick={toggleDrawer}
          onKeyDown={toggleDrawer}
        >
          <Stack spacing={1} justifyContent="center" m={2}>
            <Autocomplete
              multiple
              id="categories"
              value={selectedEventsCategories}
              onChange={handleChange}
              options={eventsCategories}
              disableCloseOnSelect
              getOptionLabel={(option) => option.name}
              // https://stackoverflow.com/questions/61947941/material-ui-autocomplete-warning-the-value-provided-to-autocomplete-is-invalid
              isOptionEqualToValue={(option, value) => option.id === value.id}
              // https://stackoverflow.com/questions/72044394/how-do-i-disable-textfield-input-with-mui-autocomplete/72045200
              renderInput={({ inputProps, ...rest }) => (
                <TextField
                  {...rest}
                  variant="standard"
                  label={t('Categories')}
                  inputProps={{ ...inputProps, readOnly: true }}
                  placeholder=""
                />
              )}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </li>
              )}
            />
          </Stack>
        </Box>
      </Drawer>
    </React.Fragment>
  ));
}