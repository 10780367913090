import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import MenuItem from '@mui/material/MenuItem';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';

import api from '../../utils/api.ts';

import {
  setConfigurationStepperOpen,
  setConfirmDeleteAccountDialogOpen,
  setSettingsDialogOpen,
} from '../../features/app/dialogsSlice';
import { setSuggestLanguagesDialogOpen } from '../../features/dialogs/suggestLanguagesDialogSlice.ts';
import {
  setAlwaysShowIntro,
  setKeepScreenOn,
  setLanguage,
  setNewEventsAnonymous,
  setNewPinsPrivate,
  setOthersCanInvite,
  setShareLocationWith,
} from '../../features/settings/settingsSlice';

import { APP_NAME } from '../../config';
import { CHECK_PERMISSIONS_INTERVAL } from '../../consts/intervals';
import {
  customDialogStyle,
  sunsetOrange,
  WIDTH_BREAKPOINT,
} from '../../theme';

import IntervalContext from '../../context/IntervalContext';

import CenteredBox from '../ui/CenteredBox.tsx';
import CloseButton from '../ui/CloseButton.tsx';
import SmallLoader from '../ui/SmallLoader.tsx';
import SuggestLanguagesDialog from '../ui/SuggestLanguagesDialog.tsx';

const StyledGridItem1 = styled(Grid)`
  display: flex;
  align-items: center;
`;

const StyledGridItem2 = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: end;
`;

interface SettingsDialogProps {
  bottomBar?: boolean;
};

const SettingsDialog: React.FC<SettingsDialogProps> = ({ bottomBar }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { addInterval } = useContext(IntervalContext);

  const shareLocationWith = useSelector((state: any) => state.settings.shareLocationWith);
  const keepScreenOn = useSelector((state: any) => state.settings.keepScreenOn);
  const newEventsAnonymous = useSelector((state: any) => state.settings.newEventsAnonymous);
  const newPinsPrivate = useSelector((state: any) => state.settings.newPinsPrivate);
  const othersCanInvite = useSelector((state: any) => state.settings.othersCanInvite);
  const alwaysShowIntro = useSelector((state: any) => state.settings.alwaysShowIntro);
  const language = useSelector((state: any) => state.settings.language);
  const width = useSelector((state: any) => state.app.width);

  const [loading, setLoading] = useState(true);

  const initialLoad = useRef(true);

  const open = useSelector((state: any) => state.dialogs.settingsDialogOpen);

  const [shareLocationWithSelectDisabled, setShareLocationWithSelectDisabled] = useState(false);
  const [languageSelectDisabled, setLanguageSelectDisabled] = useState(false);
  const [keepScreenOnSwitchDisabled, setKeepScreenOnSwitchDisabled] = useState(false);
  const [alwaysShowIntroSwitchDisabled, setAlwaysShowIntroSwitchDisabled] = useState(false);
  const [newEventsAnonymousSwitchDisabled, setNewEventsAnonymousSwitchDisabled] = useState(false);
  const [newPinsPrivateSwitchDisabled, setNewPinsPrivateSwitchDisabled] = useState(false);
  const [othersCanInviteSwitchDisabled, setOthersCanInviteSwitchDisabled] = useState(false);

  const [locationAllowed, setLocationAllowed] = useState(false);
  const [notificationsAllowed, setNotificationsAllowed] = useState(false);
  const [cameraAllowed, setCameraAllowed] = useState(false);

  const _locationAllowed = useSelector((state: any) => state.map.locationAllowed);
  const _notificationsAllowed = useSelector((state: any) => state.notifications.notificationsAllowed);
  const _cameraAllowed = useSelector((state: any) => state.app.cameraAllowed);

  const handleClose = () => {
    dispatch(setSettingsDialogOpen(false));
  };

  const handleShareLocationWithChange = async (e: SelectChangeEvent) => {
    e.stopPropagation();
    setShareLocationWithSelectDisabled(true);
    setLoading(true);
    dispatch(setShareLocationWith(e.target.value));
    await api.post('/users/settings/', {
      shareLocationWith: e.target.value,
    }).then(response => {
      setShareLocationWithSelectDisabled(false);
      setLoading(false);
    });
  };

  const handleKeepScreenOnChange = async (e) => {
    e.stopPropagation();
    setKeepScreenOnSwitchDisabled(true);
    setLoading(true);
    dispatch(setKeepScreenOn(e.target.checked));
    await api.post('/users/settings/', {
      keepScreenOn: e.target.checked,
    }).then(response => {
      setKeepScreenOnSwitchDisabled(false);
      setLoading(false);
    });
  };

  const handleNewEventsAnonymous = async (e) => {
    e.stopPropagation();
    setLoading(true);
    setNewEventsAnonymousSwitchDisabled(true);
    dispatch(setNewEventsAnonymous(e.target.checked));
    await api.post('/users/settings/', {
      newEventsAnonymous: e.target.checked,
    }).then(response => {
      setNewEventsAnonymousSwitchDisabled(false);
      setLoading(false);
    });
  };

  const handleNewPinsPrivate = async (e) => {
    e.stopPropagation();
    setLoading(true);
    setNewPinsPrivateSwitchDisabled(true);
    dispatch(setNewPinsPrivate(e.target.checked));
    await api.post('/users/settings/', {
      newPinsPrivate: e.target.checked,
    }).then(response => {
      setNewPinsPrivateSwitchDisabled(false);
      setLoading(false);
    });
  };

  const handleOthersCanInvite = async (e) => {
    e.stopPropagation();
    setLoading(true);
    setOthersCanInviteSwitchDisabled(true);
    dispatch(setOthersCanInvite(e.target.checked));
    await api.post('/users/settings/', {
      othersCanInvite: e.target.checked,
    }).then(response => {
      setOthersCanInviteSwitchDisabled(false);
      setLoading(false);
    });
  };

  const handleAlwaysShowIntro = async (e) => {
    e.stopPropagation();
    setLoading(true);
    setAlwaysShowIntroSwitchDisabled(true);
    dispatch(setAlwaysShowIntro(e.target.checked));
    localStorage.setItem('alwaysShowIntro', e.target.checked);
    await api.post('/users/settings/', {
      alwaysShowIntro: e.target.checked,
    }).then(response => {
      setAlwaysShowIntroSwitchDisabled(false);
      setLoading(false);
    });
  };

  const handleLanguageChange = async (e: SelectChangeEvent) => {
    setLanguageSelectDisabled(true);
    setLoading(true);
    dispatch(setLanguage(e.target.value));
    await api.post('/users/settings/', {
      language: e.target.value,
    }).then(response => {
      setLanguageSelectDisabled(false);
      setLoading(false);
    });
  };

  const handleSuggestOthersClick = () => {
    dispatch(setSuggestLanguagesDialogOpen(true));
  };

  const fetchData = async () => {
    if (open) {
      setLoading(true);
      await api.get('/users/settings/').then(response => {
        dispatch(setShareLocationWith(response.data.shareLocationWith));
        dispatch(setKeepScreenOn(response.data.keepScreenOn));
        dispatch(setNewEventsAnonymous(response.data.newEventsAnonymous));
        dispatch(setNewPinsPrivate(response.data.newPinsPrivate));
        dispatch(setOthersCanInvite(response.data.othersCanInvite));
        dispatch(setAlwaysShowIntro(response.data.alwaysShowIntro));
        localStorage.setItem('alwaysShowIntro', response.data.alwaysShowIntro);
        dispatch(setLanguage(response.data.language));
        setLoading(false);
      });
    };
  };

  useEffect(() => {
    // https://stackoverflow.com/questions/53253940/make-react-useeffect-hook-not-run-on-initial-render
    if (initialLoad.current) {
      initialLoad.current = false;
      return;
    } else {
      fetchData();
    };
  }, [open]);  // FIXME

  useEffect(() => {
    var interval;

    const checkPermissions = () => {
      navigator.permissions.query({ name: 'camera' as PermissionName })
        .then((result) => {
          setCameraAllowed(result.state === 'granted');
        });

      navigator.permissions.query({ name: 'geolocation' })
        .then((result) => {
          setLocationAllowed(result.state === 'granted');
        });

      navigator.permissions.query({ name: 'notifications' })
        .then((result) => {
          setNotificationsAllowed(result.state === 'granted');
        });
    };

    if (open && navigator.permissions) {
      checkPermissions();
      interval = addInterval(() => {
        checkPermissions();
      }, CHECK_PERMISSIONS_INTERVAL);
    };

    return () => clearInterval(interval);
  }, [open]);

  return (
    <>
      <Dialog
        hideBackdrop={width <= WIDTH_BREAKPOINT}
        fullScreen={width <= WIDTH_BREAKPOINT}
        fullWidth
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            borderRadius: width <= WIDTH_BREAKPOINT ? 'unset' : '25px',
            // top: width > WIDTH_BREAKPOINT ? '-5vh' : 'unset',
          }
        }}
        {...customDialogStyle}
      >
        <Box sx={{ marginBottom: bottomBar && width <= WIDTH_BREAKPOINT ? '12vh' : 'unset' }}>
          <DialogTitle textAlign="center">
            {t('Settings')}
            {loading && <SmallLoader />}
          </DialogTitle>
          <CloseButton onClick={handleClose} />
          <DialogContent sx={{
            // marginBottom: width <= WIDTH_BREAKPOINT ? '12vh' : 'unset'
          }}>
            <Typography variant="h6">{t('General')}</Typography>
            <Grid container mt={2}>
              <StyledGridItem1 item xs={8}>
                <Typography>{t('Language')}</Typography>
              </StyledGridItem1>
              <StyledGridItem2 item xs={4}>
                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={language}
                    onChange={handleLanguageChange}
                    disabled={languageSelectDisabled}
                  >
                    <MenuItem value={'en'}>{t('English')}</MenuItem>
                    <MenuItem value={'pl'}>{t('Polish')}</MenuItem>
                  </Select>
                </FormControl>
              </StyledGridItem2>
            </Grid>
            <Grid container>
              <Grid item xs={8} />
              <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button variant="text" onClick={handleSuggestOthersClick}>
                  <Typography variant="caption">
                    {t('Suggest others')}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
            <Grid container mt={2}>
              <StyledGridItem1 item xs={8}>
                <Typography>{t('Keep screen on')}</Typography>
              </StyledGridItem1>
              <StyledGridItem2 item xs={4}>
                <Switch
                  checked={keepScreenOn || false}
                  onChange={handleKeepScreenOnChange}
                  disabled={keepScreenOnSwitchDisabled}
                />
              </StyledGridItem2>
            </Grid>
            <Typography variant="caption">
              {t('Turning this option on prevents the screen from automatically turning off while using the app. \
May impact battery life.')}
            </Typography>
            <Grid container mt={2}>
              <StyledGridItem1 item xs={8}>
                <Typography>{t('Always show intro')}</Typography>
              </StyledGridItem1>
              <StyledGridItem2 item xs={4}>
                <Switch
                  checked={alwaysShowIntro || false}
                  onChange={handleAlwaysShowIntro}
                  disabled={alwaysShowIntroSwitchDisabled}
                />
              </StyledGridItem2>
            </Grid>
            <Typography variant="caption">
              {t('If you enable this setting, the app will display introduction steps every time you open it.')}
            </Typography>
            <Typography variant="h6" mt={4}>{t('Pins')}</Typography>
            <Grid container mt={2}>
              <StyledGridItem1 item xs={8}>
                <Typography>{t('New pins anonymous')}</Typography>
              </StyledGridItem1>
              <StyledGridItem2 item xs={4}>
                <Switch
                  checked={newEventsAnonymous || false}
                  onChange={handleNewEventsAnonymous}
                  disabled={newEventsAnonymousSwitchDisabled}
                />
              </StyledGridItem2>
            </Grid>
            <Typography variant="caption">
              {t('Enabling this setting will make all new pins created in the app anonymous by default.')}
            </Typography>
            <Grid container mt={2}>
              <StyledGridItem1 item xs={8}>
                <Typography>{t('New pins private')}</Typography>
              </StyledGridItem1>
              <StyledGridItem2 item xs={4}>
                <Switch
                  checked={newPinsPrivate || false}
                  onChange={handleNewPinsPrivate}
                  disabled={newPinsPrivateSwitchDisabled}
                />
              </StyledGridItem2>
            </Grid>
            <Typography variant="caption">
              {t('Enabling this setting will make all new pins created in the app private by default.')}
            </Typography>
            <Grid container mt={2}>
              <StyledGridItem1 item xs={8}>
                <Typography>{t('Others can invite')}</Typography>
              </StyledGridItem1>
              <StyledGridItem2 item xs={4}>
                <Switch
                  checked={othersCanInvite || false}  // true?
                  onChange={handleOthersCanInvite}
                  disabled={othersCanInviteSwitchDisabled}
                />
              </StyledGridItem2>
            </Grid>
            <Typography variant="caption">
              {t('Enabling this setting will allow other users to send invitations to all new pins created in the app by default.')}
            </Typography>
            <Typography variant="h6" mt={4}>{t('Privacy')}</Typography>
            <Grid container mt={2}>
              <StyledGridItem1 item xs={8}>
                <Typography>{t('Share location with')}</Typography>
              </StyledGridItem1>
              <StyledGridItem2 item xs={4}>
                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={shareLocationWith}
                    onChange={handleShareLocationWithChange}
                    disabled={shareLocationWithSelectDisabled}
                  >
                    <MenuItem value={0}>{t('no one')}</MenuItem>
                    <MenuItem value={1}>{t('friends')}</MenuItem>
                    <MenuItem value={2}>{t('everyone')}</MenuItem>
                  </Select>
                </FormControl>
              </StyledGridItem2>
            </Grid>
            <Typography variant="caption">
              {t("Your location will be shared with other users of the app based on selection. \
Please make sure that you're comfortable with selected option before changing this setting.")}
            </Typography>
            <Typography variant="h6" mt={4}>{t('App permissions')}</Typography>
            <Typography variant="caption">
              {t('App permissions are managed by the browser you used to install our app. \
If you want to change any of permissions below please look for {{appName}} settings in your browser.', { appName: APP_NAME })}
            </Typography>
            <Grid container mt={2}>
              <StyledGridItem1 item xs={8}>
                <Typography>{t('Location services')}</Typography>
              </StyledGridItem1>
              <StyledGridItem2 item xs={4}>
                <Switch
                  checked={locationAllowed || _locationAllowed}
                  disabled
                />
              </StyledGridItem2>
            </Grid>
            <Typography variant="caption">
              {t("Enabling this setting will allow the app to access your device's location.")}
            </Typography>
            <Grid container mt={2}>
              <StyledGridItem1 item xs={8}>
                <Typography>{t('Push notifications')}</Typography>
              </StyledGridItem1>
              <StyledGridItem2 item xs={4}>
                <Switch
                  checked={notificationsAllowed || _notificationsAllowed}
                  disabled
                />
              </StyledGridItem2>
            </Grid>
            <Typography variant="caption">
              {t('Enabling this setting will allow the app to send notifications directly to your device.')}
            </Typography>
            <Grid container mt={2}>
              <StyledGridItem1 item xs={8}>
                <Typography>{t('Camera access')}</Typography>
              </StyledGridItem1>
              <StyledGridItem2 item xs={4}>
                <Switch
                  checked={cameraAllowed || _cameraAllowed}
                  disabled
                />
              </StyledGridItem2>
            </Grid>
            <Typography variant="caption">
              {t('Enabling this setting will allow the app to access your camera for scanning QR codes.')}
            </Typography>
          </DialogContent>
          <CenteredBox mb={width <= WIDTH_BREAKPOINT ? 4 : 3} mt={2}>
            <Button
              onClick={() => {
                dispatch(setConfigurationStepperOpen(true));
              }}
              startIcon={<ManageHistoryIcon />}
            >
              {t('Configure')}
            </Button>
          </CenteredBox>
          <Divider />
          <CenteredBox m={width <= WIDTH_BREAKPOINT ? 4 : 3}>
            <Button
              onClick={() => {
                dispatch(setConfirmDeleteAccountDialogOpen(true));
              }}
              startIcon={<NoAccountsIcon />}
              sx={{ color: sunsetOrange }}
            >
              {t('Delete account')}
            </Button>
          </CenteredBox>
          {bottomBar && width <= WIDTH_BREAKPOINT && (
            <Divider sx={{ marginBottom: '2rem' }} />
          )}
        </Box>
      </Dialog>
      <SuggestLanguagesDialog />
    </>
  );
};

export default SettingsDialog;