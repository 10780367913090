import { createSlice } from '@reduxjs/toolkit'

export const userPositionSlice = createSlice({
  name: 'userPosition',
  initialState: {
    value: [],
  },
  reducers: {
    setUserPosition: (state, action) => {
      state.value = action.payload
    },
    resetUserPosition: (state) => {
      state.value = [];
    },
  },
})

export const {
  resetUserPosition,
  setUserPosition,
} = userPositionSlice.actions;

export default userPositionSlice.reducer;