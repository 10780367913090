import React from 'react';
import { css, styled } from '@mui/material/styles';

import AnnouncementOutlinedIcon from '@mui/icons-material/AnnouncementOutlined';
import Box from '@mui/material/Box';
import Stack, { StackProps } from '@mui/material/Stack';
import Typography, { TypographyTypeMap } from '@mui/material/Typography';

import CenteredBox from './CenteredBox.tsx';

interface NoContentAlertProps extends StackProps {
  text?: string;
  subText?: string;
  size?: string;
  color?: string;
  variant?: TypographyTypeMap['props']['variant'];
  subVariant?: TypographyTypeMap['props']['variant'];
};

const StyledAnnouncementOutlinedIcon = styled(AnnouncementOutlinedIcon) <NoContentAlertProps>`
  ${(props) => css`
    font-size: ${props.size};
  `}
`;

const TextWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const NoContentAlert: React.FC<NoContentAlertProps> = ({
  text,
  subText,
  size,
  color,
  direction,
  spacing,
  variant,
  subVariant,
}) => {
  return (
    <>
      <Stack spacing={spacing} direction={direction} sx={{ color: color }}>
        <CenteredBox>
          <StyledAnnouncementOutlinedIcon size={size} />
        </CenteredBox>
        {text && (
          <TextWrapper>
            <Typography variant={variant}>
              {text}
            </Typography>
          </TextWrapper>
        )}
        {subText && (
          <TextWrapper>
            <Typography variant={subVariant}>
              {subText}
            </Typography>
          </TextWrapper>
        )}
      </Stack>
    </>
  );
};

NoContentAlert.defaultProps = {
  text: '',
  size: '70px',
  direction: 'column',
  spacing: 4,
  color: '#fff',
};

export default NoContentAlert;