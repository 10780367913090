import React from 'react';
import { styled } from '@mui/material/styles';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';

const StyledIconButton = styled(IconButton)`
  position: relative;
  top: 12px;
  color: #fff;
`;

interface BackButtonProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  sx?: {}
}

const BackButton: React.FC<BackButtonProps> = ({ onClick, sx }) => {
  return (
    <StyledIconButton
      aria-label="close"
      onClick={onClick}
      sx={sx}
    >
      <ArrowBackIcon />
    </StyledIconButton>
  );
};

export default BackButton;