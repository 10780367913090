import { createSlice } from '@reduxjs/toolkit';

import { EMPTY_FEATURE } from '../../consts/map';

export const editEventDialogSlice = createSlice({
  name: 'editEventDialog',
  initialState: {
    open: false,
    event: EMPTY_FEATURE,
  },
  reducers: {
    resetEditEventDialogEvent: (state) => {
      state.event = EMPTY_FEATURE;
    },
    setEditEventDialogEvent: (state, action) => {
      state.event = action.payload
    },
    setEditEventDialogOpen: (state, action) => {
      state.open = action.payload
    }
  }
})

export const {
  resetEditEventDialogEvent,
  setEditEventDialogEvent,
  setEditEventDialogOpen,
} = editEventDialogSlice.actions;

export default editEventDialogSlice.reducer;
