import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import thunk from 'redux-thunk';

import accountReducer from '../features/account/accountSlice.ts';
import alertsSnackbarReducer from '../features/app/alertsSnackbarSlice';
import appReducer from '../features/app/appSlice';
import authDialogReducer from '../features/dialogs/authDialogSlice.ts';
import confirmDeleteEventDialogReducer from '../features/dialogs/confirmDeleteEventDialogSlice';
import confirmCancelEventDialogReducer from '../features/dialogs/confirmCancelEventDialogSlice.ts';
import confirmEndEventDialogReducer from '../features/dialogs/confirmEndEventDialogSlice';
import confirmRemoveEventFromSavedDialogReducer from '../features/dialogs/confirmRemoveEventFromSavedDialogSlice';
import confirmRemoveFriendReducer from '../features/dialogs/confirmRemoveFriendDialogSlice';
import createNewMenuReducer from '../features/map/createNewMenuSlice.ts';
import dialogsReducer from '../features/app/dialogsSlice';
import editEventDialogReducer from '../features/dialogs/editEventDialogSlice';
import eventDialogReducer from '../features/dialogs/eventDialogSlice';
import eventInvitationDialogReducer from '../features/dialogs/eventInvitationDialogSlice';
import eventsReducer from '../features/events/eventsSlice';
import eventsCategoriesReducer from '../features/events/eventsCategoriesSlice';
import eventInvitationsReducer from '../features/events/eventInvitationsSlice';
import imageDialogReducer from '../features/dialogs/imageDialogSlice';
import inviteFriendDialogReducer from '../features/dialogs/inviteFriendDialogSlice.ts';
import inviteFriendsDialogReducer from '../features/dialogs/inviteFriendsDialogSlice';
import mapReducer from '../features/map/mapSlice';
import mapFiltersReducer from '../features/map/mapFiltersSlice';
import networkReducer from '../features/network/networkSlice';
import newEventDialogReducer from '../features/dialogs/newEventDialogSlice.ts';
import notificationsReducer from '../features/app/notificationsSlice';
import premiumDialogReducer from '../features/dialogs/premiumDialogSlice.ts';
import premiumInfoDialogReducer from '../features/dialogs/premiumInfoDialogSlice.ts';
import profileReducer from '../features/profile/profileSlice';
import reportDialogReducer from '../features/dialogs/reportDialogSlice';
import savedEventsReducer from '../features/events/savedEventsSlice';
import settingsReducer from '../features/settings/settingsSlice';
import shareEventDialogReducer from '../features/dialogs/shareEventDialogSlice';
import suggestLanguagesDialogReducer from '../features/dialogs/suggestLanguagesDialogSlice.ts';
import userDialogReducer from '../features/dialogs/userDialogSlice';
import userEventsReducer from '../features/events/userEventsSlice';
import userPositionReducer from '../features/settings/userPositionSlice';
import usersReducer from '../features/users/usersSlice';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'mapFilters',
    'premiumDialog',
  ],
};

const rootReducer = combineReducers({
  account: accountReducer,
  alertsSnackbar: alertsSnackbarReducer,
  app: appReducer,
  authDialog: authDialogReducer,
  confirmCancelEventDialog: confirmCancelEventDialogReducer,
  confirmDeleteEventDialog: confirmDeleteEventDialogReducer,
  confirmEndEventDialog: confirmEndEventDialogReducer,
  confirmRemoveEventFromSavedDialog: confirmRemoveEventFromSavedDialogReducer,
  confirmRemoveFriendDialog: confirmRemoveFriendReducer,
  createNewMenu: createNewMenuReducer,
  dialogs: dialogsReducer,
  editEventDialog: editEventDialogReducer,
  eventDialog: eventDialogReducer,
  eventInvitationDialog: eventInvitationDialogReducer,
  events: eventsReducer,
  eventsCategories: eventsCategoriesReducer,
  eventInvitations: eventInvitationsReducer,
  imageDialog: imageDialogReducer,
  inviteFriendDialog: inviteFriendDialogReducer,
  inviteFriendsDialog: inviteFriendsDialogReducer,
  map: mapReducer,
  mapFilters: mapFiltersReducer,
  network: networkReducer,
  newEventDialog: newEventDialogReducer,
  notifications: notificationsReducer,
  premiumDialog: premiumDialogReducer,
  premiumInfoDialog: premiumInfoDialogReducer,
  profile: profileReducer,
  reportDialog: reportDialogReducer,
  savedEvents: savedEventsReducer,
  settings: settingsReducer,
  shareEventDialog: shareEventDialogReducer,
  suggestLanguagesDialog: suggestLanguagesDialogReducer,
  userDialog: userDialogReducer,
  userEvents: userEventsReducer,
  userPosition: userPositionReducer,
  users: usersReducer,
});

export const store = configureStore({
  reducer: persistReducer(persistConfig, rootReducer),
  middleware: [thunk],
});

export const persistor = persistStore(store);