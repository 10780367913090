import { createSlice } from '@reduxjs/toolkit';

import { EMPTY_GEOJSON } from '../../consts/map';

export const eventsSlice = createSlice({
  name: 'events',
  initialState: {
    value: EMPTY_GEOJSON,
  },
  reducers: {
    setEvents: (state, action) => {
      state.value = action.payload
    },
    addEvent: (state, action) => {
      const { uuid, properties, geometry } = action.payload;

      // Check if event with the same UUID already exists
      const existingEventIndex = state.value.features.findIndex(
        feature => feature.properties.uuid === uuid
      );

      if (existingEventIndex === -1) {
        const newFeature = {
          type: 'Feature',
          properties: {
            uuid,
            ...properties,
          },
          geometry,
        };

        state.value = {
          ...state.value,
          features: [...state.value.features, newFeature],
        };
      }
    },
    updateEventByUuid: (state, action) => {
      const { uuid, newProperties } = action.payload;
      const eventIndex = state.value.features.findIndex(e => e.properties.uuid === uuid);

      if (eventIndex !== -1) {
        const newFeature = {
          type: 'Feature',
          properties: {
            ...state.value.features[eventIndex].properties,
            ...newProperties,
          },
          geometry: state.value.features[eventIndex].geometry,
        };
        state.value.features[eventIndex] = newFeature;
      };
    },
    removeEventByUuid: (state, action) => {
      const uuid = action.payload;
      state.value = {
        ...state.value,
        features: state.value.features.filter(feature => feature.properties.uuid !== uuid),
      };
    },
    resetEvents: (state) => {
      state.value = EMPTY_GEOJSON;
    },
  },
})

export const {
  addEvent,
  removeEventByUuid,
  resetEvents,
  setEvents,
  updateEventByUuid,
} = eventsSlice.actions;

export default eventsSlice.reducer;
