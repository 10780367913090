import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
// import Router from "next/router";
import { API_URL } from "../config";

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  const token = JSON.parse(localStorage.getItem("authTokens"));
  if (token) {
    config.headers["Authorization"] = `Bearer ${token.access}`;
  }
  return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  return Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  return response;
};

let isRefreshing = false; // Flag to prevent multiple token refresh requests
let refreshPromise = null; // Promise for token refresh request
let failedRequestsQueue = []; // Queue to hold failed requests

const onResponseError = async (error: AxiosError): Promise<AxiosError> => {
  if (error.response) {
    // Access Token was expired
    if (error.response.status === 401) {
      const originalRequest = error.config;

      // Check if token refresh is already in progress
      if (!isRefreshing) {
        isRefreshing = true;

        const storedToken = JSON.parse(localStorage.getItem("authTokens"));
        if (storedToken) {
          if (!refreshPromise) {
            refreshPromise = axios
              .post(`${API_URL}/auth/token/refresh/`, {
                refresh: storedToken.refresh,
              })
              .then((response) => {
                // maybe todo check 403
                const newTokens = response.data;
                localStorage.setItem("authTokens", JSON.stringify(newTokens));

                // Update the headers for all queued requests with the new token
                failedRequestsQueue.forEach((request) => {
                  request.config.headers["Authorization"] = `Bearer ${newTokens.access}`;
                });

                // Retry all the queued requests
                const retryPromises = failedRequestsQueue.map((request) =>
                  axios(request.config)
                    .then(request.resolve)
                    .catch(request.reject)
                );

                failedRequestsQueue = [];
                isRefreshing = false;
                refreshPromise = null;

                return Promise.all(retryPromises);
              })
              .catch((refreshError) => {
                failedRequestsQueue.forEach((request) => {
                  request.reject(refreshError);
                });

                failedRequestsQueue = [];
                isRefreshing = false;
                refreshPromise = null;

                return Promise.reject(refreshError);
              });
          }

          // Add the current request to the queue
          return new Promise((resolve, reject) => {
            failedRequestsQueue.push({ resolve, reject, config: originalRequest });
          });
        }
      } else {
        // Token refresh is already in progress, add the request to the queue
        return new Promise((resolve, reject) => {
          failedRequestsQueue.push({ resolve, reject, config: originalRequest });
        });
      }
    }
  }
  return Promise.reject(error);
};

export const setupInterceptorsTo = (
  axiosInstance: AxiosInstance
): AxiosInstance => {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
};