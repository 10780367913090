import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import SendIcon from '@mui/icons-material/Send';
import TextField from '@mui/material/TextField';

import CloseButton from './CloseButton.tsx';
import PaperComponent from '../PaperComponent';
import SmallLoader from './SmallLoader.tsx';

import api from '../../utils/api.ts';

import {
  setAlertsSnackbarOpen,
  setAlertsSnackbarSeverity,
  setAlertsSnackbarText,
} from '../../features/app/alertsSnackbarSlice';
import {
  setReportCategories,
  setReportDialogOpen,
} from '../../features/dialogs/reportDialogSlice';
import { updateEventByUuid } from '../../features/events/eventsSlice';
import { updateSavedEventByUuid } from '../../features/events/savedEventsSlice';
import { updateFriendByUuid } from '../../features/network/networkSlice';
import { updateUserByUuid } from '../../features/users/usersSlice';

import { borderedDialogPaperProps } from '../../theme';

interface ReportCategoryProps {
  id: number;
  name: string;
};

const ReportDialog: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const reportCategories = useSelector((state: any) => state.reportDialog.reportCategories);
  const feature = useSelector((state: any) => state.reportDialog.feature);
  const open = useSelector((state: any) => state.reportDialog.open);
  const subject = useSelector((state: any) => state.reportDialog.subject);

  const [loading, setLoading] = useState(false);
  const [message, setMessasge] = useState('');
  const [selectedReportCategories, setSelectedReportCategories] = useState<ReportCategoryProps[]>([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const handleClose = () => {
    dispatch(setReportDialogOpen(false));
  };

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setLoading(true);
    setButtonDisabled(true);
    await api.post(`/report/${subject}/new/`, {
      uuid: feature.properties.uuid,
      message: message,
      categories: selectedReportCategories.map(c => c.id),
    }).then(response => {
      if (response.status === 201) {
        const updateData = {
          uuid: feature.properties.uuid,
          newProperties: { reported: true },
        };
        /* todo: check if saved / friend */
        if (subject === 'event') {
          dispatch(updateEventByUuid(updateData));
          dispatch(updateSavedEventByUuid(updateData));
        } else if (subject === 'user') {
          dispatch(updateUserByUuid(updateData));
          dispatch(updateFriendByUuid(updateData));
        };
        dispatch(setReportDialogOpen(false));
        setLoading(false);
        dispatch(setAlertsSnackbarSeverity('success'));
        dispatch(setAlertsSnackbarText(t('Thank you for reporting.')));
        dispatch(setAlertsSnackbarOpen(true));
      };
    }).catch(err => {
      dispatch(setAlertsSnackbarSeverity('error'));
      dispatch(setAlertsSnackbarText(t('Something went wrong.')));
      dispatch(setAlertsSnackbarOpen(true));
      setButtonDisabled(false);
      setLoading(false);
    });
  };

  const getReportCategories = async () => {
    setLoading(true);
    await api.get('/categories/report/').then(response => {
      dispatch(setReportCategories(response.data));
      setLoading(false);
    }).catch(err => {
      setLoading(false);
    });
  };

  useEffect(() => {
    if (open) {
      getReportCategories();
    } else {
      setTimeout(() => {
        setMessasge('');
        setSelectedReportCategories([]);
        setButtonDisabled(false);
      }, 100);
    };
  }, [open]);

  return (
    <Dialog
      open={open}
      PaperComponent={PaperComponent}
      hideBackdrop={false}
      onClose={handleClose}
      fullWidth
      aria-labelledby="draggable-dialog-title"
      aria-describedby="alert-dialog-description"
      {...borderedDialogPaperProps}
    >
      <DialogTitle textAlign="center">
        {t('Report')}{loading && (<SmallLoader />)}
      </DialogTitle>
      <DialogContent>
        <CloseButton onClick={handleClose} />
        <DialogContentText>
          {subject === 'user' && `${t('User')}: ${feature.properties.username || feature.properties.uuid}`}
          {subject === 'event' && `${t('Event')}: ${feature.properties.name}`}
        </DialogContentText>
        <form onSubmit={handleSubmit}>
          <TextField
            id="message"
            label={t('Message')}
            margin="dense"
            multiline
            fullWidth
            variant="standard"
            rows={8}
            inputProps={{
              maxLength: 1024,
            }}
            value={message}
            onChange={(newValue) => {
              setMessasge(newValue.target.value);
            }}
          // defaultValue={message}
          />
          <Autocomplete
            multiple
            noOptionsText={t('No options')}
            id="categories"
            options={reportCategories}
            disableCloseOnSelect
            getOptionLabel={(option: ReportCategoryProps) => option.name}
            value={selectedReportCategories}
            onChange={(e, value) => setSelectedReportCategories(value)}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label={t('Categories')}
                placeholder=""
                margin="normal"
              />
            )}
          />
          <ButtonGroup
            // fullWidth
            sx={{
              // bgcolor: 'primary.main',
              // height: '7vh',
              // marginTop: '50px',
              display: 'flex',
              justifyContent: 'center',
            }}>
            <Button
              type="submit"
              disabled={buttonDisabled}
              variant="contained"
              endIcon={<SendIcon />}
            // sx={{ borderRadius: '25px' }}
            >
              {t('Send')}
            </Button>
          </ButtonGroup>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default ReportDialog;