import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Link from '@mui/material/Link';

import CloseButton from '../ui/CloseButton.tsx';
import PaperComponent from '../PaperComponent';

import { setBannedPinDialogOpen } from '../../features/app/dialogsSlice';

import { CONTACT_EMAIL } from '../../config';
import { borderedDialogPaperProps } from '../../theme';

const BannedPinDialog: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const open = useSelector((state: any) => state.dialogs.bannedPinDialogOpen);

  const handleClose = () => {
    dispatch(setBannedPinDialogOpen(false));
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperComponent={PaperComponent}
      hideBackdrop={false}
      {...borderedDialogPaperProps}
      sx={{ zIndex: 1404 }}
    >
      <DialogTitle textAlign="center" variant="subtitle1">
        {t('Your pin has been banned')}
      </DialogTitle>
      <CloseButton onClick={handleClose} top="10px" />
      <DialogContent>
        <DialogContentText variant="subtitle2">
          {t('For more details please contact us \
using')} <Link href="/contact/">{t('contact form')}</Link> {t('or contact \
email')} <Link href={`mailto:${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</Link>.
        </DialogContentText>
      </DialogContent>
    </Dialog>
  )
};

export default BannedPinDialog;