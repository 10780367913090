import React from 'react';
import { css, styled } from '@mui/material/styles';

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

interface CloseButtonProps {
  top?: string;
  right?: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
};

const StyledIconButton = styled(IconButton,
  { shouldForwardProp: (prop) => prop !== 'top' && prop !== 'right' }
) <{ top: string, right: string }>`
  position: absolute;
  color: #fff;

  ${(props) => css`
    top: ${props.top};
    right: ${props.right}
  `}
`;

const CloseButton: React.FC<CloseButtonProps> = ({
  top = '12px',
  right = '10px',
  onClick,
}) => {
  return (
    <StyledIconButton
      aria-label="close"
      onClick={onClick}
      top={top}
      right={right}
    >
      <CloseIcon />
    </StyledIconButton>
  );
};

export default CloseButton;