import { createSlice } from '@reduxjs/toolkit';

export const createNewMenuSlice = createSlice({
  name: 'createNewMenu',
  initialState: {
    anchorEl: null,
    center: false,
  },
  reducers: {
    setCreateNewMenuCenter: (state, action) => {
      state.center = action.payload;
    },
    setCreateNewMenuAnchorEl: (state, action) => {
      state.anchorEl = action.payload;
    },
  }
})

export const {
  setCreateNewMenuCenter,
  setCreateNewMenuAnchorEl,
} = createNewMenuSlice.actions;

export default createNewMenuSlice.reducer;
