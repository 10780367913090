import React from 'react';
import { css, styled } from '@mui/material/styles';

import Button, { ButtonProps } from '@mui/material/Button';

import {
  black,
  equalYellowBoxShadow,
  honeyGlow,
  white,
} from '../../theme';

interface GoldButtonProps extends ButtonProps {
  children: React.ReactNode;
};

const StyledButton = styled(Button) <GoldButtonProps>`
  ${(props) => props.variant === 'contained' && css`
    background-color: ${honeyGlow};
    color: ${black};

    :hover {
      background-color: ${honeyGlow};
      box-shadow: ${equalYellowBoxShadow};
    }
  `}

  ${(props) => props.variant === 'text' && css`
    color: ${white};
    text-decoration: underline;

    &:hover {
      background-color:transparent;
      text-decoration: underline;
      text-decoration-color: ${honeyGlow};
    }
  `}
`;

const GoldButton: React.FC<GoldButtonProps> = ({ children, ...rest }) => {
  return (
    <StyledButton {...rest}>
      {children}
    </StyledButton>
  );
};

export default GoldButton;