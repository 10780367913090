import React from 'react';
import { useTranslation } from 'react-i18next';
import PasswordChecklist from 'react-password-checklist';

import {
  mediumSeaGreen,
  sunsetOrange,
} from '../../theme';

interface PasswordValidationProps {
  password1: string;
  password2: string;
  visibility: string;
  setValid: (valid: boolean) => void;
}

const PasswordValidation: React.FC<PasswordValidationProps> = ({
  password1,
  password2,
  visibility,
  setValid,
}) => {
  const { t } = useTranslation();

  return (
    <PasswordChecklist
      rules={[
        'minLength',
        // 'specialChar',
        'number',
        'capital',
        'match',
      ]}
      minLength={8}
      // rule={['minLength']}
      // minLength={4}
      value={password1}
      valueAgain={password2}
      onChange={(isValid) => setValid(isValid)}
      style={{
        fontSize: 10,
        // @ts-ignore
        visibility: visibility,
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'space-evenly',
      }}
      iconSize={8}
      messages={{
        minLength: t('8 chars') as string,
        // specialChar: t('special char') as string,
        number: t('number') as string,
        capital: t('capital letter') as string,
        match: t('match') as string,
      }}
      validColor={mediumSeaGreen}
      invalidColor={sunsetOrange}
    />
  );
};

export default PasswordValidation;