import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import PhotoOutlinedIcon from '@mui/icons-material/PhotoOutlined';
import Typography from '@mui/material/Typography';

import {
  setEventInvitationDialogInvitation,
  setEventInvitationDialogOpen,
} from '../../features/dialogs/eventInvitationDialogSlice';
import {
  setImageDialogAlt,
  setImageDialogOpen,
  setImageDialogSrc,
} from '../../features/dialogs/imageDialogSlice';

import { grayedOutFilter } from '../../theme';

export default function EventInvitationCardMin(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleEventPictureClick = () => {
    dispatch(setImageDialogSrc(props.invitation.event.properties.picture));
    dispatch(setImageDialogAlt(props.invitation.event.properties.name));
    dispatch(setImageDialogOpen(true));
  };

  const handleTitleClick = () => {
    dispatch(setEventInvitationDialogInvitation(props.invitation));
    dispatch(setEventInvitationDialogOpen(true));
  };

  return (
    <Card
      id={props.invitation.event.properties.uuid}
      variant="outlined"
      sx={{
        width: '100%',
        borderRadius: '25px',
        backgroundColor: 'transparent',
        '& .MuiCardHeader-action': { margin: 'auto' },
        '& .MuiCardHeader-root': { paddingRight: 0 },
      }}>
      <CardHeader
        avatar={
          props.invitation.event.properties.picture ? (
            <CardMedia
              component="img"
              image={props.invitation.event.properties.picture}
              alt={props.invitation.event.properties.name}
              onClick={handleEventPictureClick}
              sx={{
                width: '75px',
                height: '75px',
                cursor: 'pointer',
                aspectRatio: '1/1',
                borderRadius: '10px',
              }}
            />
          ) : (
            <CardMedia sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '75px',
              aspectRatio: '1/1',
              border: '1px solid #fff',
              borderRadius: '10px',
            }}>
              <Avatar sx={{ bgcolor: 'transparent' }} aria-label="recipe">
                <PhotoOutlinedIcon sx={{ color: '#fff', fontSize: '40px' }} />
              </Avatar>
            </CardMedia>
          )
        }
        title={
          <Typography
            // align="center"
            variant="subtitle1"
            onClick={handleTitleClick}
            sx={{
              flexGrow: 1,
              // textAlign: 'center',
              // verticalAlign: 'middle',
              wordBreak: 'break-word',
              cursor: 'pointer',
            }}
          >
            {props.invitation.event.properties.name}
          </Typography >
        }
        subheader={
          <>
            <Typography
              variant="subtitle2"
              onClick={handleTitleClick}
              display="flex"
              alignItems="center"
            >
              <span style={{ verticalAlign: 'middle' }}>{t('From')}:</span>&nbsp;<strong style={{
                filter: props.invitation.senderDeleted && grayedOutFilter,
                display: 'inline-flex',
                alignItems: 'center',
                verticalAlign: 'middle',
              }}>{props.invitation.senderDeleted && <PersonOffIcon fontSize="small" />} {props.invitation.username}</strong>
            </Typography>
            {props.invitation.message && (
              <Typography
                variant="caption"
                onClick={handleTitleClick}
              >
                {props.invitation.message}
              </Typography>
            )}
          </>
        }
        action={
          <React.Fragment>
            <IconButton
              disabled={props.actionButtonDisabled}
              aria-label="clear" color="error"
              onClick={(e) => props.handleAction(e, props.invitation.uuid, 'reject')}>
              <ClearIcon />
            </IconButton>
            <IconButton
              disabled={props.actionButtonDisabled}
              aria-label="check" color="success"
              onClick={(e) => props.handleAction(e, props.invitation.uuid, 'accept')}>
              <CheckIcon />
            </IconButton>
          </React.Fragment>
        }
      />
    </Card>
  );
};