import React from 'react';
import { isMobile } from 'react-device-detect';
import { styled } from '@mui/material/styles';

import Stack from '@mui/material/Stack';

interface CategoryChipsWrapperProps {
  children: React.ReactNode;
}

const StyledStack = styled(Stack)`
  padding-top: 1rem;
  padding-bottom: ${isMobile ? 0.75 : 0.25}rem;
  overflow-x: auto;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
`;

const CategoryChipsWrapper: React.FC<CategoryChipsWrapperProps> = ({ children }) => {
  return (
    <StyledStack direction="row" spacing={1}>
      {children}
    </StyledStack>
  );
};

export default CategoryChipsWrapper;