import React from 'react';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';

import ExploreIcon from '@mui/icons-material/Explore';
import Fab from '@mui/material/Fab';
import Fade from '@mui/material/Fade';

import { NAVIGATION_PITCH } from '../../consts/map';
import {
  backdropBlur,
  fabBoxShadow,
} from '../../theme';
import {
  coordinatesEqual,
  getZoomBasedOnCircle,
} from '../../utils/mapUtils';

const EASE_DURATION = 100;

export default function CompassFab(props) {
  const fabSize = useSelector((state) => state.app.fabSize);

  const userPosition = useSelector((state) => state.userPosition.value);
  const map = useSelector((state) => state.map.value);
  const isMapDragged = useSelector((state) => state.map.isDragged);
  const geolocateControl = useSelector((state) => state.map.geolocateControl);
  const navigating = useSelector((state) => state.map.navigating);

  const handleClick = () => {
    let targetZoom = map.getZoom();
    const targetPitch = navigating ? NAVIGATION_PITCH : 0;
    const targetBearing = navigating ? map.getBearing() : 0;
    if (userPosition.length === 2) {
      if (coordinatesEqual(map.getCenter().toArray(), userPosition)) {
        if (!navigating) {
          targetZoom = getZoomBasedOnCircle(map);
        };
        setTimeout(() => {
          if (!['WAITING_ACTIVE', 'ACTIVE_LOCK'].includes(geolocateControl._watchState)) {
            geolocateControl.trigger();
          };
        }, EASE_DURATION);
      };

      geolocateControl.options.fitBoundsOptions.bearing = targetBearing;
      geolocateControl.options.fitBoundsOptions.pitch = targetPitch;
      geolocateControl.options.fitBoundsOptions.zoom = targetZoom;
    };

    map.easeTo({
      bearing: targetBearing,
      pitch: targetPitch,
      zoom: targetZoom,
      duration: EASE_DURATION,
    }, { geolocateSource: true });

    setTimeout(() => {
      props.setOpen(false)
    }, EASE_DURATION);
  };

  return (
    <Fade in={props.open} timeout={{ enter: 0, exit: 500 }}>
      <Fab color="primary" aria-label="add" size={fabSize}
        onClick={handleClick}
        sx={{
          zIndex: 10,
          backgroundColor: 'transparent',
          fontSize: '2.5rem',
          boxShadow: fabBoxShadow,
          pointerEvents: isMapDragged ? 'none' : 'auto',
          '&:hover': {
            backgroundColor: isMobile ? 'transparent' : 'rgba(255, 255, 255, 0.08)',
          },
          backdropFilter: backdropBlur,
        }}
      >
        <ExploreIcon fontSize="2.5rem" />
      </Fab>
    </Fade>
  );
}