import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Link from '@mui/material/Link';

import { CONTACT_EMAIL } from '../../config';
import { borderedDialogPaperProps } from '../../theme';

import { setGeolocationDeniedDialogOpen } from '../../features/app/dialogsSlice';
import { setGeolocationPermissionStatus } from '../../features/map/mapSlice';

const GeolocationDeniedDialog: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const open = useSelector((state: any) => state.dialogs.geolocationDeniedDialogOpen);
  const geolocateControl = useSelector((state: any) => state.map.geolocateControl);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    dispatch(setGeolocationDeniedDialogOpen(false));
    // if (geolocateControl._watchState === 'ACTIVE_LOCK') {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        // window.location.reload();
        console.log('position', position);
        dispatch(setGeolocationPermissionStatus('granted'));
        if (geolocateControl._watchState === 'OFF') {
          geolocateControl.trigger();
        };
        // setTimeout(() => { geolocateControl.trigger() }, 10000);
      },
      (error) => {
        dispatch(setGeolocationPermissionStatus('denied'));
        console.log('error', error);
      },
    );
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      {...borderedDialogPaperProps}
    >
      <DialogTitle id="alert-dialog-title" align="center">
        {t('Enable geolocation for full app experience')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" variant="subtitle2">
          {t("We noticed that you have denied access to your device's location in our app / website. \
To enjoy all its features enable geolocation in your browser preferences.")}
        </DialogContentText>
        <br />
        <DialogContentText variant="caption">
          {t('If you have questions or need further assistance, \
feel free to contact us using our')} <Link href="/contact/">{t('contact form')}</Link> {t('or \
email')} <Link href={`mailto:${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</Link>.
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleClick}
        >
          {t('Continue')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GeolocationDeniedDialog;