import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import BackButton from '../components/ui/BackButton.tsx';
import BigLoader from '../components/ui/BigLoader.tsx';
import CenteredBox from '../components/ui/CenteredBox.tsx';
import Layout from '../components/ui/Layout.tsx';

import api from '../utils/api.ts';
import {
  APP_NAME,
  CONTACT_EMAIL,
} from '../config';
import {
  DRAWER_WIDTH,
  WIDTH_BREAKPOINT,
} from '../theme';

interface FAQ {
  question: string;
  answer: string;
};

const FAQs: React.FC = () => {
  const { t } = useTranslation();

  const width = useSelector((state: any) => state.app.width);
  const language = useSelector((state: any) => state.settings.language);

  const [expanded, setExpanded] = useState<string | false>(false);
  const [loading, setLoading] = useState(true);
  const [faqs, setFaqs] = useState<FAQ[]>([]);

  const getFAQs = async () => {
    setLoading(true);
    await api.get('/faqs/').then(response => {
      setFaqs(response.data);
      setLoading(false);
    }).catch(err => {
      setLoading(false);
    });
  };

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    setFaqs([]);
    getFAQs();
  }, [language]);

  return (
    <Layout>
      <Box sx={{
        flexGrow: 1,
        paddingLeft: width > WIDTH_BREAKPOINT ? `${DRAWER_WIDTH + 16}px` : undefined,
        minHeight: '92vh',
      }}>
        <Grid container>
          <Grid item xs={2}>
            <CenteredBox>
              <BackButton onClick={() => window.location.href = '/'} />
            </CenteredBox>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h6" gutterBottom mt={2} align="center">
              {t('FAQs')}
            </Typography>
          </Grid>
          <Grid item xs={2} />
        </Grid>
        <Grid container spacing={2} mt={1} mb={3}>
          <Grid item xs={1} />
          <Grid item xs={10}>
            <Typography mb={5} variant="body2" textAlign="justify">
              {t("We've put together a list of frequently asked questions below to help you get started.")}
            </Typography>
            {loading ? (
              <CenteredBox my={10}>
                <BigLoader />
              </CenteredBox>
            ) : (
              <>
                {faqs.map((faq, idx) => (
                  <Accordion
                    expanded={expanded === `panel${idx}`}
                    onChange={handleChange(`panel${idx}`)}
                    key={idx}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel${idx}bh-content`}
                      id={`panel${idx}bh-header`}
                    >
                      <Typography sx={{ width: '100%', flexShrink: 0 }}>
                        {faq.question}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography variant="body2" textAlign="justify">
                        {faq.answer}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                ))}
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }} mt={2}>
                  {t('Contact Us')}
                </Typography>
                <Typography variant="body2" textAlign="justify">
                  {t('If you have other questions or need further assistance, \
feel free to contact us using our')} <Link href="/contact/">{t('contact form')}</Link> {t('or \
email')} <Link href={`mailto:${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</Link>.
                </Typography>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }} align="center" my={3}>
                  {t('Thank you for using {{appName}}!', { appName: APP_NAME })}
                </Typography>
              </>
            )}
          </Grid>
          <Grid item xs={1} />
        </Grid>
      </Box>
    </Layout>
  );
};

export default FAQs;