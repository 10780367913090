import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';

import { setImageDialogOpen } from '../features/dialogs/imageDialogSlice';

export default function ImageDialog() {
  const dispatch = useDispatch();

  const open = useSelector((state) => state.imageDialog.open);
  const src = useSelector((state) => state.imageDialog.src);
  const alt = useSelector((state) => state.imageDialog.alt);

  const handleClose = () => {
    dispatch(setImageDialogOpen(false));
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent sx={{ padding: 0, display: 'flex' }}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 0,
              top: 0,
              color: 'text.primary',
            }}
          >
            <CloseIcon />
          </IconButton>
          <img style={{ maxWidth: '100%', maxHeight: '100%' }} src={src} alt={alt} />
        </DialogContent>
      </Dialog>
    </div>
  );
};

