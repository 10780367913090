import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';

import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import GppGoodOutlinedIcon from '@mui/icons-material/GppGoodOutlined';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import Skeleton from '@mui/material/Skeleton';

import { mainColor } from '../../theme';

const AvatarSkeleton = styled(Skeleton)`
  transform: unset;
  transform-origin: unset;
  border-radius: 50%;
  width: 100%;
  height: 100%;
` as typeof Skeleton;

const StyledAvatar = styled(Avatar)`
  width: 28px;
  height: 28px;
  // border: 1px solid white;
  background-color: transparent;
` as typeof Avatar;

const StyledPersonOutlineIcon = styled(PersonOutlineIcon)`
  color: #fff;
  font-size: 2rem;
`;

const StyledGppGoodOutlinedIcon = styled(GppGoodOutlinedIcon)`
  color: ${mainColor};
  font-size: 1rem;
`;

const UserAvatar: React.FC = () => {
  const [profilePictureLoaded, setProfilePictureLoaded] = useState(false);
  const picture = useSelector((state: any) => state.profile.picture);
  const staff = useSelector((state: any) => state.account.staff);

  const handleOnLoad = () => {
    setProfilePictureLoaded(true);
  };

  useEffect(() => {
    if (picture === '') {
      setProfilePictureLoaded(true);
    };
  }, [picture]);

  return (
    <Badge badgeContent={profilePictureLoaded && staff ? <StyledGppGoodOutlinedIcon /> : 0}>
      <StyledAvatar src={picture} alt='' onLoad={handleOnLoad}>
        {!profilePictureLoaded ? <AvatarSkeleton /> : <StyledPersonOutlineIcon />}
      </StyledAvatar>
    </Badge >
  );
};

export default UserAvatar;