import { css, styled } from '@mui/material/styles';

import Box from '@mui/material/Box';

interface NoContentAlertWrapperProps {
  top?: string;
};

const NoContentAlertWrapper = styled(Box) <NoContentAlertWrapperProps>`
  width: 100%;
  padding: 0 24px;
  position: fixed;
  left: 0;
  ${(props) => css`
    top: ${props.top};
  `}
`;

NoContentAlertWrapper.defaultProps = {
  top: '50%',
};

export default NoContentAlertWrapper;