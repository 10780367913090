import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import CenteredBox from './CenteredBox.tsx';
import CloseButton from './CloseButton.tsx';
import PaperComponent from '../PaperComponent';

import { setPremiumInfoDialogOpen } from '../../features/dialogs/premiumInfoDialogSlice.ts';
import { setPremiumDialogOpen } from '../../features/dialogs/premiumDialogSlice.ts';

import {
  black,
  borderedDialogPaperProps,
  equalYellowBoxShadow,
  honeyGlow,
  WIDTH_BREAKPOINT,
} from '../../theme';

const StyledButton = styled(Button)`
  background-color: ${honeyGlow};
  color: ${black};
  
  :hover {
    background-color: ${honeyGlow};
    box-shadow: ${equalYellowBoxShadow};
  }
`;

const PremiumInfoDialog: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const open = useSelector((state: any) => state.premiumInfoDialog.open);
  const text = useSelector((state: any) => state.premiumInfoDialog.text);
  const width = useSelector((state: any) => state.app.width);

  const handleClose = () => {
    dispatch(setPremiumInfoDialogOpen(false));
  };

  const handleUpgradeClick = () => {
    dispatch(setPremiumDialogOpen(true));
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperComponent={PaperComponent}
      hideBackdrop={false}
      fullWidth={width <= WIDTH_BREAKPOINT / 2}
      aria-labelledby="draggable-dialog-title"
      aria-describedby="alert-dialog-description"
      {...borderedDialogPaperProps}
    >
      <DialogTitle textAlign="center" style={{ cursor: 'move' }} id="draggable-dialog-title">
        {t('Plus account required')}
      </DialogTitle>
      <CloseButton onClick={handleClose} />
      <DialogContent>
        <DialogContentText variant="body1" textAlign="center" fontWeight="bold" color={honeyGlow}>
          {t('With Plus there are no limits!')}
        </DialogContentText>
        <DialogContentText variant="subtitle2" textAlign="center" mt={1}>
          {text}
        </DialogContentText>
        <CenteredBox mt={5}>
          <StyledButton variant="contained" onClick={handleUpgradeClick}>
            {t('Check the offer')}
          </StyledButton>
        </CenteredBox>
      </DialogContent>
    </Dialog>
  );
};

export default PremiumInfoDialog;