import React from 'react';
import { styled } from '@mui/material/styles';

import List from '@mui/material/List';

import EventInvitationCardMinSkeleton from './EventInvitationCardMinSkeleton.tsx';
import EventInvitationCardMinWrapper from './EventInvitationCardMinWrapper.tsx';

import { arrayOfInts } from '../../utils/utils';

const ELEMENTS = 6;

const StyledList = styled(List)`
  width: 100%;
` as typeof List;

const EventInvitationCardMinSkeletonList: React.FC = () => {
  return (
    <StyledList>
      {arrayOfInts(ELEMENTS).map((_, index) => (
        <EventInvitationCardMinWrapper key={index}>
          <EventInvitationCardMinSkeleton />
        </EventInvitationCardMinWrapper>
      ))}
    </StyledList>
  );
};

export default EventInvitationCardMinSkeletonList;