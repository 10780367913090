import { createSlice } from '@reduxjs/toolkit';

export const dialogsSlice = createSlice({
  name: 'dialogs',
  initialState: {
    anyDialogOpen: false,
    bannedPinDialogOpen: false,
    bannedUserDialogOpen: false,
    eventInvitationsDialogOpen: false,
    changelogDialogOpen: false,
    companyAccountInfoDialogOpen: false,
    configurationStepperOpen: false,
    confirmClearNotificationsDialogOpen: false,
    confirmDeleteAccountDialogOpen: false,
    geolocationDeniedDialogOpen: false,
    inviteFriendsDialogOpen: false,
    introStepperOpen: false,
    locationPermissionDialogOpen: false,
    networkDialogOpen: false,
    networkDialogTab: 0,
    newPinNotAllowedDialogOpen: false,
    premiumConfirmDialogOpen: false,
    profileDialogOpen: false,
    qrCodeDialogOpen: false,
    qrCodeScannerDialogOpen: false,
    savedEventsDialogOpen: false,
    searchFriendsDialogOpen: false,
    settingsDialogOpen: false,
    trialConfirmDialogOpen: false,
    userEventsDialogOpen: false,
    webGLDialogOpen: false,
  },
  reducers: {
    resetDialogs: (state) => {
      state.eventInvitationsDialogOpen = false;
      state.networkDialogOpen = false;
      state.profileDialogOpen = false;
      state.savedEventsDialogOpen = false;
      state.settingsDialogOpen = false;
      state.userEventsDialogOpen = false;
    },
    setAnyDialogOpen: (state, action) => {
      state.anyDialogOpen = action.payload;
    },
    setBannedPinDialogOpen: (state, action) => {
      state.bannedPinDialogOpen = action.payload;
    },
    setBannedUserDialogOpen: (state, action) => {
      state.bannedUserDialogOpen = action.payload;
    },
    setChangelogDialogOpen: (state, action) => {
      state.changelogDialogOpen = action.payload;
    },
    setCompanyAccountInfoDialogOpen: (state, action) => {
      state.companyAccountInfoDialogOpen = action.payload;
    },
    setConfigurationStepperOpen: (state, action) => {
      state.configurationStepperOpen = action.payload;
    },
    setConfirmClearNotificationsDialogOpen: (state, action) => {
      state.confirmClearNotificationsDialogOpen = action.payload;
    },
    setConfirmDeleteAccountDialogOpen: (state, action) => {
      state.confirmDeleteAccountDialogOpen = action.payload;
    },
    setEventInvitationsDialogOpen: (state, action) => {
      state.eventInvitationsDialogOpen = action.payload;
    },
    setGeolocationDeniedDialogOpen: (state, action) => {
      state.geolocationDeniedDialogOpen = action.payload;
    },
    setIntroStepperOpen: (state, action) => {
      state.introStepperOpen = action.payload;
    },
    setLocationPermissionDialogOpen: (state, action) => {
      state.locationPermissionDialogOpen = action.payload;
    },
    setNetworkDialogOpen: (state, action) => {
      state.networkDialogOpen = action.payload;
    },
    setNetworkDialogTab: (state, action) => {
      state.networkDialogTab = action.payload;
    },
    setNewPinNotAllowedDialogOpen: (state, action) => {
      state.newPinNotAllowedDialogOpen = action.payload;
    },
    setPremiumConfirmDialogOpen: (state, action) => {
      state.premiumConfirmDialogOpen = action.payload;
    },
    setProfileDialogOpen: (state, action) => {
      state.profileDialogOpen = action.payload;
    },
    setQrCodeDialogOpen: (state, action) => {
      state.qrCodeDialogOpen = action.payload;
    },
    setQrCodeScannerDialogOpen: (state, action) => {
      state.qrCodeScannerDialogOpen = action.payload;
    },
    setSavedEventsDialogOpen: (state, action) => {
      state.savedEventsDialogOpen = action.payload;
    },
    setSearchFriendsDialogOpen: (state, action) => {
      state.searchFriendsDialogOpen = action.payload;
    },
    setSettingsDialogOpen: (state, action) => {
      state.settingsDialogOpen = action.payload;
    },
    setTrialConfirmDialogOpen: (state, action) => {
      state.trialConfirmDialogOpen = action.payload;
    },
    setUserEventsDialogOpen: (state, action) => {
      state.userEventsDialogOpen = action.payload;
    },
    setWebGLDialogOpen: (state, action) => {
      state.webGLDialogOpen = action.payload;
    },
    toggleEventInvitationsDialogOpen: (state) => {
      state.eventInvitationsDialogOpen = !state.eventInvitationsDialogOpen;
    },
    toggleNetworkDialogOpen: (state) => {
      state.networkDialogOpen = !state.networkDialogOpen;
    },
    toggleProfileDialogOpen: (state) => {
      state.profileDialogOpen = !state.profileDialogOpen;
    },
    toggleSettingsDialogOpen: (state) => {
      state.settingsDialogOpen = !state.settingsDialogOpen;
    },
  },
})

export const {
  resetDialogs,
  setAnyDialogOpen,
  setBannedPinDialogOpen,
  setBannedUserDialogOpen,
  setChangelogDialogOpen,
  setCompanyAccountInfoDialogOpen,
  setConfigurationStepperOpen,
  setConfirmClearNotificationsDialogOpen,
  setConfirmDeleteAccountDialogOpen,
  setEventInvitationsDialogOpen,
  setGeolocationDeniedDialogOpen,
  setIntroStepperOpen,
  setLocationPermissionDialogOpen,
  setNetworkDialogOpen,
  setNetworkDialogTab,
  setNewPinNotAllowedDialogOpen,
  setPremiumConfirmDialogOpen,
  setProfileDialogOpen,
  setQrCodeDialogOpen,
  setQrCodeScannerDialogOpen,
  setSavedEventsDialogOpen,
  setSearchFriendsDialogOpen,
  setSettingsDialogOpen,
  setTrialConfirmDialogOpen,
  setUserEventsDialogOpen,
  setWebGLDialogOpen,
  toggleEventInvitationsDialogOpen,
  toggleNetworkDialogOpen,
  toggleProfileDialogOpen,
  toggleSettingsDialogOpen,
} = dialogsSlice.actions;

export default dialogsSlice.reducer;
