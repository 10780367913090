import { createSlice } from '@reduxjs/toolkit';

import { EMPTY_FEATURE } from '../../consts/map';

export const reportDialogSlice = createSlice({
  name: 'reportDialog',
  initialState: {
    reportCategories: [],
    feature: EMPTY_FEATURE,
    open: false,
    subject: '',
  },
  reducers: {
    setReportCategories: (state, action) => {
      state.reportCategories = action.payload;
    },
    setReportDialogFeature: (state, action) => {
      state.feature = action.payload;
    },
    setReportDialogOpen: (state, action) => {
      state.open = action.payload;
    },
    setReportDialogSubject: (state, action) => {
      state.subject = action.payload;
    },
  }
})

export const {
  setReportCategories,
  setReportDialogFeature,
  setReportDialogOpen,
  setReportDialogSubject,
} = reportDialogSlice.actions;

export default reportDialogSlice.reducer;
