import React from 'react';
import { styled } from '@mui/material/styles';

import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import Avatar from '@mui/material/Avatar';
import ClearIcon from '@mui/icons-material/Clear';
import FormControl from '@mui/material/FormControl';

interface EventPictureUploadProps {
  picture: any;
  setPicture: (picture: any) => void,
};

const StyledFormControl = styled(FormControl)`
  position: relative;
  display: inline-block;
  // margin-top: 5px;
  aspect-ratio: 16 / 9;
  width: 100%;
  max-width: 456px;
  max-height: 256px;
`;

const StyledAvatar = styled(Avatar)`
  background-color: transparent;
  color: #fff;
  width: 100%;
  height: 100%;
  // width: 50;
  // height: 50;
  // aspect-ratio: 7 / 5;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.7);
  font-size: 1rem;
`;

const HiddenInput = styled('input')`
  display: none;
`;

const ActionButton = styled('div')`
  position: absolute;
  bottom: 0px;
  right: 0px;
  transform: translate(50%, 50%);
  background-color: #fff;
  border-radius: 50%;
  padding: 3px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
`;

const StyledLabel = styled('label')`
  cursor: pointer;
`;

const PICTURE_UPLOAD = 'picture-upload';

const EventPictureUpload: React.FC<EventPictureUploadProps> = ({
  picture,
  setPicture,
}) => {
  const handleImageUpload = (e: React.ChangeEvent) => {
    const target = e.target || e.currentTarget as HTMLInputElement;
    const file = target['files'][0];
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        // if (reader.result !== picture) {
        setPicture(reader.result);
        // };
      };
    };

    if (file) { reader.readAsDataURL(file) };
  };

  const handleClearPicture = () => {
    setPicture('');
  };

  return (
    <StyledFormControl>
      <StyledLabel htmlFor={PICTURE_UPLOAD}>
        <StyledAvatar src={picture} variant="rounded">
          <AddPhotoAlternateOutlinedIcon fontSize="large" />
        </StyledAvatar>
      </StyledLabel>
      <HiddenInput
        id={PICTURE_UPLOAD}
        type="file"
        accept="image/*"
        onChange={handleImageUpload}
      />
      {picture !== '' && (
        <ActionButton onClick={handleClearPicture}>
          <ClearIcon fontSize="small" />
        </ActionButton>
      )}
    </StyledFormControl>
  );
};

export default EventPictureUpload;