import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import {
  setImageDialogAlt,
  setImageDialogOpen,
  setImageDialogSrc,
} from '../../features/dialogs/imageDialogSlice';
import { setUserDialogOpen } from '../../features/dialogs/userDialogSlice';
import { selectUserByUuid } from '../../features/users/usersSlice';

import AddFriendButton from './AddFriendButton.tsx';
import BigAvatar from './BigAvatar.tsx';
import CenteredBox from '../ui/CenteredBox.tsx';
import CloseButton from '../ui/CloseButton.tsx';
import InviteFriendButton from '../ui/InviteFriendButton.tsx';
import OnlineBadge from './OnlineBadge.tsx';
import ShowOnMapButton from '../ui/ShowOnMapButton';
import UserEventCard from '../events/UserEventCard.tsx';
import UserMenu from './UserMenu.tsx';

import PaperComponent from '../PaperComponent';
import {
  customDialogStyle,
  noFullScreenDialogPaperProps,
  WIDTH_BREAKPOINT,
} from '../../theme';
import {
  getDistance,
  formatDistance,
} from '../../utils/mapUtils';

import { Event } from '../../types/Event/types.ts';
import EventCardWrapper from '../events/EventCardWrapper.tsx';

const StyledBox = styled(Box)`
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow-y: auto;
  height: 40vh;
  padding-bottom: 1.5rem;
`;

const StyledDialogContent = styled(DialogContent)`
  padding: 0 1.5rem;
`;

const StyledGrid = styled(Grid)`
  padding-left: 2rem;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
`;

const StyledImg = styled('img')`
  width: 100px;
  height: 100px;
  aspect-ratio: 1/1;
  object-fit: cover;
  border: 1px solid white;
  border-radius: 50%;
`;

const StyledList = styled(List)`
  width: 100%;
  margin-top: 0.5rem;
`;

const StyledStack = styled(Stack)`
  margin-left: -12px;
`;

const StyledStack2 = styled(Stack)`
  width: 100%;
  justify-content: space-between;
`;

const UserDialog: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const _user = useSelector((state: any) => state.userDialog.user);
  const user = useSelector((state) => selectUserByUuid(state, _user.properties.uuid));
  const userPosition = useSelector((state: any) => state.userPosition.value);

  const events = useSelector((state: any) => state.events.value);
  const userEvents = events.features.filter((event: Event) => (
    event.properties.user === user.properties.username &&
    event.properties.timeline !== 'past' &&
    !event.properties.cancelled
  ));
  const savedEvents = useSelector((state: any) => state.savedEvents.value);
  const userSavedEvents = savedEvents.features.filter((event: Event) => (
    event.properties.user === user.properties.username &&
    event.properties.timeline !== 'past'
  ));

  const open = useSelector((state: any) => state.userDialog.open);
  const width = useSelector((state: any) => state.app.width);

  const handleClose = () => {
    dispatch(setUserDialogOpen(false));
  };

  const handleUserPictureClick = () => {
    dispatch(setImageDialogSrc(user.properties.picture));
    dispatch(setImageDialogAlt(user.properties.username));
    dispatch(setImageDialogOpen(true));
  };

  useEffect(() => {
    if (Object.keys(user.properties).length === 0) handleClose();
  }, [user]);

  return (
    <Dialog
      open={open}
      PaperComponent={PaperComponent}
      hideBackdrop={false}
      onClose={handleClose}
      fullWidth
      PaperProps={{
        style: {
          ...noFullScreenDialogPaperProps.PaperProps.style,
          maxHeight: width <= WIDTH_BREAKPOINT ? '75vh' : 'unset',
        }
      }}
      sx={{ ...customDialogStyle.sx }}
      BackdropProps={{
        style: {
          top: width <= WIDTH_BREAKPOINT ? undefined : '8vh',
        }
      }}
    >
      <DialogTitle textAlign="center" />
      <CloseButton onClick={handleClose} />
      <StyledDialogContent>
        <Grid container>
          <Grid item xs={4} display="flex" alignItems="center">
            {user.properties.online ? (
              <OnlineBadge
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                variant="dot"
              >
                {user.properties.picture ? (
                  <Box display="flex" justifyContent="center">
                    <StyledImg
                      src={user.properties.picture}
                      loading="lazy"
                      onClick={handleUserPictureClick}
                    />
                  </Box>
                ) : (
                  <BigAvatar />
                )}
              </OnlineBadge>
            ) : (
              user.properties.picture ? (
                <Box display="flex" justifyContent="center">
                  <StyledImg
                    src={user.properties.picture}
                    loading="lazy"
                    onClick={handleUserPictureClick}
                  />
                </Box>
              ) : (
                <BigAvatar />
              )
            )}
          </Grid>
          <StyledGrid item xs={7}>
            <StyledStack2 direction="row" alignItems="center">
              <Typography variant="h6">
                {user.properties.username || user.properties.uuid}
              </Typography>
              <UserMenu feature={user} />
            </StyledStack2>
            <Stack direction="row" alignItems="center">
              <Typography variant="subtitle2">
                {user.properties.firstName} {user.properties.lastName}
              </Typography>
            </Stack>
            <StyledStack2 direction="row" alignItems="center">
              {user.geometry.coordinates.length === 2 && userPosition.length === 2 && (
                <Typography variant="body2">
                  {formatDistance(getDistance(userPosition, user.geometry.coordinates))}
                </Typography>
              )}
              {window.location.pathname === '/' && user.geometry.coordinates.length === 2 && (
                <ShowOnMapButton feature={user} />
              )}
              {user.properties.friend && (
                <InviteFriendButton user={user} />
              )}
            </StyledStack2>
            <StyledStack direction="row" />
          </StyledGrid>
        </Grid>
        <br />
        {!user.properties.friend && user.properties.invited !== null && (
          <>
            <CenteredBox>
              <AddFriendButton user={user} />
            </CenteredBox>
            <br />
          </>
        )}
        {(userEvents.length > 0 || userSavedEvents.length > 0) && (
          <StyledBox>
            <Typography>{t('User pins')}</Typography>
            <StyledList>
              {[...userEvents, ...userSavedEvents]
                .sort((a, b) => a.properties.start - b.properties.start)
                .map(event => (
                  <EventCardWrapper key={event.properties.uuid}>
                    <UserEventCard event={event} />
                  </EventCardWrapper>
                ))}
            </StyledList>
          </StyledBox>
        )}
      </StyledDialogContent>
    </Dialog>
  );
};

export default UserDialog;