import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Alert from '@mui/material/Alert';
import Portal from '@mui/material/Portal';
import Snackbar from '@mui/material/Snackbar';

import {
  setAlertsSnackbarAutoHideDuration,
  setAlertsSnackbarOpen,
} from '../../features/app/alertsSnackbarSlice';

export default function AlertsSnackbar() {
  const dispatch = useDispatch();

  const autoHideDuration = useSelector(state => state.alertsSnackbar.autoHideDuration);
  const open = useSelector(state => state.alertsSnackbar.open);
  const severity = useSelector(state => state.alertsSnackbar.severity);
  const text = useSelector(state => state.alertsSnackbar.text);

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    };
    dispatch(setAlertsSnackbarOpen(false));
  };

  const setNodeStyle = node => {
    node.style.border = `solid 1px rgba(255, 255, 255, 0.25)`;
    node.style.borderRadius = '7px';
  };

  useEffect(() => {
    if (!open) dispatch(setAlertsSnackbarAutoHideDuration(1500));
  }, [open]);

  return (
    <Portal>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open}
        autoHideDuration={autoHideDuration}
        onClose={handleSnackbarClose}
        sx={{
          width: '15rem',
          zIndex: 1500,
        }}
        TransitionProps={{
          // timeout: 1000,
          onEnter: setNodeStyle,
          // onEntering: setNodeStyle,
          // onEntered: setNodeStyle,
          // onExit: setNodeStyle,
          // onExiting: setNodeStyle,
          // onExited: setNodeStyle,
        }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={severity}
          sx={{ width: '100%' }}>
          {text}
        </Alert>
      </Snackbar>
    </Portal>
  );
}