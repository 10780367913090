import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { css, styled } from '@mui/material/styles';
import { SocialIcon } from 'react-social-icons'

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import EmailIcon from '@mui/icons-material/Email';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import GroupsIcon from '@mui/icons-material/Groups';
import SendIcon from '@mui/icons-material/Send';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import BackButton from '../components/ui/BackButton.tsx';
import Layout from '../components/ui/Layout.tsx';

import {
  setAlertsSnackbarOpen,
  setAlertsSnackbarSeverity,
  setAlertsSnackbarText,
} from '../features/app/alertsSnackbarSlice';

import {
  APP_NAME,
  APP_URL,
} from '../config';
import {
  DRAWER_WIDTH,
  fabBoxShadow,
  WIDTH_BREAKPOINT,
} from '../theme';
import api from '../utils/api.ts';
import { arrayOfInts } from '../utils/utils';

import CenteredBox from '../components/ui/CenteredBox.tsx';

const BackButtonWrapper = styled(Grid)`
  padding-left: ${DRAWER_WIDTH + 16}px;
`;

const BackgroundDesktop = styled('div')`
  position: absolute;
  background-image: url('/background-desktop.png');
  width: 100vw;
  height: 100vh;
`;

const BackgroundMobile = styled(Box, { shouldForwardProp: (prop) => prop !== 'width' }) <{ width: number }>`
  ${(props) => css`
    background-image: ${props.width <= WIDTH_BREAKPOINT && "url('/background-mobile.png')"};
    background-size: ${props.width <= WIDTH_BREAKPOINT && '100%'};
    min-height: ${props.width <= WIDTH_BREAKPOINT && '100vh'};
    padding-left: ${props.width > WIDTH_BREAKPOINT && `${DRAWER_WIDTH + 16}px`};
  `}
`;

const ShareButton = styled(Button)`
  color: #fff;
  width: 4rem;
  height: 4rem;
  box-shadow: ${fabBoxShadow};
  border-radius: 50%;
  min-width: unset;
`;

const SocialSkeleton = styled(Skeleton)`
  transform: unset;
  transform-origin: unset;
  border-radius: 50%;
  width: 50px;
  height: 50px;
`;

const SocialsWrapper = styled(Grid)`
  width: 100%;
`;

const StyledDivider = styled(Divider)`
  border-color: #fff;
  margin: 0 1.5rem;
  border-right-width: medium;
`;

const StyledEmailIcon = styled(EmailIcon)`
  font-size: 3.5rem;
`;

const StyledFmdGoodIcon = styled(FmdGoodIcon)`
  font-size: 3.5rem;
`;

const StyledGroupsIcon = styled(GroupsIcon)`
  font-size: 3.5rem;
`;

const StyledImage = styled('img')`
  width: 245px;
  height: 63px;
`;

const StyledSendIcon = styled(SendIcon)`
  font-size: 2rem;
  transform: rotate(-30deg);
  margin-left: 0.25rem;
`;

const Wrapper = styled(Box)`
  min-height: ${100 - 8}vh;
`;

const About: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const width = useSelector((state: any) => state.app.width);

  const [loading, setLoading] = useState(true);
  const [socials, setSocials] = useState([]);

  const handleShareClick = async () => {
    if (typeof navigator.canShare !== 'undefined') {
      await navigator.share({
        // title: APP_NAME,
        text: t('Have you already seen {{appName}}?', { appName: APP_NAME }) as string,
        url: APP_URL,
      });
    } else {
      navigator.clipboard.writeText(`${t('Have you already seen {{appName}}?', { appName: APP_NAME })}\n${APP_URL}`)
        .then(() => {
          dispatch(setAlertsSnackbarSeverity('info'));
          dispatch(setAlertsSnackbarText(t('Share info copied to clipboard')));
          dispatch(setAlertsSnackbarOpen(true));
        })
        .catch((error) => {
          dispatch(setAlertsSnackbarSeverity('warning'));
          dispatch(setAlertsSnackbarText(t('Something went wrong!')));
          dispatch(setAlertsSnackbarOpen(true));
        });
    };
  };

  const getSocials = async () => {
    await api.get('/socials/').then(response => {
      setSocials(response.data);
      setLoading(false);
    }).catch(err => { });
  };

  useEffect(() => {
    getSocials();
  }, []);

  return (
    <>
      {width > WIDTH_BREAKPOINT && <BackgroundDesktop />}
      <Layout>
        <Wrapper>
          {width > WIDTH_BREAKPOINT && (
            <BackButtonWrapper container>
              <Grid xs={2}>
                <CenteredBox>
                  <BackButton onClick={() => window.location.href = '/'} />
                </CenteredBox>
              </Grid>
              <Grid xs={8} />
              <Grid xs={2} />
            </BackButtonWrapper>
          )}
          <BackgroundMobile pt={14} width={width}>
            <Grid container sx={{ position: 'relative' }}>
              <Grid xs={1} sm={4} md={4} lg={5} pl={2} />
              <Grid xs={10} sm={4} md={6} lg={4} my={2}>
                <Typography variant="h5" textTransform="uppercase">
                  {t('Together with')}
                </Typography>
              </Grid>
              <Grid xs={1} sm={4} md={4} lg={3} />
              <Grid xs={12} mt={2} mb={3} display="flex" justifyContent="center">
                <StyledImage src="/R-ound.Me-transparent.png" />
              </Grid>
              {width <= WIDTH_BREAKPOINT ? (
                <>
                  <Grid xs={2} />
                  <Grid xs={9}>
                    <Stack direction="row" justifyContent="left" alignItems="center" my={2}>
                      <StyledFmdGoodIcon />
                      <StyledDivider orientation="vertical" flexItem />
                      <Typography variant="h5" textTransform="uppercase">
                        {t("Share what's around you")}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid xs={1} />
                  <Grid xs={2} />
                  <Grid xs={9}>
                    <Stack direction="row" justifyContent="left" alignItems="center" my={2}>
                      <StyledEmailIcon />
                      <StyledDivider orientation="vertical" flexItem />
                      <Typography variant="h5" textTransform="uppercase">
                        {t('Invite your friends')}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid xs={1} />
                  <Grid xs={2} />
                  <Grid xs={9}>
                    <Stack direction="row" justifyContent="left" alignItems="center" my={2}>
                      <StyledGroupsIcon />
                      <StyledDivider orientation="vertical" flexItem />
                      <Typography variant="h5" textTransform="uppercase">
                        {t('Build your community')}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid xs={1} />
                  <Grid xs={12} display="flex" justifyContent="center" my={2}>
                    <Typography variant="h5" textTransform="uppercase">
                      {t('Tell others about us')}
                    </Typography>
                  </Grid>
                  <Grid xs={12} display="flex" justifyContent="center" my={1}>
                    <ShareButton variant="text" onClick={handleShareClick}>
                      <StyledSendIcon />
                    </ShareButton>
                  </Grid>
                  {(loading || socials.length > 0) && (
                    <Grid xs={12} display="flex" justifyContent="center" my={2}>
                      <Typography variant="h5" textTransform="uppercase">
                        {t('Join us here')}
                      </Typography>
                    </Grid>
                  )}
                  <SocialsWrapper container justifyContent="center" spacing={1} m={1}>
                    {!loading ? (
                      socials.map((url, idx) => (
                        <Grid xs={2} md={1} key={idx} display="flex" justifyContent="center" boxSizing="content-box">
                          <SocialIcon url={url} target="_blank" />
                        </Grid>
                      ))
                    ) : (
                      arrayOfInts(5).map((_, idx) => (
                        <Grid xs={2} md={1} key={idx} display="flex" justifyContent="center" boxSizing="content-box">
                          <SocialSkeleton />
                        </Grid>
                      ))
                    )}
                  </SocialsWrapper>
                </>
              ) : (
                <>
                  <Grid xs={6} pl={width > WIDTH_BREAKPOINT + 300 ? 10 : 0}>
                    <Grid container>
                      <Grid xs={2} />
                      <Grid xs={9}>
                        <Stack direction="row" justifyContent="left" alignItems="center" my={2}>
                          <StyledFmdGoodIcon />
                          <StyledDivider orientation="vertical" flexItem />
                          <Typography variant="h5" textTransform="uppercase">
                            {t("Share what's around you")}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid xs={1} />
                      <Grid xs={2} />
                      <Grid xs={9}>
                        <Stack direction="row" justifyContent="left" alignItems="center" my={2}>
                          <StyledEmailIcon />
                          <StyledDivider orientation="vertical" flexItem />
                          <Typography variant="h5" textTransform="uppercase">
                            {t('Invite your friends')}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid xs={1} />
                      <Grid xs={2} />
                      <Grid xs={9}>
                        <Stack direction="row" justifyContent="left" alignItems="center" my={2}>
                          <StyledGroupsIcon />
                          <StyledDivider orientation="vertical" flexItem />
                          <Typography variant="h5" textTransform="uppercase">
                            {t('Build your community')}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid xs={1} />
                    </Grid>
                  </Grid>
                  <Grid xs={6} pr={width > WIDTH_BREAKPOINT + 300 ? 10 : 0}>
                    <Grid container>
                      <Grid xs={1} />
                      <Grid xs={12} display="flex" justifyContent="center" my={2}>
                        <Typography variant="h5" textTransform="uppercase">
                          {t('Tell others about us')}
                        </Typography>
                      </Grid>
                      <Grid xs={12} display="flex" justifyContent="center" my={1}>
                        <ShareButton variant="text" onClick={handleShareClick}>
                          <StyledSendIcon />
                        </ShareButton>
                      </Grid>
                      {(loading || socials.length > 0) && (
                        <Grid xs={12} display="flex" justifyContent="center" my={2}>
                          <Typography variant="h5" textTransform="uppercase">
                            {t('Join us here')}
                          </Typography>
                        </Grid>
                      )}
                      <SocialsWrapper container justifyContent="center" spacing={1} m={1}>
                        {!loading ? (
                          socials.map((url, idx) => (
                            <Grid xs={2} md={3} lg={2} key={idx} display="flex" justifyContent="center" boxSizing="content-box">
                              <SocialIcon url={url} target="_blank" />
                            </Grid>
                          ))
                        ) : (
                          arrayOfInts(5).map((_, idx) => (
                            <Grid xs={2} md={3} lg={2} key={idx} display="flex" justifyContent="center" boxSizing="content-box">
                              <SocialSkeleton />
                            </Grid>
                          ))
                        )}
                      </SocialsWrapper>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </BackgroundMobile>
        </Wrapper>
      </Layout>
    </>
  );
};

export default About;