import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { css, styled, useTheme } from '@mui/material/styles';
import SwipeableViews from 'react-swipeable-views-react-18-fix';
import Bowser from 'bowser';

import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckIcon from '@mui/icons-material/Check';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import LocationDisabledIcon from '@mui/icons-material/LocationDisabled';
import LoginIcon from '@mui/icons-material/Login';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import SearchIcon from '@mui/icons-material/Search';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import api from '../../utils/api.ts';
import AuthContext from '../../context/AuthContext';

import {
  mediumSeaGreen,
  sunsetOrange,
  WIDTH_BREAKPOINT,
} from '../../theme';

import {
  setConfigurationStepperOpen,
  setSearchFriendsDialogOpen,
} from '../../features/app/dialogsSlice';
import { setNotificationsAllowed } from '../../features/app/notificationsSlice';
import {
  setAuthDialogAction,
  setAuthDialogOpen,
} from '../../features/dialogs/authDialogSlice.ts';
import {
  setGeolocationPermissionStatus,
  setLocationAllowed,
} from '../../features/map/mapSlice';

import CenteredBox from './CenteredBox.tsx';
import SearchFriendsDialog from '../user/SearchFriendsDialog.tsx';

import { registerMessagingToken } from '../../utils/utils';

const ActionButton = styled(Button)`
  background-color: #fff;
  color: #000;
  border-radius: 35px;
  height: 4rem;
  width: 18rem;
  text-transform: initial;

  .MuiButton-startIcon {
    position: absolute;
    left: 1rem;
  }

  &.Mui-disabled {
    color: #000;
  }

  :hover {
    background-color: #fff;
    color: #000;
  }
`;

const AllowButtonWrapper = styled(Box)`
  position: absolute;
  top: 41%;
  display: flex;
  width: 100%;
  justify-content: center;
`;

const NotNowButton = styled(Button, { shouldForwardProp: (prop) => prop !== 'width' }) <{ width: number }>`
  color: #fff;
  position: absolute;
  width: 100%;

  ${(props) => css`
    bottom: ${props.width > WIDTH_BREAKPOINT / 2 && '5dvh'};
    top: ${props.width <= WIDTH_BREAKPOINT && '75%'};
  `}
`;

const StartButton = styled(IconButton)`
  position: absolute;
  right: -400%;
  top: 49.5%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #fff;
  color: #000;
  box-shadow: 0px 0px 0px 3px #fff;
  border: 3px solid #171717;
`;

const StyledBackdrop = styled(Backdrop)`
  // background-color: #171717;
`;

const StyledCheckCircleIcon = styled(CheckCircleIcon)`
  && {
    font-size: 3rem;
  }
`;

const StyledImage = styled('img', { shouldForwardProp: (prop) => prop !== 'width' }) <{ width: number }>`
  width: 100%;
  // height: 100%;
  object-fit: contain;

  ${(props) => css`
    border-radius: ${props.width > WIDTH_BREAKPOINT / 2 && '25px'};
  `}
`;

const StyledLocationDisabledIcon = styled(LocationDisabledIcon)`
  && {
    font-size: 3rem;
  }
`;

const StyledMyLocationIcon = styled(MyLocationIcon)`
  && {
    font-size: 3rem;
  }
`;

const StyledLoginIcon = styled(LoginIcon)`
  && {
    font-size: 2rem;
  }
`;

const StyledNotificationsIcon = styled(NotificationsIcon)`
  && {
    font-size: 3rem;
  }
`;

const StyledNotificationsOffIcon = styled(NotificationsOffIcon)`
  && {
    font-size: 3rem;
  }
`;

const StyledPersonAddIcon = styled(PersonAddIcon)`
  && {
    font-size: 2rem;
  }
`;

const StyledSearchIcon = styled(SearchIcon)`
  && {
    font-size: 2rem;
  }
`;

const StyledStack = styled(Stack)`
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  padding: 8rem 0;
`;

const SearchButtonWrapper = styled(Box)`
  position: absolute;
  top: 46%;
  display: flex;
  width: 100%;
  justify-content: center;
`;

const Wrapper = styled(Box)`
  flex-grow: 1;
  width: 100%;
`;

const ConfigurationStepper: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useContext(AuthContext);

  const open = useSelector((state: any) => state.dialogs.configurationStepperOpen);
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const width = useSelector((state: any) => state.app.width);
  const language = useSelector((state: any) => state.settings.language);
  const geolocateControl = useSelector((state: any) => state.map.geolocateControl);

  const locationAllowed = useSelector((state: any) => state.map.locationAllowed);
  const [locationButtonClicked, setLocationButtonClicked] = useState(false);

  const notificationsAllowed = useSelector((state: any) => state.notifications.notificationsAllowed);
  const [notificationButtonClicked, setNotificationButtonClicked] = useState(false);

  const [searchButtonClicked, setSearchButtonClicked] = useState(false);

  const [imagesLoaded, setImagesLoaded] = useState(0);

  const geolocationPermissionStatus = useSelector((state: any) => state.map.geolocationPermissionStatus);
  const parsedUserAgent = Bowser.parse(window.navigator.userAgent);
  const os = parsedUserAgent.os;

  const ALLOW = t('Allow');
  const ALLOWED = t('Allowed');
  const NEXT = t('Next');
  const NOT_ALLOWED = t('Not allowed');
  const NOT_NOW = t('Not now');

  const handleOnLoad = () => {
    setImagesLoaded(prevCount => prevCount + 1);
  };

  const handleNext = () => {
    if (activeStep === maxSteps - 1) {
      dispatch(setConfigurationStepperOpen(false))
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  const handleClose = async () => {
    if (locationAllowed && geolocateControl && geolocateControl?._watchState !== 'ACTIVE_LOCK') {
      geolocateControl.trigger();
    };
    dispatch(setConfigurationStepperOpen(false));

    if (user) {
      await api.patch('/counters/configuration-pass/');
    };
  };

  const handleLoginClick = () => {
    if (!user) {
      dispatch(setAuthDialogOpen(true));
    };
  };

  const handleRegisterClick = () => {
    dispatch(setAuthDialogAction('register'));
    dispatch(setAuthDialogOpen(true));
  };

  const handleLocationClick = () => {
    setLocationButtonClicked(true);

    if (navigator.permissions) {
      navigator.geolocation.getCurrentPosition(
        position => {
          dispatch(setLocationAllowed(true));
          dispatch(setGeolocationPermissionStatus('granted'));
        },
        error => {
          dispatch(setLocationAllowed(false));
          dispatch(setGeolocationPermissionStatus('denied'));
          console.error('error', error);
        },
      );
    }
  };

  const handleNotificationClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setNotificationButtonClicked(true);
    if ('Notification' in window) {
      Notification.requestPermission()
        .then(permission => {
          console.log('request')
          if (permission === 'granted') {
            dispatch(setNotificationsAllowed(true));
            // navigator.serviceWorker.register('/service-worker.js');
            navigator.serviceWorker.register('/firebase-messaging-sw.js');
            console.log('Permission granted for push notifications');

            registerMessagingToken();
          } else {
            dispatch(setNotificationsAllowed(false));
            console.warn('Permission denied for push notifications');
          };
        })
        .catch(error => {
          dispatch(setNotificationsAllowed(false));
          console.error('Error requesting permission:', error);
        });
    } else {
      dispatch(setNotificationsAllowed(false));
      console.warn('Notifications not supported.');
    };
  };

  const handlSearchClick = () => {
    setSearchButtonClicked(true);
    if (user) {
      dispatch(setSearchFriendsDialogOpen(true));
    } else {
      dispatch(setAuthDialogAction('login'));
      dispatch(setAuthDialogOpen(true));
    };
  };

  const platform = width <= WIDTH_BREAKPOINT / 2 ? 'mobile' : 'desktop';
  const steps = [
    (
      <React.Fragment key={1}>
        <StyledImage
          src={`/configuration-stepper-${platform}-1-${language}.png`}
          onLoad={handleOnLoad}
          width={width}
        />
        <StyledStack>
          <ActionButton
            size="large"
            startIcon={<StyledPersonAddIcon />}
            onClick={handleRegisterClick}
          >
            <Typography variant="h6">
              {t('Create account')}
            </Typography>
          </ActionButton>
          <ActionButton
            size="large"
            startIcon={user ? <StyledCheckCircleIcon /> : <StyledLoginIcon />}
            onClick={handleLoginClick}
            disabled={Boolean(user)}
            sx={{
              backgroundColor: `${user ? mediumSeaGreen : '#fff'} !important`,
            }}
          >
            <Typography variant="h6">
              {user ? t('Logged in') : t('Log in')}
            </Typography>
          </ActionButton>
        </StyledStack>
        <CenteredBox>
          <NotNowButton
            variant="text"
            onClick={handleNext}
            width={width}
          >
            <Typography variant="h5">
              {user ? NEXT : NOT_NOW}
            </Typography>
          </NotNowButton>
        </CenteredBox>
      </React.Fragment>
    ),
    (
      <React.Fragment key={2}>
        <StyledImage
          src={`/configuration-stepper-${platform}-2-${language}.png`}
          onLoad={handleOnLoad}
          width={width}
        />
        <AllowButtonWrapper>
          <ActionButton
            size="large"
            startIcon={typeof locationAllowed === 'undefined' ? (
              <StyledMyLocationIcon />
            ) : (
              locationAllowed ? (
                <StyledCheckCircleIcon />
              ) : (
                <StyledLocationDisabledIcon />
              ))}
            onClick={handleLocationClick}
            disabled={locationAllowed}
            sx={{
              textTransform: 'uppercase',
              backgroundColor: `${typeof locationAllowed === 'undefined' ? '#fff' : (locationAllowed ? mediumSeaGreen : sunsetOrange)} !important `,
            }}
          >
            <Typography variant="h6">
              {typeof locationAllowed === 'undefined' ? ALLOW : (locationAllowed ? ALLOWED : NOT_ALLOWED)}
            </Typography>
          </ActionButton>
        </AllowButtonWrapper>
        <CenteredBox>
          <NotNowButton
            variant="text"
            onClick={handleNext}
            width={width}
          >
            <Typography variant="h5">
              {locationButtonClicked || locationAllowed ? NEXT : NOT_NOW}
            </Typography>
          </NotNowButton>
        </CenteredBox>
      </React.Fragment>
    ),
    (
      <React.Fragment key={3}>
        <StyledImage
          src={`/configuration-stepper-${platform}-3-${language}.png`}
          onLoad={handleOnLoad}
          width={width}
        />
        <AllowButtonWrapper>
          <ActionButton
            size="large"
            startIcon={typeof notificationsAllowed === 'undefined' ? (
              <StyledNotificationsIcon />
            ) : (
              notificationsAllowed ? (
                <StyledCheckCircleIcon />
              ) : (
                <StyledNotificationsOffIcon />
              ))}
            onClick={handleNotificationClick}
            disabled={notificationsAllowed}
            sx={{
              textTransform: 'uppercase',
              backgroundColor: `${typeof notificationsAllowed === 'undefined' ? '#fff' : (notificationsAllowed ? mediumSeaGreen : sunsetOrange)} !important`,
            }}
          >
            <Typography variant="h6">
              {typeof notificationsAllowed === 'undefined' ? ALLOW : (notificationsAllowed ? ALLOWED : NOT_ALLOWED)}
            </Typography>
          </ActionButton>
        </AllowButtonWrapper>
        <CenteredBox>
          <NotNowButton
            variant="text"
            onClick={handleNext}
            width={width}
          >
            <Typography variant="h5">
              {notificationButtonClicked || notificationsAllowed ? NEXT : NOT_NOW}
            </Typography>
          </NotNowButton>
        </CenteredBox>
      </React.Fragment>
    ),
    (
      <React.Fragment key={4}>
        <StyledImage
          src={`/configuration-stepper-${platform}-4-${language}.png`}
          onLoad={handleOnLoad}
          width={width}
        />
        <SearchButtonWrapper>
          <ActionButton
            size="large"
            startIcon={<StyledSearchIcon />}
            onClick={handlSearchClick}
            sx={{ textTransform: 'uppercase' }}
          >
            <Typography variant="h6">
              {t('Search for friends')}
            </Typography>
          </ActionButton>
        </SearchButtonWrapper>
        <CenteredBox>
          <NotNowButton
            variant="text"
            onClick={handleNext}
            width={width}
          >
            <Typography variant="h5">
              {searchButtonClicked ? NEXT : NOT_NOW}
            </Typography>
          </NotNowButton>
        </CenteredBox>
      </React.Fragment>
    ),
    (
      <React.Fragment key={5}>
        <StyledImage
          src={`/configuration-stepper-${platform}-5-${language}.png`}
          onLoad={handleOnLoad}
          width={width}
        />
        <StartButton onClick={handleClose}>
          <CheckIcon fontSize='large' />
        </StartButton>
      </React.Fragment>
    ),
  ];

  const maxSteps = steps.length;

  useEffect(() => {
    if (open) {
      if (navigator.permissions) {
        navigator.permissions.query({ name: 'geolocation' })
          .then((ps) => {
            console.log(ps.state);
            if (ps.state === 'granted') {
              dispatch(setLocationAllowed(true));
            } else if (
              ps.state === 'denied'
              // ||
              // ((geolocateControl._lastKnownPosition || os.name === 'iOS') &&
              //   geolocationPermissionStatus === 'prompt')
            ) {
              dispatch(setLocationAllowed(false));
            };
          });
      } else {
        console.log('no navigator.permissions', navigator.geolocation)
        navigator.geolocation.getCurrentPosition(
          (position) => {
            dispatch(setLocationAllowed(true));
          },
          error => {
            dispatch(setLocationAllowed(false));
            dispatch(setGeolocationPermissionStatus('denied'));
            console.error('error', error);
          },
        );
      };
      if (!('Notification' in window)) {
        dispatch(setNotificationsAllowed(false));
        console.warn('Notifications not supported.');
      };
    } else {
      setTimeout(() => {
        setActiveStep(0);
      }, 500);
    }
  }, [open]);

  useEffect(() => {
    if (geolocationPermissionStatus === 'granted') {
      dispatch(setLocationAllowed(true));
    } else if (geolocationPermissionStatus === 'denied') {
      dispatch(setLocationAllowed(false));
    };
  }, [geolocationPermissionStatus]);

  return (
    <>
      <Dialog
        id="configuration-stepper"
        hideBackdrop={false}
        open={open}
        fullScreen={width <= WIDTH_BREAKPOINT / 2}
        PaperProps={{
          style: {
            height: 'unset',
            borderRadius: width <= WIDTH_BREAKPOINT / 2 ? undefined : '25px',
          }
        }}
        sx={{ zIndex: 1402 }}
      >
        <StyledBackdrop
          open={imagesLoaded < maxSteps}
          sx={{
            // backgroundColor: imagesLoaded !== maxSteps ? '#171717' : 'rgba(0, 0, 0, 0.5)'
          }}
        >
          <CircularProgress color="inherit" />
        </StyledBackdrop>
        <Wrapper>
          <SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={activeStep}
            onChangeIndex={handleStepChange}
            enableMouseEvents
          >
            {steps}
          </SwipeableViews>
        </Wrapper>
      </Dialog>
      <SearchFriendsDialog />
    </>
  );
};

export default ConfigurationStepper;