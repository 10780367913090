import { deleteToken, getMessaging, getToken } from 'firebase/messaging';

import GraphemeSplitter from 'grapheme-splitter';

import api from './api.ts';
import { VAPID_KEY } from '../config';

import { setDrawerRightOpen } from '../features/app/appSlice';
import { updateNotificationByUuid } from '../features/app/notificationsSlice';
import {
  setAlertsSnackbarAutoHideDuration,
  setAlertsSnackbarOpen,
  setAlertsSnackbarSeverity,
  setAlertsSnackbarText,
} from '../features/app/alertsSnackbarSlice';

export const formatName = name => name.replace(/\s/g, '_');

export const calculateStepsToZero = (num, steps) => {
  var step = num / (steps - 1);
  var result = [];

  for (var i = 0; i < steps - 1; i++) {
    result.push(num);
    num -= step;
  };

  result.push(0);
  return result;
};

export const zoomSteps = (start, end, steps) => {
  const stepSize = (end - start) / (steps - 1);
  return Array.from({ length: steps }, (v, i) => start + i * stepSize);
};

export const getFabSize = (width) => {
  if (width >= 400) return 'large';
  if (width >= 300) return 'medium';
  return 'small';
};

export const registerMessagingToken = () => {
  const messaging = getMessaging();
  getToken(messaging, { vapidKey: VAPID_KEY })
    .then((token) => {
      api.post('/device/register/', { token: token })
    });
};

export const unregisterMessagingToken = () => {
  const messaging = getMessaging();
  deleteToken(messaging);
};

export const vhToPx = (vh) => {
  const windowHeight = window.innerHeight || document.documentElement.clientHeight;
  return (vh / 100) * windowHeight;
};

export const patchNotification = async (dispatch, uuid) => {
  await api.patch(`/notifications/${uuid}/`).then(response => {
    if (response.status === 200) {
      dispatch(updateNotificationByUuid({ uuid: uuid, properties: { new: false } }));
    };
  });
};

export const formatUuid = uuid => uuid.split('-')[0].slice(0, 5);

export const blobToBase64 = (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = error => reject(error);
    reader.readAsDataURL(blob);
  });
};

const splitter = new GraphemeSplitter();

export const countCharacters = (input) => {
  const graphemes = splitter.splitGraphemes(input);
  return graphemes.length;
};

export const substring = (input, start, length) => {
  const graphemes = splitter.splitGraphemes(input);
  const substringGraphemes = graphemes.slice(start, start + length);
  return substringGraphemes.join('');
};

export const somethingWentWrong = (dispatch, t) => {
  dispatch(setAlertsSnackbarAutoHideDuration(3000));
  dispatch(setAlertsSnackbarSeverity('error'));
  dispatch(setAlertsSnackbarText(t('Oops, something went wrong. Please try again later.')));
  dispatch(setAlertsSnackbarOpen(true));
};

export const closeDrawer = (dispatch) => {
  dispatch(setDrawerRightOpen(false));
};

export const throttle = (func, limit) => {
  let inThrottle;
  return function () {
    const args = arguments;
    const context = this;
    if (!inThrottle) {
      func.apply(context, args);
      inThrottle = true;
      setTimeout(() => inThrottle = false, limit);
    };
  };
};

export const arrayOfInts = (elements) => [...Array(elements).keys()];

export const arraysAreEqual = (arr1, arr2) => {
  if (arr1.length !== arr2.length) {
    return false;
  };

  const sortedArr1 = [...arr1].sort();
  const sortedArr2 = [...arr2].sort();

  for (let i = 0; i < sortedArr1.length; i++) {
    if (sortedArr1[i] !== sortedArr2[i]) {
      return false;
    };
  };

  return true;
};

export const sendChatLog = async (message) => {
  try {
    await api.post('/chat-log/', { message }).catch(err => { });
  } catch { };
};

export const roundToOneDecimal = (value) => Math.round(value * 10) / 10;