import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { QrCode } from 'react-qrcode-pretty';
import { styled } from '@mui/material/styles';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';

import CenteredBox from './CenteredBox.tsx';
import CloseButton from './CloseButton.tsx';

import { APP_URL } from '../../config';
import { equalBoxShadow } from '../../theme';

import { setQrCodeDialogOpen } from '../../features/app/dialogsSlice';

const StyledCenteredBox = styled(CenteredBox)`
  canvas {
    border-radius: 5px;
    box-shadow: ${equalBoxShadow};
  }
`;

const QrCodeDialog: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const open = useSelector((state: any) => state.dialogs.qrCodeDialogOpen);
  const uuid = useSelector((state: any) => state.account.uuid);

  const handleClose = () => {
    dispatch(setQrCodeDialogOpen(false));
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      // fullWidth
      // {...borderedDialogPaperProps}
      PaperProps={{
        style: {
          borderRadius: '25px',
          backgroundColor: '#fff',
          backgroundImage: 'unset',
        }
      }}
    >
      <DialogTitle textAlign="center">{t('Your QR code')}</DialogTitle>
      <CloseButton onClick={handleClose} />
      <DialogContent>
        <CenteredBox mb={2}>
          <Typography variant="caption" textAlign="center">
            {t('Show your QR code to your friends so they can quickly send you an invitation to their network.')}
          </Typography>
        </CenteredBox>
        <StyledCenteredBox p={1}>
          <QrCode
            value={`${APP_URL}/?action=invite-friend&uuid=${uuid}`}
            // variant="fluid"
            variant={{
              eyes: 'fluid',
              body: 'fluid',
            }}
            size={250}
            color={{
              eyes: 'rgba(255, 255, 255, 0.75)',
              body: 'rgba(255, 255, 255, 0.75)',
            }}
            margin={10}
            bgColor="#1A1A1A"
            bgRounded
          // divider
          />
        </StyledCenteredBox>
      </DialogContent>
    </Dialog>
  );
};

export default QrCodeDialog;