import React from 'react';

import IconButton from '@mui/material/IconButton';
import ShareIcon from '@mui/icons-material/Share';

import { APP_URL } from '../../config';

import { Event } from '../../types/Event/types';

interface ShareButtonProps {
  event: Event;
}

const ShareButton: React.FC<ShareButtonProps> = ({ event }) => {
  const handleClick = async () => {
    await navigator.share({
      // title: APP_NAME,
      // text: `${APP_NAME} - ${t('event')}: ${event.properties.name}`,
      url: `${APP_URL}/?pin=${event.properties.uuid}`,
    });
  };

  return (
    <IconButton
      onClick={handleClick}
      aria-label="share">
      <ShareIcon />
    </IconButton>
  );
};

export default ShareButton;