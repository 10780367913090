import {
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';

import { EMPTY_GEOJSON } from '../../consts/map';

export const savedEventsSlice = createSlice({
  name: 'savedEvents',
  initialState: {
    value: EMPTY_GEOJSON,
    past: EMPTY_GEOJSON,
  },
  reducers: {
    addSavedEvent: (state, action) => {
      const newFeature = {
        type: 'Feature',
        properties: {
          uuid: action.payload.uuid,
          ...action.payload.properties,
        },
        geometry: action.payload.geometry,
      };
      state.value = {
        ...state.value,
        features: [newFeature, ...state.value.features],
      };
    },
    removeSavedEventByUuid: (state, action) => {
      const uuid = action.payload;
      state.value = {
        ...state.value,
        features: state.value.features.filter(feature => feature.properties.uuid !== uuid),
      };
      state.past = {
        ...state.past,
        features: state.past.features.filter(feature => feature.properties.uuid !== uuid),
      };
    },
    setSavedEvents: (state, action) => {
      state.value = action.payload;
    },
    setSavedPastEvents: (state, action) => {
      state.past = action.payload;
    },
    resetSavedEvents: (state) => {
      state.value = EMPTY_GEOJSON;
      state.past = EMPTY_GEOJSON;
    },
    updateSavedEventByUuid: (state, action) => {
      const { uuid, newProperties } = action.payload;
      const eventIndex = state.value.features.findIndex(e => e.properties.uuid === uuid);

      if (eventIndex !== -1) {
        const newFeature = {
          type: 'Feature',
          properties: {
            ...state.value.features[eventIndex].properties,
            ...newProperties,
          },
          geometry: state.value.features[eventIndex].geometry,
        };
        state.value.features[eventIndex] = newFeature;
      };
    },
  },
});

// Selectors

export const selectSavedEvents = state => state.events.value.features;

export const selectSavedEventByUuid = createSelector(
  selectSavedEvents,
  (_, uuid) => uuid,
  (savedEvents, uuid) => savedEvents.find(event => event.properties.uuid === uuid) || null
);

// Actions

export const {
  addSavedEvent,
  removeSavedEventByUuid,
  resetSavedEvents,
  setSavedEvents,
  setSavedPastEvents,
  updateSavedEventByUuid,
} = savedEventsSlice.actions;

// Reducer

export default savedEventsSlice.reducer;
