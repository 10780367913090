import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { css, styled } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { setBottomBarValue } from '../../features/app/appSlice';
import { resetDialogs } from '../../features/app/dialogsSlice';
import { setAuthDialogOpen } from '../../features/dialogs/authDialogSlice.ts';

// @ts-ignore
import { ReactComponent as RoundMeLogo } from '../../assets/R-ound.Me.svg';
// @ts-ignore
import { ReactComponent as RoundMePlusLogo } from '../../assets/R+ound.Me.svg';

interface TextLogoProps {
  justify: string;
};

const StyledLink = styled(Link)`
  flex-grow: 1;
  text-align: center;
  vertical-align: middle;
`;

const StyledRoundMeLogo = styled(RoundMeLogo)`
  width: 140px;
  height: 36px;
`;

const StyledRoundMePlusLogo = styled(RoundMePlusLogo)`
  width: 140px;
  height: 36px;
`;

const StyledTypography = styled(Typography)`
  flex-grow: 1;
  text-align: center;
  vertical-align: middle;
`;

const SvgLogoWrapper = styled(Box, { shouldForwardProp: (prop) => prop !== 'justify' }) <TextLogoProps>`
  display: flex;
  cursor: pointer;

  ${(props) => css`
    justify-content: ${props.justify};
  `}
`;

const TextLogo: React.FC<TextLogoProps> = ({ justify }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const plan = useSelector((state: any) => state.account.plan);

  const handleLogoClick = () => {
    if (window.location.pathname === '/') {
      dispatch(setAuthDialogOpen(false));
      dispatch(resetDialogs());
      dispatch(setBottomBarValue(1));
    };
  };

  const Logo = () => {
    const map = t('Map');

    return (
      <StyledTypography variant="h6" noWrap>
        <Tooltip title={window.location.pathname === '/' ? '' : map}>
          <SvgLogoWrapper justify={justify} onClick={handleLogoClick}>
            {plan === 'premium' ? <StyledRoundMePlusLogo /> : <StyledRoundMeLogo />}
          </SvgLogoWrapper>
        </Tooltip>
      </StyledTypography>
    )
  };

  return (
    <React.Fragment>
      {window.location.pathname === '/' ? (
        <Logo />
      ) : (
        <StyledLink href="/">
          <Logo />
        </StyledLink>
      )}
    </React.Fragment>
  );
};

export default TextLogo;