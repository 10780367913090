import React from 'react';
import { styled } from '@mui/material/styles';

import CardMedia from '@mui/material/CardMedia';
import Skeleton from '@mui/material/Skeleton';

const StyledCardMedia = styled(CardMedia)`
  width: 50px;
  height: 50px;
  cursor: pointer;
  aspect-ratio: 1/1;
  border-radius: 10px;
` as typeof CardMedia;

const AvatarSkeleton = styled(Skeleton)`
  transform: unset; 
  transform-origin: unset;
  border-radius: 50%;
` as typeof Skeleton;

const FriendAvatarSkeleton: React.FC = () => {
  return (
    <AvatarSkeleton>
      <StyledCardMedia component="img" src="" alt="" />
    </AvatarSkeleton>
  )
}

export default FriendAvatarSkeleton;
