import api from './api.ts';

import {
  addNewNotification,
  addNotifications,
  setNewNotifications,
  setNotifications,
} from '../features/app/notificationsSlice';
import {
  addNewEventInvitation,
  setEventInvitations,
} from '../features/events/eventInvitationsSlice';
import { setSavedEvents } from '../features/events/savedEventsSlice';
import { setUserEvents } from '../features/events/userEventsSlice';
import {
  addNewFriend,
  addNewFriendRequest,
  setFriends,
  setFriendsRequests,
} from '../features/network/networkSlice';
import { setUsers } from '../features/users/usersSlice';
import { setLanguages } from '../features/dialogs/suggestLanguagesDialogSlice.ts';

export const getNotification = async (dispatch, uuid) => {
  await api.get(`/notifications/${uuid}/`).then(response => {
    dispatch(addNewNotification(response.data));
  }).catch(err => { });
};

export const getNotifications = async (dispatch) => {
  await api.get('/notifications/').then(response => {
    dispatch(setNotifications(response.data));
  }).catch(err => { });
};

export const getNewNotifications = async (dispatch) => {
  await api.get(`/notifications/?new=${true}`).then(response => {
    dispatch(setNewNotifications(response.data));
  }).catch(err => { });
};

export const getNextNotifications = async (dispatch, page) => {
  await api.get(`/notifications/?page=${page}`).then(response => {
    dispatch(addNotifications(response.data));
  }).catch(err => { });
};

export const getFriendRequest = async (dispatch, uuid) => {
  await api.get(`/friend-requests/${uuid}/`).then(response => {
    dispatch(addNewFriendRequest(response.data));
  }).catch(err => { });
};

export const getFriend = async (dispatch, uuid) => {
  await api.get(`/friends/${uuid}/`).then(response => {
    dispatch(addNewFriend(response.data));
  }).catch(err => { });
};

export const getFriendsAndFriendRequests = async (dispatch) => {
  const response = await api.get('/users/friends/');
  dispatch(setFriends(response.data));
  const _friendRequests = await api.get('/users/friends/requests/');
  dispatch(setFriendsRequests(_friendRequests.data));
  await Promise.all([response, _friendRequests]);
};

export const getEventInvitation = async (dispatch, uuid) => {
  await api.get(`/invitations/${uuid}`).then(response => {
    dispatch(addNewEventInvitation(response.data));
  }).catch(err => { });
};

export const getInvitations = async (dispatch) => {
  await api.get('/invitations/').then(response => {
    dispatch(setEventInvitations(response.data));
  }).catch(err => { });
};

export const getFriends = async (dispatch) => {
  await api.get('/users/friends/').then(response => {
    dispatch(setFriends(response.data));
  });
};

export const getFriendRequests = async (dispatch) => {
  await api.get('/users/friends/requests/').then(response => {
    dispatch(setFriendsRequests(response.data));
  });
};

export const getUsers = async (dispatch, coordinates, range) => {
  await api.get('/users/', {
    'params': {
      'lng': coordinates[0],
      'lat': coordinates[1],
      'range': range,
    }
  }).then(response => {
    dispatch(setUsers(response.data));
  });
};

export const getUserEvents = async (dispatch, params = {}) => {
  const query = { params: params };

  await api.get('/user/events/', query).then(
    response => {
      dispatch(setUserEvents(response.data));
    }).catch(err => { });
};

export const getSavedEvents = async (dispatch, params = {}) => {
  const query = { params: params };

  await api.get('/user/events/saved/', query).then(
    response => {
      dispatch(setSavedEvents(response.data));
    }).catch(err => { });
};

export const getLanguages = async (dispatch) => {
  await api.get('/languages/').then(
    response => {
      dispatch(setLanguages(response.data));
    }).catch(err => { });
};