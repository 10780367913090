import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

import BackButton from '../components/ui/BackButton.tsx';
import BigLoader from '../components/ui/BigLoader.tsx';
import CenteredBox from '../components/ui/CenteredBox.tsx';
import Layout from '../components/ui/Layout.tsx';

import api from '../utils/api.ts';

import {
  APP_NAME,
  CONTACT_EMAIL,
} from '../config';
import {
  DRAWER_WIDTH,
  WIDTH_BREAKPOINT,
} from '../theme';

interface PrivacyPolicyEntry {
  title: string;
  content: string;
};

const PrivacyPolicy: React.FC = () => {
  const { t } = useTranslation();

  const width = useSelector((state: any) => state.app.width);
  const language = useSelector((state: any) => state.settings.language);

  const [privacyPolicy, setPrivacyPolicy] = useState<{
    lastUpdate: number | null,
    entries: PrivacyPolicyEntry[] | [],
  }>({ lastUpdate: null, entries: [] });
  const [loading, setLoading] = useState(true);

  const getPrivacyPolicy = async () => {
    setLoading(true);
    await api.get('/privacy-policy/').then(response => {
      setPrivacyPolicy(response.data);
      setLoading(false);
    }).catch(err => {
      setLoading(false);
    });
  };

  useEffect(() => {
    setPrivacyPolicy({ lastUpdate: null, entries: [] });
    getPrivacyPolicy();
  }, [language]);

  return (
    <Layout>
      <Box sx={{
        flexGrow: 1,
        paddingLeft: width > WIDTH_BREAKPOINT ? `${DRAWER_WIDTH + 16}px` : undefined,
        minHeight: '92vh',
      }}>
        <Grid container>
          <Grid item xs={2}>
            <CenteredBox>
              <BackButton onClick={() => window.location.href = '/'} />
            </CenteredBox>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h6" gutterBottom mt={2} align="center">
              {t('Privacy Policy')}
            </Typography>
          </Grid>
          <Grid item xs={2} />
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={1} />
          <Grid item xs={10}>
            <Box mt={1} mb={2}>
              <Typography variant="caption" sx={{ display: 'flex', justifyContent: 'end' }}>
                {t('Last update')}: {
                  loading ? (
                    <Skeleton sx={{ marginLeft: '0.1rem' }}>
                      <Typography variant="caption">
                        00.00.0000 00:00
                      </Typography>
                    </Skeleton>
                  ) : (
                    privacyPolicy.lastUpdate ? dayjs(privacyPolicy.lastUpdate * 1000).format('L LT') : '-'
                  )
                }
              </Typography>
            </Box>
            <Typography variant={width > WIDTH_BREAKPOINT ? 'body1' : 'body2'} textAlign="justify">
              {t('{{appName}} is committed to protecting the privacy and security of its users.', { appName: APP_NAME })}
            </Typography>
            <Typography variant={width > WIDTH_BREAKPOINT ? 'body1' : 'body2'} textAlign="justify" mt={1}>
              {t('This Privacy Policy explains how we collect, use, and protect your personal information \
when you use our app.')}
            </Typography>
            {loading ? (
              <CenteredBox my={10}>
                <BigLoader />
              </CenteredBox>
            ) : (
              <>
                {privacyPolicy.entries.map((entry: PrivacyPolicyEntry, idx: number) => (
                  <React.Fragment key={idx}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }} mt={2}>
                      {entry.title}
                    </Typography>
                    <Typography variant="body2" textAlign="justify" whiteSpace="pre-line">
                      {entry.content}
                    </Typography>
                  </React.Fragment>
                ))}
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }} mt={2}>
                  {t('Contact us')}
                </Typography>
                <Typography variant="body2" textAlign="justify">
                  {t('If you have any questions or concerns about our Privacy Policy or our use of your personal information, \
please contact us using the')} <Link href="/contact/">{t('contact form')}</Link> {t('or at our contact \
email')} <Link href={`mailto:${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</Link>.
                </Typography>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }} align="center" my={3}>
                  {t('Thank you for using {{appName}}!', { appName: APP_NAME })}
                </Typography>
              </>
            )}
          </Grid>
          <Grid item xs={1} />
        </Grid>
      </Box>
    </Layout>
  );
};

export default PrivacyPolicy;