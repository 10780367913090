import React from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';

import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';

interface SearchInputProps {
  searchInput: string;
  setSearchInput: (searchInput: string) => void;
}

const ClearButton = styled(IconButton)`
  margin-right: -10px;
`;

const StyledClearIcon = styled(ClearIcon)`
  font-size: 18px;
`;

const SearchInput: React.FC<SearchInputProps> = ({ searchInput, setSearchInput }) => {
  const { t } = useTranslation();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
  };

  return (
    <TextField
      id="search-field"
      value={searchInput}
      onChange={handleChange}
      label={t('Search…')}
      fullWidth
      variant="standard"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <ClearButton
              onClick={(e) => {
                if (searchInput === '') {
                  const inputField = document.getElementById('search-field');
                  if (inputField) inputField.focus();
                } else {
                  setSearchInput('');
                };
              }}
            >
              {searchInput !== '' ? <StyledClearIcon /> : <SearchIcon />}
            </ClearButton>
          </InputAdornment>
        )
      }}
    />
  );
};

export default SearchInput;