import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { outline, Scanner } from '@yudiel/react-qr-scanner';

import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import BigLoader from './BigLoader.tsx';
import CenteredBox from './CenteredBox.tsx';
import CloseButton from './CloseButton.tsx';
import NoContentAlert from './NoContentAlert.tsx';

import {
  borderedDialogPaperProps,
  brightOrange,
} from '../../theme';
import api from '../../utils/api.ts';

import {
  setAlertsSnackbarAutoHideDuration,
  setAlertsSnackbarOpen,
  setAlertsSnackbarSeverity,
  setAlertsSnackbarText,
} from '../../features/app/alertsSnackbarSlice';
import { setCameraAllowed } from '../../features/app/appSlice';
import { setQrCodeScannerDialogOpen } from '../../features/app/dialogsSlice';
import { updateUserByUuid } from '../../features/users/usersSlice';

import { User } from '../../types/User/types.ts';

const StyledCenteredBox = styled(CenteredBox)`
  position: absolute;
  width: 100%;
  top: 50%;
`;

const StyledDialogContent = styled(DialogContent)`
  padding: 0;
  overflow: hidden;
`;

const StyledDialogTitle = styled(DialogTitle)`
  font-size: 1rem;
  margin-top: 3px;
  text-align: center;
`;

const QrCodeScannerDialog: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const open = useSelector((state: any) => state.dialogs.qrCodeScannerDialogOpen);
  const [showAlert, setShowAlert] = useState(false);
  const friends = useSelector((state: any) => state.network.friends);
  const friendsUuids = friends.features.map((f: User) => f.properties.uuid);

  const handleClose = () => {
    dispatch(setQrCodeScannerDialogOpen(false));
  };

  const handleScan = async (result) => {
    dispatch(setCameraAllowed(true));
    try {
      const value = result[0].rawValue;
      const url = new URL(value);
      const action = url.searchParams.get('action');
      const uuid = url.searchParams.get('uuid');
      if (action === 'invite-friend') {
        dispatch(setAlertsSnackbarAutoHideDuration(3000));
        if (friendsUuids.includes(uuid)) {
          dispatch(setAlertsSnackbarSeverity('info'));
          dispatch(setAlertsSnackbarText(t('You are already friends!')))
          dispatch(setAlertsSnackbarOpen(true));
        } else {
          await api.post('/users/friends/requests/', {
            action: 'create',
            receiver: uuid,
          }).then(response => {
            if (response.status === 200) {
              dispatch(setAlertsSnackbarSeverity('info'));
              dispatch(setAlertsSnackbarText(t('Invitation already sent!')))
              dispatch(setAlertsSnackbarOpen(true));
            } else if (response.status === 201) {
              dispatch(setAlertsSnackbarSeverity('success'));
              dispatch(setAlertsSnackbarText(t('Friend request sent!')));
              dispatch(setAlertsSnackbarOpen(true));
              dispatch(updateUserByUuid({
                uuid: uuid,
                newProperties: { invited: true }
              }));
            };
          });
        };
        handleClose();
      } else {
        dispatch(setAlertsSnackbarSeverity('warning'));
        dispatch(setAlertsSnackbarText(t('Invalid QR code!')));
        dispatch(setAlertsSnackbarOpen(true));
      };
    } catch {
      dispatch(setAlertsSnackbarSeverity('warning'));
      dispatch(setAlertsSnackbarText(t('Invalid QR code!')));
      dispatch(setAlertsSnackbarOpen(true));
    };
  };

  const handleError = () => {
    dispatch(setCameraAllowed(false));
    setShowAlert(true);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      {...borderedDialogPaperProps}
    >
      <StyledDialogTitle>
        {t("Scan your friend's QR Code")}
      </StyledDialogTitle>
      <CloseButton onClick={handleClose} />
      <StyledDialogContent>
        {!showAlert ? (
          <>
            <StyledCenteredBox>
              <BigLoader />
            </StyledCenteredBox>
            <Scanner
              classNames={{ container: 'scanner' }}
              onScan={handleScan}
              onError={handleError}
              formats={['qr_code']}
              components={{
                tracker: outline,
                audio: false,
                onOff: false,
                finder: true,
                torch: false,
                zoom: false,
              }}
              scanDelay={2000}
              allowMultiple
            />
          </>
        ) : (
          <Box p={2}>
            <NoContentAlert
              text={t('Access to camera disabled!') as string}
              color={brightOrange}
              spacing={2}
            />
          </Box>
        )}
      </StyledDialogContent>
    </Dialog>
  );
};

export default QrCodeScannerDialog;