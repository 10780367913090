import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { css, styled, useTheme } from '@mui/material/styles';
import SwipeableViews from 'react-swipeable-views-react-18-fix';

import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import MobileStepper from '@mui/material/MobileStepper';

import api from '../../utils/api.ts';
import AuthContext from '../../context/AuthContext';

import { setAlwaysShowIntro } from '../../features/settings/settingsSlice';

import { WIDTH_BREAKPOINT } from '../../theme';
import {
  setConfigurationStepperOpen,
  setIntroStepperOpen,
} from '../../features/app/dialogsSlice';

const StartButton = styled(IconButton)`
  position: absolute;
  right: -300%;
  top: 49.5%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #fff;
  color: #000;
  box-shadow: 0px 0px 0px 3px #fff;
  border: 3px solid #171717;
`;

const StyledBackdrop = styled(Backdrop)`
  // background-color: #171717;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  // display: flex;
  justify-content: center;
  position: absolute;
  // right: -275%;
  width: 100%;
`;

const StyledImage = styled('img', { shouldForwardProp: (prop) => prop !== 'width' }) <{ width: number }>`
  width: 100%;
  // height: 100%;
  object-fit: contain;

  ${(props) => css`
    border-radius: ${props.width > WIDTH_BREAKPOINT / 2 && '25px'};
  `}
`;

const StyledMobileStepper = styled(MobileStepper)`
  position: absolute;
  width: 100%;
  // display: none;
  background-color: transparent;

  .MuiMobileStepper-dots {
    align-items: center;
  }

  .MuiMobileStepper-dot {
    background-color: #fff;
    width: 10px;
    height: 10px;
  }

  .MuiMobileStepper-dotActive {
    // background-color: #fff;
    width: 16px;
    height: 16px;
    box-shadow: 0px 0px 0px 2px #fff;
    border: 2px solid #171717;
  }
`;

const Wrapper = styled(Box)`
  flex-grow: 1;
  width: 100%;
`;

const IntroStepper: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useContext(AuthContext);

  const open = useSelector((state: any) => state.dialogs.introStepperOpen);
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const width = useSelector((state: any) => state.app.width);
  const language = useSelector((state: any) => state.settings.language);
  const alwaysShowIntro = useSelector((state: any) => state.settings.alwaysShowIntro);
  const [alwaysShowIntroAnonymous, setAlwaysShowIntroAnonymous] = useState<string | null>(JSON.parse(localStorage.getItem('alwaysShowIntro') as boolean));
  const [load, setLoad] = useState(0);

  const [imagesLoaded, setImagesLoaded] = useState(0);

  const handleOnLoad = () => {
    setImagesLoaded(prevCount => prevCount + 1);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(setConfigurationStepperOpen(true));
    setTimeout(() => {
      dispatch(setIntroStepperOpen(false));
    }, 500);

    let checked = e.target.intro.checked;
    if (user) {
      await api.post('/users/settings/', {
        alwaysShowIntro: checked,
      });
      await api.patch('/counters/intro-pass/');
    } else {
      localStorage.setItem('alwaysShowIntro', checked);
      setAlwaysShowIntroAnonymous(checked);
    };
    setLoad(load + 2);
  };

  const handleAlwaysShowIntro = (e) => {
    e.stopPropagation();
    let checked = e.target.checked;
    if (user) {
      dispatch(setAlwaysShowIntro(checked));
    } else {
      localStorage.setItem('alwaysShowIntro', checked);
      setAlwaysShowIntroAnonymous(checked);
    };
  };

  const platform = width <= WIDTH_BREAKPOINT / 2 ? 'mobile' : 'desktop';
  const steps = [
    (
      <StyledImage
        key={1}
        src={`/intro-stepper-${platform}-1-${language}.png`}
        onLoad={handleOnLoad}
        width={width}
      />
    ),
    (
      <StyledImage
        key={2}
        src={`/intro-stepper-${platform}-2-${language}.png`}
        onLoad={handleOnLoad}
        width={width}
      />
    ),
    (
      <StyledImage
        key={3}
        src={`/intro-stepper-${platform}-3-${language}.png`}
        onLoad={handleOnLoad}
        width={width}
      />
    ),
    (
      <React.Fragment key={4}>
        <StyledImage
          src={`/intro-stepper-${platform}-4-${language}.png`}
          onLoad={handleOnLoad}
          width={width}
        />
        <form onSubmit={handleSubmit}>
          <StartButton type="submit">
            <KeyboardArrowRight fontSize='large' />
          </StartButton>
          <StyledFormControlLabel
            control={
              <Checkbox
                id="intro"
                // checked={alwaysShowIntro}
                // checked={user ? alwaysShowIntro : JSON.parse(localStorage.getItem('alwaysShowIntro'))}
                checked={user ? alwaysShowIntro || false : alwaysShowIntroAnonymous || false}
                onChange={handleAlwaysShowIntro}
                onClick={handleAlwaysShowIntro}
              />
            }
            label={t('Always show intro')}
            sx={{ bottom: width <= WIDTH_BREAKPOINT / 2 ? '30dvh' : '25%' }}
          />
        </form>
      </React.Fragment>
    ),
  ];

  const maxSteps = steps.length;

  useEffect(() => {
    if (alwaysShowIntro === null) { setLoad(load + 1) };
    if (user && alwaysShowIntro && load === 1) {
      dispatch(setIntroStepperOpen(true));
    };
    if (user) {
      setLoad(load + 1);
    } else {
      setLoad(load + 2);
    };
  }, [alwaysShowIntro]);

  useEffect(() => {
    if (!user) {
      if (
        localStorage.getItem('firstLoad') === null ||
        localStorage.getItem('alwaysShowIntro') === null ||
        JSON.parse(localStorage.getItem('alwaysShowIntro'))
      ) {
        dispatch(setIntroStepperOpen(true));
        localStorage.setItem('firstLoad', 'false');
        setLoad(load + 2);
      };
    };
  }, []);

  useEffect(() => {
    if (!open) {
      setTimeout(() => {
        setActiveStep(0);
      }, 500);
    }
  }, [open]);

  return (
    <Dialog
      id="intro-stepper"
      hideBackdrop={false}
      open={open}
      fullScreen={width <= WIDTH_BREAKPOINT / 2}
      PaperProps={{
        style: {
          height: 'unset',
          borderRadius: width <= WIDTH_BREAKPOINT / 2 ? undefined : '25px',
        }
      }}
      sx={{ zIndex: 1401 }}
    >
      <StyledBackdrop open={imagesLoaded < maxSteps}>
        <CircularProgress color="inherit" />
      </StyledBackdrop>
      <Wrapper>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
        >
          {steps}
        </SwipeableViews>
        <StyledMobileStepper
          variant="dots"
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          nextButton={
            <IconButton
              size="small"
              onClick={() => {
                if (activeStep === maxSteps - 1) {
                  // dispatch(setIntroStepperOpen(false));
                } else {
                  handleNext();
                };
              }}
              disabled={activeStep === maxSteps - 1}
              sx={{
                visibility: activeStep === maxSteps - 1 ? 'hidden' : undefined,
              }}
            >
              {theme.direction === 'rtl' ? (
                <KeyboardArrowLeft fontSize="large" />
              ) : (
                <KeyboardArrowRight fontSize="large" />
              )}
            </IconButton>
          }
          backButton={
            <IconButton
              onClick={handleBack}
              disabled={activeStep === 0}
              sx={{
                visibility: activeStep === 0 ? 'hidden' : undefined,
              }}
            >
              {theme.direction === 'rtl' ? (
                <KeyboardArrowRight fontSize="large" />
              ) : (
                <KeyboardArrowLeft fontSize="large" />
              )}
            </IconButton>
          }
          sx={{ bottom: width <= WIDTH_BREAKPOINT / 2 ? '15dvh' : 0 }}
        />
      </Wrapper>
    </Dialog>
  );
};

export default IntroStepper;