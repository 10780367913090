import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import { styled } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import GroupIcon from '@mui/icons-material/Group';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';

import {
  setAlertsSnackbarOpen,
  setAlertsSnackbarSeverity,
  setAlertsSnackbarText,
} from '../../features/app/alertsSnackbarSlice';
import {
  setNetworkDialogOpen,
  setNetworkDialogTab,
  setQrCodeScannerDialogOpen,
} from '../../features/app/dialogsSlice';
import {
  setFriends,
  setFriendsRequests,
} from '../../features/network/networkSlice';

import { a11yProps, TabPanel } from '../ui/Tabs';

import api from '../../utils/api.ts';
import {
  getFriends,
  getFriendsAndFriendRequests,
  getFriendRequests,
  getNewNotifications,
} from '../../utils/getters';

import AuthContext from '../../context/AuthContext';
import IntervalContext from '../../context/IntervalContext';

import {
  GET_FRIENDS_INTERVAL,
  GET_FRIENDS_REQUESTS_INTERVAL,
} from '../../consts/intervals';

import {
  customDialogStyle,
  WIDTH_BREAKPOINT,
} from '../../theme';
import { removeUserByUuid } from '../../features/users/usersSlice';

import CloseButton from '../ui/CloseButton.tsx';
import FriendCardMin from './FriendCardMin.tsx';
import FriendCardMinSkeleton from './FriendCardMinSkeleton.tsx';
import FriendRequestCardMin from './FriendRequestCardMin.tsx';
import FriendRequestCardMinSkeleton from './FriendRequestCardMinSkeleton.tsx';
import FriendRequestCardMinWrapper from './FriendRequestCardMinWrapper.tsx';
import NoContentAlert from '../ui/NoContentAlert.tsx';
import OnlineBadge from './OnlineBadge.tsx';
import SearchFriends from './SearchFriends.tsx';
import SearchInput from '../ui/SearchInput.tsx';
import SmallLoader from '../ui/SmallLoader.tsx';
import TabsWrapper from '../ui/TabsWrapper.tsx';

import { User } from '../../types/User/types.ts';

const FriendsWrapper = styled(Box)`
  padding: 0px 24px;
`;

const StyledListItem = styled(ListItem)`
  justify-content: center;
  padding-left: 0;
  padding-right: 0;
`;

const StyledSpan = styled('span')`
  display: inline-flex;
  align-items: center;
`;

const NetworkDialog: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { user } = useContext(AuthContext);
  const { addInterval } = useContext(IntervalContext);

  const width = useSelector((state: any) => state.app.width);

  const [searchInput, setSearchInput] = useState('');
  const friends = useSelector((state: any) => state.network.friends);
  const [filteredFriends, setFilteredFriends] = useState(friends);
  const [friendsReady, setFriendsReady] = useState(false);
  const friendsRequests = useSelector((state: any) => state.network.friendsRequests);
  const [friendRequestsReady, setFriendRequestsReady] = useState(false);

  const initialLoad = useRef(true);

  const [actionButtonDisabled, setActionButtonDisabled] = useState(false);

  const [loading, setLoading] = useState(true);

  const tabIndex = useSelector((state: any) => state.dialogs.networkDialogTab);
  const [intervalId, setIntervalId] = useState<number | undefined>();
  const [friendsIntervalId, setFriendsIntervalId] = useState<number | undefined>();

  const open = useSelector((state: any) => state.dialogs.networkDialogOpen);

  const notificationsAllowed = useSelector((state: any) => state.notifications.notificationsAllowed);

  const handleClose = () => {
    dispatch(setNetworkDialogOpen(false));
  };

  const handleAction = async (
    e: React.MouseEvent<HTMLButtonElement>,
    uuid: string,
    action: string,
  ) => {
    setLoading(true);
    setActionButtonDisabled(true);
    dispatch(setFriendsRequests(friendsRequests.filter(el => el.uuid !== uuid)));
    if (action === 'accept') {
      dispatch(setAlertsSnackbarSeverity('success'));
      dispatch(setAlertsSnackbarText(t('Friend request accepted!')));
    } else if (action === 'reject') {
      dispatch(setAlertsSnackbarSeverity('info'));
      dispatch(setAlertsSnackbarText(t('Friend request rejected!')));
    };
    dispatch(setAlertsSnackbarOpen(true));
    await api.post('/users/friends/requests/', {
      action: action,
      uuid: uuid,
    }).then(async () => {
      getNewNotifications(dispatch);
      await api.get('/users/friends/').then(response => {
        dispatch(setFriends(response.data));
        if (action === 'accept') {
          dispatch(removeUserByUuid(uuid));
        };
        setTimeout(() => { setActionButtonDisabled(false) }, 250);
        setLoading(false);
      });
    });
  };

  const fetchData = async () => {
    if (open) {
      setLoading(true);
      await getFriendsAndFriendRequests(dispatch).then(() => {
        setFriendRequestsReady(true);
        setFriendsReady(true);
      }).catch(err => { });
      setLoading(false);
    };
  };

  const handleTabChange = (e: React.SyntheticEvent, newValue: number) => {
    dispatch(setNetworkDialogTab(newValue));
  };

  useEffect(() => {
    if (open) fetchData();

    // https://stackoverflow.com/questions/53253940/make-react-useeffect-hook-not-run-on-initial-render
    if (initialLoad.current) {
      if (open) {
        initialLoad.current = false;
      };
      // return;
    } else {
      // fetchData();

      if (open) setActionButtonDisabled(false);
    };
  }, [open]);  // FIXME

  useEffect(() => {
    var interval: number | undefined;
    if (!notificationsAllowed) {
      if (user) {
        interval = addInterval(() => {
          getFriendRequests(dispatch);
        }, GET_FRIENDS_REQUESTS_INTERVAL);
        setIntervalId(interval);
      } else {
        clearInterval(intervalId);
        setIntervalId(undefined);
        initialLoad.current = true;
      };
    };
    return () => clearInterval(interval);
  }, [user, notificationsAllowed]);

  useEffect(() => {
    var interval: number | undefined;
    if (user) {
      interval = addInterval(() => {
        getFriends(dispatch);
      }, GET_FRIENDS_INTERVAL);
      setFriendsIntervalId(interval);
    } else {
      clearInterval(friendsIntervalId);
      setFriendsIntervalId(undefined);
      setFriendRequestsReady(false);
      setFriends(false);
    };
    return () => clearInterval(interval);
  }, [user]);

  useEffect(() => {
    if (!open && !initialLoad.current) {
      setTimeout(() => {
        dispatch(setNetworkDialogTab(0));
        setSearchInput('');
      }, 500);
    };
  }, [open, initialLoad]);

  useEffect(() => {
    setFilteredFriends({
      type: 'FeatureCollection',
      features: friends.features.filter((feature: User) => {
        return searchInput.trim() === '' ||
          feature.properties.username.toLowerCase().includes(searchInput.toLowerCase());
      }),
    });
  }, [friends, searchInput]);

  return user && (
    <Dialog
      hideBackdrop={width <= WIDTH_BREAKPOINT}
      fullScreen={width <= WIDTH_BREAKPOINT}
      fullWidth
      open={open}
      onClose={handleClose}
      scroll="paper"
      PaperProps={{
        style: {
          borderRadius: width > WIDTH_BREAKPOINT ? '25px' : undefined,
          height: width > WIDTH_BREAKPOINT ? '75vh' : undefined,
          // top: width > WIDTH_BREAKPOINT ? '-5vh' : 'unset',
        }
      }}
      {...customDialogStyle}
    // sx={{ zIndex: 1301 }}
    >
      <Box sx={{ marginBottom: width <= WIDTH_BREAKPOINT ? '12vh' : 'unset' }}>
        <DialogTitle textAlign="center">
          {t('My community')}
          {loading && <SmallLoader />}
          <CloseButton onClick={handleClose} />
          <TabsWrapper>
            <Tabs value={tabIndex} onChange={handleTabChange} aria-label="basic tabs example" centered>
              <Tab icon={<GroupIcon />} {...a11yProps(0)} />
              <Tab icon={<GroupAddIcon />} {...a11yProps(1)} />
            </Tabs>
          </TabsWrapper>
        </DialogTitle>
        <DialogContent sx={{
          padding: 0,
          // paddingBottom: width <= WIDTH_BREAKPOINT ? '12vh' : 'unset',
        }}>
          <TabPanel value={tabIndex} parentName="network-dialog" index={0}>
            <Typography variant="subtitle1" gutterBottom>
              {t('Invitations')}
            </Typography>
            {friendRequestsReady ? (
              friendsRequests.length > 0 ? (
                <List sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  overflowX: friendRequestsReady && friendsRequests.length > 1 ? 'scroll' : undefined,
                  width: '100%',
                  padding: friendsRequests.length === 1 || !friendRequestsReady ? '0 24px' : undefined,
                  minHeight: '100px',
                }}>
                  {friendsRequests.map((item, index: number) => (
                    <FriendRequestCardMinWrapper friendRequests={friendsRequests} key={index}>
                      <FriendRequestCardMin
                        friendRequest={item}
                        handleAction={handleAction}
                        actionButtonDisabled={actionButtonDisabled}
                      />
                      <br />
                    </FriendRequestCardMinWrapper>
                  ))}
                </List>
              ) : (
                <Box paddingX={3}>
                  <NoContentAlert
                    text={t("You don't have any invitations yet!") as string}
                    direction="row"
                    spacing={2}
                    size="30px"
                  />
                </Box>
              )) : (
              <Box paddingX={3}>
                <FriendRequestCardMinWrapper>
                  <FriendRequestCardMinSkeleton />
                </FriendRequestCardMinWrapper>
              </Box>
            )}
            <Typography variant="subtitle1" mt={2} gutterBottom>
              {t('Friends')}
              {friendsReady && friends.features.length > 0 && (
                <StyledSpan>
                  &nbsp;{'('}
                  <OnlineBadge variant="dot" sx={{ margin: '0 0.75rem 0 0.5rem' }} />
                  {`${friends.features.filter((f: User) => f.properties.online).length} / ${friends.features.length} )`}
                </StyledSpan>
              )}
            </Typography>
            <FriendsWrapper>
              {friendsReady && friends.features.length > 0 && (
                <SearchInput searchInput={searchInput} setSearchInput={setSearchInput} />
              )}
              {friendsReady ? (
                friends.features.length > 0 ? (
                  filteredFriends.features.length > 0 ? (
                    <List>
                      {filteredFriends.features.map((feature: User, index: number) => (
                        <StyledListItem key={index}>
                          <FriendCardMin feature={feature} />
                        </StyledListItem>
                      ))}
                    </List>
                  ) : (
                    <Box mt={2}>
                      <NoContentAlert text={t('No results!') as string} spacing={0} />
                    </Box>
                  )
                ) : (
                  <>
                    <NoContentAlert
                      text={t("You don't have any friends yet!") as string}
                      direction="row"
                      spacing={2}
                      size="30px"
                    />
                    <br />
                    <Button variant="outlined" onClick={() => dispatch(setNetworkDialogTab(1))}>
                      {t('Invite one')}
                    </Button>
                  </>
                )) : (
                <Box pt={2}>
                  <FriendCardMinSkeleton />
                </Box>
              )}
            </FriendsWrapper>
          </TabPanel>
          <TabPanel value={tabIndex} parentName="network-dialog" index={1}>
            <Box
              // mb={1}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
                // margin: '0 auto',
                margin: '0 auto 0.35rem auto',
                width: 'fit-content',
              }}>
              <Typography variant="subtitle1"
                // gutterBottom 
                sx={{ display: 'flex', alignItems: 'center' }}>
                {t('Add new friends')}
                {isMobile && (
                  <IconButton
                    onClick={() => {
                      dispatch(setQrCodeScannerDialogOpen(true));
                    }}
                    sx={{
                      position: 'absolute',
                      left: '100%',
                    }}
                  >
                    <QrCodeScannerIcon />
                  </IconButton>
                )}
              </Typography>
            </Box>
            <Box sx={{ padding: '0 24px' }}>
              <SearchFriends setLoading={setLoading} />
            </Box>
          </TabPanel>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default NetworkDialog;
