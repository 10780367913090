import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import PhotoOutlinedIcon from '@mui/icons-material/PhotoOutlined';
import Typography from '@mui/material/Typography';

import {
  setEventDialogEvent,
  setEventDialogOpen,
} from '../../features/dialogs/eventDialogSlice';
import {
  setImageDialogAlt,
  setImageDialogOpen,
  setImageDialogSrc,
} from '../../features/dialogs/imageDialogSlice';

import BookmarkButton from '../ui/BookmarkButton';
import ShowOnMapButton from '../ui/ShowOnMapButton';

import {
  getDistance,
  formatDistance,
} from '../../utils/mapUtils';
import {
  grayedOutFilter,
  sunsetOrange,
} from '../../theme';

import { Event } from '../../types/Event/types';

interface UserEventCardProps {
  event: Event
};

const UserEventCard: React.FC<UserEventCardProps> = ({ event }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const userPosition = useSelector((state: any) => state.userPosition.value);

  const handleEventPictureClick = () => {
    dispatch(setImageDialogSrc(event.properties.picture));
    dispatch(setImageDialogAlt(event.properties.name));
    dispatch(setImageDialogOpen(true));
  };

  return (
    <>
      <Badge
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        badgeContent={event.properties.cancelled ? t('Cancelled') : 0}
        sx={{
          position: 'absolute',
          top: '0.5rem',
          left: '2.5rem',
          '& .MuiBadge-badge': {
            backgroundColor: sunsetOrange,
          }
        }}
      />
      <Card
        // id={invitation.event.properties.uuid}
        variant="outlined"
        sx={{
          width: '100%',
          borderRadius: '25px',
          backgroundColor: 'transparent',
          '& .MuiCardHeader-action': { margin: 'auto' },
          '& .MuiCardHeader-root': { paddingRight: 0 },
        }}>
        <CardHeader
          avatar={
            event.properties.picture ? (
              <Badge
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                badgeContent={event.properties.invited ? <MarkEmailReadIcon sx={{ fontSize: '1rem' }} /> : ''}
              >
                <CardMedia
                  component="img"
                  image={event.properties.picture}
                  alt={event.properties.name}
                  onClick={handleEventPictureClick}
                  sx={{
                    width: '75px',
                    height: '75px',
                    cursor: 'pointer',
                    aspectRatio: '1/1',
                    borderRadius: '10px',
                    filter: event.properties.cancelled ? grayedOutFilter : undefined,
                  }}
                />
              </Badge>

            ) : (
              <Badge
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                badgeContent={event.properties.invited ? <MarkEmailReadIcon sx={{ fontSize: '1rem' }} /> : ''}
              >
                <CardMedia sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '75px',
                  aspectRatio: '1/1',
                  border: '1px solid #fff',
                  borderRadius: '10px',
                  filter: event.properties.cancelled ? grayedOutFilter : undefined,
                }}>
                  <Avatar sx={{ bgcolor: 'transparent' }} aria-label="recipe">
                    <PhotoOutlinedIcon sx={{ color: '#fff', fontSize: '40px' }} />
                  </Avatar>
                </CardMedia>
              </Badge>
            )
          }
          title={
            <Typography
              // align="center"
              variant="h6"
              onClick={() => {
                dispatch(setEventDialogEvent(event));
                dispatch(setEventDialogOpen(true));
              }}
              sx={{
                flexGrow: 1,
                // textAlign: 'center',
                // verticalAlign: 'middle',
                wordBreak: 'break-word',
                cursor: 'pointer',
                filter: event.properties.cancelled ? grayedOutFilter : undefined,
              }}
            >
              {event.properties.name}
            </Typography >
          }
          subheader={
            !event.properties.cancelled && userPosition.length === 2 && (
              <Typography
                variant="subtitle2"
                sx={{
                  filter: event.properties.cancelled ? grayedOutFilter : undefined,
                }}
              >
                {formatDistance(getDistance(userPosition, event.geometry.coordinates))}
              </Typography>
            )
          }
          action={
            <>
              {window.location.pathname === '/' && !event.properties.cancelled && (
                <ShowOnMapButton feature={event} />
              )}
              <BookmarkButton event={event} />
            </>
          }
        />
      </Card>
    </>
  );
};

export default UserEventCard;