import React from 'react';
import { styled } from '@mui/material/styles';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

const StyledCard = styled(Card)`
  width: 100%;
  border-radius: 25px;
  background-color: transparent;
` as typeof Card;

const StyledCardMedia = styled(CardMedia)`
  width: 75px;
  height: 75px;
  cursor: pointer;
  aspect-ratio: 1/1;
  border-radius: 10px;
` as typeof CardMedia;

const AvatarSkeleton = styled(Skeleton)`
  transform: unset; 
  transform-origin: unset;
` as typeof Skeleton;

const TitleSkeleton = styled(Skeleton)`
  max-width: 100%;
  width: auto;
  display: flex;
  flex-direction: row;
  margin-right: 32px;
` as typeof Skeleton;

const SubheaderSkeleton = styled(Skeleton)`
  max-width: 50%;
  width: auto;
  display: flex;
  flex-direction: row;
  margin-right: 32px;
` as typeof Skeleton;

const EventInvitationCardMinSkeleton: React.FC = () => {
  return (
    <StyledCard variant="outlined">
      <CardHeader
        avatar={
          <AvatarSkeleton>
            <StyledCardMedia component="img" src="" alt="" />
          </AvatarSkeleton>
        }
        title={
          <TitleSkeleton>
            <Typography variant="h5">x</Typography>
          </TitleSkeleton>
        }
        subheader={
          <SubheaderSkeleton>
            <Typography variant="h3">x</Typography>
          </SubheaderSkeleton>
        }
      />
    </StyledCard>
  );
};

export default EventInvitationCardMinSkeleton;