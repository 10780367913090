import { createSlice } from '@reduxjs/toolkit';

import { EMPTY_FEATURE } from '../../consts/map';

export const inviteFriendDialogSlice = createSlice({
  name: 'inviteFriendDialog',
  initialState: {
    open: false,
    user: EMPTY_FEATURE,
  },
  reducers: {
    setInviteFriendDialogUser: (state, action) => {
      state.user = action.payload;
    },
    setInviteFriendDialogOpen: (state, action) => {
      state.open = action.payload;
    },
  }
})

export const {
  setInviteFriendDialogOpen,
  setInviteFriendDialogUser,
} = inviteFriendDialogSlice.actions;

export default inviteFriendDialogSlice.reducer;