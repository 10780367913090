import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MenuItem from '@mui/material/MenuItem';
import StoreIcon from '@mui/icons-material/Store';

import { setCompanyAccountInfoDialogOpen } from '../../features/app/dialogsSlice';
import { setNewEventDialogOpen } from '../../features/dialogs/newEventDialogSlice.ts';
import { setCreateNewMenuAnchorEl } from '../../features/map/createNewMenuSlice.ts';

import RoundMenu from './RoundMenu.tsx';

export default function CreateNewMenu(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const anchorEl = useSelector((state) => state.createNewMenu.anchorEl);
  const open = Boolean(anchorEl);
  const center = useSelector((state) => state.createNewMenu.center);

  const handleClose = () => {
    dispatch(setCreateNewMenuAnchorEl(null));
    setTimeout(() => {
      props.newEventMapMarker.remove();
    }, 100);
  };

  const handleNewEventClick = () => {
    dispatch(setCreateNewMenuAnchorEl(null));
    dispatch(setNewEventDialogOpen(true));
  };

  const handlePlaceClick = () => {
    // handleClose();
    dispatch(setCompanyAccountInfoDialogOpen(true));
  };

  return (
    <RoundMenu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: center ? 'center' : 'left',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: center ? 'center' : 'right',
      }}
    >
      <MenuItem onClick={handleNewEventClick}>
        <ListItemIcon>
          <LocationOnIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>{t('Pin')}</ListItemText>
      </MenuItem>
      <Divider />
      <MenuItem onClick={handlePlaceClick}>
        <ListItemIcon>
          <StoreIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>{t('Place')}</ListItemText>
      </MenuItem>
    </RoundMenu>
  )
}