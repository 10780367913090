import { createSlice } from '@reduxjs/toolkit';

export const premiumInfoDialogSlice = createSlice({
  name: 'premiumInfoDialog',
  initialState: {
    open: false,
    text: '',
  },
  reducers: {
    setPremiumInfoDialogOpen: (state, action) => {
      state.open = action.payload;
    },
    setPremiumInfoDialogText: (state, action) => {
      state.text = action.payload;
    },
  },
});

export const {
  setPremiumInfoDialogOpen,
  setPremiumInfoDialogText,
} = premiumInfoDialogSlice.actions;

export default premiumInfoDialogSlice.reducer;
