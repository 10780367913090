import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import dayjs from 'dayjs';
import Bowser from 'bowser';

import CircularProgress from '@mui/material/CircularProgress';
import Fab from '@mui/material/Fab';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import GpsNotFixedIcon from '@mui/icons-material/GpsNotFixed';
import GpsOffIcon from '@mui/icons-material/GpsOff';

import {
  MAX_ZOOM,
  NAVIGATION_PITCH,
} from '../../consts/map';
import {
  backdropBlur,
  fabBoxShadow,
} from '../../theme';
import { getZoomBasedOnCircle } from '../../utils/mapUtils';

import {
  setGeolocationDeniedDialogOpen,
  setLocationPermissionDialogOpen,
} from '../../features/app/dialogsSlice';

export default function GeocontrolFab() {
  const dispatch = useDispatch();

  const map = useSelector((state) => state.map.value);
  const fabSize = useSelector((state) => state.app.fabSize);
  const isMapDragged = useSelector((state) => state.map.isDragged);
  const geolocateControl = useSelector((state) => state.map.geolocateControl);
  const geolocationPermissionStatus = useSelector((state) => state.map.geolocationPermissionStatus);
  const navigating = useSelector((state) => state.map.navigating);

  const parsedUserAgent = Bowser.parse(window.navigator.userAgent);
  const os = parsedUserAgent.os;

  const icons = {
    'OFF': <GpsOffIcon fontSize="2rem" />,
    'WAITING_ACTIVE': <CircularProgress />,
    'ACTIVE_LOCK': <GpsFixedIcon fontSize="2rem" />,
    'BACKGROUND': <GpsNotFixedIcon fontSize="2rem" />,
  };

  const handleClick = (e) => {
    // console.log(`[${dayjs().format('YYYY-MM-DDTHH:mm:ssZ')}] locationEnabled:`, locationEnabled);
    // if (locationEnabled === false) {
    console.log('geolocationPermissionStatus', geolocationPermissionStatus);
    // if (geolocationPermissionStatus === 'denied' || (os.name !== 'iOS' && isMobile && geolocationPermissionStatus === 'prompt')) {
    if (geolocationPermissionStatus === 'denied' || (os.name === 'iOS' && geolocationPermissionStatus === 'prompt')) {
      // console.log("!== 'granted'")
      dispatch(setGeolocationDeniedDialogOpen(true));
      // navigator.geolocation.getCurrentPosition(
      //   position => { },
      //   error => {
      //     console.log(`[${dayjs().format('YYYY-MM-DDTHH:mm:ssZ')}] error:`, error);
      //   },
      // );
      return;
    };
    console.log('geolocateControl._watchState', geolocateControl._watchState)
    if (geolocateControl._watchState === 'OFF' && geolocationPermissionStatus === 'prompt') {
      dispatch(setLocationPermissionDialogOpen(true));
    } else if (!['WAITING_ACTIVE', 'ACTIVE_LOCK'].includes(geolocateControl._watchState)) {
      // console.log(geolocateControl)
      geolocateControl.options.fitBoundsOptions.bearing = map.getBearing();
      if (navigating) {
        geolocateControl.options.fitBoundsOptions.pitch = NAVIGATION_PITCH;
        geolocateControl.options.fitBoundsOptions.zoom = MAX_ZOOM;
      } else {
        geolocateControl.options.fitBoundsOptions.zoom = getZoomBasedOnCircle(map);
      };
      geolocateControl.trigger();
    };
  };

  // useEffect(() => {
  //   if ('geolocation' in navigator) {
  //     navigator.geolocation.getCurrentPosition(
  //       function (position) {
  //         setLocationEnabled(true);
  //       },
  //       function (error) {
  //         console.log(`[${dayjs().format('YYYY-MM-DDTHH:mm:ssZ')}]`, error);
  //         if (error.code === error.PERMISSION_DENIED) {
  //           setLocationEnabled(false);
  //         };
  //       },
  //     );
  //   } else {
  //     setLocationEnabled(false);
  //   };
  // }, []);

  // useEffect(() => {
  //   const checkLocationStatus = async () => {
  //     try {
  //       navigator.permissions.query({ name: 'geolocation' }).then(ps => {
  //         console.log(`[${dayjs().format('YYYY-MM-DDTHH:mm:ssZ')}] result.state:`, ps.state);
  //         if (ps.state === 'granted') {
  //           setLocationEnabled(true);
  //         } else if (ps.state === 'prompt') {
  //           setLocationEnabled(null);
  //         } else {
  //           navigator.geolocation.getCurrentPosition(
  //             position => { },
  //             error => {
  //               console.log(`[${dayjs().format('YYYY-MM-DDTHH:mm:ssZ')}] error:`, error);
  //               setLocationEnabled(false);
  //             },
  //           );
  //           // console.log(`[${dayjs().format('YYYY-MM-DDTHH:mm:ssZ')}] result.state:`, ps.state);
  //           // setLocationEnabled(false);
  //         };
  //       });
  //     } catch (error) {
  //       console.log(`[${dayjs().format('YYYY-MM-DDTHH:mm:ssZ')}] error:`, error);
  //       setLocationEnabled(false);
  //     };
  //   };

  //   checkLocationStatus();

  //   const intervalId = setInterval(checkLocationStatus, 10000); // Check every 10 seconds

  //   return () => {
  //     clearInterval(intervalId);
  //   };
  // }, []);

  useEffect(() => {
    if (geolocationPermissionStatus === 'granted') {
      dispatch(setGeolocationDeniedDialogOpen(false));
      if (geolocateControl?._watchState !== 'ACTIVE_LOCK' && geolocateControl._lastKnownPosition) {
        geolocateControl.trigger();
      };
    };
  }, [geolocationPermissionStatus]);

  return (
    <Fab color="primary" aria-label="add" size={fabSize}
      onClick={handleClick}
      sx={{
        zIndex: 10,
        backgroundColor: 'transparent',
        backdropFilter: backdropBlur,
        fontSize: '2rem',
        boxShadow: fabBoxShadow,
        pointerEvents: isMapDragged ? 'none' : 'auto',
        '&:hover': {
          backgroundColor: isMobile ? 'transparent' : 'rgba(255, 255, 255, 0.08)',
        },
      }}
    >
      {(geolocationPermissionStatus === 'granted' || os.name === 'iOS') ? (
        icons[geolocateControl?._watchState] || <GpsOffIcon fontSize="2rem" />
      ) : (
        <GpsOffIcon fontSize="2rem" />
      )}
    </Fab>
  );
}