import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@mui/material/Button';
import Check from '@mui/icons-material/Check';
import Clear from '@mui/icons-material/Clear';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import CloseButton from '../ui/CloseButton.tsx';
import PaperComponent from '../PaperComponent';

import api from '../../utils/api.ts';
import {
  formatDistance,
  getDistance,
} from '../../utils/mapUtils';

import {
  setAlertsSnackbarOpen,
  setAlertsSnackbarSeverity,
  setAlertsSnackbarText,
} from '../../features/app/alertsSnackbarSlice';
import { setConfirmRemoveEventFromSavedDialogOpen } from '../../features/dialogs/confirmRemoveEventFromSavedDialogSlice';
import { setEventDialogOpen } from '../../features/dialogs/eventDialogSlice';
import { addEvent } from '../../features/events/eventsSlice';
import { removeSavedEventByUuid } from '../../features/events/savedEventsSlice';

import { useTranslation } from 'react-i18next';

import { borderedDialogPaperProps } from '../../theme';

export default function ConfirmRemoveEventFromSavedDialog() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [deleteButtonDisabled, setDeleteButtonDisabled] = useState(false);

  const withinMeters = useSelector((state) => state.settings.withinMeters);
  const userPosition = useSelector((state) => state.userPosition.value);

  const open = useSelector((state) => state.confirmRemoveEventFromSavedDialog.open);
  const event = useSelector((state) => state.confirmRemoveEventFromSavedDialog.event);

  const [distance, setDistance] = useState(null);

  const handleClose = () => {
    dispatch(setConfirmRemoveEventFromSavedDialogOpen(false));
  };

  const handleDelete = async () => {
    if (deleteButtonDisabled) { return };
    setDeleteButtonDisabled(true);
    const data = {
      uuid: event.properties.uuid,
      range: withinMeters,
      longitude: userPosition[0],
      latitude: userPosition[1],
    };

    await api.delete('/events/remove/', { 'data': data }).then(response => {
      dispatch(setAlertsSnackbarSeverity('info'));
      dispatch(setAlertsSnackbarText(t('Removed from saved')));
      dispatch(setAlertsSnackbarOpen(true));
      dispatch(removeSavedEventByUuid(event.properties.uuid));
      if (response.data) {
        dispatch(addEvent(response.data));
      };

      dispatch(setEventDialogOpen(false));
      handleClose();
      setTimeout(() => { setDeleteButtonDisabled(false) }, 1000);
    }).catch(err => {
      dispatch(setAlertsSnackbarSeverity('error'));
      dispatch(setAlertsSnackbarText(t('Something went wrong.')));
      dispatch(setAlertsSnackbarOpen(true));

      setTimeout(() => {
        setDeleteButtonDisabled(false);
      }, 1500);
    });
  };

  useEffect(() => {
    if (open && userPosition.length === 2) {
      setDistance(getDistance(event.geometry.coordinates, userPosition));
    } else {
      setDeleteButtonDisabled(false);
    };
  }, [open]);

  return (
    <Dialog
      open={open}
      PaperComponent={PaperComponent}
      hideBackdrop={false}
      aria-labelledby="draggable-dialog-title"
      aria-describedby="alert-dialog-description"
      {...borderedDialogPaperProps}
    >
      <DialogTitle textAlign="center" style={{ cursor: 'move' }} id="draggable-dialog-title">
        {t('Confirm remove')}
      </DialogTitle>
      <CloseButton onClick={handleClose} />
      <form>
        <DialogContent>
          {(event.properties?.timeline === 'past' || userPosition.length !== 2) && (
            <DialogContentText id="alert-dialog-description">
              {t('Are you sure you want to remove')} <strong>{event.properties?.name}</strong> {t('from saved')}?
            </DialogContentText>
          )}
          {event.properties?.timeline === 'upcoming' && (
            <React.Fragment>
              <DialogContentText>
                {t('Event')} <strong>{event.properties?.name}</strong> {t('is upcoming')}.
              </DialogContentText>
              <DialogContentText>
                <strong>{t("You won't be able to see it on the map until it starts or you get invited again.")}</strong>
              </DialogContentText>
            </React.Fragment>
          )}
          {event.properties?.private && (
            <React.Fragment>
              <DialogContentText>
                {t('Event')} <strong>{event.properties?.name}</strong> {t('is private')}.
              </DialogContentText>
              <DialogContentText>
                <strong>{t("You won't be able to see it on the map until you get invited again.")}</strong>
              </DialogContentText>
            </React.Fragment>
          )}
          {event.properties?.cancelled && (
            <React.Fragment>
              <DialogContentText>
                {t('Event')} <strong>{event.properties?.name}</strong> {t('is cancelled')}.
              </DialogContentText>
              <DialogContentText>
                <strong>{t("You won't be able to see it anymore.")}</strong>
              </DialogContentText>
            </React.Fragment>
          )}
          {distance && distance > withinMeters && event.properties?.timeline !== 'past' && (
            <React.Fragment>
              <DialogContentText>
                {t('Event')} <strong>{event.properties?.name}</strong> {t('is')} {formatDistance(distance)} ({t('out of your range')}).
              </DialogContentText>
              <DialogContentText>
                <strong>{t("You won't be able to see it on the map until you move closer.")}</strong>
              </DialogContentText>
            </React.Fragment>
          )}
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button variant="outlined"
            startIcon={<Clear />}
            onClick={handleClose}>
            {t('Cancel')}
          </Button>
          <Button
            disabled={deleteButtonDisabled}
            onClick={handleDelete}
            variant="outlined"
            endIcon={<Check />}
            color="error"
          >
            {t('Remove')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
};