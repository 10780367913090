import React, { Component } from 'react';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';

import About from './pages/About.tsx';
import AccountActivation from './pages/AccountActivation';
import Contact from './pages/Contact.tsx';
import Download from './pages/Download';
import EventDetails from './pages/EventDetails';
import EventInvitation from './pages/EventInvitation.tsx';
import FAQs from './pages/FAQs.tsx';
import FriendRequest from './pages/FriendRequest';
import Home from './pages/Home.tsx';
import NewPassword from './pages/NewPassword.tsx';
import PrivacyPolicy from './pages/PrivacyPolicy.tsx';
import TermsOfService from './pages/TermsOfService.tsx';

import { AuthProvider } from './context/AuthContext';
import { IntervalProvider } from './context/IntervalContext';
import { persistor, store } from './app/store';

import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

import { customTheme } from './theme';

class App extends Component {
  constructor(props) {
    super(props);
  };

  render() {
    return (
      <React.Fragment>
        <I18nextProvider i18n={i18n}>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <AuthProvider>
                <IntervalProvider>
                  <ThemeProvider theme={customTheme}>
                    <CssBaseline />
                    <Router>
                      <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/about/" element={<About />} />
                        <Route path="/accounts/activate/" element={<AccountActivation />} />
                        <Route path="/contact/" element={<Contact />} />
                        <Route path="/download/" element={<Download />} />
                        {/* <Route path="/event/:uuid" element={<EventDetails />} /> */}
                        <Route path="/event-invitation/:uuid" element={<EventInvitation />} />
                        <Route path="/faqs/" element={<FAQs />} />
                        <Route path="/friend-request/:uuid" element={<FriendRequest />} />
                        <Route path="/password/new/" element={<NewPassword />} />
                        <Route path="/privacy-policy/" element={<PrivacyPolicy />} />
                        <Route path="/terms-of-service/" element={<TermsOfService />} />
                        <Route path="*" element={<Navigate to="/" replace />} />
                      </Routes>
                    </Router>
                  </ThemeProvider>
                </IntervalProvider>
              </AuthProvider>
            </PersistGate>
          </Provider>
        </I18nextProvider>
      </React.Fragment>
    );
  }
}

export default App;