import { createSlice } from '@reduxjs/toolkit';

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: {
    value: [],
    new: [],
    notificationsAllowed: undefined,
    notificationsPanelOpen: false,
  },
  reducers: {
    addNewNotification: (state, action) => {
      state.new.unshift(action.payload);
    },
    addNotifications: (state, action) => {
      state.value = state.value.concat(action.payload);
    },
    markNotificationsAsSeen: (state) => {
      state.value = state.value.map(n => ({ ...n, new: false }));
    },
    resetNotifications: (state) => {
      state.value = [];
      state.new = [];
    },
    removeNotificationByUuid: (state, action) => {
      const uuid = action.payload;
      state.value = state.value.filter(n => n.uuid !== uuid);
    },
    updateNotificationByUuid: (state, action) => {
      const { uuid, properties } = action.payload;
      const notificationIndex = state.value.findIndex(n => n.uuid === uuid);

      if (notificationIndex !== -1) {
        state.value[notificationIndex] = {
          ...state.value[notificationIndex],
          ...properties,
        };
      };
    },
    setNewNotifications: (state, action) => {
      state.new = action.payload;
    },
    setNotifications: (state, action) => {
      state.value = action.payload;
    },
    setNotificationsAllowed: (state, action) => {
      state.notificationsAllowed = action.payload;
    },
    setNotificationsPanelOpen: (state, action) => {
      state.notificationsPanelOpen = action.payload;
    },
  },
})

export const {
  addNewNotification,
  addNotifications,
  markNotificationsAsSeen,
  removeNotificationByUuid,
  resetNotifications,
  setNewNotifications,
  setNotifications,
  setNotificationsAllowed,
  setNotificationsPanelOpen,
  updateNotificationByUuid,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;
