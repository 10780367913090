import { createSlice } from '@reduxjs/toolkit';

import { EMPTY_GEOJSON } from '../../consts/map';

export const networkSlice = createSlice({
  name: 'network',
  initialState: {
    friends: EMPTY_GEOJSON,
    // onlineFriends: EMPTY_GEOJSON,
    friendsRequests: [],
  },
  reducers: {
    addNewFriend: (state, action) => {
      state.friends.features.push(action.payload);
      state.friends.features.sort((a, b) => a.properties.username.localeCompare(b.properties.username));
    },
    addNewFriendRequest: (state, action) => {
      state.friendsRequests.unshift(action.payload);
    },
    setFriends: (state, action) => {
      state.friends = action.payload;
    },
    // setOnlineFriends: (state, action) => {
    //   state.onlineFriends = action.payload;
    // },
    resetNetwork: (state) => {
      state.friends = EMPTY_GEOJSON;
      // state.onlineFriends = EMPTY_GEOJSON;
      state.friendsRequests = [];
    },
    removeFriendByUuid: (state, action) => {
      const uuid = action.payload;
      state.friends = {
        ...state.friends,
        features: state.friends.features.filter(feature => feature.properties.uuid !== uuid),
      };
    },
    setFriendsRequests: (state, action) => {
      state.friendsRequests = action.payload;
    },
    updateFriendByUuid: (state, action) => {
      const { uuid, newProperties } = action.payload;
      const userIndex = state.friends.features.findIndex(u => u.properties.uuid === uuid);

      if (userIndex !== -1) {
        const newFeature = {
          type: 'Feature',
          properties: {
            ...state.friends.features[userIndex].properties,
            ...newProperties,
          },
          geometry: state.friends.features[userIndex].geometry,
        };
        state.friends.features[userIndex] = newFeature;
      };
    },
  },
})

export const {
  addNewFriend,
  addNewFriendRequest,
  removeFriendByUuid,
  resetNetwork,
  setFriends,
  setFriendsRequests,
  // setOnlineFriends,
  updateFriendByUuid,
} = networkSlice.actions;

export default networkSlice.reducer;
