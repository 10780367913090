import { createSlice } from '@reduxjs/toolkit';

export const suggestLanguagesDialogSlice = createSlice({
  name: 'suggestLanguagesDialog',
  initialState: {
    languages: [],
    open: false,
  },
  reducers: {
    resetLanguages: (state) => {
      state.languages = [];
    },
    setLanguages: (state, action) => {
      state.languages = action.payload;
    },
    setSuggestLanguagesDialogOpen: (state, action) => {
      state.open = action.payload;
    },
  }
})

export const {
  resetLanguages,
  setLanguages,
  setSuggestLanguagesDialogOpen,
} = suggestLanguagesDialogSlice.actions;

export default suggestLanguagesDialogSlice.reducer;
