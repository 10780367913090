import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import axios from 'axios';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import MapIcon from '@mui/icons-material/Map';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { API_URL } from '../config';
import {
  brightOrange,
  DRAWER_WIDTH,
  sunsetOrange,
  WIDTH_BREAKPOINT,
} from '../theme';

import BackButton from '../components/ui/BackButton.tsx';
import CenteredBox from '../components/ui/CenteredBox.tsx';
import Layout from '../components/ui/Layout.tsx';
import NoContentAlert from '../components/ui/NoContentAlert.tsx';
import SmallLoader from '../components/ui/SmallLoader.tsx';
import SuccessAlert from '../components/ui/SuccessAlert.tsx';

export default function AccountActivation() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const width = useSelector((state) => state.app.width);
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState();
  const [alertText, setAlertText] = useState('');
  const [alertColor, setAlertColor] = useState('');
  const [count, setCount] = useState(4);

  const activateAccount = async () => {
    setLoading(true);
    const _t = searchParams.get('t');
    const _u = searchParams.get('u');
    if (!(_t && _u)) {
      navigate('/?view=auth');
    } else {
      await axios.post(`${API_URL}/accounts/activate/`, {
        't': _t,
        'u': _u,
      }).then(response => {
        setLoading(false);
        if (response.status === 200) {
          setSuccess(true);
        } else if (response.status === 204) {
          setAlertText(t('Invalid or used token'));
          setAlertColor(brightOrange);
        };
      }).catch(err => {
        setLoading(false);
        setSuccess(false);
        console.log('err', err);
        setAlertText(t('Oops, something went wrong. Please try again later.'));
        setAlertColor(sunsetOrange);
      });
    };
  };

  useEffect(() => {
    if (success) {
      if (count === 0) {
        navigate('/?view=auth');
      };
      const timer = setInterval(() => {
        setCount((prevCount) => prevCount - 1);
      }, 1000);
      return () => clearInterval(timer);
    };
  }, [count, success]);

  useEffect(() => {
    activateAccount();
  }, []);

  return (
    <Layout>
      <Stack
        direction="column"
        sx={{
          marginLeft: width > WIDTH_BREAKPOINT && `${DRAWER_WIDTH}px`,
          height: '92vh',
          backgroundColor: 'unset',
          backgroundImage: 'linear-gradient(#171717, #191919)',
        }}
      >
        <Grid container>
          <Grid item xs={2}>
            <CenteredBox>
              <BackButton onClick={() => window.location.href = '/'} />
            </CenteredBox>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h5" gutterBottom mt={2} align="center">
              {t('Account activation')}{loading && <SmallLoader />}
            </Typography>
          </Grid>
          <Grid item xs={2} />
        </Grid>
        <Box pt={10}>
          {!loading && (
            <CenteredBox mt={4}>
              {success ? (
                <SuccessAlert text={t('Account activated')} />
              ) : (
                <NoContentAlert text={alertText} color={alertColor} />
              )}
            </CenteredBox>
          )}
          {success && (
            <CenteredBox mt={4}>
              <Typography>
                {t('Redirecting to map in')} {count}s
              </Typography>
            </CenteredBox>
          )}
        </Box>
        {!loading && (
          <CenteredBox mt={4}>
            <Button
              href="/?view=auth"
              variant="contained"
              startIcon={<MapIcon />}
              disabled={loading}
            >
              {success ? t('Go to map now') : t('Go to map')}
            </Button>
          </CenteredBox>
        )}
      </Stack>
    </Layout>
  );
}