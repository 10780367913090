import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import CloseButton from '../ui/CloseButton.tsx';
import EventCardMin from './EventCardMin.tsx';
import PaperComponent from '../PaperComponent';
import SmallLoader from '../ui/SmallLoader.tsx';

import api from '../../utils/api.ts';
import {
  arraysAreEqual,
  countCharacters,
} from '../../utils/utils';

import { setInviteFriendDialogOpen } from '../../features/dialogs/inviteFriendDialogSlice.ts';
import {
  setAlertsSnackbarOpen,
  setAlertsSnackbarSeverity,
  setAlertsSnackbarText,
} from '../../features/app/alertsSnackbarSlice';

import {
  backdropBlur,
  borderedDialogPaperProps,
  fabBoxShadow,
  WIDTH_BREAKPOINT,
} from '../../theme';
import { INVITATION_MESSAGE_MAX_LENGTH } from '../../limits.ts';

import {
  Event,
  EventGeojson,
} from '../../types/Event/types.ts';

import EventInvitationCardMinSkeleton from './EventInvitationCardMinSkeleton.tsx';
import NoContentAlert from '../ui/NoContentAlert.tsx';
import SearchInput from '../ui/SearchInput.tsx';

const InviteFriendDialog: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const open = useSelector((state: any) => state.inviteFriendDialog.open);
  const user = useSelector((state: any) => state.inviteFriendDialog.user);
  const width = useSelector((state: any) => state.app.width);

  const [loading, setLoading] = useState(true);
  const [fetching, setFetching] = useState(true);

  const [selectedEvents, setSelectedEvents] = useState<Event[]>([]);
  const [searchInput, setSearchInput] = useState('');
  const [alreadyInvited, setAlreadyInvited] = useState<{ uuid: string, status: number }[]>([]);
  const [invitationMessage, setInvitationMessage] = useState('');
  const [invitationMessageError, setInvitationMessageError] = useState(false);

  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);

  const userEvents = useSelector((state: any) => state.userEvents.value);
  const savedEvents = useSelector((state: any) => state.savedEvents.value);
  const events = {
    type: 'FeatureCollection',
    features: [
      ...userEvents.features,
      ...savedEvents.features.filter((f: Event) => f.properties.othersCanInvite),
    ].filter((f: Event) =>
      ['ongoing', 'upcoming'].includes(f.properties.timeline) && !f.properties.cancelled
    ),
  };
  const [filteredEvents, setFilteredEvents] = useState<EventGeojson>(events);

  const handleClose = () => {
    dispatch(setInviteFriendDialogOpen(false));
  };

  const handleInvitationMessageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInvitationMessageError(countCharacters(e.target.value) > INVITATION_MESSAGE_MAX_LENGTH);
    setInvitationMessage(e.target.value);
  };

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setLoading(true);

    await api.post('/user-invitations/', {
      uuid: user.properties.uuid,
      events: selectedEvents.map((f: Event) => f.properties.uuid),
      message: invitationMessage,
    }).then(response => {
      handleClose();
      setLoading(false);
      dispatch(setAlertsSnackbarSeverity('success'));
      dispatch(setAlertsSnackbarText(t('Invitations have been updated!')));
      dispatch(setAlertsSnackbarOpen(true));
    }).catch(err => {
      setLoading(false);
      dispatch(setAlertsSnackbarSeverity('error'));
      dispatch(setAlertsSnackbarText(t('Oops, something went wrong. Please try again later.')));
      dispatch(setAlertsSnackbarOpen(true));
    });
  };

  const getInvitations = async () => {
    setLoading(true);
    setFetching(true);
    await api.get(`/user-invitations/${user.properties.uuid}/`).then(response => {
      setAlreadyInvited(response.data);
      setLoading(false);
      setFetching(false);
    }).catch(err => {
      setLoading(false);
      setFetching(false);
      dispatch(setAlertsSnackbarSeverity('error'));
      dispatch(setAlertsSnackbarText(t('Oops, something went wrong. Please try again later.')));
      dispatch(setAlertsSnackbarOpen(true));
    });
  };

  useEffect(() => {
    if (open) {
      getInvitations();
    } else {
      setTimeout(() => {
        setAlreadyInvited([]);
        setSelectedEvents([]);
        setLoading(true);
        setFetching(true);
        setSearchInput('');
      }, 500);
    };
  }, [open]);

  useEffect(() => {
    const alreadyInvitedEvents: Event[] = alreadyInvited.map(el => ({
      type: 'Feature',
      properties: {
        uuid: el.uuid,
      },
      geometry: []
    }));
    setSelectedEvents(alreadyInvitedEvents);
  }, [alreadyInvited])

  useEffect(() => {
    setSubmitButtonDisabled(invitationMessageError || loading || fetching);
  }, [invitationMessageError, loading, fetching]);

  useEffect(() => {
    setFilteredEvents({
      type: 'FeatureCollection',
      features: [
        ...userEvents.features,
        ...savedEvents.features.filter((f: Event) => f.properties.othersCanInvite),
      ].filter((f: Event) => {
        return ['ongoing', 'upcoming'].includes(f.properties.timeline) &&
          !f.properties.cancelled &&
          (searchInput.trim() === '' || f.properties.name.toLowerCase().includes(searchInput.toLowerCase()));
      }),
    });
  }, [userEvents, savedEvents, searchInput]);

  useEffect(() => {
    setSubmitButtonDisabled(
      arraysAreEqual(
        alreadyInvited.map(e => e.uuid),
        selectedEvents.map(e => e.properties.uuid),
      ),
    );
  }, [alreadyInvited, selectedEvents]);

  return (
    <Dialog
      open={open}
      PaperComponent={PaperComponent}
      hideBackdrop={false}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: {
          ...borderedDialogPaperProps.PaperProps.style,
          height: width <= WIDTH_BREAKPOINT ? '70vh' : '80vh',
          minWidth: width <= WIDTH_BREAKPOINT / 2 ? '90vw' : '30vw',
        }
      }}
      sx={{ height: 'auto', maxHeight: 'unset' }}
    >
      <DialogTitle textAlign="center">
        {t('Invite')} {user.properties.username}
        {loading && <SmallLoader />}
        <form onSubmit={handleSubmit} id="invite-form">
          <TextField
            id="message"
            value={invitationMessage}
            onChange={handleInvitationMessageChange}
            label={t('Message')}
            margin="dense"
            multiline
            fullWidth
            variant="standard"
            rows={3}
            helperText={`${countCharacters(invitationMessage)}/${INVITATION_MESSAGE_MAX_LENGTH} ` + t('characters')}
            error={invitationMessageError}
          />
        </form>
        {!fetching && events.features.length > 0 && (
          <SearchInput searchInput={searchInput} setSearchInput={setSearchInput} />
        )}
      </DialogTitle>
      <CloseButton onClick={handleClose} />
      <DialogContent sx={{ padding: '0 0 5rem 0' }}>
        {filteredEvents.features.length > 0 ? (
          <List>
            {filteredEvents.features.map((feature: Event) => (
              <ListItem key={feature.properties.uuid}>
                {!fetching ? (
                  <EventCardMin
                    event={feature}
                    onClick={() => {
                      if (!alreadyInvited.some(el => el.status !== 0 && el.uuid === feature.properties.uuid)) {
                        setSelectedEvents(prevSelectedEvents => {
                          const index = prevSelectedEvents.findIndex(event => event.properties.uuid === feature.properties.uuid);
                          if (index === -1) {
                            return [...prevSelectedEvents, feature];
                          } else {
                            return prevSelectedEvents.filter(event => event.properties.uuid !== feature.properties.uuid);
                          };
                        });
                      }
                    }}
                    selected={selectedEvents.some(event => event.properties.uuid === feature.properties.uuid)}
                    disabled={alreadyInvited.some(el => el.status !== 0 && el.uuid === feature.properties.uuid)}
                  />
                ) : (
                  <EventInvitationCardMinSkeleton />
                )}
              </ListItem>
            ))}
          </List>
        ) : (
          !fetching && (
            <NoContentAlert text={t('No results!') as string} spacing={0} />
          )
        )}
      </DialogContent>
      <Stack
        spacing={0}
        direction="row"
        justifyContent="space-around"
        sx={{
          height: '4.5rem',
          padding: '1rem 1rem',
          backgroundColor: 'transparent',
          backdropFilter: backdropBlur,
          position: 'fixed',
          bottom: 0,
          left: 0,
          width: '100%',
          borderRadius: '10px 10px 0 0',
          boxShadow: fabBoxShadow,
        }}
      >
        <Button
          variant="contained"
          disabled={submitButtonDisabled || filteredEvents.features.length === 0}
          type="submit"
          form="invite-form"
          sx={{ width: '5.25rem' }}
        >
          {t('Invite')}
        </Button>
      </Stack>
    </Dialog>
  );
};

export default InviteFriendDialog;