import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import EmergencyShareIcon from '@mui/icons-material/EmergencyShare';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import { handleShareLocationClick } from '../../utils/handlers';

const ShareLocationDrawerItem: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const userPosition = useSelector((state: any) => state.userPosition.value);

  return (
    <ListItem key="Share location" disablePadding onClick={() => handleShareLocationClick(dispatch, userPosition, t)}>
      <ListItemButton>
        <ListItemIcon>
          <EmergencyShareIcon />
        </ListItemIcon>
        <ListItemText primary={t('Share location')} />
      </ListItemButton>
    </ListItem>
  );
};

export default ShareLocationDrawerItem;