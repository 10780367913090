import { createSlice } from '@reduxjs/toolkit';

export const authDialogSlice = createSlice({
  name: 'authDialog',
  initialState: {
    open: false,
    action: 'login',
  },
  reducers: {
    setAuthDialogAction: (state, action) => {
      state.action = action.payload;
    },
    setAuthDialogOpen: (state, action) => {
      state.open = action.payload;
    },
  }
})

export const {
  setAuthDialogAction,
  setAuthDialogOpen,
} = authDialogSlice.actions;

export default authDialogSlice.reducer;
