import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';

import Bowser from 'bowser';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax

import CloseIcon from '@mui/icons-material/Close';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Fade from '@mui/material/Fade';
import NavigationIcon from '@mui/icons-material/Navigation';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';

import {
  setDirectionsControl,
  setNavigating,
  setShowDirectionsControl,
} from '../../features/map/mapSlice';

import {
  DIRECTIONS_CONTROL_STYLE_OVERRIDE,
  MAX_ZOOM,
  NAVIGATION_OFFSET,
  NAVIGATION_PITCH,
} from '../../consts/map';
import {
  backdropBlur,
  DRAWER_WIDTH,
  fabBoxShadow,
  WIDTH_BREAKPOINT,
} from '../../theme';
import {
  calculateBearing,
  getZoomBasedOnCircle,
} from '../../utils/mapUtils';
import { vhToPx } from '../../utils/utils';

export default function DirectionsProfileControl(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const width = useSelector((state) => state.app.width);
  const fabSize = useSelector((state) => state.app.fabSize);

  const map = useSelector((state) => state.map.value);
  const isMapDragged = useSelector((state) => state.map.isDragged);
  const directionsControl = useSelector((state) => state.map.directionsControl);
  const userPosition = useSelector((state) => state.userPosition.value);

  const showDirectionsControl = useSelector((state) => state.map.showDirectionsControl);
  const geolocateControl = useSelector((state) => state.map.geolocateControl);

  const [open, setOpen] = useState(true);
  const [startDisabled, setStartDisabled] = useState(false);

  const parsedUserAgent = Bowser.parse(window.navigator.userAgent);
  const os = parsedUserAgent.os;

  const handleClick = () => {
    setOpen(!open);
  };

  const orientationCallback = useCallback((e) => {
    /* based on https://github.com/mapbox/mapbox-gl-js/blob/main/src/ui/control/geolocate_control.js#L515 */
    if (e.webkitCompassHeading) {
      // Safari
      map.setBearing(e.webkitCompassHeading, { geolocateSource: true });
    } else if (e.absolute === true) {
      // non-Safari alpha increases counter clockwise around the z axis
      // map.setBearing(e.alpha * -1, { geolocateSource: true });
      map.setBearing(calculateBearing(e.alpha, e.beta, e.gamma), { geolocateSource: true });
    };
  }, [map]);

  const handleStartClick = (e) => {
    e.stopPropagation();
    if (startDisabled) return;
    const navigationStarted = () => {
      dispatch(setNavigating(true));
      props.setCompassOpen(false);
      map.easeTo({
        duration: 0,
        pitch: NAVIGATION_PITCH,
        padding: {
          bottom: 0,
          top: vhToPx(NAVIGATION_OFFSET),
        },
        zoom: MAX_ZOOM,
      }, { geolocateSource: true });
      geolocateControl.options.fitBoundsOptions.zoom = MAX_ZOOM;
      geolocateControl.options.fitBoundsOptions.pitch = NAVIGATION_PITCH;
      if (geolocateControl._watchState !== 'ACTIVE_LOCK') geolocateControl.trigger();
    };

    if (window.DeviceOrientationEvent) {
      if (os.name === 'iOS') {
        window.DeviceOrientationEvent.requestPermission().then(permissionState => {
          if (permissionState === 'granted') {
            window.addEventListener('deviceorientation', orientationCallback, true);
            navigationStarted();
          } else {
            setStartDisabled(true);
          }
        });
      } else {
        window.addEventListener('deviceorientationabsolute', orientationCallback, true);
        navigationStarted();
      };
    } else {
      console.log('Device orientation not supported.');
      setStartDisabled(true);
    };
  };

  const handleStopClick = (e) => {
    e.stopPropagation();
    dispatch(setNavigating(false));
    if (os.name === 'iOS') {
      window.removeEventListener('deviceorientation', orientationCallback, true);
    } else {
      window.removeEventListener('deviceorientationabsolute', orientationCallback, true);
    };

    directionsControl.removeRoutes();
    map.setLayoutProperty('directions-destination-point', 'visibility', 'none');
    dispatch(setShowDirectionsControl(false));
    map.easeTo({
      duration: 0,
      bearing: 0,
      pitch: 0,
      padding: {
        bottom: 0,
        top: 0,
      },
    }, { geolocateSource: true });

    geolocateControl.options.fitBoundsOptions.zoom = getZoomBasedOnCircle(map);
    geolocateControl.options.fitBoundsOptions.pitch = map.getPitch();
    geolocateControl.options.fitBoundsOptions.bearing = map.getBearing();
    if (geolocateControl._watchState !== 'ACTIVE_LOCK') geolocateControl.trigger();
  };

  const updateDirectionsProfile = (e, profile) => {
    e.stopPropagation();
    const _directionsControl = new window.MapboxDirections({
      accessToken: mapboxgl.accessToken,
      profile: profile,
      interactive: false,
      flyTo: false,
      zoom: map.getZoom(),
      controls: {
        inputs: false,
        instructions: false,
        profileSwitcher: true,
      },
      styles: DIRECTIONS_CONTROL_STYLE_OVERRIDE,
    });
    _directionsControl.setOrigin(userPosition);
    _directionsControl.setDestination(directionsControl.getDestination().geometry.coordinates);
    dispatch(setDirectionsControl(_directionsControl));
  };

  return (
    <Fade in={showDirectionsControl} timeout={{ enter: 250, exit: 500 }}>
      <SpeedDial
        ariaLabel="SpeedDial playground example"
        icon={
          <SpeedDialIcon
            icon={<ExpandMoreIcon />}
            openIcon={<ExpandLessIcon />}
          />
        }
        // icon={open ?
        //   <KeyboardDoubleArrowUpIcon sx={{ fontSize: '2rem' }} />
        //   :
        //   <DirectionsIcon sx={{ fontSize: '2rem' }} />
        // }
        direction="down"
        sx={{
          position: 'fixed',
          left: width <= WIDTH_BREAKPOINT ? '1.5vh' : `${vhToPx(1.5) + DRAWER_WIDTH}px`,
          top: '10vh',
          width: 'auto',
          zIndex: 10,
          '& .MuiFab-primary': {
            backgroundColor: 'transparent',
            backdropFilter: backdropBlur,
            boxShadow: fabBoxShadow,
            pointerEvents: isMapDragged ? 'none' : 'auto',
            '&:hover': {
              backgroundColor: isMobile ? 'transparent' : 'rgba(255, 255, 255, 0.08)',
            },
          },
        }}
        FabProps={{ size: fabSize }}
        onClick={handleClick}
        open={open}
      >
        {isMobile &&
          <SpeedDialAction
            key="start"
            icon={<NavigationIcon sx={{ fill: 'white' }} />}
            tooltipTitle={t('Start')}
            onClick={handleStartClick}
            sx={{
              backgroundColor: !startDisabled ? 'transparent' : 'gray',
              backdropFilter: !startDisabled ? backdropBlur : undefined,
              fontSize: '2rem',
              boxShadow: !startDisabled ? '0px 3px 3px -2px rgb(76 175 80 / 80%), 0px 3px 4px 0px rgb(76 175 80 / 56%), 0px 1px 8px 0px rgb(76 175 80 / 48%)' : undefined,
              pointerEvents: isMapDragged ? 'none' : 'auto',
              '&:hover': {
                backgroundColor: !startDisabled ? (isMobile ? 'transparent' : 'rgba(255, 255, 255, 0.08)') : 'gray',
              },
              marginTop: 0,
            }}
          />
        }
        <SpeedDialAction
          key="mapbox/walking"
          icon={<DirectionsWalkIcon sx={{ fill: 'white' }} />}
          tooltipTitle={t('Walking')}
          onClick={(e) => updateDirectionsProfile(e, 'mapbox/walking')}
          sx={{
            backgroundColor: 'transparent',
            backdropFilter: backdropBlur,
            fontSize: '2rem',
            boxShadow: directionsControl?.options.profile === 'mapbox/walking' && fabBoxShadow,
            pointerEvents: isMapDragged ? 'none' : 'auto',
            '&:hover': {
              backgroundColor: isMobile ? 'transparent' : 'rgba(255, 255, 255, 0.08)',
            },
            marginTop: 0,
          }}
        />
        <SpeedDialAction
          key="mapbox/cycling"
          icon={<DirectionsBikeIcon sx={{ fill: 'white' }} />}
          tooltipTitle={t('Cycling')}
          onClick={(e) => updateDirectionsProfile(e, 'mapbox/cycling')}
          sx={{
            backgroundColor: 'transparent',
            backdropFilter: backdropBlur,
            fontSize: '2rem',
            boxShadow: directionsControl?.options.profile === 'mapbox/cycling' && fabBoxShadow,
            pointerEvents: isMapDragged ? 'none' : 'auto',
            '&:hover': {
              backgroundColor: isMobile ? 'transparent' : 'rgba(255, 255, 255, 0.08)',
            },
            marginTop: 0,
          }}
        />
        <SpeedDialAction
          key="mapbox/driving"
          icon={<DirectionsCarIcon sx={{ fill: 'white' }} />}
          tooltipTitle={t('Driving')}
          onClick={(e) => updateDirectionsProfile(e, 'mapbox/driving')}
          sx={{
            backgroundColor: 'transparent',
            backdropFilter: backdropBlur,
            fontSize: '2rem',
            boxShadow: directionsControl?.options.profile === 'mapbox/driving' && fabBoxShadow,
            pointerEvents: isMapDragged ? 'none' : 'auto',
            '&:hover': {
              backgroundColor: isMobile ? 'transparent' : 'rgba(255, 255, 255, 0.08)',
            },
            marginTop: 0,
          }}
        />
        <SpeedDialAction
          key="stop"
          icon={<CloseIcon sx={{ fill: 'white' }} />}
          tooltipTitle={t('Stop')}
          onClick={handleStopClick}
          sx={{
            backgroundColor: 'transparent',
            backdropFilter: backdropBlur,
            fontSize: '2rem',
            boxShadow: '0px 3px 3px -2px rgb(244 67 54 / 80%), 0px 3px 4px 0px rgb(244 67 54 / 56%), 0px 1px 8px 0px rgb(244 67 54 / 48%)',
            pointerEvents: isMapDragged ? 'none' : 'auto',
            '&:hover': {
              backgroundColor: isMobile ? 'transparent' : 'rgba(255, 255, 255, 0.08)',
            },
            marginTop: 0,
          }}
        />
      </SpeedDial>
    </Fade>
  );
}