import React from 'react';
import { css, styled } from '@mui/material/styles';

import Avatar from '@mui/material/Avatar';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';

interface BigAvatarProps {
  src?: string;
  size?: string;
  onClick?: () => void;
  sx?: object;
};

const StyledAvatar = styled(Avatar)`
  border: 1px solid white;
  background-color: transparent;
  width: 100px;
  height: 100px;
`;

const StyledPersonOutlineIcon = styled(PersonOutlineIcon) <BigAvatarProps>`
  color: #fff;
  // width: 100%;
  height: 100%;

  ${(props) => css`
    font-size: ${props.size};
  `}
`;

const BigAvatar: React.FC<BigAvatarProps> = ({
  src,
  size,
  onClick,
  sx,
}) => {
  return (
    <StyledAvatar
      src={src}
      onClick={onClick}
      sx={sx}
      aria-label=""
    >
      <StyledPersonOutlineIcon size={size} />
    </StyledAvatar>
  );
};

BigAvatar.defaultProps = {
  src: '',
  size: '70px',
  onClick: () => { },
  sx: {},
};

export default BigAvatar;