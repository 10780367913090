import React, { createContext, useContext, useEffect, useRef } from 'react';

const IntervalContext = createContext();

export function IntervalProvider({ children }) {
  const intervals = useRef([]);

  useEffect(() => {
    intervals.current = intervals.current.filter(interval => {
      if (interval.paused) {
        clearInterval(interval.id);
        return true; // Keep the interval in the list
      } else {
        return true; // Keep the interval in the list
      }
    });
  }, []);

  const addInterval = (callback, delay) => {
    const id = setInterval(() => {
      if (!document.hidden) {
        callback();
      }
    }, delay);
    intervals.current.push({ id, callback, delay, paused: false }); // Store callback and delay
    return id; // Return the interval ID
  };

  const pauseAllIntervals = () => {
    intervals.current.forEach(interval => {
      clearInterval(interval.id);
      interval.paused = true;
    });
  };

  const resumeAllIntervals = () => {
    intervals.current.forEach(interval => {
      if (interval.paused) {
        interval.id = setInterval(() => {
          if (!document.hidden) {
            interval.callback(); // Call the saved callback
          }
        }, interval.delay); // Use the saved delay
        interval.paused = false;
      }
    });
  };

  const value = {
    addInterval,
    pauseAllIntervals,
    resumeAllIntervals,
  };

  return (
    <IntervalContext.Provider value={value}>
      {children}
    </IntervalContext.Provider>
  );
}

export default IntervalContext;