import { styled } from '@mui/material/styles';

import Badge from '@mui/material/Badge';

import { mediumSeaGreen } from '../../theme';

const OnlineBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: mediumSeaGreen,
    color: mediumSeaGreen,
    boxShadow: `0 0 4px ${mediumSeaGreen}`,
    width: '10px',
    height: '10px',
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      // animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid #fff',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

export default OnlineBadge;