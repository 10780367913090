import React from 'react';

import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

interface ShowPasswordButtonProps {
  visible: boolean;
  onClick: () => void;
};

const ShowPasswordButton: React.FC<ShowPasswordButtonProps> = ({ visible, onClick }) => {
  const handleMouseDown = (e: React.MouseEvent) => {
    e.preventDefault();
  };

  return (
    <IconButton
      aria-label="toggle password visibility"
      onClick={onClick}
      onMouseDown={handleMouseDown}
      tabIndex={-1}
    >
      {visible ? <VisibilityOff /> : <Visibility />}
    </IconButton>
  );
};

export default ShowPasswordButton;