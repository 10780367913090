import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import PersonAddDisabledIcon from '@mui/icons-material/PersonAddDisabled';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import {
  setAlertsSnackbarOpen,
  setAlertsSnackbarSeverity,
  setAlertsSnackbarText,
} from '../../features/app/alertsSnackbarSlice';
import { updateUserByUuid } from '../../features/users/usersSlice';

import api from '../../utils/api.ts';

interface AddFriendButtonProps {
  user: {
    properties: {
      uuid: string,
      invited: boolean,
    },
  };
};

const AddFriendButton: React.FC<AddFriendButtonProps> = ({ user }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [disabled, setDisabled] = useState(false);

  const handleAddFriend = async () => {
    setDisabled(true);
    const action = !user.properties.invited ? 'create' : 'cancel';
    if (action === 'create') {
      dispatch(setAlertsSnackbarSeverity('success'));
      dispatch(setAlertsSnackbarText(t('Friend request sent!')));
    } else if (action === 'cancel') {
      dispatch(setAlertsSnackbarSeverity('info'));
      dispatch(setAlertsSnackbarText(t('Friend request cancelled!')));
    };
    dispatch(setAlertsSnackbarOpen(true));
    await api.post('/users/friends/requests/', {
      action: action,
      receiver: user.properties.uuid,
    }).then(response => {
      let invited;
      if (response.status === 200) {
        invited = false;
      } else if (response.status === 201) {
        invited = true;
      };
      dispatch(updateUserByUuid({
        uuid: user.properties.uuid,
        newProperties: { invited: invited }
      }));
      setDisabled(false);
    });
  };

  return (
    <Button
      variant={!user.properties.invited ? 'contained' : 'outlined'}
      startIcon={!user.properties.invited ? <PersonAddIcon /> : <PersonAddDisabledIcon />}
      disabled={disabled}
      onClick={handleAddFriend}
      sx={{ textTransform: 'none' }}
    >
      {!user.properties.invited ? t('Add to friends') : t('Cancel invitation')}
    </Button>
  );
}

export default AddFriendButton;