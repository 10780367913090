import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';

import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import {
  setConfirmRemoveFriendDialogOpen,
  setConfirmRemoveFriendDialogUser,
} from '../../features/dialogs/confirmRemoveFriendDialogSlice';
import {
  setReportDialogFeature,
  setReportDialogOpen,
  setReportDialogSubject,
} from '../../features/dialogs/reportDialogSlice';

import ReportButton from '../ui/ReportButton';
import RoundMenu from '../ui/RoundMenu.tsx';

import { User } from '../../types/User/types.ts';

const StyledIconButton = styled(IconButton)`
  &:hover {
    background-color: transparent;
  };
`;

interface UserMenuProps {
  feature: User;
};

const UserMenu: React.FC<UserMenuProps> = ({ feature }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteClick = () => {
    dispatch(setConfirmRemoveFriendDialogUser(feature));
    dispatch(setConfirmRemoveFriendDialogOpen(true));
    setAnchorEl(null);
  };

  const handleReportClick = () => {
    dispatch(setReportDialogSubject('user'));
    dispatch(setReportDialogFeature(feature));
    dispatch(setReportDialogOpen(true));
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <RoundMenu
        anchorEl={anchorEl}
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
      >
        {feature.properties.friend && (
          <MenuItem onClick={handleDeleteClick}>
            <StyledIconButton size="large" color="inherit">
              <DeleteIcon />
            </StyledIconButton>
            {t('Remove')}
          </MenuItem>
        )}
        <MenuItem onClick={handleReportClick} disabled={feature.properties.reported}>
          <ReportButton feature={feature} subject="user" />
          {t('Report')}
        </MenuItem>
      </RoundMenu>
    </React.Fragment>
  );
};

export default UserMenu;