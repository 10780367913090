import { createSlice } from '@reduxjs/toolkit';

export const alertsSnackbarSlice = createSlice({
  name: 'alertsSnackbar',
  initialState: {
    autoHideDuration: 1500,
    open: false,
    severity: 'info',
    text: '',
  },
  reducers: {
    setAlertsSnackbarAutoHideDuration: (state, action) => {
      state.autoHideDuration = action.payload;
    },
    setAlertsSnackbarOpen: (state, action) => {
      state.open = action.payload;
    },
    setAlertsSnackbarSeverity: (state, action) => {
      state.severity = action.payload;
    },
    setAlertsSnackbarText: (state, action) => {
      state.text = action.payload;
    },
  },
})

export const {
  setAlertsSnackbarAutoHideDuration,
  setAlertsSnackbarOpen,
  setAlertsSnackbarSeverity,
  setAlertsSnackbarText,
} = alertsSnackbarSlice.actions;

export default alertsSnackbarSlice.reducer;