import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import './App.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'mapbox-gl/dist/mapbox-gl.css';

// import { initializeApp } from 'firebase/app';
// import { getMessaging, getToken, onMessage } from 'firebase/messaging';

// import api from './utils/api.ts';

// const firebaseConfig = {
//   apiKey: "AIzaSyA8f2wDTCcdiBn3kMCCnEJ-y7ikgtevR-k",
//   authDomain: "dev-r-ound-me.firebaseapp.com",
//   projectId: "dev-r-ound-me",
//   storageBucket: "dev-r-ound-me.appspot.com",
//   messagingSenderId: "917572477638",
//   appId: "1:917572477638:web:447fe8143ded72d0ee6dc7",
//   measurementId: "G-T3CXJKYTXB"
// };

// Initialize Firebase
// const app = initializeApp(firebaseConfig);

// const messaging = getMessaging(app);

// getToken(messaging, { vapidKey: 'BMDijnLmmMcKJz1EW_chLG2jxZbULAr0cjByP0uLCTHJB1VsV43mqRLo7yn0BnFeFpqxw5e-uCcIzVIUCBPKPMw' }).then((token) => {
//   console.log(token)
// Send the token to your Django backend
// fetch('/api/device/register/', {
//   method: 'POST',
//   headers: {
//     'Content-Type': 'application/json',
//   },
//   body: JSON.stringify({ token }),
// });
// api.post('/device/register/', { token: token })
// });

// onMessage(messaging, (payload) => {
//   console.log('Message received. ', payload);
//   navigator.serviceWorker.ready.then(function (registration) {
//     registration.showNotification(payload.notification.title, {
//       body: payload.notification.body,
//       icon: 'logo192.png',
//     });
//   });
// });

const root = ReactDOM.createRoot(document.getElementById('root'));
/* <React.StrictMode> causes that every component useEffect is called twice */
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );
root.render(
  <App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    // Notification.requestPermission()
    // .then(permission => {
    if ('Notification' in window) {
      if (Notification.permission === 'granted') {
        // navigator.serviceWorker.register('/service-worker.js');
        navigator.serviceWorker.register('/firebase-messaging-sw.js');
      };
    };
  });
  // });
};