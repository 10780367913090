import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import CloseButton from '../ui/CloseButton.tsx';
import NoContentAlert from '../ui/NoContentAlert.tsx';

import { setNewPinNotAllowedDialogOpen } from '../../features/app/dialogsSlice';

import { borderedDialogPaperProps } from '../../theme';

const NewPinNotAllowedDialog: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const open = useSelector((state: any) => state.dialogs.newPinNotAllowedDialogOpen);

  const handleClose = () => {
    dispatch(setNewPinNotAllowedDialogOpen(false));
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      {...borderedDialogPaperProps}
    >
      <DialogContent>
        <CloseButton onClick={handleClose} />
        <NoContentAlert
          text={t('It seems like you are trying to create a pin outside circle.') as string}
          subText={t('Move closer to desired location.') as string}
          size="50px"
          spacing={1}
          variant="body2"
          subVariant="caption"
        />
      </DialogContent>
    </Dialog>
  );
};

export default NewPinNotAllowedDialog;