import React, { createContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import jwt_decode from 'jwt-decode';
import i18next from 'i18next';

import {
  resetAccount,
  setPlan,
  setStaff,
  setUuid,
} from '../features/account/accountSlice.ts';
import { setBottomBarValue } from '../features/app/appSlice';
import { resetDialogs } from '../features/app/dialogsSlice';
import { resetNotifications } from '../features/app/notificationsSlice';
import { setAuthDialogOpen } from '../features/dialogs/authDialogSlice.ts';
import { resetLanguages } from '../features/dialogs/suggestLanguagesDialogSlice.ts';
import {
  resetNetwork,
  setFriends,
  setFriendsRequests,
} from '../features/network/networkSlice';
import {
  resetProfile,
  setDisplayName,
  setFirstName,
  setLastName,
  setPicture,
} from '../features/profile/profileSlice';
import {
  resetSettings,
  setAlwaysShowIntro,
  setKeepScreenOn,
  setLanguage,
  setNewEventsAnonymous,
  setNewPinsPrivate,
  setOthersCanInvite,
  setShareLocationWith,
  setWithinMeters,
} from '../features/settings/settingsSlice';
import {
  resetEvents,
  setEvents,
} from '../features/events/eventsSlice';
import {
  resetEventInvitations,
  setEventInvitations,
} from '../features/events/eventInvitationsSlice';
import { resetSavedEvents } from '../features/events/savedEventsSlice';
import { resetUserEvents } from '../features/events/userEventsSlice';
import { resetUsers } from '../features/users/usersSlice';

import { API_URL } from '../config';
import { LANGUAGE_CODE } from '../consts/app';

import api from '../utils/api.ts';
import {
  getLanguages,
  getNewNotifications,
  getSavedEvents,
  getUserEvents,
} from '../utils/getters';
import {
  registerMessagingToken,
  unregisterMessagingToken,
} from '../utils/utils';

const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();

  const events = useSelector((state) => state.events.value);
  const savedEvents = useSelector((state) => state.savedEvents.value);

  const [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null
  );
  const [user, setUser] = useState(() =>
    localStorage.getItem("authTokens")
      ? jwt_decode(localStorage.getItem("authTokens"))
      : null
  );
  const [loading, setLoading] = useState(true);

  // const history = useNavigate();

  const getAccount = async () => {
    await api.get('/users/account').then(
      response => {
        if (response.status === 200) {
          dispatch(setPlan(response.data.plan));
          dispatch(setStaff(response.data.staff));
          dispatch(setUuid(response.data.uuid));
        };
      });
  };

  const getProfile = async () => {
    await api.get('/users/profile/').then(
      response => {
        if (response.status === 200) {
          dispatch(setDisplayName(response.data.displayName));
          dispatch(setFirstName(response.data.firstName));
          dispatch(setLastName(response.data.lastName));
          dispatch(setPicture(response.data.picture));
        };
      });
  };

  const getSettings = async () => {
    await api.get('/users/settings/').then(
      response => {
        if (response.status === 200) {
          dispatch(setShareLocationWith(response.data.shareLocationWith));
          dispatch(setKeepScreenOn(response.data.keepScreenOn));
          dispatch(setNewEventsAnonymous(response.data.newEventsAnonymous));
          dispatch(setNewPinsPrivate(response.data.newPinsPrivate));
          dispatch(setOthersCanInvite(response.data.othersCanInvite));
          dispatch(setAlwaysShowIntro(response.data.alwaysShowIntro));
          localStorage.setItem('alwaysShowIntro', response.data.alwaysShowIntro);
          dispatch(setWithinMeters(response.data.withinMeters));
          dispatch(setLanguage(response.data.language));
        };
      });
  };

  const getFriends = async () => {
    const response = await api.get('/users/friends/');
    dispatch(setFriends(response.data));
    const _friendRequests = await api.get('/users/friends/requests/');
    dispatch(setFriendsRequests(_friendRequests.data));
    await Promise.all([response, _friendRequests]);
  };

  // const getEvents = async () => {
  //   const eventsResponse = await api.get('/events/');
  //   dispatch(setEvents(eventsResponse.data));
  // };

  const getEventInvitations = async () => {
    await api.get('/invitations/').then(response => {
      dispatch(setEventInvitations(response.data));
    });
  };

  const loginUser = async (username, password) => {
    const response = await fetch(`${API_URL}/auth/token/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        username,
        password
      })
    });

    if (response.status === 200) {
      const data = await response.json();
      // console.log('authdata', data)
      setAuthTokens(data);
      setUser(jwt_decode(data.access));
      localStorage.setItem("authTokens", JSON.stringify(data));
      //   history.push("/");
      //   const navigate = useNavigate();
      //     navigate('/');
      getAccount();
      getProfile();
      getSettings();
      // getEvents();
      getEventInvitations();
      // getUserEvents();
      // getSavedEvents();
      getNewNotifications(dispatch);
      getFriends();
      getLanguages(dispatch);

      if ('Notification' in window && Notification.permission === 'granted') {
        registerMessagingToken();
      };

    };
    return response.status;
  };

  const registerUser = async (username, nickname, password, password2) => {
    const response = await fetch(`${API_URL}/auth/register/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        username,
        nickname,
        password,
        password2
      })
    });
    // const data = await response.json();
    // console.log('registerdata', data);
    // if (response.status === 201) {
    //   //   history.push("/login");
    //   // const navigate = useNavigate();
    //   // navigate('/login');
    // } else {
    //   alert("Something went wrong!");
    // }
    return response;
  };

  const registerAndLogin = async (username, password, password2) => {
    const response = await fetch(`${API_URL}/auth/register/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        username,
        password,
        password2
      })
    });
    if (response.status === 201) {
      const response = await fetch(`${API_URL}/auth/token/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          username,
          password
        })
      });
      const data = await response.json();

      if (response.status === 200) {
        setAuthTokens(data);
        setUser(jwt_decode(data.access));
        localStorage.setItem("authTokens", JSON.stringify(data));
        //   history.push("/");
        //   const navigate = useNavigate();
        //     navigate('/');
      } else {
        // alert("Something went wrong!");
        return response.status;
      }
    } else {
      // alert("Something went wrong!");
    };
    return response.status;
  };

  const updateTokens = async () => {
    // console.log('get new refresh, old: refresh', authTokens?.refresh)
    const response = await fetch(`${API_URL}/auth/token/refresh/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ 'refresh': authTokens?.refresh })
    })
    // console.log('refresh data', data)
    // console.log('local storage before', localStorage)
    if (response.status === 200) {
      const data = await response.json();
      setAuthTokens(data)
      setUser(jwt_decode(data.access))
      localStorage.setItem('authTokens', JSON.stringify(data))
      // console.log('local storage after', localStorage)
    }
    else {
      logoutUser();
    };
    // if (loading) {
    //   setLoading(false)
    // }
  };

  const logoutUser = () => {
    // TODO: move somewhere else
    dispatch(setEvents({
      type: 'FeatureCollection',
      features: [
        ...events.features,
        ...savedEvents.features,
      ]
    }));

    // dispatch(setAuthDialogOpen(false));
    dispatch(resetDialogs());
    dispatch(setBottomBarValue(1));

    // dispatch(resetEvents());
    dispatch(resetEventInvitations());
    dispatch(resetUserEvents());
    dispatch(resetSavedEvents());
    // getEvents();  // TODO: missing coordinates

    dispatch(resetUsers());
    dispatch(resetNetwork());

    dispatch(resetAccount());
    dispatch(resetProfile());
    dispatch(resetSettings());
    // i18next.changeLanguage(LANGUAGE_CODE);
    dispatch(resetNotifications());
    dispatch(resetLanguages());

    setAuthTokens(null);
    setUser(null);
    localStorage.removeItem("authTokens");
    // history.push("/");
    // const navigate = useNavigate();
    // navigate('/');

    if ('Notification' in window && Notification.permission === 'granted') {
      unregisterMessagingToken();
    };
  };

  const resetPassword = async (email) => {
    const response = await fetch(`${API_URL}/password/reset/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ email })
    });
    return response;
  };

  const setNewPassword = async (t, u, password, password2) => {
    const response = await fetch(`${API_URL}/password/new/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        t, u,
        password,
        password2
      })
    });
    return response;
  };

  const resendActivationEmail = async (email) => {
    const response = await fetch(`${API_URL}/auth/resend/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ email })
    });
    return response;
  };

  const contextData = {
    user,
    setUser,
    authTokens,
    setAuthTokens,
    registerUser,
    registerAndLogin,
    loginUser,
    // updateTokens,
    logoutUser,
    resetPassword,
    setNewPassword,
    resendActivationEmail,
  };

  useEffect(() => {
    if (authTokens) {
      setUser(jwt_decode(authTokens.access));
    }
    setLoading(false);

    if (loading) {
      updateTokens().then(() => {
        if (user) {
          getAccount();
          getProfile();
          getSettings();
          // getEvents();
          getEventInvitations();
          // getUserEvents();
          // getSavedEvents();
          getNewNotifications(dispatch);
          getFriends();
          getLanguages(dispatch);

          if ('Notification' in window && Notification.permission === 'granted') {
            registerMessagingToken();
          };
        };
      });
    };

    // let fourMinutes = 1000 * 60 * 4

    // let interval = setInterval(() => {
    //   if (authTokens) {
    //     updateTokens()
    //   }
    // }, fourMinutes)
    // return () => clearInterval(interval)
  }, [authTokens, loading]);

  // useEffect(() => {

  //   // if (loading) {
  //   //   updateTokens()
  //   // }

  //   let fourMinutes = 1000 * 60 * 4

  //   let interval = setInterval(() => {
  //     if (authTokens) {
  //       updateTokens()
  //     }
  //   }, fourMinutes)
  //   return () => clearInterval(interval)

  // }, [authTokens, loading])

  return (
    <AuthContext.Provider value={contextData}>
      {loading ? null : children}
    </AuthContext.Provider>
  );
};