import { createSlice } from '@reduxjs/toolkit';

import { EMPTY_FEATURE } from '../../consts/map';

export const inviteFriendsDialogSlice = createSlice({
  name: 'inviteFriendsDialog',
  initialState: {
    open: false,
    event: EMPTY_FEATURE,
  },
  reducers: {
    resetInviteFriendsDialogEvent: (state) => {
      state.event = EMPTY_FEATURE;
    },
    setInviteFriendsDialogEvent: (state, action) => {
      state.event = action.payload
    },
    setInviteFriendsDialogOpen: (state, action) => {
      state.open = action.payload
    }
  }
})

export const {
  resetInviteFriendsDialogEvent,
  setInviteFriendsDialogEvent,
  setInviteFriendsDialogOpen,
} = inviteFriendsDialogSlice.actions;

export default inviteFriendsDialogSlice.reducer;
