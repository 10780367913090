import { createSlice } from '@reduxjs/toolkit';

export const premiumDialogSlice = createSlice({
  name: 'premiumDialog',
  initialState: {
    currencyCode: 'PLN',
    open: false,
    period: 1,
  },
  reducers: {
    setCurrencyCode: (state, action) => {
      state.currencyCode = action.payload;
    },
    setPeriod: (state, action) => {
      state.period = action.payload;
    },
    setPremiumDialogOpen: (state, action) => {
      state.open = action.payload;
    },
    togglePremiumDialogOpen: (state) => {
      state.open = !state.open;
    },
  },
});

export const {
  setCurrencyCode,
  setPeriod,
  setPremiumDialogOpen,
  togglePremiumDialogOpen,
} = premiumDialogSlice.actions;

export default premiumDialogSlice.reducer;
