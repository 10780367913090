import { createSlice } from '@reduxjs/toolkit';

export const eventInvitationsSlice = createSlice({
  name: 'eventInvitations',
  initialState: {
    value: [],
  },
  reducers: {
    addNewEventInvitation: (state, action) => {
      state.value.unshift(action.payload);
    },
    setEventInvitations: (state, action) => {
      state.value = action.payload;
    },
    resetEventInvitations: (state) => {
      state.value = [];
    },
  },
})

export const {
  addNewEventInvitation,
  resetEventInvitations,
  setEventInvitations,
} = eventInvitationsSlice.actions;

export default eventInvitationsSlice.reducer;
