import React from 'react';
import { useSelector } from 'react-redux';
import { css, styled } from '@mui/material/styles';

import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { WIDTH_BREAKPOINT } from '../../theme';

const AvatarSkeleton = styled(Skeleton)`
  transform: unset; 
  transform-origin: unset;
  border-radius: 50%;
` as typeof Skeleton;

const StyledCardMedia = styled(CardMedia)`
  width: 100px;
  height: 100px;
  cursor: pointer;
  aspect-ratio: 1/1;
  border-radius: 10px;
` as typeof CardMedia;

const StyledGridItem = styled(Grid, { shouldForwardProp: (prop) => prop !== 'width' }) <{ width: number }>`
  display: flex;

  ${(props) => css`
    justify-content: ${props.width > WIDTH_BREAKPOINT / 2 && 'center'};
  `}
` as typeof Grid;

const StyledStack = styled(Stack)`
  margin-top: 2rem !important;
` as typeof Stack;

const SubtitleSkeleton = styled(Skeleton)`
  max-width: 100%;
  width: 50%;
  display: flex;
  flex-direction: row;
` as typeof Skeleton;

const TitleSkeleton = styled(Skeleton)`
  max-width: 100%;
  width: auto;
  display: flex;
  flex-direction: row;
` as typeof Skeleton;

const ProfileSkeleton: React.FC = () => {
  const width = useSelector((state: any) => state.app.width);

  return (
    <Grid container alignItems="center">
      <StyledGridItem item xs={4} width={width}>
        <AvatarSkeleton>
          <StyledCardMedia component="img" src="" alt="" />
        </AvatarSkeleton>
      </StyledGridItem>
      <Grid item xs={8}>
        <Stack direction="column" spacing={1}>
          <TitleSkeleton>
            <Typography variant="h4">x</Typography>
          </TitleSkeleton>
          <StyledStack direction="row" spacing={1}>
            <SubtitleSkeleton>
              <Typography variant="h4">x</Typography>
            </SubtitleSkeleton>
            <SubtitleSkeleton>
              <Typography variant="h4">x</Typography>
            </SubtitleSkeleton>
          </StyledStack>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default ProfileSkeleton;