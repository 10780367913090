import { createSlice } from '@reduxjs/toolkit';

import { EMPTY_FEATURE } from '../../consts/map';

export const confirmRemoveEventFromSavedDialogSlice = createSlice({
  name: 'confirmRemoveEventFromSavedDialog',
  initialState: {
    open: false,
    event: EMPTY_FEATURE,
  },
  reducers: {
    resetConfirmRemoveEventFromSavedDialogEvent: (state) => {
      state.event = EMPTY_FEATURE;
    },
    setConfirmRemoveEventFromSavedDialogEvent: (state, action) => {
      state.event = action.payload
    },
    setConfirmRemoveEventFromSavedDialogOpen: (state, action) => {
      state.open = action.payload
    }
  }
})

export const {
  resetConfirmRemoveEventFromSavedDialogEvent,
  setConfirmRemoveEventFromSavedDialogEvent,
  setConfirmRemoveEventFromSavedDialogOpen,
} = confirmRemoveEventFromSavedDialogSlice.actions;

export default confirmRemoveEventFromSavedDialogSlice.reducer;
