import { createSlice } from '@reduxjs/toolkit'

export const profileSlice = createSlice({
  name: 'profile',
  initialState: {
    firstName: '',
    lastName: '',
    displayName: '',
    picture: null,
  },
  reducers: {
    setFirstName: (state, action) => {
      state.firstName = action.payload;
    },
    setLastName: (state, action) => {
      state.lastName = action.payload;
    },
    setDisplayName: (state, action) => {
      state.displayName = action.payload;
    },
    setPicture: (state, action) => {
      state.picture = action.payload;
    },
    resetProfile: (state) => {
      state.firstName = '';
      state.lastName = '';
      state.displayName = '';
      state.picture = null;
    },
  },
})

export const {
  resetProfile,
  setDisplayName,
  setFirstName,
  setLastName,
  setPicture,
} = profileSlice.actions;

export default profileSlice.reducer;