import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import Checkbox from '@mui/material/Checkbox';
import PhotoOutlinedIcon from '@mui/icons-material/PhotoOutlined';
import Typography from '@mui/material/Typography';

import {
  setImageDialogAlt,
  setImageDialogOpen,
  setImageDialogSrc,
} from '../../features/dialogs/imageDialogSlice';

import {
  getDistance,
  formatDistance,
} from '../../utils/mapUtils';

import { Event } from '../../types/Event/types';

interface EventCardMinProps {
  event: Event;
  onClick: () => void;
  selected: boolean;
  disabled: boolean;
};

const EventCardMin: React.FC<EventCardMinProps> = ({
  event,
  onClick,
  selected,
  disabled,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const userPosition = useSelector((state: any) => state.userPosition.value);

  const handleEventPictureClick = () => {
    dispatch(setImageDialogSrc(event.properties.picture));
    dispatch(setImageDialogAlt(event.properties.name));
    dispatch(setImageDialogOpen(true));
  };

  return (
    <Card
      // id={invitation.event.properties.uuid}
      variant="outlined"
      onClick={onClick}
      sx={{
        width: '100%',
        borderRadius: '25px',
        backgroundColor: 'transparent',
        '& .MuiCardHeader-action': { margin: 'auto' },
        '& .MuiCardHeader-root': { paddingRight: 0 },
      }}>
      <CardHeader
        avatar={
          event.properties.picture ? (
            <CardMedia
              component="img"
              image={event.properties.picture}
              alt={event.properties.name}
              onClick={handleEventPictureClick}
              sx={{
                width: '50px',
                height: '50px',
                cursor: 'pointer',
                aspectRatio: '1/1',
                borderRadius: '10px',
              }}
            />
          ) : (
            <CardMedia sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '50px',
              aspectRatio: '1/1',
              border: '1px solid #fff',
              borderRadius: '10px',
            }}>
              <Avatar sx={{ bgcolor: 'transparent' }} aria-label="recipe">
                <PhotoOutlinedIcon sx={{ color: '#fff', fontSize: '28px' }} />
              </Avatar>
            </CardMedia>
          )
        }
        title={
          <Typography
            // align="center"
            variant="body1"
            sx={{
              flexGrow: 1,
              // textAlign: 'center',
              // verticalAlign: 'middle',
              wordBreak: 'break-word',
            }}
          >
            {event.properties.name}
          </Typography >
        }
        subheader={userPosition.length === 2 && (
          <Typography variant="caption">
            {formatDistance(getDistance(userPosition, event.geometry.coordinates))}
          </Typography>
        )}
        action={
          <Checkbox
            // onChange={() => event.selected = !event.selected}
            checked={selected}
            disabled={disabled}
          />
        }
      />
    </Card >
  );
};

export default EventCardMin;