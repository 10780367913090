import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';

import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import SearchIcon from '@mui/icons-material/Search';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import {
  setAlertsSnackbarOpen,
  setAlertsSnackbarSeverity,
  setAlertsSnackbarText,
} from '../../features/app/alertsSnackbarSlice';

import {
  fabBoxShadow,
  WIDTH_BREAKPOINT,
} from '../../theme';
import api from '../../utils/api.ts';
import { somethingWentWrong } from '../../utils/utils';

import NoContentAlert from '../ui/NoContentAlert.tsx';
import NoContentAlertWrapper from '../ui/NoContentAlertWrapper.tsx';
import UserSearchCardMin from './UserSearchCardMin.tsx';

import { UserSearchResult } from '../../types/User/types.ts';

const ClearButton = styled(IconButton)`
  margin-right: -10px;
`;

const SearchButton = styled(IconButton)`
  box-shadow: ${fabBoxShadow};
`;

const StyledClearIcon = styled(ClearIcon)`
  font-size: 18px;
`;

const StyledListItem = styled(ListItem)`
  justify-content: center;
  padding-left: 0;
  padding-right: 0;
`;

const StyledTextField = styled(TextField)`
  width: 100%;
  padding-right: 16px;
  justify-content: center;
`;

interface SearchFriendsProps {
  setLoading?: (loading: boolean) => void;
};

const SearchFriends: React.FC<SearchFriendsProps> = ({ setLoading }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const width = useSelector((state: any) => state.app.width);

  const [username, setUsername] = useState('');
  const [actionButtonDisabled, setActionButtonDisabled] = useState(false);
  const [searchResults, setSearchResults] = useState<UserSearchResult | null>(null);
  const [searchDisabled, setSearchDisabled] = useState(true);

  const handleSearchSubmit = async (e: React.FormEvent) => {
    if (setLoading) setLoading(true);
    e.preventDefault();
    setSearchDisabled(true);
    await api.get('/users/search/', {
      'params': {
        'username': username,
      },
    }).then(response => {
      if (response.status === 200) {
        setSearchResults(response.data);
        setSearchDisabled(false);
        if (setLoading) setLoading(false);
      };
    });
  };

  // todo: alerts snackbar z-index
  const handleAddFriend = async (
    e: React.MouseEvent<HTMLButtonElement>,
    uuid: string,
    action: string,
  ) => {
    setActionButtonDisabled(true);
    await api.post('/users/friends/requests/', {
      action: action,
      receiver: uuid,
    }).then(response => {
      if (action === 'create') {
        dispatch(setAlertsSnackbarSeverity('success'));
        dispatch(setAlertsSnackbarText(t('Friend request sent!')));
        if (searchResults) {
          setSearchResults({
            features: searchResults.features.map(feature => feature.properties.uuid === uuid ? {
              properties: { ...feature.properties, invited: true },
              geometry: { coordinates: [] }
            } : feature),
          });
        };
      } else if (action === 'cancel') {
        dispatch(setAlertsSnackbarSeverity('info'));
        dispatch(setAlertsSnackbarText(t('Friend request cancelled!')));
        if (searchResults) {
          // remove from list ?
          // setSearchResults({
          //   features: searchResults.features.filter(feature => feature.properties.uuid !== uuid),
          // });
          setSearchResults({
            features: searchResults.features.map(feature => feature.properties.uuid === uuid ? {
              properties: { ...feature.properties, invited: false },
              geometry: { coordinates: [] }
            } : feature),
          });
        };
      };
      dispatch(setAlertsSnackbarOpen(true));
      setTimeout(() => { setActionButtonDisabled(false) }, 500);
    }).catch(err => {
      somethingWentWrong(dispatch, t);
      setTimeout(() => { setActionButtonDisabled(false) }, 1000);
    });
  };

  return (
    <>
      <form
        onSubmit={handleSearchSubmit}
        style={{
          paddingBottom: !searchResults || searchResults?.features.length === 0 ? '12px' : undefined,
        }}
      >
        <Stack direction="row" spacing={1} justifyContent="center" sx={{ paddingLeft: '24px' }}>
          <StyledTextField
            variant="standard"
            placeholder={t('Search…') as string}
            inputProps={{
              'id': 'nickname',
              'aria-label': 'search',
              'autoComplete': navigator.userAgent.includes('Firefox') ? 'new-password' : 'off',
            }}
            InputProps={{
              endAdornment: (
                username !== '' && (
                  <InputAdornment position="end">
                    <ClearButton
                      onClick={e => {
                        setUsername('');
                        setSearchDisabled(true);
                        setSearchResults(null);
                      }}
                    >
                      <StyledClearIcon />
                    </ClearButton>
                  </InputAdornment>
                )
              )
            }}
            value={username}
            onChange={(e) => {
              setSearchDisabled(!Boolean(e.target.value));
              setUsername(e.target.value);
            }}
          />
          <SearchButton type="submit" disabled={searchDisabled}>
            <SearchIcon />
          </SearchButton>
        </Stack>
      </form>
      {searchResults && searchResults.features?.length > 0 ?
        <React.Fragment>
          <List>
            {searchResults.features.map((item, index) => (
              <StyledListItem key={item.properties.uuid}>
                <UserSearchCardMin
                  feature={item}
                  handleAddFriend={handleAddFriend}
                  actionButtonDisabled={actionButtonDisabled}
                />
              </StyledListItem>
            ))}
          </List>
        </React.Fragment>
        : searchResults && (
          <NoContentAlertWrapper top={width <= WIDTH_BREAKPOINT ? '45%' : '50%'}>
            <NoContentAlert text={t('No results!') as string} />
          </NoContentAlertWrapper>
        )}
    </>
  );
};

export default SearchFriends;