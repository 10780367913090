import { useDispatch, useSelector } from 'react-redux';

import IconButton from '@mui/material/IconButton';
import PinDropIcon from '@mui/icons-material/PinDrop';

import { setBottomBarValue } from '../../features/app/appSlice';
import {
  setNetworkDialogOpen,
  setSavedEventsDialogOpen,
  setUserEventsDialogOpen,
} from '../../features/app/dialogsSlice';
import { setEventDialogOpen } from '../../features/dialogs/eventDialogSlice';
import { setUserDialogOpen } from '../../features/dialogs/userDialogSlice';

import { DEFAULT_ZOOM } from '../../consts/map';

export default function ShowOnMapButton(props) {
  const dispatch = useDispatch();

  const map = useSelector((state) => state.map.value);

  const handleClick = () => {
    dispatch(setEventDialogOpen(false));
    dispatch(setUserDialogOpen(false));
    dispatch(setSavedEventsDialogOpen(false));
    dispatch(setUserEventsDialogOpen(false));
    dispatch(setNetworkDialogOpen(false));
    dispatch(setBottomBarValue(1));

    const currentZoom = map.getZoom();
    map.flyTo({
      center: props.feature.geometry.coordinates,
      zoom: currentZoom <= DEFAULT_ZOOM ? DEFAULT_ZOOM : currentZoom,
      duration: 500,
    });
  };

  return (
    <IconButton
      onClick={handleClick}
      aria-label="show on map">
      <PinDropIcon />
    </IconButton>
  );
};