import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import CloseButton from './CloseButton.tsx';
import PaperComponent from '../PaperComponent';

import api from '../../utils/api.ts';

import {
  setAlertsSnackbarOpen,
  setAlertsSnackbarSeverity,
  setAlertsSnackbarText,
} from '../../features/app/alertsSnackbarSlice';
import { setSuggestLanguagesDialogOpen } from '../../features/dialogs/suggestLanguagesDialogSlice.ts';

import { borderedDialogPaperProps } from '../../theme';

interface Language {
  name: string;
  code: string;
  flag: string;
};

const SuggestLanguagesDialog: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
  const open = useSelector((state: any) => state.suggestLanguagesDialog.open);
  const languages = useSelector((state: any) => state.suggestLanguagesDialog.languages);
  const [selectedLanguages, setSelectedLanguages] = useState<Language[] | []>([]);

  const handleClose = () => {
    dispatch(setSuggestLanguagesDialogOpen(false));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (submitButtonDisabled) { return };
    setSubmitButtonDisabled(true);

    await api.post('/languages-suggestion/', { languages: selectedLanguages.map((l: Language) => l.code) }).then(response => {
      if (response.status === 201) {
        handleClose();
        dispatch(setAlertsSnackbarSeverity('success'));
        dispatch(setAlertsSnackbarText(t('Thank you for suggestion')));
        dispatch(setAlertsSnackbarOpen(true));
      };
    }).catch(err => {
      dispatch(setAlertsSnackbarSeverity('error'));
      dispatch(setAlertsSnackbarText(t('Something went wrong.')));
      dispatch(setAlertsSnackbarOpen(true));

      setTimeout(() => {
        setSubmitButtonDisabled(false);
      }, 1500);
    });
  };

  useEffect(() => {
    if (!open) {
      setSubmitButtonDisabled(false);
      setTimeout(() => {
        setSelectedLanguages([]);
      }, 500);
    };
  }, [open]);

  return (
    <Dialog
      open={open}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      aria-describedby="alert-dialog-description"
      {...borderedDialogPaperProps}
      fullWidth
    >
      <DialogTitle textAlign="center" style={{ cursor: 'move' }} id="draggable-dialog-title">
        {t('Suggest new languages')}
      </DialogTitle>
      <CloseButton onClick={handleClose} />
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Typography>
          </Typography>
          <Autocomplete
            id="languages-select"
            options={languages}
            value={selectedLanguages}
            onChange={(e, value) => setSelectedLanguages(value)}
            autoHighlight
            getOptionLabel={(option: Language) => option.name}
            filterOptions={(languages, { inputValue }) => languages.filter(l => l.name.toLowerCase().includes(inputValue.toLowerCase()) || l.code.includes(inputValue.toLowerCase()))}
            multiple
            noOptionsText={t('No options')}
            renderOption={(props, option) => (
              <Box component="li" {...props}>
                {option.flag} {option.name}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                label={t('Select languages')}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                  required: selectedLanguages.length === 0
                }}
              />
            )}
          />
        </DialogContent>
        <DialogActions sx={{
          justifyContent: 'center',
          marginBottom: '1rem',
        }}>
          <Button
            disabled={submitButtonDisabled}
            variant="contained"
            type="submit"
          >
            {t('Submit')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
};

export default SuggestLanguagesDialog;