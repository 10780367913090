import React from 'react';
import { styled } from '@mui/material/styles';

import AddAPhotoOutlinedIcon from '@mui/icons-material/AddAPhotoOutlined';
import Avatar from '@mui/material/Avatar';
import ClearIcon from '@mui/icons-material/Clear';

import {
  backdropBlur,
  equalWhiteBoxShadow,
} from '../../theme';

interface AvatarWithImageUploadProps {
  // picture: string | ArrayBuffer | undefined | null;
  // setPicture: (picture: string | ArrayBuffer | undefined | null) => void,
  picture: any;
  setPicture: (picture: any) => void,
  setSaveButtonDisabled: (disabled: boolean) => void,
};

const ActionButton = styled('div')`
  position: absolute;
  bottom: 12px;
  right: 12px;
  transform: translate(50%, 50%);
  background-color: transparent;
  border-radius: 50%;
  padding: 3px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  box-shadow: ${equalWhiteBoxShadow};
  backdrop-filter: ${backdropBlur};
  width: 1.5rem;
  height: 1.5rem;
`;

const HiddenInput = styled('input')`
  display: none;
`;

const StyledAddAPhotoOutlinedIcon = styled(AddAPhotoOutlinedIcon)`
  color: #fff;
  // width: 100%;
  height: 100%;
  font-size: 2rem;
`;

const StyledAvatar = styled(Avatar)`
  border: 1px solid white;
  background-color: transparent;
  width: 100px;
  height: 100px;
`;

const StyledClearIcon = styled(ClearIcon)`
  color: #fff;
  font-size: 1rem;
`;

const StyledLabel = styled('label')`
  display: flex;
  cursor: pointer;
`;

const Wrapper = styled('div')`
  position: relative;
  display: inline-block;
`;

const AVATAR_UPLOAD = 'avatar-upload';

const AvatarWithImageUpload: React.FC<AvatarWithImageUploadProps> = ({
  picture,
  setPicture,
  setSaveButtonDisabled,
}) => {
  const handleImageUpload = (e: React.ChangeEvent) => {
    const target = e.target || e.currentTarget as HTMLInputElement;
    const file = target['files'][0];
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        if (typeof reader.result === 'string' || !reader.result) {
          setPicture(reader.result);
        } else {
          const decoder = new TextDecoder();
          setPicture(decoder.decode(reader.result));
        };

        // if (reader.result !== picture) {
        //   setSaveButtonDisabled(false);
        // } else {
        //   setSaveButtonDisabled(true);
        // };

        setSaveButtonDisabled(false);
        e.target['value'] = null;
      };
    };

    if (file) { reader.readAsDataURL(file) };
  };

  const handleClearPicture = () => {
    setPicture('');
    setSaveButtonDisabled(false);
  };

  return (
    <Wrapper>
      <StyledLabel htmlFor={AVATAR_UPLOAD}>
        <StyledAvatar src={picture}>
          <StyledAddAPhotoOutlinedIcon />
        </StyledAvatar>
      </StyledLabel>
      <HiddenInput
        id={AVATAR_UPLOAD}
        type="file"
        accept="image/*"
        onChange={handleImageUpload}
      />
      {picture && (
        <ActionButton onClick={handleClearPicture}>
          <StyledClearIcon fontSize="small" />
        </ActionButton>
      )}
    </Wrapper>
  );
};

export default AvatarWithImageUpload;