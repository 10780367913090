import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import Check from '@mui/icons-material/Check';
import Clear from '@mui/icons-material/Clear';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import CloseButton from '../ui/CloseButton.tsx';
import PaperComponent from '../PaperComponent';

import api from '../../utils/api.ts';

import {
  setAlertsSnackbarOpen,
  setAlertsSnackbarSeverity,
  setAlertsSnackbarText,
} from '../../features/app/alertsSnackbarSlice';
import { setCancelEventDialogOpen } from '../../features/dialogs/confirmCancelEventDialogSlice.ts';
import { setEventDialogOpen } from '../../features/dialogs/eventDialogSlice';
import { updateUserEvent } from '../../features/events/userEventsSlice';

import { borderedDialogPaperProps } from '../../theme';

const ConfirmCancelEventDialog: React.FC = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [cancelButtonDisabled, setCancelButtonDisabled] = useState(false);

  const open = useSelector((state: any) => state.confirmCancelEventDialog.open);
  const event = useSelector((state: any) => state.confirmCancelEventDialog.event);

  const handleClose = () => {
    dispatch(setCancelEventDialogOpen(false));
  };

  const handleCancel = async () => {
    if (cancelButtonDisabled) { return };
    setCancelButtonDisabled(true);
    const data = { uuid: event.properties.uuid };

    await api.patch('/events/cancel/', data).then(response => {
      const updateData = {
        uuid: response.data.uuid,
        properties: {
          end: response.data.end,
          cancelled: response.data.cancelled,
        },
      };
      dispatch(updateUserEvent(updateData));

      dispatch(setAlertsSnackbarSeverity('info'));
      dispatch(setAlertsSnackbarText(t('Event has been cancelled')));
      dispatch(setAlertsSnackbarOpen(true));
      dispatch(setEventDialogOpen(false));
      handleClose();
      setTimeout(() => { setCancelButtonDisabled(false) }, 1000);
    }).catch(err => {
      dispatch(setAlertsSnackbarSeverity('error'));
      dispatch(setAlertsSnackbarText(t('Something went wrong.')));
      dispatch(setAlertsSnackbarOpen(true));

      setTimeout(() => {
        setCancelButtonDisabled(false);
      }, 1500);
    });
  };

  useEffect(() => {
    if (!open) setCancelButtonDisabled(false);
  }, [open]);

  return (
    <Dialog
      open={open}
      PaperComponent={PaperComponent}
      hideBackdrop={false}
      aria-labelledby="draggable-dialog-title"
      aria-describedby="alert-dialog-description"
      {...borderedDialogPaperProps}
    >
      <DialogTitle textAlign="center" style={{ cursor: 'move' }} id="draggable-dialog-title">
        {t('Confirm cancel')}
      </DialogTitle>
      <CloseButton onClick={handleClose} />
      <form>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('Are you sure you want to cancel')} <strong>{event.properties?.name}</strong>?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button
            variant="outlined"
            startIcon={<Clear />}
            onClick={handleClose}
          >
            {t('Cancel')}
          </Button>
          <Button
            disabled={cancelButtonDisabled}
            onClick={handleCancel}
            variant="outlined"
            endIcon={<Check />}
            color="warning"
          >
            {t('Confirm')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
};

export default ConfirmCancelEventDialog;