import { createSlice } from '@reduxjs/toolkit';

import {
  EMPTY_FEATURE,
  EMPTY_GEOJSON,
} from '../../consts/map';

export const usersSlice = createSlice({
  name: 'users',
  initialState: {
    value: EMPTY_GEOJSON,
  },
  reducers: {
    setUsers: (state, action) => {
      state.value = action.payload
    },
    removeUserByUuid: (state, action) => {
      const uuid = action.payload;
      state.value = {
        ...state.value,
        features: state.value.features.filter(feature => feature.properties.uuid !== uuid),
      };
    },
    updateUserByUuid: (state, action) => {
      const { uuid, newProperties } = action.payload;
      const userIndex = state.value.features.findIndex(u => u.properties.uuid === uuid);

      if (userIndex !== -1) {
        const newFeature = {
          type: 'Feature',
          properties: {
            ...state.value.features[userIndex].properties,
            ...newProperties,
          },
          geometry: state.value.features[userIndex].geometry,
        };
        state.value.features[userIndex] = newFeature;
      };
    },
    resetUsers: (state) => {
      state.value = EMPTY_GEOJSON;
    },
  },
})

/* TODO: move somewhere else */
export const selectUserByUuid = (state, uuid) => {
  return (
    state.users.value.features.find(feature => feature.properties.uuid === uuid) ||
    state.network.friends.features.find(feature => feature.properties.uuid === uuid) ||
    EMPTY_FEATURE
  );
};

export const {
  removeUserByUuid,
  resetUsers,
  setUsers,
  updateUserByUuid,
} = usersSlice.actions;

export default usersSlice.reducer;
