import { createSlice } from '@reduxjs/toolkit';

import { EMPTY_FEATURE } from '../../consts/map';

export const eventDialogSlice = createSlice({
  name: 'eventDialog',
  initialState: {
    open: false,
    event: EMPTY_FEATURE,
  },
  reducers: {
    resetEventDialogEvent: (state) => {
      state.event = EMPTY_FEATURE;
    },
    setEventDialogEvent: (state, action) => {
      state.event = action.payload
    },
    setEventDialogOpen: (state, action) => {
      state.open = action.payload
    }
  }
})

export const {
  resetEventDialogEvent,
  setEventDialogEvent,
  setEventDialogOpen,
} = eventDialogSlice.actions;

export default eventDialogSlice.reducer;
