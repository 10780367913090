import React from 'react';
import { useTranslation } from 'react-i18next';
import { css, styled } from '@mui/material/styles';

import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import ClearIcon from '@mui/icons-material/Clear';
import CloseIcon from '@mui/icons-material/Close';
import EmailIcon from '@mui/icons-material/Email';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import FmdBadIcon from '@mui/icons-material/FmdBad';
import IconButton from '@mui/material/IconButton';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import PlaceIcon from '@mui/icons-material/Place';
import Typography from '@mui/material/Typography';

import {
  grayedOutFilter,
  sunsetOrange,
} from '../../theme';

interface NotificationCardProps {
  notification: {
    picture: string;
    subject: string;
    title: string;
    body: string;
    past: boolean;
    status: number;
    uuid: string;
    userDeleted: boolean;
  };
  handleDeleteClick: (uuid: string) => void;
  handleNotificationClick: (subject: string) => void;
};

interface Props {
  filter: boolean;
};

const ClearButton = styled(IconButton)`
  left: 8px;
`;

const StyledAvatar = styled(Avatar, { shouldForwardProp: (prop) => prop !== 'filter' }) <Props>`
  background-color: transparent;
  color: #fff;
  border: 1px solid #fff;
  width: 28px;
  height: 28px;
  margin-right: 1rem;

  ${(props) => css`
    ${props.filter && `filter: ${grayedOutFilter};`}
  `}
`;

const StyledBadge = styled(Badge)`
  .MuiBadge-badge {
    padding: 0;
    background-color: ${sunsetOrange};
  };
`;

const StyledCard = styled(Card)`
  width: 100%;
  border-radius: 25px;
  background-color: transparent;

  .MuiCardHeader-avatar {
    margin-right: 0;
  };

  .MuiCardHeader-action {
    margin: auto;
  };
`;

const StyledCardHeader = styled(CardHeader)`
  text-wrap: wrap;
`;

const StyledCloseIcon = styled(CloseIcon)`
  font-size: 12px;
`;

const StyledEmailIcon = styled(EmailIcon)`
  font-size: 14px;
`;

const StyledEmojiPeopleIcon = styled(EmojiPeopleIcon)`
  font-size: 18px;
`;

const StyledFmdBadIcon = styled(FmdBadIcon)`
  font-size: 14px;
`;

const StyledPersonOutlineIcon = styled(PersonOutlineIcon)`
  font-size: 18px;
`;

const StyledPlaceIcon = styled(PlaceIcon)`
  font-size: 14px;
`;

const StyledSpan1 = styled('span', { shouldForwardProp: (prop) => prop !== 'filter' }) <Props>`
  display: inline-flex;
  align-items: center;
  vertical-align: middle;

  ${(props) => css`
    ${props.filter && `filter: ${grayedOutFilter};`}
  `}
`;

const StyledSpan2 = styled('span')`
  vertical-align: middle;
`;

const StyledTypography = styled(Typography, { shouldForwardProp: (prop) => prop !== 'filter' }) <Props>`
  ${(props) => css`
    ${props.filter && `filter: ${grayedOutFilter};`}
  `}
`;

const notificationSubjectIconMap = {
  'eventDeleted': <StyledPlaceIcon />,
  'eventBanned': <StyledFmdBadIcon />,
  'eventInvitation': <StyledEmailIcon />,
  'friendRequest': <StyledPersonOutlineIcon />,
  'welcome': <StyledEmojiPeopleIcon />,
};

const NotificationCard: React.FC<NotificationCardProps> = ({
  notification,
  handleDeleteClick,
  handleNotificationClick,
}) => {
  const { t } = useTranslation();

  const _handleNotificationClick = () => {
    handleNotificationClick(notification.subject);
  };

  const _handleDeleteClick = () => {
    handleDeleteClick(notification.uuid);
  };

  return (
    <StyledCard variant="outlined">
      <StyledCardHeader
        avatar={(
          <StyledBadge
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            badgeContent={notification.status === -1 ? <StyledCloseIcon /> : 0}
            onClick={_handleNotificationClick}
          >
            <StyledAvatar
              src={notification.picture}
              aria-label="recipe"
              filter={notification.past}
            >
              {notificationSubjectIconMap[notification.subject]}
            </StyledAvatar>
          </StyledBadge>
        )}
        action={(
          <ClearButton edge="end" aria-label="delete" size="small"
            onClick={_handleDeleteClick}
          >
            <ClearIcon />
          </ClearButton>
        )}
        title={(
          <StyledTypography
            onClick={_handleNotificationClick}
            filter={notification.past}
          >
            <StyledSpan1 filter={notification.userDeleted}>
              {notification.userDeleted && <PersonOffIcon fontSize="small" />}{notification.title}&nbsp;
            </StyledSpan1>

            <StyledSpan2>
              {`${notification.body}${notification.past ? ` (${t('over')})` : ''}`}
            </StyledSpan2>
          </StyledTypography>
        )}
      />
    </StyledCard>
  );
};

export default NotificationCard;