import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax

import Box from '@mui/material/Box';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';

import {
  setAlertsSnackbarOpen,
  setAlertsSnackbarSeverity,
  setAlertsSnackbarText,
} from '../../features/app/alertsSnackbarSlice';
import { setEventInvitationsDialogOpen } from '../../features/app/dialogsSlice';
import { setEventInvitationDialogOpen } from '../../features/dialogs/eventInvitationDialogSlice';
import { setEventInvitations } from '../../features/events/eventInvitationsSlice';
import { addSavedEvent } from '../../features/events/savedEventsSlice';

import api from '../../utils/api.ts';
import {
  getInvitations,
  getNewNotifications,
} from '../../utils/getters';

import { GET_EVENTS_INVITATIONS_INTERVAL } from '../../consts/intervals';
import {
  DEFAULT_ZOOM,
  MARKER_HEIGHT,
} from '../../consts/map';

import AuthContext from '../../context/AuthContext';
import IntervalContext from '../../context/IntervalContext';

import CloseButton from '../ui/CloseButton.tsx';
import EventInvitationCardMin from './EventInvitationCardMin';
import EventInvitationCardMinSkeletonList from './EventInvitationCardMinSkeletonList.tsx';
import EventInvitationCardMinWrapper from './EventInvitationCardMinWrapper.tsx';
import EventInvitationDialog from './EventInvitationDialog';
import NoContentAlert from '../ui/NoContentAlert.tsx';
import NoContentAlertWrapper from '../ui/NoContentAlertWrapper.tsx';
import SmallLoader from '../ui/SmallLoader.tsx';

import {
  customDialogStyle,
  WIDTH_BREAKPOINT,
} from '../../theme';

export default function EventInvitationsDialog() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { user } = useContext(AuthContext);
  const { addInterval } = useContext(IntervalContext);

  const width = useSelector((state) => state.app.width);
  const eventInvitations = useSelector((state) => state.eventInvitations.value);

  const [actionButtonDisabled, setActionButtonDisabled] = useState(false);

  const [eventInvitationsReady, setEventInvitationsReady] = useState(false);
  const [loading, setLoading] = useState(true);
  const [intervalId, setIntervalId] = useState(null);

  const open = useSelector((state) => state.dialogs.eventInvitationsDialogOpen);

  const map = useSelector((state) => state.map.value);
  const eventInvitationMarker = useRef(null);
  const popupRef = useRef(null);
  const [showPopupContent, setShowPopContent] = useState(false);
  const [currentInvitation, setCurrentInvitation] = useState(null);

  const notificationsAllowed = useSelector((state) => state.notifications.notificationsAllowed);

  const handleClose = () => {
    dispatch(setEventInvitationsDialogOpen(false));
  };

  const handleAction = async (e, uuid, action) => {
    removeMarker();
    setLoading(true);
    setActionButtonDisabled(true);
    dispatch(setEventInvitations(eventInvitations.filter(el => el.uuid !== uuid)));
    await api.post('/invitations/', {
      action: action,
      uuid: uuid,
    }).then(response => {
      getNewNotifications(dispatch);
      setLoading(false);
      if (action === 'accept') {
        dispatch(setAlertsSnackbarSeverity('success'));
        dispatch(setAlertsSnackbarText(t('Invitation accepted!')));
      } else if (action === 'reject') {
        dispatch(setAlertsSnackbarSeverity('info'));
        dispatch(setAlertsSnackbarText(t('Invitation rejected!')));
      };
      dispatch(setAlertsSnackbarOpen(true));
      if (response.data) { dispatch(addSavedEvent(response.data)) };
      dispatch(setEventInvitationDialogOpen(false));
      setTimeout(() => { setActionButtonDisabled(false) }, 250);
    }).catch(err => {
      setLoading(false);
      if (err.response.status === 404) {
        dispatch(setAlertsSnackbarSeverity('error'));
        dispatch(setAlertsSnackbarText(t('No such ongoing event!')));
        dispatch(setAlertsSnackbarOpen(true));
      };
      setTimeout(() => { setActionButtonDisabled(false) }, 250);
    });
  };

  const fetchData = async () => {
    setLoading(true);
    await getInvitations(dispatch).then(() => {
      setEventInvitationsReady(true);
    }).catch(err => {
      dispatch(setAlertsSnackbarSeverity('error'));
      dispatch(setAlertsSnackbarText(t('Oops, something went wrong. Please try again later.')));
      dispatch(setAlertsSnackbarOpen(true));
    });
    setLoading(false);
  };

  const removeMarker = () => {
    eventInvitationMarker.current?.remove();
    eventInvitationMarker.current = null;
  };

  const handleShowOnMapClick = (e, item) => {
    setCurrentInvitation(item);
    const event = item.event;
    removeMarker();

    const eventInvitationMarkerElement = document.createElement('div');
    eventInvitationMarkerElement.classList.add('event-invitation-marker', `${event.properties.timeline}-event-invitation-marker`);
    eventInvitationMarkerElement.style.width = '33px';
    eventInvitationMarkerElement.style.height = `${MARKER_HEIGHT}px`;
    const _eventInvitationMarker = new mapboxgl.Marker({
      element: eventInvitationMarkerElement,
      rotationAlignment: 'horizon',
      anchor: 'bottom',
    });

    const popup = new mapboxgl.Popup({
      closeOnClick: false,
      closeButton: false,
      offset: MARKER_HEIGHT,
    });

    setShowPopContent(true);
    popup.setDOMContent(popupRef.current);

    dispatch(setEventInvitationDialogOpen(false));
    handleClose();

    const currentZoom = map.getZoom();
    var eventInvitationMarkerLabel = document.createElement('div');
    eventInvitationMarkerLabel.className = 'event-invitation-marker-label';
    eventInvitationMarkerLabel.textContent = event.properties.name;
    _eventInvitationMarker.getElement().appendChild(eventInvitationMarkerLabel);
    _eventInvitationMarker.setLngLat(event.geometry.coordinates).setPopup(popup).addTo(map).togglePopup();
    eventInvitationMarker.current = _eventInvitationMarker;

    map.flyTo({
      center: event.geometry.coordinates,
      zoom: currentZoom <= DEFAULT_ZOOM ? DEFAULT_ZOOM : currentZoom,
      duration: 250,
    });
  };

  const handleMapPopupClose = () => {
    removeMarker();
  };

  useEffect(() => {
    if (open) fetchData();
  }, [open]);  // FIXME

  useEffect(() => {
    var interval;
    if (!notificationsAllowed) {
      if (user) {
        interval = addInterval(() => {
          getInvitations(dispatch);
        }, GET_EVENTS_INVITATIONS_INTERVAL);
        setIntervalId(interval);
      } else {
        clearInterval(intervalId);
        setIntervalId(null);
      };
    };
    return () => clearInterval(interval);
  }, [user, notificationsAllowed]);

  return user && (
    <>
      <Dialog
        hideBackdrop={width <= WIDTH_BREAKPOINT}
        fullScreen={width <= WIDTH_BREAKPOINT}
        fullWidth
        open={open}
        onClose={handleClose}
        // TransitionComponent={Transition}
        PaperProps={{
          style: {
            borderRadius: width <= WIDTH_BREAKPOINT ? 'unset' : '25px',
            height: width <= WIDTH_BREAKPOINT ? '100%' : '80vh',
          }
        }}
        {...customDialogStyle}
      >
        <Box sx={{ marginBottom: width <= WIDTH_BREAKPOINT ? '12vh' : 'unset' }}>
          <DialogTitle textAlign="center">
            {t('Invitations')}
            {loading && <SmallLoader />}
          </DialogTitle>
          <CloseButton onClick={handleClose} />
          <Stack
            spacing={2}
            direction="column"
          >
            {eventInvitationsReady ? (
              eventInvitations.length > 0 ? (
                <List sx={{ width: '100%' }}>
                  {eventInvitations.map((invitation, index) => (
                    <EventInvitationCardMinWrapper key={index}>
                      <EventInvitationCardMin
                        invitation={invitation}
                        handleAction={handleAction}
                        actionButtonDisabled={actionButtonDisabled}
                      />
                    </EventInvitationCardMinWrapper>
                  ))}
                </List>
              ) : (
                <NoContentAlertWrapper top={width <= WIDTH_BREAKPOINT ? '40%' : '50%'}>
                  <NoContentAlert text={t("You don't have pin invitations yet!")} />
                </NoContentAlertWrapper>
              )) : (
              <EventInvitationCardMinSkeletonList />
            )}
          </Stack>
        </Box>
      </Dialog>
      <Box ref={popupRef} sx={{ display: showPopupContent ? 'inline' : 'none' }}>
        <Box sx={{ display: 'flex', color: '#000' }}>
          <Grid container>
            <Grid item xs={2} />
            <Grid item xs={8} sx={{ display: 'flex', justifyContent: 'center' }}>
              <span>{t('Accept')}</span>
            </Grid>
            <Grid item xs={2} onClick={handleMapPopupClose}>
              <IconButton sx={{ padding: 0 }}>
                <ClearIcon sx={{ fontSize: '.75rem', color: '#000' }} />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
        <Stack
          direction="row"
          spacing={0}
        >
          <IconButton
            disabled={actionButtonDisabled}
            aria-label="clear" color="error"
            onClick={(e) => handleAction(e, currentInvitation.uuid, 'reject')}>
            <ClearIcon />
          </IconButton>
          <IconButton
            disabled={actionButtonDisabled}
            aria-label="check" color="success"
            onClick={(e) => handleAction(e, currentInvitation.uuid, 'accept')}>
            <CheckIcon />
          </IconButton>
        </Stack>
      </Box>
      <EventInvitationDialog
        handleAction={handleAction}
        actionButtonDisabled={actionButtonDisabled}
        handleShowOnMapClick={handleShowOnMapClick}
      />
    </>
  );
}
