import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import IconButton from '@mui/material/IconButton';
import LinkIcon from '@mui/icons-material/Link';

import {
  setAlertsSnackbarOpen,
  setAlertsSnackbarSeverity,
  setAlertsSnackbarText,
} from '../../features/app/alertsSnackbarSlice';

interface CopyLinkButtonProps {
  link: string;
};

const CopyLinkButton: React.FC<CopyLinkButtonProps> = ({ link }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleMouseDown = (e: React.MouseEvent) => {
    e.preventDefault();
  };

  const handleClick = () => {
    navigator.clipboard.writeText(link)
      .then(() => {
        dispatch(setAlertsSnackbarSeverity('info'));
        dispatch(setAlertsSnackbarText(t('Link copied to clipboard')));
        dispatch(setAlertsSnackbarOpen(true));
      })
      .catch((error) => {
        dispatch(setAlertsSnackbarSeverity('warning'));
        dispatch(setAlertsSnackbarText(t('Something went wrong!')));
        dispatch(setAlertsSnackbarOpen(true));
      });
  };

  return (
    <IconButton
      aria-label="toggle password visibility"
      onClick={handleClick}
      onMouseDown={handleMouseDown}
      tabIndex={-1}
    >
      <LinkIcon />
    </IconButton>
  );
};

export default CopyLinkButton;