import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';

import Button from '@mui/material/Button';
import Check from '@mui/icons-material/Check';
import Checkbox from '@mui/material/Checkbox';
import Clear from '@mui/icons-material/Clear';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';

import CloseButton from '../ui/CloseButton.tsx';
import PaperComponent from '../PaperComponent';

import api from '../../utils/api.ts';
import {
  setAlertsSnackbarOpen,
  setAlertsSnackbarSeverity,
  setAlertsSnackbarText,
} from '../../features/app/alertsSnackbarSlice';
import { setConfirmDeleteAccountDialogOpen } from '../../features/app/dialogsSlice';

import AuthContext from '../../context/AuthContext';
import { borderedDialogPaperProps } from '../../theme';

const StyledDialogActions = styled(DialogActions)`
  justify-content: space-evenly;
`;

const ConfirmDeleteAccountDialog: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { logoutUser } = useContext(AuthContext)

  const open = useSelector((state: any) => state.dialogs.confirmDeleteAccountDialogOpen);

  const [deleteButtonDisabled, setDeleteButtonDisabled] = useState(false);
  const [deletePinsToo, setDeletePinsToo] = useState(false);

  const handleClose = () => {
    dispatch(setConfirmDeleteAccountDialogOpen(false));
    setDeleteButtonDisabled(false);
  };

  const handleRemoveClick = async () => {
    if (deleteButtonDisabled) { return };
    setDeleteButtonDisabled(true);
    await api.delete('/accounts/delete/',
      { 'data': { deletePins: deletePinsToo } }).then(response => {
        if (response.status === 200) {
          logoutUser();
          handleClose();
          dispatch(setAlertsSnackbarSeverity('info'));
          dispatch(setAlertsSnackbarText(t('Your account has been deleted')));
          dispatch(setAlertsSnackbarOpen(true));
        };
      }).catch(err => {
        dispatch(setAlertsSnackbarSeverity('error'));
        dispatch(setAlertsSnackbarText(t('Something went wrong.')));
        dispatch(setAlertsSnackbarOpen(true));

        setTimeout(() => {
          setDeleteButtonDisabled(false);
        }, 1500);
      });
  };

  const handleDeletePinsTooChange = () => {
    setDeletePinsToo(!deletePinsToo);
  };

  return (
    <Dialog
      open={open}
      PaperComponent={PaperComponent}
      {...borderedDialogPaperProps}
    >
      <DialogTitle textAlign="center">
        {t('Confirm delete')}
      </DialogTitle>
      <CloseButton onClick={handleClose} />
      <form>
        <DialogContent>
          <Typography>
          </Typography>
          <DialogContentText id="alert-dialog-description" mb={2}>
            {t('Are you sure you want to delete your account?')}
          </DialogContentText>
          <FormControlLabel
            control={
              <Checkbox
                id="intro"
                onChange={handleDeletePinsTooChange}
                checked={deletePinsToo}
              />
            }
            label={
              <Typography variant="body2">
                {t('Delete all my pins too')}
              </Typography>}
          />
        </DialogContent>
        <StyledDialogActions>
          <Button
            variant="outlined"
            startIcon={<Clear />}
            onClick={handleClose}>
            {t('Cancel')}
          </Button>
          <Button
            disabled={deleteButtonDisabled}
            onClick={handleRemoveClick}
            variant="outlined"
            endIcon={<Check />}
            color="error">
            {t('Delete')}
          </Button>
        </StyledDialogActions>
      </form>
    </Dialog>
  );
};

export default ConfirmDeleteAccountDialog;