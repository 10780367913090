import React from 'react';
import { useDispatch } from 'react-redux';

import ForwardToInboxOutlinedIcon from '@mui/icons-material/ForwardToInboxOutlined';
import IconButton from '@mui/material/IconButton';

import {
  setInviteFriendDialogOpen,
  setInviteFriendDialogUser,
} from '../../features/dialogs/inviteFriendDialogSlice.ts';

import { User } from '../../types/User/types';

interface InviteFriendButtonProps {
  user: User;
};

const InviteFriendButton: React.FC<InviteFriendButtonProps> = ({ user }) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(setInviteFriendDialogUser(user));
    dispatch(setInviteFriendDialogOpen(true));
  };

  return (
    <IconButton onClick={handleClick} sx={{ marginLeft: 'auto' }}>
      <ForwardToInboxOutlinedIcon />
    </IconButton>
  )
};

export default InviteFriendButton;