import { createSlice } from '@reduxjs/toolkit';

import { EMPTY_FEATURE } from '../../consts/map';

export const userDialogSlice = createSlice({
  name: 'userDialog',
  initialState: {
    open: false,
    user: EMPTY_FEATURE,
  },
  reducers: {
    resetUserDialogUser: (state) => {
      state.user = EMPTY_FEATURE;
    },
    setUserDialogUser: (state, action) => {
      state.user = action.payload
    },
    setUserDialogOpen: (state, action) => {
      state.open = action.payload
    }
  }
})

export const {
  resetUserDialogUser,
  setUserDialogOpen,
  setUserDialogUser,
} = userDialogSlice.actions;

export default userDialogSlice.reducer;
