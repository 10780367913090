import axios, { AxiosRequestConfig } from 'axios';
import { setupInterceptorsTo } from './interceptors';
import { API_URL } from '../config';

const baseURL = API_URL;

const api = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use((config: AxiosRequestConfig) => {
  const language = localStorage.getItem('language') || 'en';
  if (!config.headers) {
    config.headers = {};
  };
  config.headers['Accept-Language'] = language;
  return config;
}, (error) => {
  return Promise.reject(error);
});

setupInterceptorsTo(api);

export default api;
