import { useDispatch } from 'react-redux';

import IconButton from '@mui/material/IconButton';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';

import {
  setReportDialogFeature,
  setReportDialogOpen,
  setReportDialogSubject,
} from '../../features/dialogs/reportDialogSlice';

export default function ReportButton(props) {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(setReportDialogSubject(props.subject));
    dispatch(setReportDialogFeature(props.feature));
    dispatch(setReportDialogOpen(true));
  };

  return (
    <IconButton
      // onClick={handleClick}
      color="error"
      aria-label="share">
      <ReportGmailerrorredIcon />
    </IconButton>
  );
};