import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { format, subDays } from 'date-fns';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HistoryIcon from '@mui/icons-material/History';
import List from '@mui/material/List';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import UpdateIcon from '@mui/icons-material/Update';

import api from '../../utils/api.ts';

import { a11yProps, TabPanel } from '../ui/Tabs';

import { RANGE_CALENDAR_DATE_FORMAT } from '../../consts/app';
import { setBottomBarValue } from '../../features/app/appSlice';
import {
  setEventInvitationsDialogOpen,
  setNetworkDialogOpen,
  setProfileDialogOpen,
  setSavedEventsDialogOpen,
  setSettingsDialogOpen,
  setUserEventsDialogOpen,
} from '../../features/app/dialogsSlice';
import {
  setSavedEvents,
  setSavedPastEvents,
} from '../../features/events/savedEventsSlice';
import { disableCalendarDays } from '../../utils/eventsUtils';

import { EMPTY_GEOJSON } from '../../consts/map';
import {
  customDialogStyle,
  WIDTH_BREAKPOINT,
} from '../../theme';

import CalendarDialog from './CalendarDialog';
import CloseButton from '../ui/CloseButton.tsx';
import EventCard from './EventCard';
import EventCardSkeletonList from './EventCardSkeletonList.tsx';
import EventCardWrapper from './EventCardWrapper.tsx';
import NoContentAlert from '../ui/NoContentAlert.tsx';
import NoContentAlertWrapper from '../ui/NoContentAlertWrapper.tsx';
import SearchInput from '../ui/SearchInput.tsx';
import SmallLoader from '../ui/SmallLoader.tsx';
import TabsWrapper from '../ui/TabsWrapper.tsx';

import {
  Event,
  EventGeojson,
} from '../../types/Event/types.ts';

const SavedEventsDialog: React.FC = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const width = useSelector((state: any) => state.app.width);

  const [searchInput, setSearchInput] = useState('');
  const savedEvents = useSelector((state: any) => state.savedEvents.value);
  const savedPastEvents = useSelector((state: any) => state.savedEvents.past);
  const savedOngoingEvents = {
    type: 'FeatureCollection',
    features: savedEvents.features.filter((f: Event) => f.properties.timeline === 'ongoing'),
  };
  const [savedOngoingFilteredEvents, setSavedOngoingFilteredEvents] = useState<EventGeojson>(EMPTY_GEOJSON);

  const savedUpcomingEvents = {
    type: 'FeatureCollection',
    features: savedEvents.features.filter((f: Event) => f.properties.timeline === 'upcoming'),
  };
  const [savedUpcomingFilteredEvents, setSavedUpcomingFilteredEvents] = useState<EventGeojson>(EMPTY_GEOJSON);

  const [savedPastEventsReady, setSavedPastEventsReady] = useState(false);
  const [savedEventsReady, setSavedEventsReady] = useState(false);
  const [loading, setLoading] = useState(true);

  const [tabIndex, setTabIndex] = useState(1);

  const open = useSelector((state: any) => state.dialogs.savedEventsDialogOpen);

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [past, setPast] = useState(false);

  const [dateRangeDialogOpen, setDateRangeDialogOpen] = useState(false);
  const [calendarState, setCalendarState] = useState([
    {
      startDate: subDays(new Date(), 7),
      endDate: new Date(),
      key: 'selection'
    }
  ]);

  const handleClose = () => {
    dispatch(setSavedEventsDialogOpen(false));
    dispatch(setBottomBarValue(1));
  };

  const getSavedEvents = async (params: any = {}) => {
    const query = { params: params };
    setLoading(true);
    await api.get('/user/events/saved/', query).then(response => {
      if (params.past) {
        dispatch(setSavedPastEvents(response.data));
        setSavedPastEventsReady(true);
      } else {
        dispatch(setSavedEvents(response.data));
        setSavedEventsReady(true);
      };
      setTotalPages(response.data.description.pages);
      disableCalendarDays(false);
      setLoading(false);
    });
  };

  const handleTabChange = (e: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const handleChangePage = (e: React.ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage);
  };

  useEffect(() => {
    if (open) {
      dispatch(setBottomBarValue(0));
      setTimeout(() => {
        dispatch(setEventInvitationsDialogOpen(false));
        dispatch(setNetworkDialogOpen(false));
        dispatch(setProfileDialogOpen(false));
        dispatch(setSettingsDialogOpen(false));
        dispatch(setUserEventsDialogOpen(false));
      }, 100);
    } else {
      setTimeout(() => {
        setTabIndex(1);
        setPast(false);
        setSearchInput('');
      }, 500);
    };
  }, [open]);

  useEffect(() => {
    setPast(tabIndex === 0);
  }, [tabIndex]);

  useEffect(() => {
    let params: any = { past: past };
    if (past) {
      params.page = page;
      params.start_date = format(calendarState[0].startDate, RANGE_CALENDAR_DATE_FORMAT);
      params.end_date = format(calendarState[0].endDate, RANGE_CALENDAR_DATE_FORMAT);
    };
    if (open) getSavedEvents(params);
  }, [past, page, open, calendarState]);

  useEffect(() => {
    setSavedOngoingFilteredEvents({
      type: 'FeatureCollection',
      features: savedEvents.features.filter((feature: Event) => {
        return feature.properties.timeline === 'ongoing' &&
          (searchInput.trim() === '' || feature.properties.name.toLowerCase().includes(searchInput.toLowerCase()));
      }),
    });
    setSavedUpcomingFilteredEvents({
      type: 'FeatureCollection',
      features: savedEvents.features.filter((feature: Event) => {
        return feature.properties.timeline === 'upcoming' &&
          (searchInput.trim() === '' || feature.properties.name.toLowerCase().includes(searchInput.toLowerCase()));
      }),
    });
  }, [savedEvents, searchInput]);

  return (
    <>
      <Dialog
        hideBackdrop={width <= WIDTH_BREAKPOINT}
        fullScreen={width <= WIDTH_BREAKPOINT}
        fullWidth
        open={open}
        onClose={handleClose}
        scroll="paper"
        PaperProps={{
          style: {
            borderRadius: width <= WIDTH_BREAKPOINT ? 'unset' : '25px',
            height: width <= WIDTH_BREAKPOINT ? '100%' : '80vh',
          }
        }}
        {...customDialogStyle}
      >
        <Box sx={{ marginBottom: width <= WIDTH_BREAKPOINT ? '12vh' : 'unset' }}>
          <DialogTitle textAlign="center">
            {t('Saved')}
            {loading && <SmallLoader />}
            <CloseButton onClick={handleClose} />
            <TabsWrapper>
              <Tabs value={tabIndex} onChange={handleTabChange} aria-label="basic tabs example" centered>
                <Tab icon={<HistoryIcon />} {...a11yProps(0)} />
                <Tab icon={<AccessTimeIcon />} {...a11yProps(1)} />
                <Tab icon={<UpdateIcon />} {...a11yProps(2)} />
              </Tabs>
            </TabsWrapper>
          </DialogTitle>
          <DialogContent>
            <Stack
              spacing={2}
              direction="column"
            >
              <TabPanel value={tabIndex} parentName="saved-events-dialog" index={0}>
                <Typography variant="subtitle1" gutterBottom
                  sx={{ display: 'flex', justifyContent: 'center' }}>
                  {t('Past')}
                </Typography>
                <Box display="flex" justifyContent="end" alignItems="center">
                  <Typography variant="caption">{t('Show pins from')}:&nbsp;&nbsp;</Typography>
                  <Button
                    variant="outlined"
                    color="secondary"
                    endIcon={dateRangeDialogOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    onClick={() => setDateRangeDialogOpen(true)}
                    sx={{
                      borderRadius: '10px',
                      border: '1px solid #fff',
                      padding: '0.1rem 0.5rem',
                      color: '#fff',
                    }}
                  >
                    {format(calendarState[0].startDate, RANGE_CALENDAR_DATE_FORMAT)} - {format(calendarState[0].endDate, RANGE_CALENDAR_DATE_FORMAT)}
                  </Button>
                </Box>
                {savedPastEventsReady ? (
                  savedPastEvents.features.length > 0 ? (
                    <>
                      <List sx={{ width: '100%' }}>
                        {savedPastEvents.features.map((feature: Event) => (
                          <EventCardWrapper key={feature.properties.uuid}>
                            <EventCard feature={feature} save report />
                          </EventCardWrapper>
                        ))}
                      </List>
                      {totalPages > 1 && (
                        <Box display="flex" justifyContent="center">
                          <Pagination
                            count={totalPages}
                            page={page}
                            onChange={handleChangePage}
                            color="primary"
                          />
                        </Box>
                      )}
                    </>
                  ) : (
                    <NoContentAlertWrapper top={width <= WIDTH_BREAKPOINT ? '45%' : '50%'}>
                      <NoContentAlert text={t("You don't have any saved past pins during selected period!") as string} />
                    </NoContentAlertWrapper>
                  )) : (
                  <EventCardSkeletonList />
                )}
              </TabPanel>
              <TabPanel value={tabIndex} parentName="saved-events-dialog" index={1}>
                <Typography variant="subtitle1" gutterBottom
                  sx={{ display: 'flex', justifyContent: 'center' }}>
                  {t('Ongoing')}
                </Typography>
                <SearchInput searchInput={searchInput} setSearchInput={setSearchInput} />
                {savedEventsReady ? (
                  savedOngoingEvents.features.length > 0 ? (
                    savedOngoingFilteredEvents.features.length > 0 ? (
                      <List sx={{ width: '100%' }}>
                        {savedOngoingFilteredEvents.features.map((feature: Event) => (
                          <EventCardWrapper key={feature.properties.uuid}>
                            <EventCard feature={feature} save report />
                          </EventCardWrapper>
                        ))}
                      </List>
                    ) : (
                      <Box mt={2}>
                        <NoContentAlert text={t('No results!') as string} spacing={0} />
                      </Box>
                    )
                  ) : (
                    <NoContentAlertWrapper top={width <= WIDTH_BREAKPOINT ? '45%' : '50%'}>
                      <NoContentAlert text={t("You don't have any saved ongoing pins!") as string} />
                      <br />
                      <Button variant="outlined" onClick={handleClose}>
                        {t('Save one')}
                      </Button>
                    </NoContentAlertWrapper>
                  )) : (
                  <EventCardSkeletonList />
                )}
              </TabPanel>
              <TabPanel value={tabIndex} parentName="saved-events-dialog" index={2}>
                <Typography variant="subtitle1" gutterBottom
                  sx={{ display: 'flex', justifyContent: 'center' }}>
                  {t('Upcoming')}
                </Typography>
                <SearchInput searchInput={searchInput} setSearchInput={setSearchInput} />
                {savedEventsReady ? (
                  savedUpcomingEvents.features.length > 0 ? (
                    savedUpcomingFilteredEvents.features.length > 0 ? (
                      <List sx={{ width: '100%' }}>
                        {savedUpcomingFilteredEvents.features.map((feature: Event) => (
                          <EventCardWrapper key={feature.properties.uuid}>
                            <EventCard feature={feature} save report />
                          </EventCardWrapper>
                        ))}
                      </List>
                    ) : (
                      <Box mt={2}>
                        <NoContentAlert text={t('No results!') as string} spacing={0} />
                      </Box>
                    )
                  ) : (
                    <NoContentAlertWrapper top={width <= WIDTH_BREAKPOINT ? '45%' : '50%'}>
                      <NoContentAlert text={t("You don't have any saved upcoming pins!") as string} />
                      <br />
                      <Button variant="outlined" onClick={handleClose}>
                        {t('Save one')}
                      </Button>
                    </NoContentAlertWrapper>
                  )) : (
                  <EventCardSkeletonList />
                )}
              </TabPanel>
            </Stack>
          </DialogContent>
        </Box>
      </Dialog>
      <CalendarDialog
        open={dateRangeDialogOpen}
        handleClose={() => setDateRangeDialogOpen(false)}
        state={calendarState}
        setState={setCalendarState}
      />
    </>
  );
};

export default SavedEventsDialog;