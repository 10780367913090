import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';

import InfiniteScroll from 'react-infinite-scroll-component';
import { initializeApp } from 'firebase/app';
import { getMessaging, onMessage } from 'firebase/messaging';

import Backdrop from '@mui/material/Backdrop';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Typography from '@mui/material/Typography';

import api from '../../utils/api.ts';
import {
  getEventInvitation,
  getFriend,
  getFriendRequest,
  getNewNotifications,
  getNotification,
} from '../../utils/getters';

import { GET_NOTIFICATIONS_INTERVAL } from '../../consts/intervals';
import { APP_NAME } from '../../config';

import AuthContext from '../../context/AuthContext';

import {
  setBannedPinDialogOpen,
  setConfirmClearNotificationsDialogOpen,
  setEventInvitationsDialogOpen,
  setNetworkDialogOpen,
} from '../../features/app/dialogsSlice';
import {
  addNotifications,
  removeNotificationByUuid,
  resetNotifications,
  setNotificationsPanelOpen,
} from '../../features/app/notificationsSlice';
import { updateSavedEventByUuid } from '../../features/events/savedEventsSlice';
import { removeUserEventByUuid } from '../../features/events/userEventsSlice';

import FullBox from './FullBox.tsx';
import NoContentAlert from './NoContentAlert.tsx';
import NotificationCard from './NotificationCard.tsx';
import NotificationCardSkeleton from './NotificationCardSkeleton.tsx';
import NotificationCardSkeletonList from './NotificationCardSkeletonList.tsx';

import { FIREBASE_CONFIG } from '../../config';
import {
  navbarBoxShadow,
  WIDTH_BREAKPOINT,
} from '../../theme';
import {
  closeDrawer,
  registerMessagingToken,
  vhToPx,
} from '../../utils/utils';

const NotificationTimelineWrapper = styled(MenuItem)`
  padding-left: 20px;
  opacity: unset !important;
`;

const NotificationCardSkeletonWrapper = styled(Box)`
  padding: 6px 16px;
`;

const ClearAllButtonWrapper = styled(MenuItem)`
  width: min-content;
  margin-left: auto;
`;

const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
  zIndex: theme.zIndex.drawer,
  backgroundColor: 'transparent',
  // top: '8vh',
}));

const NotificationsPanel: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const width = useSelector((state: any) => state.app.width);

  const { user } = useContext(AuthContext);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);;

  const notifications = useSelector((state: any) => state.notifications.value);
  const groupedNotifications = useRef({});
  const newNotifications = useSelector((state: any) => state.notifications.new);
  const [alreadyOpened, setAlreadyOpened] = useState(false);

  const [intervalId, setIntervalId] = useState<ReturnType<typeof setInterval> | undefined>();

  const notificationsAllowed = useSelector((state: any) => state.notifications.notificationsAllowed);

  const [currentPage, setCurrentPage] = useState(2);
  const [hasMore, setHasMore] = useState(true);

  const [notificationsReady, setNotificationsReady] = useState(false);

  const drawerRightOpen = useSelector((state: any) => state.app.drawerRightOpen);
  const anyDialogOpen = useSelector((state: any) => state.dialogs.anyDialogOpen);
  // || eventDialogOpen;

  const fetchData = async () => {
    await getNewNotifications(dispatch);
    await api.get(`/notifications/?new=${false}&page=${1}`).then(response => {
      dispatch(addNotifications(response.data));
      setNotificationsReady(true);
    });
  };

  const postNotifications = async () => {
    if (user) {
      await api.post('/notifications/', [
        ...newNotifications,
        ...notifications,
      ].map(n => n.uuid));
    };
  };

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
    fetchData();

    if ('Notification' in window) {
      Notification.requestPermission()
        .then(permission => {
          console.log('request')
          if (permission === 'granted') {
            // navigator.serviceWorker.register('/service-worker.js');
            navigator.serviceWorker.register('/firebase-messaging-sw.js');
            console.log('Permission granted for push notifications');

            registerMessagingToken();

          } else {
            console.warn('Permission denied for push notifications');
          };
        })
        .catch(error => {
          console.error('Error requesting permission:', error);
        });
    };
  };

  const handleDeleteClick = async (uuid: string) => {
    await api.delete(`/notifications/${uuid}/`).then(response => {
      if (response.status === 200) {
        dispatch(removeNotificationByUuid(uuid));
      };
    });
  };

  const handleClearAllClick = () => {
    dispatch(setConfirmClearNotificationsDialogOpen(true));
  };

  const handleMenuClose = () => {
    closeDrawer(dispatch);
    setAnchorEl(null);
  };

  const handleNotificationClick = (subject: string) => {
    if (subject === 'eventInvitation') {
      handleMenuClose();
      dispatch(setNetworkDialogOpen(false));
      dispatch(setEventInvitationsDialogOpen(true));
    } else if (subject === 'friendRequest') {
      handleMenuClose();
      dispatch(setEventInvitationsDialogOpen(false));
      dispatch(setNetworkDialogOpen(true));
    } else if (subject === 'eventBanned') {
      handleMenuClose();
      dispatch(setBannedPinDialogOpen(true));
    };
  };

  const handlePullToRefresh = () => {
    console.log('handlePullToRefresh')
  };

  const fetchMoreItems = () => {
    setTimeout(async () => {
      await api.get(`/notifications/?new=${false}&page=${currentPage}`).then(response => {
        if (response.data.length > 0) {
          dispatch(addNotifications(response.data));
          setCurrentPage((prevPage) => prevPage + 1);
        } else {
          setHasMore(false);
        };
      });
    }, 500);
  };

  const isMenuOpen = Boolean(anchorEl);

  useEffect(() => {
    if (!isMenuOpen && alreadyOpened) {
      postNotifications();
      setTimeout(() => {
        dispatch(resetNotifications());
        setHasMore(true);
        setCurrentPage(2);
      }, 500);
    };
    if (isMenuOpen) {
      setAlreadyOpened(true);
      groupedNotifications.current = {};
      if (navigator.serviceWorker) {
        navigator.serviceWorker.ready.then((registration) => {
          registration.getNotifications().then((existingNotifications) => {
            existingNotifications.forEach((notification) => {
              notification.close();
            });
          });
        });
      };
    };
  }, [isMenuOpen]);

  useEffect(() => {
    var interval: ReturnType<typeof setInterval> | undefined;
    if (!notificationsAllowed) {
      if (user) {
        interval = setInterval(() => {
          getNewNotifications(dispatch);
        }, GET_NOTIFICATIONS_INTERVAL);
        setIntervalId(interval);

      } else {
        clearInterval(intervalId);
        setIntervalId(undefined);
      };
    };
    return () => {
      clearInterval(interval);
    };
  }, [user, notificationsAllowed]);

  useEffect(() => {
    initializeApp(FIREBASE_CONFIG);
  }, []);

  useEffect(() => {
    if (user && navigator.serviceWorker) {
      const messaging = getMessaging();

      onMessage(messaging, (payload) => {
        // console.log('onMessage', payload)
        navigator.serviceWorker.ready.then(function (registration) {
          let data = payload.data!;
          let tag = data.subject;
          let count = groupedNotifications.current[tag] ? groupedNotifications.current[tag].count + 1 : 1;

          if (groupedNotifications.current[tag]) {
            registration.getNotifications({ tag: tag }).then(function (existingNotifications) {
              existingNotifications.forEach(function (notification) {
                notification.close();
              });
            });
          }

          let title = count > 1 ? data.multipleTitle : data.title;
          let body = count > 1 ? `${data.multipleBody}: ${count}` : data.body;

          registration.showNotification(title, {
            body: body,
            icon: data.icon || 'logo-192.png',
            badge: 'badge-96.png',
            data: { url: data.url },
            tag: tag
          });

          groupedNotifications.current[tag] = { count: count };
        });

        getNotification(dispatch, payload.data!.uuid);
        if (payload.data!.subject === 'event') {
          const status = parseInt(payload.data!.status);
          switch (status) {
            // cancelled
            case -1:
              const updateData = {
                uuid: payload.data!.subjectUuid,
                newProperties: { cancelled: true },
              };
              dispatch(updateSavedEventByUuid(updateData));
              break;
            // banned
            case -2:
              dispatch(removeUserEventByUuid(payload.data!.subjectUuid));
              break;
            default:
              break
          };
        } else if (payload.data!.subject === 'eventInvitation') {
          if (parseInt(payload.data!.status) == 0) {
            getEventInvitation(dispatch, payload.data!.subjectUuid);
          };
        } else if (payload.data!.subject === 'friendRequest') {
          if (parseInt(payload.data!.status) == 0) {
            getFriendRequest(dispatch, payload.data!.subjectUuid);
          } else if (parseInt(payload.data!.status) == 1) {
            getFriend(dispatch, payload.data!.subjectUuid);
          };
          // TODO: removeUserByUuid
        };
      });
    };
  }, [user]);

  useEffect(() => {
    dispatch(setNotificationsPanelOpen(isMenuOpen));
    if (!isMenuOpen) {
      setTimeout(() => {
        setNotificationsReady(false);
      }, 500);
    };
  }, [isMenuOpen]);

  useEffect(() => {
    document.title = newNotifications.length > 0 ? `(${newNotifications.length}) ${APP_NAME}` : APP_NAME;
    return () => {
      document.title = APP_NAME;
    };
  }, [newNotifications]);

  const floating = width <= WIDTH_BREAKPOINT && !anyDialogOpen && !drawerRightOpen;

  return user && (
    <React.Fragment>
      <IconButton
        onClick={handleClick}
        size="large"
        // aria-label="show 17 new notifications"
        color="inherit"
      >
        <Badge badgeContent={newNotifications.length} color="primary">
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        id="notifications-panel"
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isMenuOpen || false}
        onClose={handleMenuClose}
        PaperProps={{
          sx: {
            backgroundImage: 'unset',
            // borderRadius: '0 0 25px 25px',
            borderRadius: '25px',
            // top: '9vh',
            width: width <= WIDTH_BREAKPOINT ? '95vw' : '30vw',
            maxHeight: '50vh',
            /* WebKit */
            '::-webkit-scrollbar': {
              width: 0,
              height: 0,
            },
            scrollbarWidth: 'none', /* Firefox */
            msOverflowStyle: 'none',  /* Internet Explorer 10+ */
            filter: 'drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.32))',
            boxShadow: navbarBoxShadow,
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 19,
              width: 10,
              height: 10,
              bgcolor: 'inherit',
              backgroundImage: 'inherit',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        sx={{
          top: floating ? '2vh' : '1vh',
        }}
      >
        <StyledBackdrop open={!notificationsReady} />
        <InfiniteScroll
          dataLength={notifications.length + newNotifications.length}
          next={fetchMoreItems}
          hasMore={hasMore}
          refreshFunction={handlePullToRefresh}
          // pullDownToRefresh
          // pullDownToRefreshThreshold={50}
          // pullDownToRefreshContent={
          //   <h3 style={{ textAlign: 'center' }}>Pull down to refresh</h3>
          // }
          // releaseToRefreshContent={
          //   <h3 style={{ textAlign: 'center' }}>Release to refresh</h3>
          // }
          scrollableTarget="notifications-panel"
          // height={vhToPx(50)}
          height={300}
          loader={(
            <NotificationCardSkeletonWrapper>
              <NotificationCardSkeleton />
            </NotificationCardSkeletonWrapper>
          )}
        >
          {!notificationsReady && (
            <>
              <MenuItem />
              <MenuItem />
              <NotificationCardSkeletonList />
            </>
          )}
          {newNotifications.length + notifications.length === 0 ?
            <FullBox>
              <MenuItem onClick={handleMenuClose}>
                <NoContentAlert text={t('No notifications!') as string} />
              </MenuItem>
            </FullBox>
            :
            <ClearAllButtonWrapper>
              <Button
                variant="outlined"
                size="small"
                onClick={handleClearAllClick}
              >
                {t('Clear all')}
              </Button>
            </ClearAllButtonWrapper>
          }
          {newNotifications.length > 0 && (
            <NotificationTimelineWrapper disabled>
              <Typography>{t('New')}</Typography>
            </NotificationTimelineWrapper>
          )}
          {newNotifications.map(notification => (
            <MenuItem key={notification.uuid}>
              <NotificationCard
                notification={notification}
                handleDeleteClick={handleDeleteClick}
                handleNotificationClick={handleNotificationClick}
              />
            </MenuItem>
          ))}
          {notifications.length > 0 && (
            <NotificationTimelineWrapper disabled>
              <Typography>{t('Older')}</Typography>
            </NotificationTimelineWrapper>
          )}
          {notifications.map(notification => (
            <MenuItem key={notification.uuid}>
              <NotificationCard
                notification={notification}
                handleDeleteClick={handleDeleteClick}
                handleNotificationClick={handleNotificationClick}
              />
            </MenuItem>
          ))}
        </InfiniteScroll>
      </Menu>
    </React.Fragment>
  );
};

export default NotificationsPanel;