import { createSlice } from '@reduxjs/toolkit';

export const appSlice = createSlice({
  name: 'app',
  initialState: {
    bottomBarValue: 1,
    cameraAllowed: null,
    drawerRightOpen: false,
    fabSize: 'small',
    installed: null,
    width: window.innerWidth,
  },
  reducers: {
    setBottomBarValue: (state, action) => {
      state.bottomBarValue = action.payload;
    },
    setCameraAllowed: (state, action) => {
      state.cameraAllowed = action.payload;
    },
    setDrawerRightOpen: (state, action) => {
      state.drawerRightOpen = action.payload;
    },
    setFabSize: (state, action) => {
      state.fabSize = action.payload;
    },
    setInstalled: (state, action) => {
      state.installed = action.payload;
    },
    setWidth: (state, action) => {
      state.width = action.payload;
    },
  },
})

export const {
  setBottomBarValue,
  setCameraAllowed,
  setDrawerRightOpen,
  setFabSize,
  setInstalled,
  setWidth,
} = appSlice.actions;

export default appSlice.reducer;
