import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import axios from 'axios';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { API_URL } from '../config';
import AuthContext from '../context/AuthContext';

import {
  brightOrange,
  DRAWER_WIDTH,
  sunsetOrange,
  WIDTH_BREAKPOINT,
} from '../theme';

import {
  setAlertsSnackbarOpen,
  setAlertsSnackbarSeverity,
  setAlertsSnackbarText,
} from '../features/app/alertsSnackbarSlice';

import BackButton from '../components/ui/BackButton.tsx';
import CenteredBox from '../components/ui/CenteredBox.tsx';
import Layout from '../components/ui/Layout.tsx';
import NoContentAlert from '../components/ui/NoContentAlert.tsx';
import PasswordValidation from '../components/user/PasswordValidation.tsx';
import ShowPasswordButton from '../components/ui/ShowPasswordButton.tsx';
import SmallLoader from '../components/ui/SmallLoader.tsx';

const NewPassword: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const width = useSelector((state: any) => state.app.width);

  const { setNewPassword } = useContext(AuthContext);
  const [showAlert, setShowAlert] = useState(false);
  const [alertColor, setAlertColor] = useState('');
  const [alertText, setAlertText] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [passwordChecklistVisibility, setPasswordChecklistVisbility] = useState('hidden');
  const [passwordsValid, setPasswordsValid] = useState(false)

  const [showPassword, setShowPassword] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const _t = searchParams.get('t');
  const _u = searchParams.get('u');
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const handleNewPasswordFormSubmit = async (e) => {
    e.preventDefault();
    if (passwordsValid) {
      setButtonDisabled(true);
      let response = await setNewPassword(_t, _u, password1, password2);
      if (response.status === 200) {
        dispatch(setAlertsSnackbarSeverity('success'));
        dispatch(setAlertsSnackbarText(t('New password set, you can now log in')));
        dispatch(setAlertsSnackbarOpen(true));
        // redirect ?
      } else if (response.status === 204) {
        dispatch(setAlertsSnackbarSeverity('warning'));
        dispatch(setAlertsSnackbarText(t('Invalid or used token')));
        dispatch(setAlertsSnackbarOpen(true));
      } else {
        dispatch(setAlertsSnackbarSeverity('error'));
        dispatch(setAlertsSnackbarText(t('Something went wrong, please retry')));
        dispatch(setAlertsSnackbarOpen(true));
        setButtonDisabled(false);
      };
    };
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const endAdornment = (
    <InputAdornment position="end">
      <ShowPasswordButton
        visible={showPassword}
        onClick={handleClickShowPassword}
      />
    </InputAdornment>
  );

  const checkToken = async () => {
    if (!(_t && _u)) {
      navigate('/');
    } else {
      await axios.post(`${API_URL}/token/check/`, {
        't': _t,
        'u': _u,
      }).then(response => {
        if (response.status === 204) {
          setAlertText(t('Invalid or used token') as string);
          setAlertColor(brightOrange);
          setShowAlert(true);
          setButtonDisabled(true);
        };
        setLoading(false);
      }).catch(err => {
        setAlertText(t('Something went wrong, please retry') as string);
        setAlertColor(sunsetOrange);
        setShowAlert(true);
        setButtonDisabled(true);
        setLoading(false);
      });
    };
  };

  useEffect(() => {
    if (password1 === '' && password2 === '') setPasswordChecklistVisbility('hidden');
  }, [password1, password2]);

  useEffect(() => {
    checkToken();
  }, []);

  return (
    <Layout>
      <Box sx={{
        flexGrow: 1,
        paddingLeft: width > WIDTH_BREAKPOINT ? `${DRAWER_WIDTH + 16}px` : undefined,
        backgroundColor: 'unset',
        backgroundImage: 'linear-gradient(#171717, #191919)',
        height: '92vh',
      }}>
        <Grid container>
          <Grid item xs={2}>
            <CenteredBox>
              <BackButton onClick={() => window.location.href = '/'} />
            </CenteredBox>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h5" gutterBottom mt={2} align="center">
              {t('Set new password')}{loading && <SmallLoader />}
            </Typography>
          </Grid>
          <Grid item xs={2} />
        </Grid>
        <Grid container spacing={2} pt={10}>
          <Grid item xs={width <= WIDTH_BREAKPOINT ? 2 : 3} />
          <Grid item xs={width <= WIDTH_BREAKPOINT ? 8 : 6}>
            <CenteredBox mt={4}>
              {showAlert ? (
                <NoContentAlert text={alertText} color={alertColor} />
              ) : (
                <Typography variant="body2">{t('You can set new password')}</Typography>
              )}
            </CenteredBox>
            <form onSubmit={handleNewPasswordFormSubmit} style={{ marginTop: '4rem' }}>
              <TextField
                id="password1"
                label={t('Password')}
                type={showPassword ? 'text' : 'password'}
                // value={password}
                fullWidth
                autoComplete="current-password"
                variant="standard"
                onChange={e => {
                  setPassword1(e.target.value);
                  setPasswordChecklistVisbility('visible');
                }}
                required
                InputProps={{ endAdornment: endAdornment }}
              />
              <TextField
                id="password2"
                label={t('Confirm password')}
                type={showPassword ? 'text' : 'password'}
                // value={password2}
                fullWidth
                autoComplete="current-password"
                variant="standard"
                onChange={e => {
                  setPassword2(e.target.value);
                  setPasswordChecklistVisbility('visible');
                }}
                required
                InputProps={{ endAdornment: endAdornment }}
              />
              <PasswordValidation
                password1={password1}
                password2={password2}
                visibility={passwordChecklistVisibility}
                setValid={setPasswordsValid}
              />
              <CenteredBox pt={5}>
                <Button type="submit" disabled={buttonDisabled} variant="contained">
                  {t('Confirm')}
                </Button>
              </CenteredBox>
            </form>
          </Grid>
          <Grid item xs={width <= WIDTH_BREAKPOINT ? 2 : 3} />
        </Grid>
      </Box>
    </Layout>
  );
};

export default NewPassword;