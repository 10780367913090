import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import {
  borderedDialogPaperProps,
  WIDTH_BREAKPOINT,
} from '../../theme';

import { setEventInvitationDialogOpen } from '../../features/dialogs/eventInvitationDialogSlice';

import CloseButton from '../ui/CloseButton.tsx';
import EventInvitationCard from './EventInvitationCard';
import PaperComponent from '../PaperComponent';

export default function EventInvitationDialog(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const width = useSelector((state) => state.app.width);

  const open = useSelector((state) => state.eventInvitationDialog.open);
  const invitation = useSelector((state) => state.eventInvitationDialog.invitation);

  const handleClose = () => {
    dispatch(setEventInvitationDialogOpen(false));
  };

  return (
    <Dialog
      open={open}
      PaperComponent={PaperComponent}
      hideBackdrop={false}
      onClose={handleClose}
      // aria-labelledby="alert-dialog-title"
      aria-labelledby="draggable-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: {
          ...borderedDialogPaperProps.PaperProps.style,
          maxHeight: width <= WIDTH_BREAKPOINT ? '75vh' : 'unset',
        }
      }}
    >
      <DialogTitle
        id="draggable-dialog-title"
        textAlign="center"
        sx={{
          cursor: 'move',
          wordBreak: 'break-word',
        }}>
        {t('Pin invitation')}
      </DialogTitle>
      <CloseButton onClick={handleClose} />
      <DialogContent sx={{ padding: '0 0.5rem' }}>
        <EventInvitationCard
          invitation={invitation}
          handleAction={props.handleAction}
          actionButtonDisabled={props.actionButtonDisabled}
          handleShowOnMapClick={props.handleShowOnMapClick}
        />
      </DialogContent>
    </Dialog>
  )
};
