import React from 'react';
import { useDispatch } from 'react-redux';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import {
  setImageDialogAlt,
  setImageDialogOpen,
  setImageDialogSrc,
} from '../../features/dialogs/imageDialogSlice';

import BigAvatar from './BigAvatar.tsx';

interface FriendRequestCardMinProps {
  friendRequest: {
    username: string;
    firstName: string;
    lastName: string;
    picture: string;
    uuid: string;
  };
  handleAction: (e, friendRequest, action) => void;
  actionButtonDisabled: boolean;
};

const FriendRequestCardMin: React.FC<FriendRequestCardMinProps> = ({
  friendRequest,
  handleAction,
  actionButtonDisabled,
}) => {
  const dispatch = useDispatch();

  // const handleTitleClick = () => {
  //   dispatch(setEventInvitationDialogInvitation(props.invitation));
  //   dispatch(setEventInvitationDialogOpen(true));
  // };

  const handleUserPictureClick = () => {
    if (friendRequest.picture) {
      dispatch(setImageDialogSrc(friendRequest.picture));
      dispatch(setImageDialogAlt(friendRequest.username));
      dispatch(setImageDialogOpen(true));
    };
  };

  return (
    <Card
      variant="outlined"
      sx={{
        width: '100%',
        borderRadius: '25px',
        backgroundColor: 'transparent',
        '& .MuiCardHeader-action': { margin: 'auto' },
        '& .MuiCardHeader-root': { paddingRight: 0 },
        // flex: '0 0 auto',
      }}>
      <CardHeader
        avatar={
          <BigAvatar
            src={friendRequest.picture}
            size="30px"
            onClick={handleUserPictureClick}
            sx={{ width: '50px', height: '50px' }}
          />
        }
        title={
          <Typography variant={friendRequest.username ? 'h6' : 'caption'}>
            {friendRequest.username || friendRequest.uuid}
          </Typography>
        }
        subheader={
          <Typography variant="caption">
            {[friendRequest.firstName, friendRequest.lastName].join(' ')}
          </Typography>
        }
        action={
          <React.Fragment>
            <IconButton
              disabled={actionButtonDisabled}
              aria-label="clear" color="error"
              onClick={(e) => handleAction(e, friendRequest.uuid, 'reject')}>
              <ClearIcon />
            </IconButton>
            <IconButton
              disabled={actionButtonDisabled}
              aria-label="check" color="success"
              onClick={(e) => handleAction(e, friendRequest.uuid, 'accept')}>
              <CheckIcon />
            </IconButton>
          </React.Fragment>
        }
      />
    </Card >
  );
};

export default FriendRequestCardMin;