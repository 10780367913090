import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import Typography from '@mui/material/Typography';

import CloseButton from './CloseButton.tsx';
import SmallLoader from './SmallLoader.tsx';
import PaperComponent from '../PaperComponent';

import api from '../../utils/api.ts';

import { borderedDialogPaperProps } from '../../theme';

import { setChangelogDialogOpen } from '../../features/app/dialogsSlice';

const DescriptionWrapper = styled(Typography)`
  white-space: pre-line;
`;

const StyledDivider = styled(Divider)`
  margin-top: 1rem;
`;

const StyledLibraryBooksIcon = styled(LibraryBooksIcon)`
  margin-right: 0.5rem;
`;

const StyledDialogContent = styled(DialogContent)`
  padding-top: 0;
  max-height: 40vh;
`;

const TitleWrapper = styled(Box)`
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

interface LogEntry {
  component_display: string;
  version: string;
  description: string;
  date: string;
};

const ChangelogDialog: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const open = useSelector((state: any) => state.dialogs.changelogDialogOpen);
  const [loading, setLoading] = useState(false);
  const [changelog, setChangelog] = useState<LogEntry[] | []>([]);

  const handleClose = () => {
    dispatch(setChangelogDialogOpen(false));
  };

  const getChangelog = async () => {
    setLoading(true);
    await api.get('/changelog/').then(response => {
      setChangelog(response.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    if (open) getChangelog();
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperComponent={PaperComponent}
      hideBackdrop={false}
      fullWidth
      aria-labelledby="draggable-dialog-title"
      aria-describedby="alert-dialog-description"
      {...borderedDialogPaperProps}
      sx={{ zIndex: (theme) => theme.zIndex.modal }}
    >
      <DialogTitle
        id="draggable-dialog-title"
        textAlign="center"
        style={{ cursor: 'move' }}
      >
        <TitleWrapper>
          <StyledLibraryBooksIcon />{t('Changelog')}
        </TitleWrapper>
        {loading && <SmallLoader />}
      </DialogTitle>
      <CloseButton onClick={handleClose} />
      <StyledDialogContent>
        <DialogContentText variant="subtitle2" textAlign="center">
          {t('This list contains all changes, updates, and bug fixes.')}
        </DialogContentText>
        {changelog.map((entry: LogEntry, idx: number) => (
          <Box key={idx} my={2}>
            <Typography variant="body1">
              {entry.component_display} {entry.version} ({entry.date})
            </Typography>
            <Box ml={2}>
              <DescriptionWrapper variant="caption">
                {entry.description.split('\r\n').join('\n')}
              </DescriptionWrapper>
            </Box>
            {idx !== changelog.length - 1 && <StyledDivider />}
          </Box>
        ))}
      </StyledDialogContent>
    </Dialog>
  )
};

export default ChangelogDialog;