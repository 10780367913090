import { createSlice } from '@reduxjs/toolkit';

export const imageDialogSlice = createSlice({
  name: 'imageDialog',
  initialState: {
    open: false,
    src: '',
    alt: '',
  },
  reducers: {
    setImageDialogOpen: (state, action) => {
      state.open = action.payload;
    },
    setImageDialogSrc: (state, action) => {
      state.src = action.payload;
    },
    setImageDialogAlt: (state, action) => {
      state.alt = action.payload;
    },
  }
})

export const {
  setImageDialogAlt,
  setImageDialogOpen,
  setImageDialogSrc,
} = imageDialogSlice.actions;

export default imageDialogSlice.reducer;
