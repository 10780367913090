import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Link from '@mui/material/Link';

import CloseButton from './CloseButton.tsx';
import PaperComponent from '../PaperComponent';

import { setCompanyAccountInfoDialogOpen } from '../../features/app/dialogsSlice.js';

import { CONTACT_EMAIL } from '../../config';
import { borderedDialogPaperProps } from '../../theme';

const CompanyAccountInfoDialog: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const open = useSelector((state: any) => state.dialogs.companyAccountInfoDialogOpen);

  const handleClose = () => {
    dispatch(setCompanyAccountInfoDialogOpen(false));
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperComponent={PaperComponent}
      hideBackdrop={false}
      aria-labelledby="draggable-dialog-title"
      aria-describedby="alert-dialog-description"
      {...borderedDialogPaperProps}
    >
      <DialogTitle textAlign="center" style={{ cursor: 'move' }} id="draggable-dialog-title">
        {t('Company account required')}
      </DialogTitle>
      <CloseButton onClick={handleClose} />
      <DialogContent>
        <DialogContentText variant="subtitle2">
          {t('If you are interested in our features for company account, \
please feel free to contact our sales team \
using')} <Link href="/contact/">{t('contact form')}</Link> {t('or contact \
email')} <Link href={`mailto:${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</Link>.
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default CompanyAccountInfoDialog;