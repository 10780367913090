import { createSlice } from '@reduxjs/toolkit';

import { EMPTY_FEATURE } from '../../consts/map';

export const confirmEndEventDialogSlice = createSlice({
  name: 'confirmEndEventDialog',
  initialState: {
    open: false,
    event: EMPTY_FEATURE,
  },
  reducers: {
    resetEndEventDialogEvent: (state) => {
      state.event = EMPTY_FEATURE;
    },
    setEndEventDialogEvent: (state, action) => {
      state.event = action.payload
    },
    setEndEventDialogOpen: (state, action) => {
      state.open = action.payload
    }
  }
})

export const {
  resetEndEventDialogEvent,
  setEndEventDialogEvent,
  setEndEventDialogOpen,
} = confirmEndEventDialogSlice.actions;

export default confirmEndEventDialogSlice.reducer;
