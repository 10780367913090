import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import dayjs from 'dayjs';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import PhotoOutlinedIcon from '@mui/icons-material/PhotoOutlined';
import PinDropIcon from '@mui/icons-material/PinDrop';
import Typography from '@mui/material/Typography';

import {
  setImageDialogAlt,
  setImageDialogOpen,
  setImageDialogSrc,
} from '../../features/dialogs/imageDialogSlice';

import { grayedOutFilter } from '../../theme';
import {
  getDistance,
  formatDistance,
} from '../../utils/mapUtils';

export default function EventInvitationCard(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const userPosition = useSelector((state) => state.userPosition.value);

  const handleEventPictureClick = (userEvent) => {
    dispatch(setImageDialogSrc(userEvent.properties.picture));
    dispatch(setImageDialogAlt(userEvent.properties.name));
    dispatch(setImageDialogOpen(true));
  };

  return (
    <Card
      id={props.invitation.event.properties.uuid}
      variant="outlined"
      sx={{
        width: '100%',
        borderRadius: '25px',
        backgroundColor: 'transparent',
        borderColor: !props.border && 'transparent',
      }}>
      <CardHeader
        avatar={
          props.invitation.event.properties.picture ? (
            <CardMedia
              component="img"
              image={props.invitation.event.properties.picture}
              alt={props.invitation.event.properties.name}
              onClick={() => handleEventPictureClick(props.invitation.event)}
              sx={{
                width: '100px',
                height: '100px',
                cursor: 'pointer',
                aspectRatio: '1/1',
                borderRadius: '10px',
              }}
            />
          ) : (
            <CardMedia sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100px',
              aspectRatio: '1/1',
              border: '1px solid #fff',
              borderRadius: '10px',
            }}>
              <Avatar sx={{ bgcolor: 'transparent' }} aria-label="recipe">
                <PhotoOutlinedIcon sx={{ color: '#fff', fontSize: '40px' }} />
              </Avatar>
            </CardMedia>
          )
        }
        title={
          <Typography
            // align="center"
            variant="h5"
            sx={{
              flexGrow: 1,
              // textAlign: 'center',
              // verticalAlign: 'middle',
              wordBreak: 'break-word',
            }}
          >
            {props.invitation.event.properties.name}
          </Typography >
        }
        subheader={
          <React.Fragment>
            {userPosition.length > 0 && (
              <Typography>
                {formatDistance(getDistance(props.invitation.event.geometry.coordinates, userPosition))}
              </Typography>
            )}
            <Typography>
              <span style={{ verticalAlign: 'middle' }}>{t('From')}:</span>&nbsp;<strong style={{
                filter: props.invitation.senderDeleted && grayedOutFilter,
                display: 'inline-flex',
                alignItems: 'center',
                verticalAlign: 'middle',
              }}>{props.invitation.senderDeleted && <PersonOffIcon fontSize="small" />}{props.invitation.username}</strong></Typography>
          </React.Fragment>
        }
      />
      <CardContent>
        <Typography variant="subtitle1">{t('Details')}:</Typography>
        <Box mb={1}>
          <Typography variant="caption">
            {props.invitation.event.properties.private ? t('Private pin') : t('Public pin')}
          </Typography>
        </Box>
        {!props.invitation.event.properties.anonymous && (
          <Typography>
            <span style={{ verticalAlign: 'middle' }}>{t('Created by')}:</span>&nbsp;<strong style={{
              filter: props.invitation.event.properties.advertiserDeleted && grayedOutFilter,
              display: 'inline-flex',
              alignItems: 'center',
              verticalAlign: 'middle',
            }}>{props.invitation.event.properties.advertiserDeleted && <PersonOffIcon fontSize="small" />}{props.invitation.event.properties.user}</strong>
          </Typography>
        )}
        <Grid container my={1}>
          <Grid item xs={10}>
            <Typography>
              {props.invitation.event.properties.address}
            </Typography>
          </Grid>
          {window.location.pathname === '/' && (
            <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <IconButton onClick={(e) => props.handleShowOnMapClick(e, props.invitation)}>
                <PinDropIcon />
              </IconButton>
            </Grid>
          )}
        </Grid>
        <Typography>
          <strong>
            {dayjs.unix(props.invitation.event.properties.start).format('L LT')} - {dayjs.unix(props.invitation.event.properties.end).format('L LT')}
          </strong>
        </Typography>
        {props.invitation.message && (
          <React.Fragment>
            <Typography variant="subtitle1" sx={{ marginTop: '1rem' }}>
              {t('Message')}:
            </Typography>
            <Typography sx={{ wordBreak: 'break-word' }}>
              {props.invitation.message}
            </Typography>
          </React.Fragment>
        )}
      </CardContent>
      <CardActions sx={{ width: '100%', justifyContent: 'space-around' }}>
        <Button
          variant="outlined"
          color="error"
          disabled={props.actionButtonDisabled}
          onClick={(e) => props.handleAction(e, props.invitation.uuid, 'reject')}
          sx={{ width: '5.25rem' }}
        >
          {t('Decline')}
        </Button>
        <Button
          variant="outlined"
          color="success"
          disabled={props.actionButtonDisabled}
          onClick={(e) => props.handleAction(e, props.invitation.uuid, 'accept')}
          sx={{ width: '5.25rem' }}
        >
          {t('Accept')}
        </Button>
      </CardActions>
    </Card >
  );
};