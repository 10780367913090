import { createSlice } from '@reduxjs/toolkit';

import { EMPTY_FEATURE } from '../../consts/map';

export const confirmCancelEventDialogSlice = createSlice({
  name: 'confirmCancelEventDialog',
  initialState: {
    open: false,
    event: EMPTY_FEATURE,
  },
  reducers: {
    resetCancelEventDialogEvent: (state) => {
      state.event = EMPTY_FEATURE;
    },
    setCancelEventDialogEvent: (state, action) => {
      state.event = action.payload;
    },
    setCancelEventDialogOpen: (state, action) => {
      state.open = action.payload;
    },
  }
})

export const {
  resetCancelEventDialogEvent,
  setCancelEventDialogEvent,
  setCancelEventDialogOpen,
} = confirmCancelEventDialogSlice.actions;

export default confirmCancelEventDialogSlice.reducer;
