import { createSlice } from '@reduxjs/toolkit';

export const mapSlice = createSlice({
  name: 'map',
  initialState: {
    directionsControl: null,
    geolocateControl: null,
    geolocationPermissionStatus: undefined,
    isDragged: false,
    locationAllowed: undefined,
    navigating: false,
    showDirectionsControl: false,
    value: null,
  },
  reducers: {
    setDirectionsControl: (state, action) => {
      state.directionsControl = action.payload;
    },
    setGeolocateControl: (state, action) => {
      state.geolocateControl = action.payload;
    },
    setGeolocationPermissionStatus: (state, action) => {
      state.geolocationPermissionStatus = action.payload;
    },
    setIsDragged: (state, action) => {
      state.isDragged = action.payload;
    },
    setLocationAllowed: (state, action) => {
      state.locationAllowed = action.payload;
    },
    setMap: (state, action) => {
      state.value = action.payload;
    },
    setNavigating: (state, action) => {
      state.navigating = action.payload;
    },
    setShowDirectionsControl: (state, action) => {
      state.showDirectionsControl = action.payload;
    },
  },
})

export const {
  setDirectionsControl,
  setGeolocateControl,
  setGeolocationPermissionStatus,
  setIsDragged,
  setLocationAllowed,
  setMap,
  setNavigating,
  setShowDirectionsControl,
} = mapSlice.actions;

export default mapSlice.reducer;
