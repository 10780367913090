import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

export const TabPanel = (props) => {
  const { children, value, parentName, index, refProp, ...other } = props;

  return (
    <div
      style={{ textAlign: 'center', marginTop: 0, width: '100%' }}
      role="tabpanel"
      hidden={value !== index}
      id={`${parentName}-tabpanel-${index}`}
      aria-labelledby={`${parentName}-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }} ref={refProp}>
          {children}
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  parentName: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  refProp: PropTypes.object,
};

export default TabPanel;

export const a11yProps = (parentName, index) => {
  return {
    id: `${parentName}-tab-${index}`,
    'aria-controls': `${parentName}-tabpanel-${index}`,
  };
};