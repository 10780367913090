import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Link from '@mui/material/Link';

import CloseButton from './CloseButton.tsx';
import PaperComponent from '../PaperComponent';

import { setWebGLDialogOpen } from '../../features/app/dialogsSlice';

import {
  APP_NAME,
  CONTACT_EMAIL,
} from '../../config';
import { borderedDialogPaperProps } from '../../theme';

const WEBGL_LINK = 'https://get.webgl.org/webgl2/enable.html';
const HARDWARE_ACCELERATION_LINK = 'https://support.google.com/chrome/thread/274266347?hl=en&msgid=274267394';

const WebGLDialog: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const open = useSelector((state: any) => state.dialogs.webGLDialogOpen);

  const handleClose = () => {
    dispatch(setWebGLDialogOpen(false));
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperComponent={PaperComponent}
      hideBackdrop={false}
      {...borderedDialogPaperProps}
      fullWidth
    >
      <DialogTitle textAlign="center">
        {t('WebGL2 required')}
      </DialogTitle>
      <CloseButton onClick={handleClose} />
      <DialogContent>
        <DialogContentText variant="body2">
          {t('{{appName}} requires WebGL2 to function properly.', { appName: APP_NAME })}
        </DialogContentText>
        <br />
        <DialogContentText variant="body2">
          {t('It seems that WebGL2 is not available or cannot be initialized in your browser.')}
        </DialogContentText>
        <br />
        <DialogContentText variant="caption">
          <Link
            href={WEBGL_LINK}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('Click here for instructions on how to enable WebGL2 in your browser.')}
          </Link>
        </DialogContentText>
        <br />
        <DialogContentText variant="body2">
          {t('If you are using Chrome browser, ensure that hardware acceleration is enabled.')}
        </DialogContentText>
        <br />
        <DialogContentText variant="caption">
          <Link
            href={HARDWARE_ACCELERATION_LINK}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('Click here for instructions on how to enable hardware acceleration.')}
          </Link>
        </DialogContentText>
        <br />
        <DialogContentText variant="body2">
          {t('After that restart the app or reload our website.')}
        </DialogContentText>
        <br />
        <DialogContentText variant="caption">
          {t('In case of issues, please feel free to contact our support team \
using')} <Link href="/contact/">{t('contact form')}</Link> {t('or contact \
email')} <Link href={`mailto:${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</Link>.
        </DialogContentText>
      </DialogContent>
    </Dialog>
  )
};

export default WebGLDialog;