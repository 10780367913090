import { createSlice } from '@reduxjs/toolkit';

import { EMPTY_FEATURE } from '../../consts/map';

export const confirmRemoveFriendDialogSlice = createSlice({
  name: 'confirmRemoveFriendDialog',
  initialState: {
    open: false,
    user: EMPTY_FEATURE,
  },
  reducers: {
    resetConfirmRemoveFriendDialogUser: (state) => {
      state.user = EMPTY_FEATURE;
    },
    setConfirmRemoveFriendDialogUser: (state, action) => {
      state.user = action.payload
    },
    setConfirmRemoveFriendDialogOpen: (state, action) => {
      state.open = action.payload
    }
  }
})

export const {
  resetConfirmRemoveFriendDialogUser,
  setConfirmRemoveFriendDialogUser,
  setConfirmRemoveFriendDialogOpen,
} = confirmRemoveFriendDialogSlice.actions;

export default confirmRemoveFriendDialogSlice.reducer;
