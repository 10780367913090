import React from 'react';
import { styled } from '@mui/material/styles';

import List from '@mui/material/List';

import EventCardSkeleton from './EventCardSkeleton.tsx';
import EventCardWrapper from './EventCardWrapper.tsx';

import { arrayOfInts } from '../../utils/utils';

const ELEMENTS = 5;

const StyledList = styled(List)`
  width: 100%;
` as typeof List;

const EventCardSkeletonList: React.FC = () => {
  return (
    <StyledList>
      {arrayOfInts(ELEMENTS).map((_, index) => (
        <EventCardWrapper key={index}>
          <EventCardSkeleton />
        </EventCardWrapper>
      ))}
    </StyledList>
  );
};

export default EventCardSkeletonList;