import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import axios from 'axios';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import RedoIcon from '@mui/icons-material/Redo';
import WrongLocationIcon from '@mui/icons-material/WrongLocation';

import {
  setCancelEventDialogEvent,
  setCancelEventDialogOpen,
} from '../../features/dialogs/confirmCancelEventDialogSlice.ts';
import {
  setConfirmDeleteEventDialogEvent,
  setConfirmDeleteEventDialogOpen,
} from '../../features/dialogs/confirmDeleteEventDialogSlice';
import {
  setEndEventDialogEvent,
  setEndEventDialogOpen,
} from '../../features/dialogs/confirmEndEventDialogSlice';
import {
  setEditEventDialogEvent,
  setEditEventDialogOpen,
} from '../../features/dialogs/editEventDialogSlice';
import {
  setNewEventDialogEvent,
  setNewEventDialogOpen,
  setNewEventProperty,
} from '../../features/dialogs/newEventDialogSlice.ts';
import {
  setReportDialogFeature,
  setReportDialogOpen,
  setReportDialogSubject,
} from '../../features/dialogs/reportDialogSlice';

import { blobToBase64 } from '../../utils/utils';

import ReportButton from '../ui/ReportButton';
import RoundMenu from '../ui/RoundMenu.tsx';

export default function EventMenu(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditClick = () => {
    dispatch(setEditEventDialogEvent(props.feature));
    dispatch(setEditEventDialogOpen(true));
    setAnchorEl(null);
  };

  const handleEndClick = () => {
    dispatch(setEndEventDialogEvent(props.feature));
    dispatch(setEndEventDialogOpen(true));
    setAnchorEl(null);
  };

  const handleCancelClick = () => {
    dispatch(setCancelEventDialogEvent(props.feature));
    dispatch(setCancelEventDialogOpen(true));
    setAnchorEl(null);
  };

  const handleRedoClick = async () => {
    setAnchorEl(null);
    dispatch(setNewEventDialogEvent(props.feature));
    if (props.feature.properties.picture) {
      const response = await axios.get(props.feature.properties.picture, { responseType: 'blob' });
      dispatch(setNewEventProperty({ key: 'picture', value: await blobToBase64(response.data) }));
    };
    dispatch(setNewEventDialogOpen(true));
  };

  const handleDeleteClick = () => {
    dispatch(setConfirmDeleteEventDialogEvent(props.feature));
    dispatch(setConfirmDeleteEventDialogOpen(true));
    setAnchorEl(null);
  };

  const handleReportClick = () => {
    dispatch(setReportDialogSubject('event'));
    dispatch(setReportDialogFeature(props.feature));
    dispatch(setReportDialogOpen(true));
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        sx={{ marginLeft: 'auto' }}
      >
        <MoreVertIcon />
      </IconButton>
      <RoundMenu
        anchorEl={anchorEl}
        keepMounted
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
      >
        {props.edit && (
          <MenuItem onClick={handleEditClick}>
            <IconButton
              size="large"
              color="inherit"
              sx={{
                "&:hover": {
                  backgroundColor: 'transparent'
                }
              }}>
              <EditIcon />
            </IconButton>
            {t('Edit')}
          </MenuItem>
        )}
        {props.end && (
          <MenuItem onClick={handleEndClick}>
            <IconButton
              size="large"
              color="inherit"
              sx={{
                "&:hover": {
                  backgroundColor: 'transparent'
                }
              }}>
              <EventBusyIcon />
            </IconButton>
            {t('End')}
          </MenuItem>
        )}
        {props.cancel && (
          <MenuItem onClick={handleCancelClick}>
            <IconButton
              size="large"
              color="inherit"
              sx={{
                "&:hover": {
                  backgroundColor: 'transparent'
                }
              }}>
              <WrongLocationIcon />
            </IconButton>
            {t('Cancel')}
          </MenuItem>
        )}
        {props.redo && (
          <MenuItem onClick={handleRedoClick}>
            <IconButton
              size="large"
              color="inherit"
              sx={{
                "&:hover": {
                  backgroundColor: 'transparent'
                }
              }}>
              <RedoIcon />
            </IconButton>
            {t('Redo')}
          </MenuItem>
        )}
        {props.delete && (
          <MenuItem onClick={handleDeleteClick}>
            <IconButton
              size="large"
              color="inherit"
              sx={{
                "&:hover": {
                  backgroundColor: 'transparent'
                }
              }}>
              <DeleteIcon />
            </IconButton>
            {t('Delete')}
          </MenuItem>
        )}
        {props.report && (
          <MenuItem onClick={handleReportClick} disabled={props.feature.properties.reported}>
            <ReportButton feature={props.feature} subject="event" />
            {t('Report')}
          </MenuItem>
        )}
      </RoundMenu>
    </React.Fragment>
  )
};