import dayjs from 'dayjs';

import { OFFSET_HOURS } from './pins.ts';
import {
  mainColor,
  roadGray,
} from '../theme';

export const DEFAULT_ZOOM = 15;
export const MAX_ZOOM = 19;
export const MIN_ZOOM = 1;

export const NOW = dayjs();

export const EMPTY_FEATURE = {
  type: 'Feature',
  properties: {},
  geometry: {
    coordinates: []
  }
};
export const EMPTY_GEOJSON = {
  type: 'FeatureCollection',
  features: []
};
export const EMPTY_EVENT = {
  type: 'Feature',
  geometry: {
    coordinates: [0, 0],
  },
  properties: {
    name: '',
    description: '',
    picture: '',
    categories: [],
    anonymous: false,
    private: false,
    othersCanInvite: true,
    startDatetime: NOW,
    startTimestamp: NOW.unix(),
    endDatetime: NOW.add(OFFSET_HOURS, 'hour'),
    endTimestamp: NOW.add(OFFSET_HOURS, 'hour').unix(),
    address: '',
  }
}

export const GEOCODING_API = 'https://api.mapbox.com/geocoding/v5';
export const GOOGLE_MAPS_URL = 'http://maps.google.com/maps';

export const CIRCLE_LAYERS = ['circle', 'circle-fill'];
export const EVENTS_LAYERS = ['events', 'userEvents', 'savedEvents'];
export const PINS_LAYERS = ['users', 'friends', 'events', 'userEvents', 'savedEvents'];
export const USERS_LAYERS = ['users', 'friends'];

export const MAP_LIGHT_STYLE_URL = '';
export const MAP_DARK_STYLE_URL = 'mapbox://styles/roundme/clmnbheno01vm01pj7xly6mzu/draft?fresh=true';

export const MARKER_HEIGHT = 50;

export const EVENT_MARKERS = [
  { url: 'ongoing-event-marker.png', id: 'ongoing-event-marker' },
  { url: 'ongoing-friend-event-marker.png', id: 'ongoing-friend-event-marker' },
];
export const USER_EVENT_MARKERS = [
  { url: 'user-ongoing-event-marker.png', id: 'user-ongoing-event-marker' },
  { url: 'user-upcoming-event-marker.png', id: 'user-upcoming-event-marker' },
];
export const SAVED_EVENT_MARKERS = [
  { url: 'saved-ongoing-event-marker.png', id: 'saved-ongoing-event-marker' },
  { url: 'saved-ongoing-friend-event-marker.png', id: 'saved-ongoing-friend-event-marker' },
  { url: 'saved-upcoming-event-marker.png', id: 'saved-upcoming-event-marker' },
  { url: 'saved-upcoming-friend-event-marker.png', id: 'saved-upcoming-friend-event-marker' },
];

export const NAVIGATION_MARKERS = [
  { url: 'navigation-start-marker.png', id: 'navigation-start-marker' },
  { url: 'navigation-end-marker.png', id: 'navigation-end-marker' },
];
export const NAVIGATION_OFFSET = 50;
export const NAVIGATION_PITCH = 60;

// https://github.com/mapbox/mapbox-gl-directions/blob/main/src/directions_style.js
export const DIRECTIONS_CONTROL_STYLE_OVERRIDE = [
  {
    'id': 'directions-route-line-casing',
    'type': 'line',
    'source': 'directions',
    'layout': {
      'line-cap': 'round',
      'line-join': 'round'
    },
    'paint': {
      'line-color': mainColor,
      'line-width': 12
    },
    'filter': [
      'all',
      ['in', '$type', 'LineString'],
      ['in', 'route', 'selected']
    ]
  }, {
    'id': 'directions-route-line',
    'type': 'line',
    'source': 'directions',
    'layout': {
      'line-cap': 'butt',
      'line-join': 'round'
    },
    'paint': {
      'line-color': {
        'property': 'congestion',
        'type': 'categorical',
        'default': roadGray,
        'stops': [
          ['unknown', roadGray],
          ['low', roadGray],
          ['moderate', '#f09a46'],
          ['heavy', '#e34341'],
          ['severe', '#8b2342']
        ]
      },
      'line-width': 7
    },
    'filter': [
      'all',
      ['in', '$type', 'LineString'],
      ['in', 'route', 'selected']
    ]
  }, {
    'id': 'directions-origin-point',
    'type': 'symbol',
    'source': 'directions',
    'layout': {
      'icon-image': 'navigation-start-marker',
      'icon-size': 0.25,
      'text-field': '',
      'icon-allow-overlap': true
    },
    'filter': [
      'all',
      ['in', '$type', 'Point'],
      ['in', 'marker-symbol', 'A']
    ]
  }, {
    'id': 'directions-origin-label',
    'type': 'symbol',
    'source': 'directions',
    'layout': {
      'text-field': 'A'
    },
    'paint': {
      'text-color': 'transparent'
    },
    'filter': [
      'all',
      ['in', '$type', 'Point'],
      ['in', 'marker-symbol', 'A']
    ]
  }, {
    'id': 'directions-destination-point',
    'type': 'symbol',
    'source': 'directions',
    'layout': {
      'icon-image': 'navigation-end-marker',
      'icon-size': 0.25,
      'text-field': '',
      'icon-allow-overlap': true
    },
    'filter': [
      'all',
      ['in', '$type', 'Point'],
      ['in', 'marker-symbol', 'B']
    ]
  }, {
    'id': 'directions-destination-label',
    'type': 'symbol',
    'source': 'directions',
    'layout': {
      'text-field': 'B'
    },
    'paint': {
      'text-color': 'transparent'
    },
    'filter': [
      'all',
      ['in', '$type', 'Point'],
      ['in', 'marker-symbol', 'B']
    ]
  }
];

export const RANGES = [100, 500, 1000];
export const PREMIUM_RANGE = 2000;