export const FIRST_NAME_MAX_LENGTH: number = 15;
export const LAST_NAME_MAX_LENGTH: number = 15;
export const DISPLAY_NAME_MAX_LENGTH: number = 15;

export const EMAIL_MAX_LENGTH: number = 320;

export const EVENT_NAME_MAX_LENGTH: number = 30;
export const EVENT_DESCRIPTION_MAX_LENGTH: number = 400;

export const CONTACT_MESSAGE_MAX_LENGTH: number = 5000;
export const INVITATION_MESSAGE_MAX_LENGTH: number = 200;

export const MAX_PIN_INVITEES: number = 10;

export const FREE_MAX_DAYS_PIN_DURATION: number = 1;
export const FREE_MAX_DAYS_FROM_NOW: number = 3;
export const PREMIUM_MAX_DAYS_PIN_DURATION: number = 7;
export const PREMIUM_MAX_DAYS_FROM_NOW: number = 7;