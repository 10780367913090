import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import AuthContext from '../context/AuthContext';
import CompanyAccountInfoDialog from '../components/ui/CompanyAccountInfoDialog.tsx';
import Layout from '../components/ui/Layout.tsx';
import MapContainer from '../components/map/MapContainer';
import PremiumInfoDialog from '../components/ui/PremiumInfoDialog.tsx';
import SavedEventsDialog from '../components/events/SavedEventsDialog.tsx';
import UserEventsDialog from '../components/events/UserEventsDialog.tsx';
import WebGLDialog from '../components/ui/WebGLDialog.tsx';

import { getEventCategories } from '../utils/eventsUtils';
import { patchNotification } from '../utils/utils';

import {
  setAlertsSnackbarAutoHideDuration,
  setAlertsSnackbarOpen,
  setAlertsSnackbarSeverity,
  setAlertsSnackbarText,
} from '../features/app/alertsSnackbarSlice';
import {
  setBannedPinDialogOpen,
  setEventInvitationsDialogOpen,
  setNetworkDialogOpen,
  setNetworkDialogTab,
  setSavedEventsDialogOpen,
} from '../features/app/dialogsSlice';
import { setAuthDialogOpen } from '../features/dialogs/authDialogSlice.ts';

const Home: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);

  const [searchParams, setSearchParams] = useSearchParams();
  const keepScreenOn = useSelector((state: any) => state.settings.keepScreenOn);
  const language = useSelector((state: any) => state.settings.language);

  useEffect(() => {
    localStorage.setItem('help-expanded', 'false');
  }, []);

  useEffect(() => {
    getEventCategories(dispatch);
  }, [language]);

  useEffect(() => {
    const notificationUuid = searchParams.get('notification');
    const view = searchParams.get('view');
    const action = searchParams.get('action');

    if (user && notificationUuid) {
      patchNotification(dispatch, notificationUuid);
      if (!view && !action) {
        setSearchParams([]);
      };
    };

    if (view) {
      if (user) {
        switch (view) {
          case 'auth':
            if (!user) {
              dispatch(setAuthDialogOpen(true));
            } else {
              setSearchParams([]);
            };
            break;
          case 'eventBanned':
            dispatch(setBannedPinDialogOpen(true));
            break;
          case 'invitations':
            dispatch(setEventInvitationsDialogOpen(true));
            break;
          case 'network':
            dispatch(setNetworkDialogOpen(true));
            break;
          case 'saved':
            dispatch(setSavedEventsDialogOpen(true));
            break;
          default:
            break
        };
      } else {
        dispatch(setAuthDialogOpen(true));
      };
    };

    if (action) {
      if (user) {
        switch (action) {
          case 'invite-friend':
            const uuid = searchParams.get('uuid');
            if (uuid) {
              dispatch(setNetworkDialogOpen(true));
              dispatch(setNetworkDialogTab(1));
              dispatch(setAlertsSnackbarAutoHideDuration(3000));
              dispatch(setAlertsSnackbarSeverity('info'));
              dispatch(setAlertsSnackbarText(t('Use the scanner in our app')));
              dispatch(setAlertsSnackbarOpen(true));
            } else {
              setSearchParams([]);
            };
        };
      } else {
        dispatch(setAuthDialogOpen(true));
      };
    };
  }, [user]);

  // useEffect(() => {
  //   const handleTouch = (e) => {
  //     dispatch(setIsDragged(e.touches.length >= 2))
  //   };

  //   ['touchstart', 'touchend'].forEach(event => {
  //     document.getElementById('root').addEventListener(event, handleTouch);
  //   });

  //   return () => {
  //     ['touchstart', 'touchend'].forEach(event => {
  //       document.getElementById('root').removeEventListener(event, handleTouch);
  //     });
  //   };
  // }, []);

  useEffect(() => {
    if (!(user || localStorage.getItem('alwaysShowIntro'))) {
      localStorage.setItem('alwaysShowIntro', 'false');
    };
  }, [keepScreenOn]);

  return (
    <>
      <Layout bottomBar>
        <MapContainer />
        <WebGLDialog />
      </Layout>
      {user && (
        <>
          <CompanyAccountInfoDialog />
          <PremiumInfoDialog />
          <SavedEventsDialog />
          <UserEventsDialog />
        </>
      )}
    </>
  );
};

export default Home;