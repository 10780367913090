import React from 'react';

import MenuItem from '@mui/material/MenuItem';

import NotificationCardSkeleton from './NotificationCardSkeleton.tsx';

import { arrayOfInts } from '../../utils/utils';

const ELEMENTS = 3;

const NotificationCardSkeletonList: React.FC = () => {
  return (
    <>
      {arrayOfInts(ELEMENTS).map((_, index) => (
        <MenuItem key={index}>
          <NotificationCardSkeleton />
        </MenuItem>
      ))}
    </>
  );
};

export default NotificationCardSkeletonList;