import { useDispatch } from 'react-redux';

import GroupAddIcon from '@mui/icons-material/GroupAdd';
import IconButton from '@mui/material/IconButton';

import {
  setInviteFriendsDialogEvent,
  setInviteFriendsDialogOpen,
} from '../../features/dialogs/inviteFriendsDialogSlice';

export default function InviteFriendsButton(props) {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(setInviteFriendsDialogEvent(props.event));
    dispatch(setInviteFriendsDialogOpen(true));
  };

  return (
    <IconButton
      onClick={handleClick}
      aria-label="invite">
      <GroupAddIcon />
    </IconButton>
  );
};