import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Fab from '@mui/material/Fab';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import NavigationIcon from '@mui/icons-material/Navigation';
import Typography from '@mui/material/Typography';

import {
  backdropBlur,
  fabBoxShadow,
  navbarBoxShadow,
  WIDTH_BREAKPOINT,
} from '../../theme';
import {
  getDistance,
  formatDistance,
} from '../../utils/mapUtils';
import {
  countCharacters,
  substring,
} from '../../utils/utils';

import NoContentAlert from '../ui/NoContentAlert.tsx';

export default function EventsDrawer(props) {
  const { t } = useTranslation();

  const width = useSelector((state) => state.app.width);
  const fabSize = useSelector((state) => state.app.fabSize);

  const events = useSelector((state) => state.events.value);

  const userPosition = useSelector((state) => state.userPosition.value);
  const map = useSelector((state) => state.map.value);
  const isMapDragged = useSelector((state) => state.map.isDragged);

  const toggleDrawer = (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    };

    props.setOpen(!props.open);
  };

  const list = () => (
    <Box
      sx={{
        minWidth: width <= WIDTH_BREAKPOINT ? '40vw' : '20vw',
        maxWidth: '50vw',
      }}
      role="presentation"
      onClick={toggleDrawer}
      onKeyDown={toggleDrawer}
    >
      <List sx={{
        width: width <= WIDTH_BREAKPOINT ? '50vw' : '15vw',
      }}>
        {[...events.features].map(feature => {
          return {
            ...feature,
            properties: {
              ...feature.properties,
              distance: getDistance(feature.geometry.coordinates, userPosition),
            },
          };
        }).sort((a, b) => a.properties.distance - b.properties.distance).map((feature, index) => (
          <ListItem
            key={index}
            disablePadding
            onClick={(e) => {
              e.stopPropagation();
              if (map) {
                map.flyTo({
                  center: feature.geometry.coordinates,
                  zoom: 18,
                  // duration: 1000,
                });
              };
            }}
            sx={{
              width: width <= WIDTH_BREAKPOINT ? '50vw' : '15vw',
            }}>
            <ListItemButton>
              <ListItemIcon sx={{ minWidth: '35px' }}>
                <NavigationIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  width > WIDTH_BREAKPOINT ? feature.properties.name :
                    substring(feature.properties.name, 0, 5) + (countCharacters(feature.properties.name) > 5 ? '.' : '')
                }
                secondary={formatDistance(feature.properties.distance)}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (userPosition.length > 0 && (
    <React.Fragment key={'right'} >
      <Fab color="primary" aria-label="add" size={fabSize}
        onClick={toggleDrawer}
        sx={{
          zIndex: 10,
          backgroundColor: 'transparent',
          backdropFilter: backdropBlur,
          fontSize: '3rem',
          boxShadow: fabBoxShadow,
          pointerEvents: isMapDragged ? 'none' : 'auto',
          '&:hover': {
            backgroundColor: isMobile ? 'transparent' : 'rgba(255, 255, 255, 0.08)',
          },
        }}
      >
        {props.open ?
          <KeyboardDoubleArrowRightIcon fontSize="3rem" />
          : <KeyboardDoubleArrowLeftIcon fontSize="3rem" />}
      </Fab>
      <Drawer
        anchor="right"
        open={props.open}
        onClose={toggleDrawer}
        BackdropProps={{ invisible: true }}
        PaperProps={{
          style: {
            top: '20vh',
            maxHeight: '25vh',
            overflowX: 'hidden',
            width: width <= WIDTH_BREAKPOINT ? '50vw' : '15vw',
            display: 'inline-block',
            height: 'auto',
            borderRadius: '15px 0 0 15px',
            zIndex: 10,
            boxShadow: navbarBoxShadow,
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            backgroundImage: 'unset',
            backdropFilter: backdropBlur,
          }
        }}
        sx={{ position: 'unset' }}
      >
        <Box sx={{ backgroundColor: 'rgba(23, 23, 23, 0.6)' }}>
          {events.features.length > 0 ? (
            <React.Fragment>
              <Typography align="center" pt={1} variant="subtitle1">
                {t('Ongoing around')}
              </Typography>
              {list()}
            </React.Fragment>
          ) :
            <Box p={1}>
              <NoContentAlert
                text={t('There are no nearby ongoing pins')}
                direction="column"
                size="30px"
                spacing={1}
                variant="body2"
              />
            </Box>
          }
        </Box>
      </Drawer>
    </React.Fragment>
  ));
}