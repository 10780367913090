import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import {
  setAlertsSnackbarOpen,
  setAlertsSnackbarSeverity,
  setAlertsSnackbarText,
} from '../../features/app/alertsSnackbarSlice';

import { borderedDialogPaperProps } from '../../theme';

export default function ConnectionLostDialog() {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [showOfflineDialog, setShowOfflineDialog] = useState(false);

  useEffect(() => {
    const handleOnline = () => {
      setShowOfflineDialog(false);
      dispatch(setAlertsSnackbarSeverity('success'));
      dispatch(setAlertsSnackbarText(t('Online again!')));
      dispatch(setAlertsSnackbarOpen(true));
    };

    const handleOffline = () => {
      setShowOfflineDialog(true);
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return (
    <Dialog
      open={showOfflineDialog}
      {...borderedDialogPaperProps}
    >
      <DialogTitle textAlign="center">{t('Offline')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('It seems like you are currently offline. \
Please check your internet connection and try again.')}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}