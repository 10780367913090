import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';

import LanguageDetector from 'i18next-browser-languagedetector';

import enTranslation from './locales/en.json';
import plTranslation from './locales/pl.json';

import { I18N_DEBUG } from './config';

i18n
  // .use(Backend)
  // .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    detection: {
      order: ['localStorage', 'cookie', 'navigator'],
    },
    fallbackLng: 'en',
    debug: I18N_DEBUG,
    nsSeparator: false,
    keySeparator: false,
    // interpolation: {
    //   escapeValue: false,
    // },
    interpolation: {
      prefix: '{{',
      suffix: '}}'
    },
    resources: {
      en: {
        translation: enTranslation,
      },
      pl: {
        translation: plTranslation,
      },
    },
  });

export default i18n;