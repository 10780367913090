import React, { useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';

import axios from 'axios';

import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import SendIcon from '@mui/icons-material/Send';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import api from '../utils/api.ts';
import {
  API_URL,
  APP_NAME,
  CONTACT_EMAIL,
} from '../config';
import { CONTACT_MESSAGE_MAX_LENGTH } from '../limits.ts';

import AuthContext from "../context/AuthContext";

import BackButton from '../components/ui/BackButton.tsx';
import CenteredBox from '../components/ui/CenteredBox.tsx';
import Layout from '../components/ui/Layout.tsx';
import SmallLoader from '../components/ui/SmallLoader.tsx';

import {
  backdropBlur,
  DRAWER_WIDTH,
  fabBoxShadow,
  WIDTH_BREAKPOINT,
} from '../theme';
import { countCharacters } from '../utils/utils';

import {
  setAlertsSnackbarOpen,
  setAlertsSnackbarSeverity,
  setAlertsSnackbarText,
} from '../features/app/alertsSnackbarSlice';

const StyledButtonGroup = styled(ButtonGroup)`
  display: flex;
  justify-content: center;
`;

const StyledStack = styled(Stack)`
  justify-content: space-around;
  height: 4.5rem;
  padding: 1rem 1rem;
  background-color: transparent;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  backdrop-filter: ${backdropBlur};
  border-radius: 10px 10px 0 0;
  box-shadow: ${fabBoxShadow};
`;

interface MessageCategoryProps {
  id: string;
  name: string;
};

const Contact: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { user } = useContext(AuthContext);
  const language = useSelector((state: any) => state.settings.language);

  const width = useSelector((state: any) => state.app.width);

  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [messagesCategories, setMessagesCategories] = useState([]);
  const [selectedMessagesCategories, setSelectedMessagesCategories] = useState<MessageCategoryProps[]>([]);
  const [contactMessageError, setContactMessageError] = useState(false);

  const [loading, setLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const getMessagesCategories = async () => {
    setLoading(true);
    await api.get('/categories/contact-messages/').then(response => {
      setMessagesCategories(response.data);
      setLoading(false);
    }).catch(err => {
      setLoading(false);
    });
  };

  const handleMessageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(e.target.value);
    setContactMessageError(countCharacters(e.target.value) > CONTACT_MESSAGE_MAX_LENGTH);
  };

  const handleContactFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setButtonDisabled(true);
    const userMessage = e.target.message.value;
    var postData: any = {
      message: userMessage.trim(),
      categories: selectedMessagesCategories.map(c => c.id),
    };
    if (user) {
      await api.post('/contact-messages/new/', postData).then(response => {
        if (response.status === 200) {
          dispatch(setAlertsSnackbarSeverity('success'));
          dispatch(setAlertsSnackbarText(t('Thank you for contacting us!')));
          dispatch(setAlertsSnackbarOpen(true));
          setLoading(false);

          setTimeout(() => {
            setEmail('');
            setMessage('');
            setSelectedMessagesCategories([]);
            setButtonDisabled(false);
          }, 5000);
        }
      }).catch(err => {
        console.log('err', err);
        dispatch(setAlertsSnackbarSeverity('error'));
        dispatch(setAlertsSnackbarText(t('Oops, something went wrong. Please try again later.')));
        dispatch(setAlertsSnackbarOpen(true));
        setLoading(false);

        setTimeout(() => {
          setButtonDisabled(false);
        }, 2000);
      });
    } else {
      postData.email = email;
      await axios.post(`${API_URL}/contact-messages/new/`, postData).then(response => {
        if (response.status === 200) {
          dispatch(setAlertsSnackbarSeverity('success'));
          dispatch(setAlertsSnackbarText(t('Thank you for contacting us!')));
          dispatch(setAlertsSnackbarOpen(true));
          setLoading(false);

          setTimeout(() => {
            setEmail('');
            setMessage('');
            setSelectedMessagesCategories([]);
            setButtonDisabled(false);
          }, 2000);
        }
      }).catch(err => {
        console.log('err', err);
        dispatch(setAlertsSnackbarSeverity('error'));
        dispatch(setAlertsSnackbarText(t('Oops, something went wrong. Please try again later.')));
        dispatch(setAlertsSnackbarOpen(true));
        setLoading(false);

        setTimeout(() => {
          setButtonDisabled(false);
        }, 2000);
      });
    };
  };

  useEffect(() => {
    getMessagesCategories();
  }, [language])

  return (
    <Layout>
      <Box sx={{
        flexGrow: 1,
        paddingLeft: width > WIDTH_BREAKPOINT ? `${DRAWER_WIDTH + 16}px` : undefined,
        backgroundColor: 'unset',
        backgroundImage: 'linear-gradient(#171717, #191919)',
        height: '92vh',
      }}>
        <Grid container>
          <Grid item xs={2}>
            <CenteredBox>
              <BackButton onClick={() => window.location.href = '/'} />
            </CenteredBox>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h5" gutterBottom mt={2} align="center">
              {t('Contact us')}{loading && (<SmallLoader />)}
            </Typography>
          </Grid>
          <Grid item xs={2} />
        </Grid>
        <Grid container mt={2} justifyContent="center" sx={{
          paddingBottom: width <= WIDTH_BREAKPOINT / 2 ? '5rem' : undefined,
        }}>
          <Grid item xs={1} />
          <Grid item xs={10} sm={8} md={5}>
            <Typography variant="body2" align="justify"
              mt={1} mb={1}>
              {t('Thank you for using {{appName}}! If you have any questions, concerns or feedback, \
get in touch with us using the form below.', { appName: APP_NAME })}
            </Typography>
            <Typography variant="caption" align="justify"
              mt={1} mb={1}>
              {t('Alternatively you can use our contact email')} <Link href={`mailto:${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</Link>.
            </Typography>
            <form onSubmit={handleContactFormSubmit}>
              {!user && (
                <TextField
                  id="email"
                  required
                  // ref={myRef}
                  // ref={focusUsernameInputField}
                  // inputRef={focusNameInputField}
                  // ref={useRef("test")}
                  // autoFocus
                  // focused
                  margin="dense"
                  // id="username"
                  value={email}
                  onChange={(newValue) => {
                    setEmail(newValue.target.value);
                  }}
                  label={t('Email Address')}
                  type="email"
                  fullWidth
                  variant="standard"
                  autoComplete="on"
                  inputProps={{
                    // autoFocus: true,
                    maxLength: 64,
                  }}
                />
              )}
              <TextField
                id="message"
                label={t('Your message')}
                margin="dense"
                multiline
                fullWidth
                variant="standard"
                rows={8}
                required
                value={message}
                onChange={handleMessageChange}
                helperText={`${countCharacters(message)}/${CONTACT_MESSAGE_MAX_LENGTH} ` + t('characters')}
                error={contactMessageError}
              />
              <Autocomplete
                multiple
                noOptionsText={t('No options')}
                disableCloseOnSelect
                id="categories"
                options={messagesCategories}
                getOptionLabel={(option: MessageCategoryProps) => option.name}
                value={selectedMessagesCategories}
                onChange={(e, value) => setSelectedMessagesCategories(value)}
                // https://stackoverflow.com/questions/72044394/how-do-i-disable-textfield-input-with-mui-autocomplete/72045200
                renderInput={({ inputProps, ...rest }) => (
                  <TextField
                    {...rest}
                    variant="standard"
                    label={t('Subject')}
                    inputProps={{ ...inputProps, readOnly: true }}
                    placeholder=""
                    margin="normal"
                  />
                )}
              />
              {width <= WIDTH_BREAKPOINT / 2 ? (
                <StyledStack spacing={0} direction="row">
                  <Button
                    type="submit"
                    disabled={buttonDisabled || contactMessageError}
                    variant="contained"
                    endIcon={<SendIcon />}
                  >
                    {t('Send')}
                  </Button>
                </StyledStack>
              ) : (
                <Box mt={4}>
                  <StyledButtonGroup>
                    <Button
                      type="submit"
                      disabled={buttonDisabled || contactMessageError}
                      variant="contained"
                      endIcon={<SendIcon />}
                    >
                      {t('Send')}
                    </Button>
                  </StyledButtonGroup>
                </Box>
              )}
            </form>
          </Grid>
          <Grid item xs={1} />
        </Grid>
      </Box>
    </Layout>
  );
};

export default Contact;