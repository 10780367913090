import { createSlice } from '@reduxjs/toolkit';

import { EMPTY_EVENT } from '../../consts/map';

export const newEventDialogSlice = createSlice({
  name: 'newEventDialog',
  initialState: {
    open: false,
    event: EMPTY_EVENT,
  },
  reducers: {
    resetNewEventDialogEvent: (state) => {
      state.event = EMPTY_EVENT;
    },
    setNewEventCoordinates: (state, action) => {
      state.event.geometry.coordinates = action.payload;
    },
    setNewEventProperty: (state, action) => {
      state.event.properties[action.payload.key] = action.payload.value;
    },
    setNewEventDialogEvent: (state, action) => {
      state.event = action.payload
    },
    setNewEventDialogOpen: (state, action) => {
      state.open = action.payload
    }
  }
})

export const {
  resetNewEventDialogEvent,
  setNewEventCoordinates,
  setNewEventProperty,
  setNewEventDialogEvent,
  setNewEventDialogOpen,
} = newEventDialogSlice.actions;

export default newEventDialogSlice.reducer;
