import React from 'react';
import { styled } from '@mui/material/styles';

import CircularProgress from '@mui/material/CircularProgress';

const StyledCircularProgress = styled(CircularProgress)`
  // position: absolute;
  // margin: 0.5rem 0 0 0.5rem;
`;

const BigLoader: React.FC = () => {
  return <StyledCircularProgress color="inherit" size="5rem" />;
};

export default BigLoader;