import { createSlice } from '@reduxjs/toolkit'

export const accountSlice = createSlice({
  name: 'account',
  initialState: {
    uuid: '',
    plan: '',
    staff: false,
  },
  reducers: {
    resetAccount: (state) => {
      state.plan = '';
      state.staff = false;
      state.uuid = '';
    },
    setPlan: (state, action) => {
      state.plan = action.payload;
    },
    setStaff: (state, action) => {
      state.staff = action.payload;
    },
    setUuid: (state, action) => {
      state.uuid = action.payload;
    },
  },
})

export const {
  resetAccount,
  setPlan,
  setStaff,
  setUuid,
} = accountSlice.actions;

export default accountSlice.reducer;